import { withRouter, Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { useEffect, useState } from "react";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { reloadOrganizationActiveModuleSettingsFromServer } from 'src/store/modules/actions';
import { getCurrentLanguage } from 'src/i18n';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Label,
    List,
    Nav,
    NavLink,
    NavItem,
    TabPane,
    TabContent,
    Popover,
    PopoverHeader,
    PopoverBody,
    Spinner,
} from 'reactstrap';
import classnames from 'classnames';
import Dropzone from 'react-dropzone';
import Breadcrumbs from 'src/components/Common/Breadcrumb';
import { getActiveModuleInfos, isFeatureEnabledForActiveModule } from 'src/helpers/module_helper';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { 
    API_URL_UPDATE_MODULE_SETTINGS,
    API_URL_GET_MODULE_SETTINGS, 
    API_URL_FETCH_COUNTRIES 
} from 'src/modules/3rd-party-management/constants';
import axios from "axios";

const customFonts = [
    {
        style: 'normal',
        family: 'Inter',
        weight: 400,
    },
    {
        style: 'normal',
        family: 'Nunito',
        weight: 400,
    },
    {
        style: 'normal',
        family: 'Raleway',
        weight: 400,
    },
    {
        style: 'normal',
        family: 'Roboto Mono',
        weight: 400,
    },
    {
        style: 'normal',
        family: 'Noto Serif Toto',
        weight: 400,
    },
    {
        style: 'normal',
        family: 'Kumbh Sans',
        weight: 400,
    },
    {
        style: 'normal',
        family: 'Padyakke Expanded One',
        weight: 400,
    },
    {
        style: 'normal',
        family: 'Noto Sans Cherokee',
        weight: 400,
    },
];

const dropzoneMessages = {
    title: 'Please Note',
    secondaryLogo:
        'This logo is used for the views with colored menu bar. we recommend uploading a white logo with transparent background\nYou can upload image files in the following formats: .jpg, .jpeg, .png, .JPG, .JPEG, .PNG.',
    primaryLogo:
        'This logo is used for the login pages. we recommend uploading a colored logo\nYou can upload image files in the following formats: .jpg, .jpeg, .png, .JPG, .JPEG, .PNG.',
};

const Customization = ({
    t,
    token,
    organization,
    modules,

    reloadActiveModuleSettings
}) => {
    const [ activeTab, setActiveTab ] = useState('organization');

    const [ primaryLogos, setPrimaryLogos ] = useState([]);
    const [ primaryLogoNotePopover, setPrimaryLogoNotePopover] = useState(false)

    const [ secondaryLogos, setSecondaryLogos ] = useState([]);
    const [ secondaryLogoNotePopover, setSecondaryLogoNotePopover] = useState(false)

    const [ displayOtherTranslations, setDisplayOtherTranslations ] = useState(false)
    const [ activeModuleTranslations, setActiveModuleTranslations ] = useState({})

    const {
        data        :   organizationCurrentData,
        isFetching  :   organizationDataIsFetching   
    } = useQuery({
        queryKey: ["fetch-organization-customization-settings-query"],
        queryFn: async () => {
            const {
                data    :   {
                    data  
                }
            } = await axios.post(
                API_URL_GET_MODULE_SETTINGS,
                {
                    id  :   organization.id,
                    url :   decodeURI(organization.url),
                },
                {
                    headers : {
                        Authorization: `Bearer ${token}`
                    }
                }
            )

            return data || {}
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (error) => {
            toast(t("An error occurred while fetching organization data."), {
                type: "error"
            });
        },
	});

    const {
        data        :   locations,
        isFetching  :   locationsAreFetching   
    } = useQuery({
        queryKey: ["fetch-locations-query"],
        queryFn: async () => {
            const {
                data : {
                    data
                }
            } = await axios.post(
                API_URL_FETCH_COUNTRIES,
                {
                    headers : {
                        Authorization: `Bearer ${token}`
                    }
                }
            )

            return data || []
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (error) => {
            toast(t("An error occurred while locations list."), {
                type: "error"
            });
        },
	});

    const {
        mutate      :   updateSettingsMutation,
        isLoading   :   updateSettingsMutationInProcess   
    } = useMutation({
        mutationFn: async (payload) => {
            const response = await axios.post(API_URL_UPDATE_MODULE_SETTINGS, payload,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                    )}`,
                  },
                }
            );
      
            return response.data?.data;
        },
        onSuccess: () => {
            reloadActiveModuleSettings();

            toast(t("Data was saved successfully"), {
                type: "success"
            });
        },
        onError: (error) => {
            if (error.response) {
                const data = error.response.data;
                toast(t(data.message || data.title), {
                    type: "error"
                });
            }
        }
    });

    const sortLocationsByLanguage = (language, locations) => {
		if (locations && Array.isArray(locations)) {	
            const collator = new Intl.Collator(language, {
                sensitivity: "base",
            });
            return locations.sort((a, b) =>
                collator.compare(t(a.name), t(b.name))
            );
		} 
        else {
		  return [];
		}
	}

    const formatBytes = (bytes, decimals = 2) => {
		if (bytes === 0) return '0 Bytes';
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	};

    const handleSubmit = (e, values) => {
		const formData = new FormData();

		formData.append('name', values.name);
		formData.append('setting[fontFamily]', values.primaryFont || '');

		formData.append('setting[fontColor]', values.fontColor || '');
		formData.append('setting[primaryColor]', values.primaryColor || '');

		formData.append('setting[primaryLogoSize]', values.primaryLogoSize || '');
		formData.append('setting[secondaryLogoSize]', values.secondaryLogoSize || '');
		
		formData.append('country', values.country|| '');
		formData.append('city', values.city || '');
		formData.append('abbreviation', values.abbreviation || '');

		if (primaryLogos.length > 0 && !primaryLogos[0].prev) {
			formData.append('setting[primaryLogo]', primaryLogos[0]);
		}

		if (secondaryLogos.length > 0 && !secondaryLogos[0].prev) {
			formData.append('setting[secondaryLogo]', secondaryLogos[0]);
		}

        Object.keys(activeModuleTranslations).map((lngCode, index) => {
            const trans = activeModuleTranslations[lngCode];

            formData.append(`translations[${index + 100}][type]`, 'title');
			formData.append(`translations[${index + 100}][lang]`, trans.langCode);
			formData.append(`translations[${index + 100}][text]`, values.pageTitles[trans.langCode]);
			formData.append(`translations[${index + 100}][module]`, trans.moduleId);

            formData.append(`translations[${index}][type]`, 'intro');
			formData.append(`translations[${index}][lang]`, trans.langCode);
			formData.append(`translations[${index}][text]`, draftToHtml(convertToRaw(trans.text.getCurrentContent())));
			formData.append(`translations[${index}][module]`, trans.moduleId);
        })

        updateSettingsMutation(formData);
	}

    useEffect(() => {
        if(organizationCurrentData){
            const {
                translations,
                languages
            } = organizationCurrentData;

            const result = {};

            (languages || []).forEach((lng) => {
                const langTrans = translations.find((trans) => {
                    return trans.lang === lng.language_code && trans.module === modules.active
                })

                result[lng.language_code] = {
                    isDefault   :   lng.is_default,
                    title       :   langTrans?.title,
                    text        :    EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(langTrans?.intro).contentBlocks)),
                    langCode    :   lng.language_code,
                    langName    :   lng.name,
                    module      :   langTrans.module,
                    moduleId    :   langTrans.moduleId
                }
            })

            setActiveModuleTranslations(result)
        }
    }, [organizationCurrentData])

    let defaultLangTranslation = null;
    const otherLangTranslations = [];

    Object.keys(activeModuleTranslations).forEach((langCode) => {
        if(activeModuleTranslations[langCode].isDefault){
            defaultLangTranslation = activeModuleTranslations[langCode];
        }
        else{
            otherLangTranslations.push(activeModuleTranslations[langCode]);
        }
    })

    return (
        <div className='page-content'>
            <Container fluid>
                <Breadcrumbs title={t('Customisation')} breadcrumbItems={[
                    { title: 'SIP', link: '/' },
                    { title: t('Customisation'), link: '#' }
                ]} />
                <Row>
                    <Col xl='12'>
                        {organizationDataIsFetching ? (
                            <>{t('Loading')}...</>
                        ) : (
                            <AvForm className='needs-validation' onValidSubmit={handleSubmit}>
                                <Card>
                                    <CardBody>
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: 'pointer' }}
                                                    className={classnames({
                                                        active  : activeTab === 'organization'
                                                    })}
                                                    onClick={() => {
                                                        setActiveTab('organization')
                                                    }}
                                                >
                                                    <span className='d-block'>{`${t('Organization')}`}</span>
                                                </NavLink>
                                                {isFeatureEnabledForActiveModule('styling') && (
                                                    <NavItem>
                                                        <NavLink
                                                            style={{ cursor: 'pointer' }}
                                                            className={classnames({
                                                                active: activeTab === 'styling',
                                                            })}
                                                            onClick={() => {
                                                                setActiveTab('styling')
                                                            }}
                                                        >
                                                            <span className='d-block'>{`${t('Styling')}`}</span>
                                                        </NavLink>
                                                    </NavItem>
                                                )}
                                            </NavItem>
                                        </Nav>

                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId='organization' className='pt-4 pb-4'>
                                                <div>
                                                    <div className='mb-3'>
                                                        <CardTitle>
                                                            {t('Customise Smart Integrity Platform - Reporting Channel Landing Page')}
                                                        </CardTitle>
                                                    </div>
                                                    <Row>
                                                        {locations && locations?.length > 0 && <Col lg={4}>
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="country">
                                                                    {t("Country")}
                                                                </Label>
                                                                <AvField id="country"
                                                                    type="select"
                                                                    name="country"
                                                                    errorMessage={t(
                                                                        "This field cannot be blank"
                                                                    )}
                                                                    className="form-control"
                                                                    defaultValue={ organizationCurrentData?.country }
                                                                >
                                                                {sortLocationsByLanguage(
                                                                    getCurrentLanguage(),
                                                                    locations
                                                                ).map((location) => {
                                                                    return (
                                                                        <option key={location.id} value={location.id} hidden={!location?.name}>
                                                                            {t(location.name)}
                                                                        </option>
                                                                    );
                                                                })}
                                                                </AvField>
                                                            </div>
                                                        </Col>}

                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <Label className="form-label"
                                                                    htmlFor="city">
                                                                    {t("City")}
                                                                </Label>
                                                                <AvField id="city" type="text" name="city"
                                                                    errorMessage={t(
                                                                        "This field cannot be blank"
                                                                    )}
                                                                    className="form-control"
                                                                    defaultValue={organizationCurrentData?.city}
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <Label className="form-label"  htmlFor="abbreviation">
                                                                    {t("Abbreviation")}
                                                                </Label>
                                                                <AvField
                                                                    id="abbreviation"
                                                                    type="text"
                                                                    name="abbreviation"
                                                                    errorMessage={t("This field cannot be blank")}
                                                                    className="form-control"
                                                                    defaultValue={organizationCurrentData?.abbreviation}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    {isFeatureEnabledForActiveModule('name') && (
                                                        <div className='mb-4'>
                                                            <Row>
                                                                <Col md='12'>
                                                                    <div className='mb-3'>
                                                                        <Row className='align-items-end'>
                                                                            <Col md='12'>
                                                                                <div>
                                                                                    <Label className='form-label' htmlFor='name'>
                                                                                        {t('Name')}
                                                                                    </Label>
                                                                                    <AvField
                                                                                        name='name'
                                                                                        type='text'
                                                                                        errorMessage={t('This field cannot be blank')}
                                                                                        className='form-control'
                                                                                        validate={{
                                                                                            required: { value: true },
                                                                                        }}
                                                                                        value={organizationCurrentData?.name}
                                                                                        id='name'
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                    {isFeatureEnabledForActiveModule('customization-custom-fonts') && (
                                                        <div className='mb-5'>
                                                            <hr className='pb-3' />
                                                            <Row>
                                                                <Col xs='12'>
                                                                    <Label className='form-label'>
                                                                        {t('Font Family')}:
                                                                    </Label>
                                                                    <AvField name='primaryFont' type='select' className='form-control' value={organizationCurrentData?.font_family}>
                                                                        {customFonts.map((item, index) => (
                                                                            <option style={{fontFamily: item.family}} key={`option-font-family-${index}`} value={item.family}>
                                                                                {`${t(item.family)}`}
                                                                            </option>
                                                                        ))}
                                                                    </AvField>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                    {isFeatureEnabledForActiveModule('customization-custom-colors') && (
                                                        <div className='mb-5'>
                                                            <hr className='pb-3' />
                                                            <Row>
                                                                <Col sm='6'>
                                                                    <Label className='form-label'>{t('Primary color')}:</Label>
                                                                    <AvField
                                                                        name='primaryColor'
                                                                        type='color'
                                                                        defaultValue={organizationCurrentData?.primary_color || '#5664d2'}
                                                                        className='form-control form-control-color mw-100'
                                                                        style={{ width: '100%' }}
                                                                    />
                                                                </Col>
                                                                <Col sm='6'>
                                                                    <Label className='form-label'>
                                                                        {t('Font color')} ({t('This is the font color of your side/page menu bar')}
                                                                        ):
                                                                    </Label>
                                                                    <AvField
                                                                        name='fontColor'
                                                                        type='color'
                                                                        defaultValue={organizationCurrentData?.font_color || '#ffffff'}
                                                                        className='form-control form-control-color mw-100'
                                                                        style={{ width: '100%' }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                    {isFeatureEnabledForActiveModule('logo') && (
                                                        <div className='mb-3'>
                                                            <hr className='pb-3' />
                                                            <Row>
                                                                <Col lg={6} className='mb-3'>
                                                                    <Label className='form-label'>
                                                                        {t('Colored Logo')}
                                                                    </Label>
                                                                    <div style={{ position: 'relative' }}>
                                                                        <Dropzone onDrop={(files) => {
                                                                            const result = files.map((file) => {
                                                                                return {
                                                                                    ...file,
                                                                                    preview         : URL.createObjectURL(file),
                                                                                    formattedSize   : formatBytes(file.size)
                                                                                }
                                                                            });

                                                                            setPrimaryLogos(result);
                                                                        }}>
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <div className='dropzone'>
                                                                                    <div className='dz-message needsclick' {...getRootProps()}>
                                                                                        <input {...getInputProps()} />
                                                                                        <div className='mb-3'>
                                                                                            <i className='display-4 text-muted ri-upload-cloud-2-line'></i>
                                                                                        </div>
                                                                                        <h5>{t('Drop files here or click to upload')}</h5>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Dropzone>
                                                                        <Button
                                                                            id='dropzone-popover-primary-logo-note'
                                                                            className='button'
                                                                            type='button'
                                                                            color='link'
                                                                            style={{
                                                                                position: 'absolute',
                                                                                right: 8,
                                                                                bottom: 6,
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={() => {
                                                                                setPrimaryLogoNotePopover(!primaryLogoNotePopover)
                                                                            }}>
                                                                            <i className='ri-information-line' style={{ fontSize: 18 }}></i>
                                                                        </Button>
                                                                        <Popover
                                                                            placement='bottom'
                                                                            target='dropzone-popover-primary-logo-note'
                                                                            isOpen={primaryLogoNotePopover}
                                                                            toggle={() => setPrimaryLogoNotePopover(!primaryLogoNotePopover)}
                                                                        >
                                                                            <PopoverHeader>
                                                                                {t(dropzoneMessages.title)}
                                                                            </PopoverHeader>
                                                                            <PopoverBody>
                                                                                {dropzoneMessages.primaryLogo.split('\n').map((item, key) => (
                                                                                    <span key={key}>
                                                                                        {t(item)}
                                                                                        <br />
                                                                                    </span>
                                                                                ))}
                                                                            </PopoverBody>
                                                                        </Popover>
                                                                    </div>
                                                                    <div className='dropzone-previews mt-3'>
                                                                        {primaryLogos.length > 0 && (
                                                                            <div>
                                                                                {primaryLogos.map((f, i) => {
                                                                                    return (
                                                                                        <Card
                                                                                            className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                                                                                            key={`primary-logo-file-${i}`}
                                                                                        >
                                                                                            <div className='p-2'>
                                                                                                <Row className='align-items-center'>
                                                                                                    <Col className='col-auto'>
                                                                                                        <div
                                                                                                            style={{
                                                                                                                padding: 6,
                                                                                                                marginLeft: 6,
                                                                                                            }}
                                                                                                        >
                                                                                                            <img
                                                                                                                className='avatar-sm rounded bg-light'
                                                                                                                alt={f.name}
                                                                                                                src={f.preview}
                                                                                                                style={{
                                                                                                                    width: 'auto',
                                                                                                                    height: 40,
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                    <Col>
                                                                                                        <Link to='#' className='text-muted fw-bold'>
                                                                                                            {f.name}
                                                                                                        </Link>
                                                                                                        <p className='mb-0'>
                                                                                                            <strong>{f.formattedSize}</strong>
                                                                                                        </p>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </div>
                                                                                        </Card>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                    {primaryLogos.length > 0 && (
                                                                        <div className='mt-3'>
                                                                            <Label className='form-label'>
                                                                                {`${t('Logo Size')} (${t('Pixel')})`}
                                                                            </Label>
                                                                            <AvField
                                                                                name='primaryLogoSize'
                                                                                type='number'
                                                                                errorMessage={t("This value should be between") + " 15 " + t("and") + " 120 "}
                                                                                className='form-control'
                                                                                validate={{
                                                                                    required: { value: false },
                                                                                }}
                                                                                value={organizationCurrentData?.primary_logo_size || ''}
                                                                                min={15}
                                                                                max={120}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <Label className='form-label'>
                                                                        {t('White Logo')}
                                                                    </Label>
                                                                    <div style={{ position: 'relative' }}>
                                                                        <Dropzone onDrop={(files) => {
                                                                            const result = files.map((file) => {
                                                                                return {
                                                                                    ...file,
                                                                                    preview         : URL.createObjectURL(file),
                                                                                    formattedSize   : formatBytes(file.size)
                                                                                }
                                                                            });

                                                                            setSecondaryLogos(result);
                                                                        }}>
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <div className='dropzone'>
                                                                                    <div className='dz-message needsclick' {...getRootProps()}>
                                                                                        <input {...getInputProps()} />
                                                                                        <div className='mb-3'>
                                                                                            <i className='display-4 text-muted ri-upload-cloud-2-line'></i>
                                                                                        </div>
                                                                                        <h5>{t('Drop files here or click to upload')}</h5>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Dropzone>
                                                                        <Button
                                                                            id='dropzone-popover-light-logo-note'
                                                                            className='button'
                                                                            type='button'
                                                                            color='link'
                                                                            style={{
                                                                                position: 'absolute',
                                                                                right: 8,
                                                                                bottom: 6,
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={() => setSecondaryLogoNotePopover(!secondaryLogoNotePopover)}>
                                                                            <i className='ri-information-line' style={{ fontSize: 18 }}></i>
                                                                        </Button>
                                                                        <Popover
                                                                            placement='bottom'
                                                                            target='dropzone-popover-light-logo-note'
                                                                            isOpen={secondaryLogoNotePopover}
                                                                            toggle={() => setSecondaryLogoNotePopover(!secondaryLogoNotePopover)}>
                                                                            <PopoverHeader>
                                                                                {t(dropzoneMessages.title)}
                                                                            </PopoverHeader>
                                                                            <PopoverBody>
                                                                                {dropzoneMessages.secondaryLogo.split('\n').map((item, key) => (
                                                                                    <span key={key}>
                                                                                        {t(item)}
                                                                                        <br />
                                                                                    </span>
                                                                                ))}
                                                                            </PopoverBody>
                                                                        </Popover>
                                                                    </div>
                                                                    <div className='dropzone-previews mt-3'>
                                                                        {secondaryLogos.length > 0 && (
                                                                            <div>
                                                                                {secondaryLogos.map((f, i) => {
                                                                                    return (
                                                                                        <Card
                                                                                            className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                                                                                            key={`primary-logo-file-${i}`}
                                                                                        >
                                                                                            <div className='p-2'>
                                                                                                <Row className='align-items-center'>
                                                                                                    <Col className='col-auto'>
                                                                                                        <div
                                                                                                            style={{
                                                                                                                padding: 6,
                                                                                                                marginLeft: 6,
                                                                                                            }}
                                                                                                        >
                                                                                                            <img
                                                                                                                className='avatar-sm rounded bg-light'
                                                                                                                alt={f.name}
                                                                                                                src={f.preview}
                                                                                                                style={{
                                                                                                                    width: 'auto',
                                                                                                                    height: 40,
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                    <Col>
                                                                                                        <Link to='#' className='text-muted fw-bold'>
                                                                                                            {f.name}
                                                                                                        </Link>
                                                                                                        <p className='mb-0'>
                                                                                                            <strong>{f.formattedSize}</strong>
                                                                                                        </p>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </div>
                                                                                        </Card>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                    {secondaryLogos.length > 0 && (
                                                                        <div className='mt-3'>
                                                                            <Label className='form-label'>
                                                                                {`${t('Logo Size')} (${t('Pixel')})`}
                                                                            </Label>
                                                                            <AvField
                                                                                name='secondaryLogoSize'
                                                                                type='number'
                                                                                errorMessage={t("This value should be between") + " 15 " + t("and") + " 70 "}
                                                                                className='form-control'
                                                                                validate={{
                                                                                    required: { value: false },
                                                                                }}
                                                                                value={organizationCurrentData?.secondary_logo_size || ''}
                                                                                min={15}
                                                                                max={70}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                    {isFeatureEnabledForActiveModule('intro') && (
                                                        <div className='pt-3 mb-3'>
                                                            <hr className='pt-3 pb-3' />
                                                            <Row>
                                                                <Col sm='9'>
                                                                    <div className='mb-3'>
                                                                        <Label className='form-label'>
                                                                            {t('Smart Integrity Platform - Reporting Channel Intro Text')}
                                                                        </Label>
                                                                        <p className='text-muted mb-3'>
                                                                            {t(
                                                                                'This will appear on your landing page. Provide any information you would like for internal and external parties to understand about reporting on the Smart Integrity Platform.',
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </Col>
                                                                {otherLangTranslations.length && (
                                                                    <Col style={{ textAlign: 'right' }}>
                                                                        <Button
                                                                            color='secondary'
                                                                            type='button'
                                                                            disabled={displayOtherTranslations}
                                                                            onClick={() =>
                                                                                setDisplayOtherTranslations(true)
                                                                            }
                                                                        >
                                                                            {t('Add new translation')}
                                                                        </Button>
                                                                    </Col>
                                                                )}

                                                                {isFeatureEnabledForActiveModule('title_channel_report_tp') || (
                                                                    <div className='mb-4'>
                                                                        <Row>
                                                                            <Col md='12'>
                                                                                <div className='mb-3'>
                                                                                    <Row className='align-items-end'>
                                                                                        <Col md='12'>
                                                                                            <div>
                                                                                                <Label className='form-label' htmlFor='title_channel_report_tp'>
                                                                                                    {t('Page Title')}
                                                                                                </Label>
                                                                                                <AvField
                                                                                                    name='title_channel_report_tp'
                                                                                                    type='text'
                                                                                                    errorMessage={t('This field cannot be blank')}
                                                                                                    className='form-control'
                                                                                                    validate={{
                                                                                                        required: {
                                                                                                            value: false,
                                                                                                        }
                                                                                                    }}
                                                                                                    value={organizationCurrentData?.title_channel_report_tp || ''}
                                                                                                    id='title_channel_report_tp'
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                )}

                                                                <Col sm='12'>
                                                                    <div className='mb-3'>
                                                                        <List
                                                                            type='unstyled'
                                                                            style={{
                                                                                padding: 20,
                                                                                margin: '20px 0',
                                                                                borderRadius: 10,
                                                                                border: '1px solid #eee',
                                                                            }}
                                                                        >
                                                                            {defaultLangTranslation && (
                                                                                <li style={{
                                                                                        paddingBottom: 10,
                                                                                        marginBottom: 10,
                                                                                    }}
                                                                                >
                                                                                    <Row className='align-items-center'>
                                                                                        <Col md={2}>
                                                                                            <Label className='form-label d-flex align-items-center'>
                                                                                                <span>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            display: 'block',
                                                                                                        }}
                                                                                                    >
                                                                                                        {t('Default language')}:
                                                                                                    </span>
                                                                                                    <span>{t(defaultLangTranslation.langName)}:</span>
                                                                                                </span>
                                                                                            </Label>
                                                                                        </Col>
                                                                                        <Col md={10}>
                                                                                            <div className='mt-3 mb-4'>
                                                                                                <Label className='form-label'>{t('Page Title')}</Label>
                                                                                                <AvField
                                                                                                    name={`pageTitles[${defaultLangTranslation.langCode}]`}
                                                                                                    type='text'
                                                                                                    errorMessage={t('This field cannot be blank')}
                                                                                                    className='form-control'
                                                                                                    validate={{
                                                                                                        required: {
                                                                                                            value: false
                                                                                                        }
                                                                                                    }}
                                                                                                    defaultValue={defaultLangTranslation?.title || ''}
                                                                                                />
                                                                                            </div>
                                                                                            <div className='mb-3'>
                                                                                                <Label className='form-label'>
                                                                                                    {t('Intro Text')}
                                                                                                </Label>
                                                                                                <Editor
                                                                                                    toolbarClassName='toolbarClassName'
                                                                                                    wrapperClassName='wrapperClassName'
                                                                                                    editorClassName='editorClassName'
                                                                                                    editorState={defaultLangTranslation.text}
                                                                                                    onEditorStateChange={(e) => {
                                                                                                        setActiveModuleTranslations((currentTrans) => {
                                                                                                            const newState = {
                                                                                                                ...currentTrans,
                                                                                                                [defaultLangTranslation.langCode] : {
                                                                                                                    ...currentTrans[defaultLangTranslation.langCode],
                                                                                                                    text    :   e
                                                                                                                }
                                                                                                            };

                                                                                                            return newState;
                                                                                                        })
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </li>
                                                                            )}
                                                                        </List>

                                                                        {displayOtherTranslations && (
                                                                            <List
                                                                                type='unstyled'
                                                                                style={{
                                                                                    padding: 20,
                                                                                    margin: '20px 0',
                                                                                    borderRadius: 10,
                                                                                    border: '1px solid #eee',
                                                                                }}
                                                                            >
                                                                                {otherLangTranslations.map((item, index) => (
                                                                                    <li
                                                                                        key={index}
                                                                                        style={{
                                                                                            borderBottom: '1px solid #eee',
                                                                                            paddingBottom: 10,
                                                                                            marginBottom: 10,
                                                                                        }}
                                                                                    >
                                                                                        <Row className='align-items-center'>
                                                                                            <Col md={2}>
                                                                                                <Label className='form-label d-flex align-items-center'>{t(item.langName)}:</Label>
                                                                                            </Col>
                                                                                            <Col md={10}>
                                                                                                <div className='mt-3 mb-4'>
                                                                                                    <Label className='form-label'>{t('Page Title')}</Label>
                                                                                                    <AvField
                                                                                                        name={`pageTitles[${item.langCode}]`}
                                                                                                        type='text'
                                                                                                        errorMessage={t('This field cannot be blank')}
                                                                                                        className='form-control'
                                                                                                        validate={{
                                                                                                            required: {
                                                                                                                value: false,
                                                                                                            }
                                                                                                        }}
                                                                                                        defaultValue={item?.title || ''}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className='mb-3'>
                                                                                                    <Label className='form-label'>
                                                                                                        {t('Intro Text')}
                                                                                                    </Label>
                                                                                                    <Editor
                                                                                                        toolbarClassName='toolbarClassName'
                                                                                                        wrapperClassName='wrapperClassName'
                                                                                                        editorClassName='editorClassName'
                                                                                                        editorState={item.text}
                                                                                                        onEditorStateChange={(e) => {
                                                                                                            setActiveModuleTranslations((currentTrans) => {
                                                                                                                const newState = {
                                                                                                                    ...currentTrans,
                                                                                                                    [item.langCode] : {
                                                                                                                        ...currentTrans[item.langCode],
                                                                                                                        text    :   e
                                                                                                                    }
                                                                                                                };
    
                                                                                                                return newState;
                                                                                                            })
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </li>
                                                                                ))}
                                                                            </List>
                                                                        )}
                                                                        
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                    <Row>
                                                        <Col sm='12' className='mt-3'>
                                                            <Button color='primary' type='submit' disabled={updateSettingsMutationInProcess}>
                                                                {updateSettingsMutationInProcess ? (
                                                                    <>
                                                                        <Spinner className='me-2' size='sm' color='light' />
                                                                        <span>
                                                                            {t('Saving')}
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <span>
                                                                        {t('Save')}
                                                                    </span>
                                                                )}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </TabPane>
                                            {isFeatureEnabledForActiveModule('styling') && (
                                                <TabPane tabId='2' className='pt-4 pb-4'>
                                                    <Row>
                                                        <Col lg={6}>
                                                            <div>
                                                                <div className='mb-3'>
                                                                    <CardTitle>{t('Typography')}</CardTitle>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <div>
                                                                <div className='mb-3'>
                                                                    <CardTitle>{t('Color Scheme')}</CardTitle>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            )}
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </AvForm>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

const mapStatetoProps = (state) => {
    const { token } = state.Login;
	const organization = state.Organization;
	const App = state.App;
    const Modules = state.Modules;

	return { organization, token, App, modules: Modules };
};

const mapDispachToProps = (dispach) => {
	return {
		reloadActiveModuleSettings   : () => dispach(reloadOrganizationActiveModuleSettingsFromServer()),
	};
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, mapDispachToProps)(Customization)));
