import { withNamespaces } from 'react-i18next';

import { Col, Row, Button, Label, Card} from 'reactstrap';

import DateUtils from 'src/services/utils/DateUtils';

import { INTERNATIONAL_DATE_FORMAT } from 'src/components/constants';

import { useEffect } from 'react';

import { QuestionnaireSectionsImages } from '../../../constants/Common';

import uploadedFileIcon from 'src/assets/images/uploaded-file.svg';

const QuestionarConfirmForm = ({
    t,
    questions,
    answers,
    onConfirmButtonClicked,
    onBackButtonClicked,
    settings,
    isFormSubmiting
}) => {

    const dateUtils = new DateUtils();

    const findQuestionAnswers = (question) => {
        const questionIdToSearch = !question?.isReactSubQuestion ? question.id : question.parentQuestionId;

        const item = (answers.questionAnswers && Array.isArray(answers.questionAnswers)) ? answers.questionAnswers.find((questionData) => {
            return questionData.question === questionIdToSearch;
        }) : null;

        if(item){
            if(!question?.isReactSubQuestion){
                switch(question.type){
                    case 'textarea':
                    case 'text':
                    case 'date':
                    case 'select':
                    case 'country_list':
                        return item.answer[0]?.title;

                    case 'checkbox':
                    case 'category':
                    case 'file_upload':
                        return item.answer || [];

                    case 'radiobox':
                        const userAnswer = (item.answer && Array.isArray(item.answer) && item.answer.length > 0) ? item.answer[0] : null;

                        if(userAnswer){
                            const optionBaseInfos = question.options.find((option) => {
                                return option.id === userAnswer.value;
                            });

                            if(optionBaseInfos){
                                return {
                                    value       :   optionBaseInfos.id,
                                    title       :   optionBaseInfos.title,
                                    subTitle    :   (optionBaseInfos.type === 'date' || optionBaseInfos.type === 'text') ? userAnswer.title : null
                                };
                            }
                        }
                    break;
                    
                    default:
                        return null;
                }
            }
            else{
                const answer = item.answer[0];

                if(answer && answer?.children && Array.isArray(answer.children)){
                    let preFilledSubQuestionData = answer.children.find((subQuestionData) => {
                        return Number.parseInt(subQuestionData.id) === question.id;
                    });

                    if(preFilledSubQuestionData?.type === 'file_upload'){
                        return [{
                            title   :   preFilledSubQuestionData?.title,
                            name    :   preFilledSubQuestionData?.name
                        }];
                    }

                    return preFilledSubQuestionData?.title;
                }
            }
        }

        return null;
    }

    const groupQuestionsBySection = () => {

        const groupedQuestionsBySection = [];

        const findSection = (sectionName) => {
            return groupedQuestionsBySection.find((section) => {
                return section.name === sectionName;
            });
        }

        for(let i = 0; i < questions.length; i++){
            const question = questions[i];
            let section = findSection(question.section || '');

            if(!section){
                section = {
                    name : question.section || '',
                    items : []
                }
                groupedQuestionsBySection.push(section);
            }

            section.items.push(question);
        }

        return groupedQuestionsBySection;
    }

    const renderQuestionsGroup = (items, settings = {
        displayNumbers: true
    }) => {
        return items.map((question, index) => {
            const {
                title, type
            } = question;

            const userAnswers = findQuestionAnswers(question);

            if (type === "textarea" || type === "text" || type === "country_list" || type === "select") {
                return (
                    <div className="question" key={ index }>
                        <Label className="form-label question-title">
                            { (settings.displayNumbers ? ((index + 1) + '. ') : '') } {t(title)}
                        </Label>
                        <p>
                            { userAnswers }
                        </p>
                    </div>
                );
            }
            else if(type === "date"){
                return (
                    <div className="question" key={ index }>
                        <Label className="form-label question-title">
                            { (settings.displayNumbers ? ((index + 1) + '. ') : '') } {t(title)}
                        </Label>
                        <p>
                            {userAnswers && dateUtils.convertDateToDate(
                                userAnswers,
                                INTERNATIONAL_DATE_FORMAT
                            )}
                        </p>
                    </div>
                )
            }
            else if(type === "checkbox" || type === "category"){
                return (
                    <div className="question" key={ index }>
                        <Label className="form-label question-title">
                            { (settings.displayNumbers ? ((index + 1) + '. ') : '') } {t(title)}
                        </Label>
                        <Row>
                            {
                                userAnswers.map((eachCheckboxAnswer, checkboxIndex) => {
                                    return (
                                        <Col sm="12" md="6" key={ checkboxIndex }>
                                            <p>
                                                {
                                                    t(eachCheckboxAnswer?.title)
                                                }
                                            </p>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </div>
                );
            }
            else if(type === "radiobox"){
                const selectedOptionInfos = (question.options || []).find((o) => o.id === userAnswers?.value);

                return (
                    <div className="question" key={ index }>
                        <Label className="form-label question-title">
                            { (settings.displayNumbers ? ((index + 1) + '. ') : '') } {t(title)}
                        </Label>
                        <Row>
                            {
                                userAnswers && (
                                    <Col sm="12">
                                        <Label className="form-label question-title">
                                            {t(userAnswers?.title)}
                                        </Label>
                                        <div className="sub-question-container">
                                            {
                                                (selectedOptionInfos && selectedOptionInfos?.children && selectedOptionInfos.children.length > 0) && (
                                                    renderQuestionsGroup(selectedOptionInfos.children, {
                                                        displayNumbers: false
                                                    })
                                                )
                                            }
                                        </div>
                                    </Col>
                                )
                            }
                        </Row>
                    </div>
                );
            }
            else if(type === "file_upload"){
                return (
                    <div className="question" key={ index }>
                        <Label className="form-label question-title">
                            { (settings.displayNumbers ? ((index + 1) + '. ') : '') } {t(title)}
                        </Label>

                        <div className="files-container">
                            {
                                (userAnswers || []).map((f, i) => {
                                    return (
                                        <div className="file-preview" key={i + "-file"}>
                                            <span className="file-name">
                                                { f.name }
                                            </span>
                                            <img src={ uploadedFileIcon } alt="" />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                );
            }

            return null;
        })
    }

    return (
        <>
            <div className="confirm-form-sections-container">
                {
                    groupQuestionsBySection().map((section, index) => {
                        return (
                            <div className="section" key={index}>
                                <div className="section-header">
                                    <img src={QuestionnaireSectionsImages[`${(section.name.toString()).replaceAll(' ', '_').replaceAll('-', '_').toLowerCase()}`]} alt="" />
                                    <span>{`${index + 1}. ${section.name}`}</span>
                                </div>

                                <div className="section-body">
                                    { renderQuestionsGroup(section.items) }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <Row className="mb-4">
                <Col sm="12" className="d-flex justify-content-between align-items-center col-sm-12">
                    {
                        settings?.buttons?.back?.enabled && (
                            <Button
                                onClick={() => {
                                    if(onBackButtonClicked){
                                        onBackButtonClicked();
                                    }
                                }}
                                disabled={isFormSubmiting}
                                color="btn btn-secondary tpdd-btn"
                                type="button">
                                {t(settings.buttons.back?.title || "Back")}
                            </Button>
                        )
                    }

                    {
                        settings?.buttons?.confirm?.enabled && (
                            <Button
                                onClick={() => {
                                    if(onConfirmButtonClicked){
                                        onConfirmButtonClicked();
                                    }
                                }}
                                disabled={isFormSubmiting}
                                color="btn btn-primary tpdd-btn"
                                type="button">
                                {t(settings.buttons.confirm?.title || "Confirm")}
                            </Button>
                        )
                    }
                </Col>
            </Row>
        </>
    );
}

export default withNamespaces()(QuestionarConfirmForm);