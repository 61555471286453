import { memo, useEffect, useState } from 'react';
import {Nav, NavItem, NavLink} from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';


const Tabs = memo(({t}) => {
    const [activeTab, setActiveTab] = useState("");
    const history = useHistory();
    const location = useLocation();

    const isActiveTab = (currentTab, tab) => currentTab === tab;

    const tabsLinks = [
        {
            key     :   "overview",
            title   :   "Overview"
        },
        {
            key     :   "basic-information",
            title   :   "Basic Information"
        },
        {
            key     :   "findings",
            title   :   "Findings"
        },
        {
            key     :   "reports",
            title   :   "Reports"
        },
        {
            key     :   "communication",
            title   :   "External Communication"
        },
        {
            key     :   "internal-communication",
            title   :   "Internal Communication"
        },
        {
            key     :   "risk-assessment",
            title   :   "Risk Assessment"
        },
        {
            key     :   "task-manager",
            title   :   "Task Management"
        },
        {
            key     :   "history-log",
            title   :   "History Log"
        }
    ];

    useEffect(() => {
        setActiveTab((new URLSearchParams(location.search)).get('tab'));
    }, [ location.search ]);

    useEffect(() => {
        if(!(new URLSearchParams(location.search)).get('tab')){
            history.push({
                search : '?tab=overview'
            })
        }
    }, []);

    return (
        <div style={{
            backgroundColor: '#D1D2D6'
        }}>
            <Nav tabs className="custom-style-1">
                {
                    tabsLinks.map((link) => {
                        return (
                            <NavItem className={isActiveTab(activeTab, link.key) ? 'active': ''} key={link.key}>
                                <NavLink
                                    className={isActiveTab(activeTab, link.key) ? 'active': ''}
                                    onClick={() => history.push({
                                        search : '?tab=' + link.key
                                    })}>
                                    {t(link.title)}
                                </NavLink>
                            </NavItem>
                        )
                    })
                }
            </Nav>
        </div>
	);
});

export default Tabs;
