import axios from 'axios';

import {
    API_URL_ADMIN_GET_ASSIGN_ANALYST_LIST,
    API_URL_ADMIN_AND_TASK_OWNERS_LIST,
    API_URL_CHECK_USER_EXISTENCE
} from '../constants/index';

export default class CommonService {
	static instance;

	static getInstance() {
		if (!CommonService.instance) {
			CommonService.instance = new CommonService();
		}

		return CommonService.instance;
	}

    async fetchAnalystAndAdminAnalystList(payload = {}){
        const response = await axios.post(API_URL_ADMIN_GET_ASSIGN_ANALYST_LIST, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
              },
            }
        );
  
        return response.data?.data;
    }

    async fetchTaskOwners(payload = {}){
        const response = await axios.post(API_URL_ADMIN_AND_TASK_OWNERS_LIST, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
              },
            }
        );
  
        return response.data?.data;
    }

    async checkUserExistence(payload) {
        const response = await axios.post(API_URL_CHECK_USER_EXISTENCE,
            payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
              },
            }
        );
    
        return response.data.data
    }
}
