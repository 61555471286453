import {
    ROLE_ADMIN,
    ROLE_ANALYST,
    ROLE_ANALYST_ADMIN
} from "src/components/constants";

import {
    ROUTE_PATH_ADMIN_DASHBOARD,
    ROUTE_PATH_ADMIN_KANBAN_BOARD,
    ROUTE_PATH_ADMIN_HOME,
    ROUTE_PATH_ADMIN_SETTINGS,
    ROUTE_PATH_ADMIN_CUSTOMIZATION
} from "src/modules/3rd-party-management/constants"

import {
    ROUTE_PATH_ADMIN_DOCUMENTS,
    ROUTE_PATH_ADMIN_E_LEARNING,
    ROUTE_PATH_ADMIN_TUTORIAL,
    ROUTE_PATH_ADMIN_RISK_MANAGMENT,
    ROUTE_PATH_ADMIN_ENTITY_MANAGMENT
} from "src/common/constants";

const AdminMenuItems = [
    {
        titleKey    :   "Dashboard",
        path        :   ROUTE_PATH_ADMIN_DASHBOARD,
        icon        :   "ri-home-4-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Kanban Board",
        path        :   ROUTE_PATH_ADMIN_KANBAN_BOARD,
        icon        :   "ri-bar-chart-2-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Third Party Management",
        path        :   ROUTE_PATH_ADMIN_HOME,
        icon        :   "ri-file-list-3-fill",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Risk Management",
        path        :   ROUTE_PATH_ADMIN_RISK_MANAGMENT,
        icon        :   "ri-error-warning-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [
            ROLE_ADMIN,
            ROLE_ANALYST,
            ROLE_ANALYST_ADMIN
        ],
        customValidator :   null
    },
    {
        titleKey    :   "Entity Management",
        path        :   ROUTE_PATH_ADMIN_ENTITY_MANAGMENT,
        icon        :   "ri-error-warning-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [
            ROLE_ADMIN,
            ROLE_ANALYST_ADMIN
        ],
        customValidator :   null
    },
    {
        titleKey    :   "Administration",
        path        :   "#",
        icon        :   "ri-admin-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null,
        items: [
            {
                titleKey    :   "Settings",
                path        :   ROUTE_PATH_ADMIN_SETTINGS,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   null,
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            },
            {
                titleKey    :   "Customisation",
                path        :   ROUTE_PATH_ADMIN_CUSTOMIZATION,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   null,
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            },
            {
                titleKey    :   "Documents",
                path        :   ROUTE_PATH_ADMIN_DOCUMENTS,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   null,
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            }
        ]
    },
    {
        titleKey    :   "E-Learning",
        path        :   ROUTE_PATH_ADMIN_E_LEARNING,
        icon        :   "ri-video-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   "isElearning",
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Tutorial",
        path        :   ROUTE_PATH_ADMIN_TUTORIAL,
        icon        :   "ri-video-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    }
];

export default AdminMenuItems;