import react, { useState } from "react";
import { AvField } from "availity-reactstrap-validation";
export default function Password(props) {
  const { name, id, placeholder, errorMessage, validate, onChange, value } =
    props;
  const [fieldType, setFieldType] = useState("password");

  const togglePasswordFieldType = (e) => {
    e.preventDefault();
    setFieldType(fieldType === "password" ? "text" : "password");
  };

  return (
    <div className="eye-password-field">
      <button className="toggle-field-type text-dark" tabIndex={-1}>
        <i
          onClick={togglePasswordFieldType}
          className={
            fieldType === "password" ? "ri-eye-off-fill" : "ri-eye-fill"
          }
        ></i>
      </button>
      <AvField
        name={name}
        type={fieldType}
        className="form-control"
        id={id}
        value={value}
        placeholder={placeholder}
        errorMessage={errorMessage}
        validate={validate}
        onChange={onChange}
        dir={"ltr"}
      />
    </div>
  );
}
