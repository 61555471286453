import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import React, { useState, useEffect, memo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { successNotification, errorNotification } from "src/store/actions.js";
import { toast } from "react-toastify";
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Select from "react-select";

import OverviewHeader from "../../../../components/overview-header";

import DateUtils from "src/services/utils/DateUtils";

import {
    EMPTY_LIST,
    INTERNATIONAL_DATE_FORMAT
} from "src/common/constants";

import SupplierTaskAssessmentService from "src/modules/3rd-party-management/apis/SupplierTaskAssessmentService";

import {
    TasksStatuses,
    TasksPriorities
} from "src/modules/3rd-party-management/constants";

import CreateTaskModal from "src/modules/3rd-party-management/components/RisksAndTasks/tasks/new";

const TaskManager = (props) => {
    const {
        t,
        supplierId
    } = props;

    const history = useHistory();

    const [ pageIndex, setPageIndex ] = useState(1);
    const [ pageSize, setPageSize ] = useState(12);
    const [ deleteTaskId, setDeleteTaskId ] = useState(null);
    const [ owners, setOwners ] = useState([]);
    const [ tasks, setTasks ] = useState([]);

    const [ createTaskModalStatus, setCreateTaskModalStatus ] = useState(false);
    const [ deleteTaskModalStatus, setDeleteTaskModalStatus ] = useState(false);

    const dateUtils = new DateUtils();

    const [ searchFilters, setSearchFilters ] = useState({
        status      :   null,
        priority    :   null,
        owner       :   null
    });

    const handleFetchSupplierTasksQuery = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-tasks', pageIndex, pageSize],
		queryFn: async () => {
			const service = SupplierTaskAssessmentService.getInstance();

            let filtersToSend = {}

            for(const filterKey in searchFilters){
                if(searchFilters[filterKey]){
                    filtersToSend[filterKey] = searchFilters[filterKey]?.value;
                }
            }

            return await service.fetchTasks(supplierId, {
                ...filtersToSend,
                pageIndex   :   pageIndex,
                pageSize    :   pageSize
            });
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching supplier tasks.'), {
				type: 'error',
			});
		},
	});

    const handleFetchSupplierTasksOwners = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-tasks-owners'],
		queryFn: async () => {
			const service = SupplierTaskAssessmentService.getInstance();

            return await service.fetchOwners(supplierId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching owners.'), {
				type: 'error',
			});
		},
	});

    const handleDeleteSupplierTaskMutation = useMutation({
        mutationFn: async (taskId) => {
            const service = SupplierTaskAssessmentService.getInstance();

            return await service.deleteTask(supplierId, taskId);
        },
        onSuccess: () => {
            setDeleteTaskModalStatus(false);

            handleFetchSupplierTasksQuery.refetch();
            
            toast(t("Task deleted successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("Failed to delete task."), {
                type: "error",
            });
        }
    });

    useEffect(() => {
        if(handleFetchSupplierTasksQuery.data){
            setTasks(handleFetchSupplierTasksQuery.data.tasks)
        }
    }, [handleFetchSupplierTasksQuery.data]);

    useEffect(() => {
        if(handleFetchSupplierTasksOwners.data){
            setOwners(handleFetchSupplierTasksOwners.data.map((item) => {
                return {
                    value: item.id,
                    label: `${item.first_name} ${item.last_name}`,
                };
            }));
        }
    }, [handleFetchSupplierTasksOwners.data]);

    useEffect(() => {
        handleFetchSupplierTasksQuery.refetch();
    }, [searchFilters]);

    const isLoading = ((handleFetchSupplierTasksQuery.isFetching || handleFetchSupplierTasksQuery.isLoading) ||
            (handleFetchSupplierTasksOwners.isFetching || handleFetchSupplierTasksOwners.isLoading) || !props.user);

    const taskSelectedToShow = (id) => {
        history.push(`/admin/3rd-party/suppliers/${supplierId}/tasks/${id}/details`);
    }

    const priorities = [];
    const statuses = [];

    for(const priorityKey in TasksPriorities){
        const priority = TasksPriorities[priorityKey];
        priorities.push({
            value       : priorityKey,
            baseLabel   : priority.title,
            label       : (
                <>
                    <span style={{
                            color	:	`${priority.color}`
                        }}>
                        { priority.icon }
                    </span>
                    <span>
                        {t(priority.title)}
                    </span>
                </>
            ),
        });
    }

    for(const statusKey in TasksStatuses){
        const status = TasksStatuses[statusKey];
        statuses.push({
            value       : statusKey,
            label       : t(status.title)
        });
    }

    const renderModals = () => {
        if(isLoading){
            return null;
        }

        const closeDeleteTaskModal = () => {
            setDeleteTaskId(null);
            setDeleteTaskModalStatus(false);
        }

        return (
            <React.Fragment>
                <CreateTaskModal
                    isOpen={createTaskModalStatus}
                    closeModal={() => setCreateTaskModalStatus(false)}
                    owners={owners}
                    initialTaskStatus={{ value: 'NEW', label: t('New') }}
                    priorities={ priorities }
                    successCreation={() => handleFetchSupplierTasksQuery.refetch()}
                    creationRequestPayload={{
                        supplier  :   supplierId
                    }}
                />

                <Modal size="lg" scrollable={true} isOpen={deleteTaskModalStatus} backdrop="static">
                    <ModalHeader toggle={closeDeleteTaskModal}>
                        {t("Delete Task")}
                    </ModalHeader>

                    <ModalBody>
                        <Row>
                            <Col sm="12">
                                <Label>
                                    {t("Are you sure?")}
                                </Label>
                            </Col>
                        </Row>
                        <ModalFooter>
                            <Button
                                color="danger"
                                className="waves-effect waves-light"
                                type="submit"
                                onClick={() => {
                                    handleDeleteSupplierTaskMutation.mutate(deleteTaskId);
                                }}>
                                    {t("Delete")}
                            </Button>

                            <Button
                                color="secondary"
                                className="waves-effect waves-light"
                                type="button"
                                onClick={closeDeleteTaskModal}>
                                    {t("Cancel")}
                            </Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }

    const renderFilters = () => {
        return (
            <Row className='d-flex'>
                <Col sm='12' md='3' lg='3' className='mb-2 '>
                    {!isLoading ? (
                        <Select
                            classNamePrefix='select2-selection'
                            options={statuses}
                            value={searchFilters.status}
                            isClearable={true}
                            onChange={(e) => {
                                setSearchFilters((filters) => {
                                    return {
                                        ...filters,
                                        status  :   e
                                    }
                                })
                            }}
                            placeholder={t('Select status')}
                        />
                    ) : (
                        <div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
                    )}
                </Col>

                {/* priorities */}
                <Col sm='12' md='3' lg='3' className='mb-2 '>
                    {!isLoading ? (
                        <Select
                            classNamePrefix='select2-selection'
                            options={priorities}
                            value={searchFilters.priority}
                            isClearable={true}
                            onChange={(e) => {
                                setSearchFilters((filters) => {
                                    return {
                                        ...filters,
                                        priority  :   e
                                    }
                                })
                            }}
                            placeholder={t('Select priority')}
                        />
                    ) : (
                        <div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
                    )}
                </Col>

                {/* owners */}
                <Col sm='12' md='3' lg='3' className='mb-2'>
                    {!isLoading ? (
                        <Select
                            classNamePrefix='select2-selection'
                            options={owners}
                            value={searchFilters.owner}
                            isClearable={true}
                            onChange={(e) => {
                                setSearchFilters((filters) => {
                                    return {
                                        ...filters,
                                        owner  :   e
                                    }
                                })
                            }}
                            placeholder={t('Task owner')}
                        />
                    ) : (
                        <div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
                    )}
                </Col>

                {/* create risk button */}
                <Col sm='12' md='3' lg='3'>
                    <Button color='primary'
                        className='mb-3'
                        style={{
                            display: 'block',
                        }}
                        onClick={() => setCreateTaskModalStatus(true)}
                        outline
                        // disabled={isDeleted || deletedByName}
                    >
                        {t('Create Task')}
                    </Button>
                </Col>
            </Row>
        );
    }

    const renderTasksTable = () => {
        return (
            <table className='dt-list dt-tasks-list'>
                <thead>
                    <tr>
                        {/* task id */}
                        <td style={{ width: '5%' }}>{t('Task ID')}</td>

                        {/* title */}
                        <td style={{ width: '16%' }}>{t('Title')}</td>

                        {/* creation date */}
                        <td style={{ width: '7%' }}>{t('Creation Date')}</td>

                        {/* deadline */}
                        <td style={{ width: '7%' }}>{t('Deadline')}</td>

                        {/* task manager */}
                        <td style={{ width: '12.5%' }}>{t('Task Manager')}</td>

                        {/* task owner */}
                        <td style={{ width: '12.5%' }}>{t('Task owner')}</td>

                        {/* priority */}
                        <td style={{ width: '10%' }}>{t('Priority')}</td>

                        {/* status */}
                        <td style={{ width: '10%' }}>{t('Status')}</td>

                        {/* deletion date */}
                        <td style={{ width: '10%' }}>{t('Deletion date')}</td>

                        {/* actions */}
                        <td style={{ width: '10%' }}></td>
                    </tr>
                </thead>

                <tbody>
                    {!isLoading ? (
                        tasks && tasks.length > 0 ? (
                            tasks.map((task) => {
                                const taskPriority = TasksPriorities[task.priority];
                                const taskStatus = TasksStatuses[task.status];
                                const taskID = task.relatedRisk && task.relatedRisk.secondary_id ? `R${task.relatedRisk.secondary_id}/T${task.secondary_id}` : `T${task.secondary_id}`

                                return (
                                    <tr key={task.id}>
                                        {/* task id */}
                                        <td style={{ width: '5%' }}>
                                            <div
                                                className='dt-task-id'
                                                style={{
                                                    cursor: !task.deleted_at ? 'pointer' : 'default',
                                                }}
                                                onClick={() => (!task.deleted_at && taskSelectedToShow(task.id))}>
                                                {taskID}
                                            </div>
                                        </td>

                                        {/* title */}
                                        <td style={{ width: '16%' }}>
                                            <div
                                                className={`dt-task-title ${task.deleted_at ? '' : 'hoverable'}`}
                                                style={{
                                                    cursor: !task.deleted_at ? 'pointer' : 'default',
                                                }}
                                                onClick={() => (!task.deleted_at && taskSelectedToShow(task.id))}
                                            >
                                                {task.name}
                                            </div>
                                        </td>

                                        {/* creation date */}
                                        <td style={{ width: '7%' }}>
                                            <div className='dt-task-creation-date'>{dateUtils.convertTimeStampToDate(task.created_at, INTERNATIONAL_DATE_FORMAT)}</div>
                                        </td>

                                        {/* deadline */}
                                        <td style={{ width: '7%' }}>
                                            <div className='dt-task-deadline-date'>{dateUtils.convertTimeStampToDate(task.ended_at, INTERNATIONAL_DATE_FORMAT)}</div>
                                        </td>

                                        {/* task manager */}
                                        <td style={{ width: '12.5%' }}>
                                            <div>
                                                <span className='dt-list-col-bullet'>{task.manager[0]}</span> <span className='dt-list-col-bullet-text'>{task.manager}</span>
                                            </div>
                                        </td>

                                        {/* task owner */}
                                        <td style={{ width: '12.5%' }}>
                                            <div>
                                                <span className='dt-list-col-bullet'>{task?.analyst?.full_name[0]}</span>{' '}
                                                <span className='dt-list-col-bullet-text'>{task?.analyst?.full_name}</span>
                                            </div>
                                        </td>

                                        {/* priority */}
                                        <td style={{ width: '10%' }}>
                                            <div className='dt-task-priority'>
                                                <span style={{
                                                    color   :   `${taskPriority.color}`
                                                }}>
                                                    { taskPriority.icon }
                                                </span>

                                                <span style={{ marginLeft: '4px' }}>
                                                    {t(taskPriority.title)}
                                                </span>
                                            </div>
                                        </td>

                                        {/* status */}
                                        <td style={{ width: '10%' }}>
                                            <div className='dt-task-status text-dark' style={{
                                                backgroundColor :   `${taskStatus.color}`
                                            }}>
                                                {t(taskStatus.title)}
                                            </div>
                                        </td>

                                        {/* deletion date */}
                                        <td style={{ width: '10%' }}>
                                            <div>{task.deleted_at ? dateUtils.convertTimeStampToDate(task.deleted_at, INTERNATIONAL_DATE_FORMAT) : null}</div>
                                        </td>

                                        {/* actions */}
                                        <td style={{ width: '10%' }}>
                                            {!task.deleted_at ? (
                                                <div
                                                    style={{
                                                        fontSize: 20,
                                                        textAlign: 'center',
                                                        display: 'block',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        setDeleteTaskId(task.id);
                                                        setDeleteTaskModalStatus(true);
                                                    }}
                                                >
                                                    <i className='ri-delete-bin-line text-danger'></i>
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        fontWeight: 300,
                                                        textAlign: 'center',
                                                        display: 'block',
                                                        color: 'red',
                                                    }}
                                                >
                                                    {t('Deleted')}
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={10}>
                                    <div className='alert alert-warning' role='alert'>
                                        <p style={{ textAlign: 'center', marginBottom: 0, width: '100%' }}>{t(EMPTY_LIST)}</p>
                                    </div>
                                </td>
                            </tr>
                        )
                    ) : (
                        <>
                            <tr>
                                {/* task id */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-id dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* title */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-title dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* creation date */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-creation-date dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* deadline */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-deadline-date dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* task manager */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-list-col-bullet dt-skeleton'></span>
                                        <span className='dt-list-col-bullet-text dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* task owner */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-list-col-bullet dt-skeleton'></span>
                                        <span className='dt-list-col-bullet-text dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* priority */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-priority dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* status */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-status dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* deletion date */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-deletion-date dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* actions */}
                                <td style={{ width: '10%' }}></td>
                            </tr>
                            <tr>
                                {/* task id */}
                                <td style={{ width: '5%' }}>
                                    <div>
                                        <span className='dt-task-id dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* title */}
                                <td style={{ width: '16%' }}>
                                    <div>
                                        <span className='dt-task-title dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* creation date */}
                                <td style={{ width: '7%' }}>
                                    <div>
                                        <span className='dt-task-creation-date dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* deadline */}
                                <td style={{ width: '7%' }}>
                                    <div>
                                        <span className='dt-task-deadline-date dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* task manager */}
                                <td style={{ width: '12.5%' }}>
                                    <div>
                                        <span className='dt-list-col-bullet dt-skeleton'></span>
                                        <span className='dt-list-col-bullet-text dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* task owner */}
                                <td style={{ width: '12.5%' }}>
                                    <div>
                                        <span className='dt-list-col-bullet dt-skeleton'></span>
                                        <span className='dt-list-col-bullet-text dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* priority */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-priority dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* status */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-status dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* deletion date */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-deletion-date dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* actions */}
                                <td style={{ width: '10%' }}></td>
                            </tr>
                            <tr>
                                {/* task id */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-id dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* title */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-title dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* creation date */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-creation-date dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* deadline */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-deadline-date dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* task manager */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-list-col-bullet dt-skeleton'></span>
                                        <span className='dt-list-col-bullet-text dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* task owner */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-list-col-bullet dt-skeleton'></span>
                                        <span className='dt-list-col-bullet-text dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* priority */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-priority dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* status */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-status dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* deletion date */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-deletion-date dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* actions */}
                                <td style={{ width: '10%' }}></td>
                            </tr>
                            <tr>
                                {/* task id */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-id dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* title */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-title dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* creation date */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-creation-date dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* deadline */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-deadline-date dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* task manager */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-list-col-bullet dt-skeleton'></span>
                                        <span className='dt-list-col-bullet-text dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* task owner */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-list-col-bullet dt-skeleton'></span>
                                        <span className='dt-list-col-bullet-text dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* priority */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-priority dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* status */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-status dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* deletion date */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-deletion-date dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* actions */}
                                <td style={{ width: '10%' }}></td>
                            </tr>
                            <tr>
                                {/* task id */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-id dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* title */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-title dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* creation date */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-creation-date dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* deadline */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-deadline-date dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* task manager */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-list-col-bullet dt-skeleton'></span>
                                        <span className='dt-list-col-bullet-text dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* task owner */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-list-col-bullet dt-skeleton'></span>
                                        <span className='dt-list-col-bullet-text dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* priority */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-priority dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* status */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-status dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* deletion date */}
                                <td style={{ width: '10%' }}>
                                    <div>
                                        <span className='dt-task-deletion-date dt-skeleton'></span>
                                    </div>
                                </td>

                                {/* actions */}
                                <td style={{ width: '10%' }}></td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
        );
    }

    return (
        <div className="p-4 d-flex flex-column gap-4">
            { renderModals() }
            <Row>        
                <Col sm="12">
                    <OverviewHeader supplierId={ supplierId } />
                </Col>

                <Col sm="12">
                    <div className="p-4">
                        { renderFilters() }
                        { renderTasksTable() }
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    const { Organization } = state;
    return {
        user : state.Login.user,
        token,
        organization : Organization
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps,
        {
            successNotification,
            errorNotification
        }
    )(memo(TaskManager)))
);