import { withNamespaces } from 'react-i18next';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useEffect, useState } from "react";

import { Row, Col, Label, Button } from "reactstrap";

import { API_URL_SIGN_IN, API_URL_VERIFY_OTP } from '../../../../common/constants';

import { LOCAL_STORAGE_KEY_ACCESS_TOKEN } from "../../../../services/constants/LocalStorage";

import axios from 'axios';

import { connect } from "react-redux";

import MaterialInput from "@material-ui/core/Input";

import {
    errorNotification,
    successNotification,
    checkLogin,
    setUserAccessToken,
    appError,
    appLoadStatusChanged,
    authCurrentUser,
    logoutUser,
    loginUserSuccessful,
    changeWorkspace
  } from "../../../../store/actions";

  import InputMask from "react-input-mask";

const OTPForm = (props) => {
    const {
        t, 
        otp, 
        setOtp,
        username,
        password,
        onValidSubmit,
        onBackButtonClicked,
        settings,
    } = props;

    const [authFormIsSubmiting, setAuthFormIsSubmiting] = useState(false);

    const loginUser = (credentials) => {
        
        return axios.post(API_URL_SIGN_IN, credentials)
        .then((response) => {
            
            const authedUserInfos = response.data.data;
            
            localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, authedUserInfos.token);

            props.setUserAccessToken(authedUserInfos.token);

            props.loginUserSuccessful(authedUserInfos);
            
            onValidSubmit();
        })
        .catch(({ response : { data : errorResponse } }) => {
            props.errorNotification({
                code: errorResponse.code,
                message: errorResponse.message,
            })
        }).finally(() => {
            setAuthFormIsSubmiting(false);
        });
    }
    
    const verifyOTP = () => {
        setAuthFormIsSubmiting(true);
        axios.post(API_URL_VERIFY_OTP,  {otp} , {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN")}`,
            },
        }).then((response) => {
            const data = response.data;
            // if (data.ok === false) {
            //     return props.errorNotification({
            //         code: "invalid_otp",
            //         message: t("Invalid OTP"),
            //     });
            // }
            localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN"));
            props.setUserAccessToken(localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN"));
            loginUser({
                organization: props.Organization.id,
                username: username,
                password: password,
                isThirdPartyUser: true,
                "2fa": false
            });
        }).catch((err) => {
            props.errorNotification({
                code: "invalid_otp",
                message: t("Invalid OTP", err),
            });
        });
    };


    return (
        <AvForm className="needs-validation"
            onValidSubmit={verifyOTP}
            >
                <Row>
                    <Col sm="12" className="mb-3">
                        <Label className="form-label" htmlFor="otp">
                            {t("OTP Code")}
                        </Label>
                        {/* <AvField
                            name="otp"
                            placeholder=""
                            type="text"
                            className="form-control"
                            errorMessage={t("This field cannot be blank")}
                            onChange={(e) => setOtp(e.target.value)}
                            validate={{ required: { value: true } }}
                            value={otp}
                            id="otp"
                        /> */}
                        <InputMask
                            autoFocus
                            mask="9-9-9-9-9-9"
                            className="form-control input-color"
                            value={otp}
                            onChange={(e) => {
                                setOtp(e.target.value.replaceAll("-", "").replaceAll("OTP ", ""))
                            }}
                        >
                            {(inputProps) => (
                            <MaterialInput {...inputProps} disableUnderline />
                            )}
                        </InputMask>
                    </Col>
                </Row>


                <Row>
                    <Col sm="12" className="d-flex justify-content-between align-items-center col-sm-12">
                        <Button
                            disabled={ authFormIsSubmiting }
                            onClick={() => {
                                if(onBackButtonClicked){
                                    onBackButtonClicked();
                                }
                            }}
                            color="btn btn-outline-light"
                            type="button">
                            {t(settings?.buttons?.back?.title || "Back")}
                        </Button>

                        <Button
                            disabled={ authFormIsSubmiting }
                            color="btn btn-primary"
                            type="submit">
                            {t(settings?.buttons?.confirm?.title)}
                        </Button>
                    </Col>
                </Row>
        </AvForm>
    )
}

const mapStateToProps = (state) => {
    const { token, user } = state.Login;
    const { Organization, App } = state;

    return { token, Organization, user, App };
};

export default withNamespaces()(
    connect(mapStateToProps, {
        logoutUser,
        errorNotification,
        successNotification,
        checkLogin,
        loginUserSuccessful,
        authCurrentUser,
        changeWorkspace,
        appError,
        appLoadStatusChanged,
        setUserAccessToken,
    })(OTPForm)
);