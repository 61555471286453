import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Label,Button } from "reactstrap";
import MultiSelect from "../../../../../../../components/MultiSelect";
import UserUtils from "src/services/utils/UserUtils";
import ReportIncidenService from "../../../../components/api/ReportIncidenService";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import InfoModal from "./infoModal";

const Index = (props) => {
  const { t, report, caseId, currentUser, categories, reportLoading, token } =
    props;
  const [onlyAnalysts, setOnlyAnalysts] = useState([]);
  const userUtils = new UserUtils();
  const [userCategories, setUserCategories] = useState([]);
  const [isModalsOpen, setIsModalOpen] = useState(false);
  const isAnalystOrAnalystAdmin = () => {
    return userUtils.isAnalystOrAnalystAdmin(currentUser);
  };

    //used for show the modal of kewords
    const toggleInfoModal = () => {
      setIsModalOpen(!isModalsOpen);
    };

    
  const handleUpdateAdminCategories = useMutation({
    mutationFn: async (payload) => {
      const service = ReportIncidenService.getInstance();

      return await service.UpdateAdminCategories(payload);
    },
  });

  const handleChangeCategories = (item) => {
    handleUpdateAdminCategories.mutate(
      {
        reportCaseId: caseId,
        category: item,
      },
      {
        onSuccess: () => {
          toast(t("Admin categories updated successfully."), {
            type: "success",
          });
          props.reportRefetch();
        },
        onError: (error) => {
          toast(t("Failed to update admin categories."), {
            type: "error",
          });
          // Refetch link if mutation fails
          setOnlyAnalysts([...onlyAnalysts]);
        },
      }
    );
  };

  const handleMultiSelectChangeCategories = (selectedValues) => {
    const mappedValues = selectedValues.map((item) => item.id);
    handleChangeCategories(mappedValues);
    setUserCategories(selectedValues);
  };

  // Update user categories when report loading changes
  useEffect(() => {
    if (categories?.length > 0) {
      const categoryObjects = categories.filter((category) =>
        report?.admin_category.includes(category.id)
      );
      setUserCategories(categoryObjects);
    }
  }, [reportLoading, categories, report]);

  return (
    <div style={{ maxWidth: "396px" }}>
      {/* Add New Category */}
     <div className="d-flex justify-content-between align-items-center">
      <Label>{t("Category By The Admin")}</Label>
      <Button
          color="secondry"
          size="sm"
          style={{marginTop:'-5px', fontSize:'11px'}}
          onClick={() => setIsModalOpen(true)}
        >
          {t("Description")}
        </Button>
     </div> 
      {!reportLoading ? (
        <MultiSelect
          options={categories}
          selectedValues={userCategories}
          onChange={handleMultiSelectChangeCategories}
          placeholder={t("Select...")}
          isDisabled={report?.is_read_only_analyst}
        />
      ) : (
        // skeleton
        <>
          <p
            className={`dt-field dt-skeleton ${
              isAnalystOrAnalystAdmin ? "dt-select-list" : ""
            }`}
          ></p>
          <p className="dt-field dt-skeleton dt-update-date"></p>
        </>
      )}
        <InfoModal
        modal={isModalsOpen}
        setModal={setIsModalOpen}
        toggle={toggleInfoModal}
        t={t}
      />
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token, currentUser } = state.Login;
  return {
    currentUser,
    token,
  };
};

export default withNamespaces()(connect(mapStatetoProps)(Index));
