import { memo, useCallback, useEffect, useState } from 'react';
import { 
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Container, Row, Col, Badge
} from 'reactstrap';

import "./RiskMatrix.scss";

const RiskMatrix = function (props) {
	RiskMatrix.displayName = 'Memorized Component - Risk Matrix';
	if (process?.env?.NODE_ENV === 'development') console.log('---- rendering memorized component >>> RiskMatrix');

	const { selectRisk, matrix, consequences, currency, likelihoods, tableColors, t } = props;

	const [ colsDrops, setColdsDrops ] = useState([]);

  	const toggleColDrop = (colId) => {
		setColdsDrops((drops) => {
			const newDrops = {...drops};
			newDrops[colId] = !newDrops[colId];
			return newDrops;
		});
	}

  	const renderRisksDrop = (colId, risks) => {
		return risks?.length ? (
			<Dropdown direction="left" isOpen={!!colsDrops[colId]} toggle={() => toggleColDrop(colId)}>
				<DropdownToggle className="else-risks-drop-btn">
					<i className='ri-arrow-down-s-line'></i>
				</DropdownToggle>
				<DropdownMenu>
					{
						risks.map((risk, index) => {
							return (
								<DropdownItem onClick={() => { selectRisk(risk.id) }} key={ index }>
									{'R' + (risk.secondaryId)}
								</DropdownItem>
							)
						})
					}
				</DropdownMenu>
			</Dropdown>
		) : null;
	}
  
	/**
	 * this method renders the bullets of the risks in matrix.
	 * @param {Object} likelihood the likelihood that may exist a risk
	 * @param {Object} damage the damage that may exist a risk
	 */
	const renderRiskBullets = useCallback(
		(risks) => {
			const result = risks.map((r) =>
				<span key={r.id} style={{ cursor: 'pointer' }} className='dt-matrix-risk-bullet' onClick={() => { selectRisk(r.id) }}></span>
			);

			if(risks.length){
				result.push(
					<div key={'dt-matrix-risk-bullet-else-key'} className="dt-matrix-risk-bullet-else">
						{ risks.length }
					</div>
				);
			}

			return result;
		},
		[],
	);

	const isMobile = window.matchMedia('(max-width: 768px)').matches;

	return (
		<Container>
			<Row>
				<Col>
					{matrix && consequences && currency && likelihoods && tableColors ? (
						<table className='dt-matrix'>
							{/* captions */}
							<caption
								style={{
									margin: 'auto',
									textAlign: 'center',
								}}
							>
								{tableColors.map((item) => {
									return (
										<Badge
											className='m-1 p-3 lg'
											style={{
												backgroundColor: `#${item.color}`,
												fontSize: '0.8rem',
												cursor: 'pointer',
											}}
											onClick={() => {
												props.filterRisks({ value: item.label, label: item.label });
											}}
										>
											{props.t(item.label)}
										</Badge>
									);
								})}
							</caption>

							<tbody>
								{matrix ? (
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td className='dt-matrix-x-axis-label' colSpan={matrix.length}>
											<div>{t('Damage in') + ` ${t(currency)}`}</div>
										</td>
									</tr>
								) : null}

								{/* consequences */}
								{consequences ? (
									<tr>
										<td></td>
										<td></td>
										<td></td>
										{consequences.map((i) => (
											<td key={i.id} className='dt-matrix-info dt-matrix-x-axis'>
												<div>
													<span className='dt-matrix-x-axis-title'>{t(i.label)}</span>
													<span hidden={isMobile} className='dt-matrix-x-axis-value'>{'> ' + (Number(i?.title) / 1000).toLocaleString("en-US") + 'K'}</span>
												</div>
											</td>
										))}
									</tr>
								) : null}

								{matrix
									? matrix.map((row, rowIndex) => {
											return (
												<>
													<tr key={row.rowIndex}>
														{rowIndex === 0 ? (
															<td
																style={{
																	// width: '4%',
																	textAlign: 'center',
																}}
																className='dt-matrix-y-axis-label'
																rowSpan={matrix.length + 1}
															>
																<span hidden={isMobile}>{t('Likelihood')}</span>
															</td>
														) : null}

														{/* likelihoods */}
														{likelihoods ? (
															<>
																<td 
																// style={{ width: '6%' }} 
																className='dt-matrix-y-axis'
																>
																	<span className='dt-matrix-y-axis-value'>{likelihoods[rowIndex].label}%</span>
																</td>

																<td 
																// style={{ width: '10%' }} 
																className='dt-matrix-y-axis'
																>
																	<span hidden={isMobile} className='dt-matrix-y-axis-title'>{t(likelihoods[rowIndex]?.title)}</span>
																</td>
															</>
														) : null}

														{/* columns */}
														{row.map((col, colIndex) => {
															return (
																<td
																	key={col.id}
																	style={{
																		backgroundColor: `#${col.color}`,
																		// width: `calc(80% / ${matrix.length} )`,
																		width: "40px"
																	}}
																	className='dt-matrix-column'
																>
																	<div>
																		{col?.risks && col?.risks?.length > 0 
																		? renderRiskBullets(col.risks)
																		: null}
																	</div>
																	<span>
																		{ 
																			col?.risks && renderRisksDrop(
																				col.id,
																				col.risks
																			) 
																		}
																	</span>
																</td>
															);
														})}
													</tr>
												</>
											);
									  })
									: null}
							</tbody>
						</table>
					) : (
						<table className='dt-matrix'>
							{/* captions */}
							<caption
								style={{
									margin: 'auto',
									textAlign: 'center',
								}}
							>
								<Badge className='m-1 p-3 lg dt-skeleton dt-skeleton-table-caption-badge' style={{ display: 'inline-block' }}></Badge>
								<Badge className='m-1 p-3 lg dt-skeleton dt-skeleton-table-caption-badge' style={{ display: 'inline-block' }}></Badge>
								<Badge className='m-1 p-3 lg dt-skeleton dt-skeleton-table-caption-badge' style={{ display: 'inline-block' }}></Badge>
								<Badge className='m-1 p-3 lg dt-skeleton dt-skeleton-table-caption-badge' style={{ display: 'inline-block' }}></Badge>
								<Badge className='m-1 p-3 lg dt-skeleton dt-skeleton-table-caption-badge' style={{ display: 'inline-block' }}></Badge>
							</caption>

							<tbody>
								{/* x axis label */}
								<tr>
									<td></td>
									<td></td>
									<td></td>
									<td className='dt-matrix-x-axis-label' colSpan='5'>
										<div>
											Damage in <span className='dt-skeleton'></span>
										</div>
									</td>
								</tr>

								{/* x axis */}
								<tr>
									<td></td>
									<td></td>
									<td></td>
									<td className='dt-matrix-info dt-matrix-x-axis'>
										<div>
											<span className='dt-matrix-x-axis-title dt-skeleton'></span>
											<br />
											<span className='dt-matrix-x-axis-value dt-skeleton'></span>
										</div>
									</td>
									<td className='dt-matrix-info dt-matrix-x-axis'>
										<div>
											<span className='dt-matrix-x-axis-title dt-skeleton'></span>
											<br />
											<span className='dt-matrix-x-axis-value dt-skeleton'></span>
										</div>
									</td>
									<td className='dt-matrix-info dt-matrix-x-axis'>
										<div>
											<span className='dt-matrix-x-axis-title dt-skeleton'></span>
											<br />
											<span className='dt-matrix-x-axis-value dt-skeleton'></span>
										</div>
									</td>
									<td className='dt-matrix-info dt-matrix-x-axis'>
										<div>
											<span className='dt-matrix-x-axis-title dt-skeleton'></span>
											<br />
											<span className='dt-matrix-x-axis-value dt-skeleton'></span>
										</div>
									</td>
									<td className='dt-matrix-info dt-matrix-x-axis'>
										<div>
											<span className='dt-matrix-x-axis-title dt-skeleton'></span>
											<br />
											<span className='dt-matrix-x-axis-value dt-skeleton'></span>
										</div>
									</td>
								</tr>

								<tr>
									<td style={{ width: '4%' }} className='dt-matrix-y-axis-label' rowSpan='6'>
										Likelihood
									</td>
									<td style={{ width: '6%' }} className='dt-matrix-y-axis'>
										<div>
											<span className='dt-matrix-y-axis-value dt-skeleton'></span>
										</div>
									</td>
									<td style={{ width: '10%' }} className='dt-matrix-y-axis'>
										<div>
											<span className='dt-matrix-y-axis-title dt-skeleton'></span>
										</div>
									</td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
								</tr>

								<tr>
									<td style={{ width: '6%' }} className='dt-matrix-y-axis'>
										<div>
											<span className='dt-matrix-y-axis-value dt-skeleton'></span>
										</div>
									</td>
									<td style={{ width: '10%' }} className='dt-matrix-y-axis'>
										<div>
											<span className='dt-matrix-y-axis-title dt-skeleton'></span>
										</div>
									</td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
								</tr>
								<tr>
									<td style={{ width: '6%' }} className='dt-matrix-y-axis'>
										<div>
											<span className='dt-matrix-y-axis-value dt-skeleton'></span>
										</div>
									</td>
									<td style={{ width: '10%' }} className='dt-matrix-y-axis'>
										<div>
											<span className='dt-matrix-y-axis-title dt-skeleton'></span>
										</div>
									</td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
								</tr>
								<tr>
									<td style={{ width: '6%' }} className='dt-matrix-y-axis'>
										<div>
											<span className='dt-matrix-y-axis-value dt-skeleton'></span>
										</div>
									</td>
									<td style={{ width: '10%' }} className='dt-matrix-y-axis'>
										<div>
											<span className='dt-matrix-y-axis-title dt-skeleton'></span>
										</div>
									</td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
								</tr>
								<tr>
									<td style={{ width: '6%' }} className='dt-matrix-y-axis'>
										<div>
											<span className='dt-matrix-y-axis-value dt-skeleton'></span>
										</div>
									</td>
									<td style={{ width: '10%' }} className='dt-matrix-y-axis'>
										<div>
											<span className='dt-matrix-y-axis-title dt-skeleton'></span>
										</div>
									</td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
									<td style={{ width: '16%' }} className='dt-matrix-column dt-skeleton'></td>
								</tr>
							</tbody>
						</table>
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default memo(RiskMatrix);
