import { withNamespaces } from 'react-i18next';
import { memo, useEffect, useState } from 'react';
import { Col, Container, Row, Input, Label, FormGroup, Form, Button } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Select from "react-select";
import { useQuery, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { withRouter } from "react-router-dom";
import DataProtectionHardwareService from '../../../apis/DataProtectionHardwareService';
// styles
import './style.scss';
// components
import PageBody from '../../../components/page-body';
import PageHeader from '../../../components/page-header';
import PageContent from '../../../components/page-content';
import CommonService from '../../../apis/CommonService';
import { connect } from "react-redux";
import MultiSelect from "src/modules/data-protection/components/MultiSelect";

const DataProtectionHardwareCreate = (props) => {
	const history = useHistory();

	const [submitingFormStatus, setSubmitingFormStatus] = useState(false);

	const [deviceTypeList, setDeviceTypeList] = useState([]);
	const [deviceType, setDeviceType] = useState(null);
	const [deviceType_errors, setDeviceType_errors] = useState([]);

	const [serialNo, setSerialNo] = useState('');
	const [serialNo_errors, setSerialNo_errors] = useState([]);

	const [manufacturersList, setManufacturersList] = useState([]);
	const [customManufacturer, setCustomManufacturer] = useState('');
	const [selectedManufacturer, setSelectedManufacturer] = useState(null);
	const [manufacturer_errors, setManufacturer_errors] = useState([]);

	const [modelsList, setModelsList] = useState([]);
	const [customModel, setCustomModel] = useState('');
	const [selectedModel, setSelectedModel] = useState(null);
	const [model_errors, setModel_errors] = useState([]);

	const [typeOfOwnerShip, setTypeOfOwnerShip] = useState(undefined);
	const [typeOfOwnerShip_errors, setTypeOfOwnerShip_errors] = useState([]);
	const [owner, setOwner] = useState(undefined);
	const [owner_errors, setOwner_errors] = useState([]);

	const [operatingSystemsList, setOperatingSystemsList] = useState([]);
	const [customOperatingSystem, setCustomOperatingSystem] = useState('');
	const [selectedOperatingSystem, setSelectedOperatingSystem] = useState(null);
	const [operatingSystem_errors, setOperatingSystem_errors] = useState([]);

	const [hardwareNo, setHardwareNo] = useState('');
	const [hardwareNo_errors, setHardwareNo_errors] = useState([]);

	const [priority, setPriority] = useState(undefined);
	const [priority_errors, setPriority_errors] = useState([]);
	const [users, setUsers] = useState([]);

	const [legalEntitiesOptions, setLegalEntitiesOptions] = useState([]);
	const [selectedLegalEntities, setSelectedLegalEntities] = useState([]);
	const [legalEntities_errors, setLegalEntities_errors] = useState([]);

	const { t } = props;

	const handleGetSelectedManufacturerName = (manufacturer) => {
		if (manufacturer.value === '-custom-') return customManufacturer;
		else return manufacturer.label;
	};

	const handleGetSelectedModelName = (model) => {
		if (model.value === '-custom-') return customModel;
		else return model.label;
	};

	const handleGetSelectedOperatingSystemsName = (operating) => {
		if (operating.value === '-custom-') return customOperatingSystem;
		return operating.label;
	};

	const handleResetFormErrors = () => {
		setDeviceType_errors([]);
		setSerialNo_errors([]);
		setManufacturer_errors([]);
		setModel_errors([]);
		setTypeOfOwnerShip_errors([]);
		setOwner_errors([]);
		setOperatingSystem_errors([]);
		setLegalEntities_errors([]);
		setHardwareNo_errors([]);
		setPriority_errors([]);
	};

	const handleValidateForm = () => {
		let isValid = true;
		handleResetFormErrors();

		if (!deviceType) {
			setDeviceType_errors([props.t('Device type is required.')]);
			isValid = false;
		}

		if (!serialNo) {
			setSerialNo_errors([props.t('Serial no is required.')]);
			isValid = false;
		}

		if (!selectedManufacturer) {
			setManufacturer_errors([props.t('Manufacturer is required.')]);
			isValid = false;
		} 
		else if (selectedManufacturer.value === '-custom-' && !customManufacturer) {
			setManufacturer_errors([props.t('Please write a custom manufacturer.')]);
			isValid = false;
		}

		if (!selectedModel) {
			setModel_errors([props.t('Model is required.')]);
			isValid = false;
		} else if (selectedModel.value === '-custom-' && !customModel) {
			setModel_errors([props.t('Please write a custom model.')]);
			isValid = false;
		}

		if (typeOfOwnerShip !== 'PRIVATE' && typeOfOwnerShip !== 'COMPANY') {
			setTypeOfOwnerShip_errors([props.t('Type of ownership is required.')]);
			isValid = false;
		}

		if (!owner) {
			setOwner_errors([props.t('Owner is required.')]);
			isValid = false;
		}

		if (!selectedOperatingSystem ) {
			setOperatingSystem_errors([props.t('Operating System is required.')]);
			isValid = false;
		} else if (selectedOperatingSystem.value === '-custom-' && !customOperatingSystem) {
			setOperatingSystem_errors([props.t('Please write a custom operating system.')]);
			isValid = false;
		}

		if (!selectedLegalEntities.length ) {
			setLegalEntities_errors([props.t('Legal entity is required.')]);
			isValid = false;
		}

		if (!hardwareNo) {
			setHardwareNo_errors([props.t('Hardware No is required.')]);
			isValid = false;
		}

		if (priority !== 'LOW' && priority !== 'MEDIUM' && priority !== 'HIGH') {
			setPriority_errors([props.t('Priority is required.')]);
			isValid = false;
		}

		return isValid;
	};

	const handleSubmit = () => {
		const legalEntityValues = selectedLegalEntities?.map((item) => item?.id.toString());
		if (handleValidateForm())
			addHardwareMutation.mutate({
				assetType: deviceType.value,
				serialNo: serialNo,
				manufacturer: handleGetSelectedManufacturerName(selectedManufacturer),
				model: handleGetSelectedModelName(selectedModel),
				typeOfOwnerShip: typeOfOwnerShip,
				owner: owner,
				operatingSystem: handleGetSelectedOperatingSystemsName(selectedOperatingSystem),
				legalEntities: legalEntityValues,
				hardwareNo: hardwareNo,
				priority: priority,
			});
	};

	const handleFetchManufacturersListQuery = useQuery({
		queryKey: ['data-protection-manufacturers-list'],
		queryFn: async () => {
			const service = DataProtectionHardwareService.getInstance();

			return await service.manufacturers(1000, 1);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			if (process.env.NODE_ENV === 'development') console.error(error);

			toast(props.t('An error occurred while fetching hardwares manufacturers.'), {
				type: 'error',
			});
		},
	});

	const handleFetchModelsListQuery = useQuery({
		queryKey: ['data-protection-models-list'],
		queryFn: async () => {
			const service = DataProtectionHardwareService.getInstance();

			return await service.models(1000, 1);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			if (process.env.NODE_ENV === 'development') console.error(error);

			toast(props.t('An error occured while fetching hardwares models.'), {
				type: 'error',
			});
		},
	});

	const handleFetchOperatingSystemsListQuery = useQuery({
		queryKey: ['data-protection-operating-systems-list'],
		queryFn: async () => {
			const service = DataProtectionHardwareService.getInstance();

			return await service.operatingSystems(1000, 1);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			if (process.env.NODE_ENV === 'development') console.error(error);

			toast(props.t('An error occured while fetching hardwares operating systems.'), {
				type: 'error',
			});
		},
	});

	const handleFetchDeviceTypesListQuery = useQuery({
		queryKey: ['data-protection-deviceTypes-list'],
		queryFn: async () => {
			const service = DataProtectionHardwareService.getInstance();

			return await service.deviceTypes();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			if (process.env.NODE_ENV === 'development') console.error(error);

			toast(props.t('An error occurred while fetching hardwares device types.'), {
				type: 'error',
			});
		},
	});

	const addHardwareMutation = useMutation({
		mutationFn: async (hardware) => {
			const service = DataProtectionHardwareService.getInstance();
			return await service.create({
				assets: [hardware],
			});
		},
		onSuccess: () => {
			toast(props.t('Hardware successfully added.'), {
				type: 'success',
			});
			history.push('/admin/data-protection/hardwares');
		},
		onError: () => {
			toast(props.t('An error occurred while adding hardware.'), {
				type: 'error',
			});
		},
		onMutate: () => {
			setSubmitingFormStatus(true);
		},
		onSettled: () => {
			setSubmitingFormStatus(false);
		}
	});

	const fetchUsers = useQuery({
		queryKey: ['users'],
		queryFn: async () => {
		const service = CommonService.getInstance();

		return await service.analystAdminsAndAnalysts();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
		if (process.env.NODE_ENV === 'development') console.error(error);

		toast(props.t("An error occured while fetching users."), {
			type: "error",
		});
		},
	});

	 // Fetch Legal Enteties Options
	 const fetchLegalEntitiesOptions = useQuery({
		queryKey: ["data-protection-enteties-list"],
		queryFn: async () => {
		  const service = CommonService.getInstance();
	
		  return await service.legalEntities(props?.Organization?.id);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
		  if (process.env.NODE_ENV === "development") console.error(error);
	
		  toast(props.t("An error occurred while fetching legal entities."), {
			type: "error",
		  });
		},
	  });

	useEffect(() => {
		if (fetchUsers.data && fetchUsers.data?.length) {
			const users = fetchUsers.data.map((u) => {
				return {
					value: u.id,
					label: u.first_name + " " + u.last_name,
				};
			});
			setUsers(users);
		}
	}, [fetchUsers.data]);

	useEffect(() => {
		const operatings = handleFetchOperatingSystemsListQuery?.data?.operationSystems.map((os) => {
			return {
				label : os.name,
				value : os.id
			};
		});

		setOperatingSystemsList([
			{
				label : props.t('Custom'),
				value : '-custom-'
			},
			...(operatings || [])
		]);
	}, [handleFetchOperatingSystemsListQuery.data]);

	useEffect(() => {
		const models = handleFetchModelsListQuery?.data?.models.map((model) => {
			return {
				label : model.name,
				value : model.id
			};
		});

		setModelsList([
			{
				label : props.t('Custom'),
				value : '-custom-'
			},
			...(models || [])
		]);
	}, [handleFetchModelsListQuery.data]);

	useEffect(() => {
		const manufacturers = handleFetchManufacturersListQuery?.data?.manufacturers.map((m) => {
			return {
				label : m.name,
				value : m.id
			};
		});

		setManufacturersList([
			{
				label : props.t('Custom'),
				value : '-custom-'
			},
			...(manufacturers || [])
		]);
	}, [handleFetchManufacturersListQuery.data]);

	useEffect(() => {
		const types = handleFetchDeviceTypesListQuery?.data?.map((deviceType) => {
			return {
				label : t(deviceType.replaceAll('_', ' ').toLowerCase()),
				value : deviceType
			};
		});

		setDeviceTypeList(types || []);
	}, [handleFetchDeviceTypesListQuery.data]);

	useEffect(() => {
		const entities = fetchLegalEntitiesOptions?.data;
		if(entities){

		setLegalEntitiesOptions(entities);}
	  }, [fetchLegalEntitiesOptions?.data])

	return (
    <PageContent classNames="data-protection-hardware-add-page">
      <Container fluid>
        {/* page header */}
        <PageHeader title={props.t("Add new hardware")} />

        {/* page body */}
        <PageBody classNames="p-4">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Row className="mb-4">
              <Col sm="12" md="6" lg="4">
                <FormGroup>
                  <Label
                    for="device-type"
                    className={deviceType_errors.length ? "text-danger" : ""}
                  >
                    {props.t("Device Type")}
                  </Label>
                  <Select
                    className="mt-1 text-capitalize"
                    placeholder={props.t("Select") + "..."}
                    id="device-type"
                    name="device-type"
                    value={deviceType}
                    onChange={(newType) => {
                      setDeviceType(newType);
                      setDeviceType_errors([]);
                    }}
                    options={deviceTypeList}
                    menuPortalTarget={document.body}
                    isLoading={
                      handleFetchDeviceTypesListQuery.isLoading ||
                      handleFetchDeviceTypesListQuery.isFetching
                    }
                    isDisabled={
                      handleFetchDeviceTypesListQuery.isLoading ||
                      handleFetchDeviceTypesListQuery.isFetching
                    }
                  />

                  {deviceType_errors.length
                    ? deviceType_errors.map((e) => {
                        return (
                          <small key={e} className="text-danger">
                            {e}
                          </small>
                        );
                      })
                    : null}
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="4">
                <FormGroup>
                  <Label
                    for="serial-mo"
                    className={serialNo_errors.length ? "text-danger" : ""}
                  >
                    {props.t("Serial No")}
                  </Label>
                  <Input
                    className="mt-1 text-capitalize"
                    id="serial-mo"
                    name="serial-mo"
                    type="text"
                    value={serialNo}
                    onChange={(e) => {
                      setSerialNo(e.target.value);
                      setSerialNo_errors([]);
                    }}
                    invalid={serialNo_errors.length ? true : false}
                  />
                  {serialNo_errors.length
                    ? serialNo_errors.map((e) => {
                        return (
                          <small key={e} className="text-danger">
                            {e}
                          </small>
                        );
                      })
                    : null}
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="4">
                <FormGroup>
                  <Label
                    for="type-of-ownership"
                    className={
                      typeOfOwnerShip_errors.length ? "text-danger" : ""
                    }
                  >
                    {props.t("Type of Ownership")}
                  </Label>
                  <Input
                    className="mt-1 text-capitalize"
                    id="type-of-ownership"
                    name="type-of-ownership"
                    type="select"
                    value={typeOfOwnerShip}
                    onChange={(e) => {
                      setTypeOfOwnerShip(e.target.value);
                      setTypeOfOwnerShip_errors([]);
                    }}
                    invalid={typeOfOwnerShip_errors.length ? true : false}
                    defaultValue={undefined}
                  >
                    <option value={undefined}>{props.t("Select")}</option>
                    <option value="PRIVATE">{props.t("Private")}</option>
                    <option value="COMPANY">{props.t("Company")}</option>
                  </Input>
                  {typeOfOwnerShip_errors.length
                    ? typeOfOwnerShip_errors.map((e) => {
                        return (
                          <small key={e} className="text-danger">
                            {e}
                          </small>
                        );
                      })
                    : null}
                </FormGroup>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col sm="12" md="6" lg="4">
                <FormGroup>
                  <Label
                    for="hardware-no"
                    className={hardwareNo_errors.length ? "text-danger" : ""}
                  >
                    {props.t("Hardware No")}
                  </Label>
                  <Input
                    className="mt-1 text-capitalize"
                    id="hardware-no"
                    name="hardware-no"
                    type="text"
                    onChange={(e) => {
                      setHardwareNo(e.target.value);
                      setHardwareNo_errors([]);
                    }}
                    invalid={hardwareNo_errors.length ? true : false}
                  />
                  {hardwareNo_errors.length
                    ? hardwareNo_errors.map((e) => {
                        return (
                          <small key={e} className="text-danger">
                            {e}
                          </small>
                        );
                      })
                    : null}
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="4">
                <FormGroup>
                  <Label
                    for="priority"
                    className={priority_errors.length ? "text-danger" : ""}
                  >
                    {props.t("Priority")}
                  </Label>
                  <Input
                    className="mt-1 text-capitalize"
                    id="priority"
                    name="priority"
                    type="select"
                    value={priority}
                    onChange={(e) => {
                      setPriority(e.target.value);
                      setPriority_errors([]);
                    }}
                    invalid={priority_errors.length ? true : false}
                    defaultValue={undefined}
                  >
                    <option value={undefined}>{props.t("Select")}</option>
                    <option value="LOW">{props.t("Low")}</option>
                    <option value="MEDIUM">{props.t("Medium")}</option>
                    <option value="HIGH">{props.t("High")}</option>
                  </Input>
                  {priority_errors.length
                    ? priority_errors.map((e) => {
                        return (
                          <small key={e} className="text-danger">
                            {e}
                          </small>
                        );
                      })
                    : null}
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="4">
                <FormGroup>
                  <Label
                    for="owner"
                    className={owner_errors.length ? "text-danger" : ""}
                  >
                    {props.t("Owner")}
                  </Label>
                  <Input
                    className="mt-1 text-capitalize"
                    id="owner"
                    name="owner"
                    type="select"
                    value={owner}
                    onChange={(e) => {
                      setOwner(e.target.value);
                      setOwner_errors([]);
                    }}
                    invalid={owner_errors.length ? true : false}
                    defaultValue={undefined}
                  >
                    <option value={null}>
                      {props.t(fetchUsers.isLoading ? "Loading" : "Select")}...
                    </option>
                    {users.map((user) => (
                      <option key={user.value} value={user.value}>
                        {user.label}
                      </option>
                    ))}
                  </Input>
                  {owner_errors.length
                    ? owner_errors.map((e) => {
                        return (
                          <small key={e} className="text-danger">
                            {e}
                          </small>
                        );
                      })
                    : null}
                </FormGroup>
              </Col>
            </Row>
            <Row className="mb-4">
              {/* manufacturer */}
              <Col sm="12" md="6" lg="4">
                <FormGroup>
                  <Label
                    for="manufacturer"
                    className={manufacturer_errors.length ? "text-danger" : ""}
                  >
                    {props.t("Manufacturer")}
                  </Label>
                  <Select
                    className="mt-1 text-capitalize"
                    placeholder={props.t("Select") + "..."}
                    id="select-manufacturer"
                    name="select-manufacturer"
                    value={selectedManufacturer}
                    onChange={(newM) => {
                      setSelectedManufacturer(newM);
                      setManufacturer_errors([]);
                    }}
                    options={manufacturersList}
                    menuPortalTarget={document.body}
                    isLoading={
                      handleFetchManufacturersListQuery.isLoading ||
                      handleFetchManufacturersListQuery.isFetching
                    }
                    isDisabled={
                      handleFetchManufacturersListQuery.isLoading ||
                      handleFetchManufacturersListQuery.isFetching
                    }
                  />
                  {selectedManufacturer?.value === "-custom-" ? (
                    <Input
                      className="mt-1"
                      id="manufacturer"
                      name="manufacturer"
                      type="text"
                      value={customManufacturer}
                      onChange={(e) => {
                        setCustomManufacturer(e.target.value);
                        setManufacturer_errors([]);
                      }}
                      invalid={manufacturer_errors.length ? true : false}
                      placeholder={props.t("Type a custom manufacturer")}
                    />
                  ) : null}
                  {manufacturer_errors.length
                    ? manufacturer_errors.map((e) => {
                        return (
                          <small key={e} className="text-danger">
                            {e}
                          </small>
                        );
                      })
                    : null}
                </FormGroup>
              </Col>
              {/* model */}
              <Col sm="12" md="6" lg="4">
                <FormGroup>
                  <Label
                    for="model"
                    className={model_errors.length ? "text-danger" : ""}
                  >
                    {props.t("Model")}
                  </Label>
                  <Select
                    className="mt-1 text-capitalize"
                    placeholder={props.t("Select") + "..."}
                    id="selected-model"
                    name="selected-model"
                    value={selectedModel}
                    onChange={(newModel) => {
                      setSelectedModel(newModel);
                      setModel_errors([]);
                    }}
                    options={modelsList}
                    menuPortalTarget={document.body}
                    isLoading={
                      handleFetchModelsListQuery.isLoading ||
                      handleFetchModelsListQuery.isFetching
                    }
                    isDisabled={
                      handleFetchModelsListQuery.isLoading ||
                      handleFetchModelsListQuery.isFetching
                    }
                  />
                  {selectedModel?.value === "-custom-" ? (
                    <Input
                      className="mt-1 text-capitalize"
                      id="model"
                      name="model"
                      type="text"
                      value={customModel}
                      onChange={(e) => {
                        setCustomModel(e.target.value);
                        setModel_errors([]);
                      }}
                      invalid={model_errors.length ? true : false}
                    />
                  ) : null}
                  {model_errors.length
                    ? model_errors.map((e) => {
                        return (
                          <small key={e} className="text-danger">
                            {e}
                          </small>
                        );
                      })
                    : null}
                </FormGroup>
              </Col>
              {/* operating system */}
              <Col sm="12" md="6" lg="4">
                <FormGroup>
                  <Label
                    for="operating-system"
                    className={
                      operatingSystem_errors.length ? "text-danger" : ""
                    }
                  >
                    {props.t("Operating System")}
                  </Label>
                  <Select
                    className="mt-1 text-capitalize"
                    placeholder={props.t("Select") + "..."}
                    id="selected-operating-system"
                    name="selected-operating-system"
                    value={selectedOperatingSystem}
                    onChange={(newOP) => {
                      setSelectedOperatingSystem(newOP);
                      setOperatingSystem_errors([]);
                    }}
                    options={operatingSystemsList}
                    menuPortalTarget={document.body}
                    isLoading={
                      handleFetchOperatingSystemsListQuery.isLoading ||
                      handleFetchOperatingSystemsListQuery.isFetching
                    }
                    isDisabled={
                      handleFetchOperatingSystemsListQuery.isLoading ||
                      handleFetchOperatingSystemsListQuery.isFetching
                    }
                  />
                  {selectedOperatingSystem?.value === "-custom-" ? (
                    <Input
                      className="mt-1 text-capitalize"
                      id="operating-system"
                      name="operating-system"
                      type="text"
                      value={customOperatingSystem}
                      onChange={(e) => {
                        setCustomOperatingSystem(e.target.value);
                        setOperatingSystem_errors([]);
                      }}
                      invalid={operatingSystem_errors.length ? true : false}
                      placeholder={props.t("Type a custom operating system")}
                    />
                  ) : null}
                  {operatingSystem_errors.length
                    ? operatingSystem_errors.map((e) => {
                        return (
                          <small key={e} className="text-danger">
                            {e}
                          </small>
                        );
                      })
                    : null}
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-4">
              {/* Legal Entities */}
              <Col sm="12" md="6" lg="4">
                <FormGroup>
                  <Label
                    for="entities"
                    className={legalEntities_errors.length ? "text-danger" : ""}
                  >
                    {props.t("Legal Entity")}
                  </Label>
           
                  <MultiSelect
                    options={legalEntitiesOptions}
                    selectedValues={selectedLegalEntities}
                    onChange={(newOP) => {
					
                       setSelectedLegalEntities(newOP);
                       setLegalEntities_errors([]);
                    }}
                    placeholder={t("Select...")}
                    isDisabled={fetchLegalEntitiesOptions.isLoading}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="pt-3">
              <Col sm="12" md="6" lg="4">
                <Button
                  disabled={submitingFormStatus}
                  className="btn-primary"
                  type="submit"
                >
                  {props.t("Save")}
                </Button>
                <Link
                  to={"/admin/data-protection/hardwares"}
                  className="btn btn-danger mx-2"
                >
                  {props.t("Back to list")}
                </Link>
              </Col>
            </Row>
          </Form>
        </PageBody>
      </Container>
    </PageContent>
  );
};

const mapStatetoProps = (state) => {

    const { Organization } = state;
  
    return {
      Organization
    };
  };
  
export default withNamespaces()(withRouter(connect(mapStatetoProps)(DataProtectionHardwareCreate)));


