import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { TbLoader } from 'react-icons/tb';
import NotificationsHandler from './NotificationsHandler';
import { loadMoreNotifications, markAllNotificationsAsRead } from 'src/store/actions';
import './NotificationDropdown.scss';

const NotificationDropdown = ({
    t,
    notifications,
     
    markAllAsRead,
    loadMore
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const firstNewNotificationRef = useRef();

    const readCount = ((notifications.list || []).filter((notification) => notification.isRead)).length;
    const calculatedTotalCount = notifications.totalCount - readCount;

    return (
        <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} className='d-inline-block'>
            <DropdownToggle tag='button' className='btn header-item noti-icon waves-effect' id='page-header-notifications-dropdown'>
                <i className='ri-notification-3-line'></i>
                {calculatedTotalCount > 0 ? (
                    <sup
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'super',
                            fontSize: 'medium',
                            padding: 0,
                            marginLeft: '-0.5rem',
                        }}
                    >
                        <span className='badge rounded-pill bg-danger' style={{ top: -7 }}>
                            {calculatedTotalCount}
                        </span>
                    </sup>
                ) : null}
            </DropdownToggle>

            <DropdownMenu className='dropdown-menu-end dropdown-menu-lg p-0' aria-labelledby='page-header-notifications-dropdown'>
                <div className='p-3'>
                    <Row className='align-items-center'>
                        <Col>
                            <h6 className='m-0'>{t('Notifications')}</h6>
                        </Col>

                        {calculatedTotalCount ? (
                            <Col style={{ textAlign: 'right', paddingRight: 9 }}>
                                <button
                                    type='button'
                                    style={{
                                        outline: 0,
                                        border: 0,
                                        backgroundColor: 'transparent',
                                        borderRadius: '50%',
                                        padding: 12,
                                    }}
                                    onClick={markAllAsRead}
                                    title={t('Clear all notifications.')}
                                >
                                    <i className='ri-notification-off-line' />
                                </button>
                            </Col>
                        ) : null}
                    </Row>
                </div>

                <SimpleBar
                    style={{
                        maxHeight: '230px',
                    }}
                    scrollableNodeProps={{ ref: firstNewNotificationRef }}
                >
                    <NotificationsHandler />
                </SimpleBar>

                <div className='p-2 border-top'>
                    <button
                        disabled={notifications.totalCount <= notifications.list.length || notifications.isLoading}
                        type='button'
                        className='btn btn-sm btn-link btn-block text-center'
                        style={{
                            fontSize: '.9em',
                            textTransform: 'capitalize',
                            textDecoration: 'none',
                        }}
                        onClick={loadMore}
                    >
                        <i className='ri-add-line' style={{ position: 'relative', top: 3, marginRight: 4 }}></i>
                        {t('View more')}
                        {notifications.isLoading ? <TbLoader style={{ marginLeft: '6px' }} /> : null}
                    </button>
                </div>
            </DropdownMenu>
        </Dropdown>
    )
}

const mapStatetoProps = (state) => {
	return {
        user            :   state.Login?.user,
        token           :   state.Login?.token,
		notifications   :   state.AppNotification,
        modules         :   state.Modules
	};
};

const mapDispachToProps = (dispatch) => {
	return {
        markAllAsRead   :   () => dispatch(markAllNotificationsAsRead()),
        loadMore        :   () => dispatch(loadMoreNotifications())
    };
};

export default connect(
    mapStatetoProps, mapDispachToProps
)(withNamespaces()(
    NotificationDropdown
));
