import { withNamespaces } from 'react-i18next';
import { Container, Row, Col, Card, CardBody, NavItem, NavLink, CardTitle, Button } from 'reactstrap';
import axios from 'axios';

import QuestionarConfirmForm from '../../../components/questionar/ConfirmForm';

import { toast } from "react-toastify";
import { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import { withRouter } from "react-router-dom";

import Form from 'react-bootstrap/Form';

import {
	API_BASE_URL,
	ROUTE_PATH_THIRDPARTY_HOME,
	ROUTE_PATH_THIRDPARTY_FILL_QUESTIONNAIRE
} from '../../../constants';

import {
	confirmPromptHide
} from 'src/store/actions';

import Spinner from 'react-bootstrap/Spinner';

import UrlUtils from 'src/services/utils/UrlUtils';

import CompanyDetails from './tabs-content/company-details/company-details';
import FillQuestionnaire from './tabs-content/fill-questionnaire';

const ThirdPartyQuestioner = (props) => {
    const {
        t,
		exitConfirmPromptOptions,
		hideConfirmPrompt,
    } = props;

	const urlUtils = new UrlUtils();

	const [ questionnaireData, setQuestionnaireData ] = useState({
		reportCaseId	:	null,
		questions		:	[],
		answers			:	null
	});

    const [ isCreateNewFormSubmiting, setIsCreateNewFormSubmiting ] = useState(false);
	const [ declarationFormConfirmed, setDeclarationFormConfirmed ] = useState(false);
	const [ tabs, setTabs ] = useState([
		{
			name		:	'company-details',
			title		:	'Company Details',
			completed	:	false,
			isCurrent	:	true
		},
		{
			name		:	'fill-questions-step',
			title		:	'Fill Questionnaire',
			completed	:	false,
			isCurrent	:	false
		},
		{
			name		:	'review-details',
			title		:	'Review Details',
			completed	:	false,
			isCurrent	:	false
		},
		{
			name		:	'declaration',
			title		:	'Declaration',
			completed	:	false,
			isCurrent	:	false
		}	
	]);

	const setActiveTab = (tab) => {
		setTabs((tabs) => {
			const currentActiveTabIndex = tabs.findIndex((t) => t.isCurrent);
			const nextActiveTabIndex = tabs.findIndex((t) => t.name === tab);

			if(currentActiveTabIndex > -1){
				tabs[currentActiveTabIndex].completed = true;
				tabs[currentActiveTabIndex].isCurrent = false;
			}

			if(nextActiveTabIndex > -1){
				tabs[nextActiveTabIndex].isCurrent = true;
			}

			return [
				...tabs
			];
		});
	}

	const handleSubmit = () => {
		setIsCreateNewFormSubmiting(true);

		axios.post(`${API_BASE_URL}/report/${questionnaireData.reportCaseId}/submit`, questionnaireData.answers, {
			headers: {
				Authorization: `Bearer ${props.token}`,
			}
		})
		.then(() => {
			toast(t('Request Submitted'), {
				type: 'success',
			});

			(exitConfirmPromptOptions.readyToShow) && hideConfirmPrompt();

			setTimeout(() => {
				props.history.push(ROUTE_PATH_THIRDPARTY_HOME);
			}, 250);
		})
		.catch((error) => {
			console.error(error);

			toast(t('Error!'), {
				type: 'error',
			});
		})
		.finally(() => {
			setIsCreateNewFormSubmiting(false);
		});
	}

	const renderStepsWizard = () => {
		const currentActiveTabIndex = tabs.findIndex((t) => t.isCurrent);

		return (
			<Row>
				<Col sm={12}>
					<div className="m-2 bg-white wizard-steps-container mb-5">
						<div className="twitter-bs-wizard">
							<ul className="twitter-bs-wizard-nav nav-justified nav nav-pills p-3">
								{
									tabs.map((tab, index) => {
										return (
											<NavItem key={ index } className={ tab.completed ? 'completed' : '' }>
												<NavLink className={ (index <= currentActiveTabIndex ? 'active ' : '') + (tab.isCurrent ? 'current' : '') }>
													<span className="step-number">
														{
															tab.completed ? (
																<i className='fa fa-check'></i>
															) : ( index + 1 )
														}
													</span>

													<span className="step-title">
														{props.t(tab.title)}
													</span>
												</NavLink>
											</NavItem>
										);
									})
								}
							</ul>
						</div>
					</div>
				</Col>
			</Row>
		)
	}

	const renderConfirmForm = () => {
		return (
			<div className="m-2">
				<QuestionarConfirmForm 
					questions={ questionnaireData.questions }
					answers={ questionnaireData.answers }
					isFormSubmiting={ false }
					onConfirmButtonClicked={() => {
						setActiveTab('declaration');
					}}
					onBackButtonClicked={() => {
						setActiveTab('fill-questions-step');
					}}
					settings={{
						buttons	:	{
							back	:	{
								enabled	:	true
							},
							confirm	:	{
								enabled	:	true,
								title	:	'Next'
							}
						}
					}}
				/>
			</div>
		)
	}

	const renderDeclarationTabContent = () => {
		return (
			<div className="declaration-confirm-form m-2 mt-0">
				<Card>
					<CardBody>
						<CardTitle className="mb-3">
							{ t('Declaration of Accuracy and Completeness') }
						</CardTitle>

						<p className="mb-5">
							{
								t('I hereby affirm that the information provided in this questionnaire is true, accurate, and complete to the best of my knowledge and belief. I understand that any false statements, omissions, or misrepresentations may result in consequences as determined by the entity requesting this information (which could include legal actions or disqualification from a process, depending on the context). I commit to notify the relevant parties immediately should any information change after the submission of this questionnaire.')
							}
						</p>

						<div>
							<Form.Check
								onChange={({ target }) => setDeclarationFormConfirmed(target.checked)} 
								type='checkbox' 
								id={`confirm-declaration-checkbox`} 
								label={t('I confirm the information above.')} />
						</div>
					</CardBody>
				</Card>

				<div className="d-flex justify-content-end align-items-center mb-4">
					<Button color="primary"
						disabled={ !declarationFormConfirmed || isCreateNewFormSubmiting }
						onClick={ handleSubmit }
						type="button">
                            {
                                isCreateNewFormSubmiting ? (
                                    <>
                                        <Spinner className="me-2" animation="border" size="sm"/>
                                        {t('Submitting')}...
                                    </>
                                ) : (
                                    <>{t('Submit')}</>
                                )
                            }
					</Button>
				</div>
			</div>
		);
	}

    const redirectUserToLoginPageIfNotLoggedIn = () => {
        if(props.App.loaded){
			if(!props.user){
				return props.history.push(`/third-party/signin?redirectUrl=${urlUtils.getAppRootPath(props.Organization.id, props.Organization.url)}${ROUTE_PATH_THIRDPARTY_FILL_QUESTIONNAIRE}`);
			}
		}
    }

	useEffect(() => {
		if(questionnaireData.answers){
			setActiveTab('review-details');
		}
	}, [ questionnaireData.answers ]);

	useEffect(() => {
        redirectUserToLoginPageIfNotLoggedIn();
	}, []);

    useEffect(() => {
        redirectUserToLoginPageIfNotLoggedIn();
	}, [ props.user ]);

	const activeTab = tabs.find((t) => t.isCurrent);

    if(!props.user) return null;

    return (
        <div className='page-content'>
			<Container fluid>
				{ renderStepsWizard() }

                <Row>
                    <Col sm={12}>

						{
							activeTab.name === 'company-details' && (
								<CompanyDetails onSubmitSucceed={() => {
									setActiveTab('fill-questions-step');
								}} />
							)
						}

						{
							activeTab.name === 'fill-questions-step' && (
								<FillQuestionnaire 
									onBackButtonClicked={() => setActiveTab('company-details')}
									onValidSubmit={ ({
										reportCaseId,
										questions,
										answers
									}) => {
										setQuestionnaireData({
											reportCaseId	:	reportCaseId,
											questions		:	questions,
											answers			:	answers
										});
									}}
								/>
							)
						}

						{ 
							activeTab.name === 'review-details' && renderConfirmForm()
						}

						{
							activeTab.name === 'declaration' && renderDeclarationTabContent()
						}
                    </Col>
                </Row>

            </Container>
        </div>
    )
};

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	const { Organization, App, ExitConfirmPrompt} = state;
	
	return {
		token,
		user,
		Organization,
		App,
		exitConfirmPromptOptions	:	ExitConfirmPrompt
	};
};

export default withNamespaces()(
	withRouter(
		connect(mapStatetoProps, {
			hideConfirmPrompt	:	() => confirmPromptHide(),
		})(ThirdPartyQuestioner)
	)
);