import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import { TbLoader } from "react-icons/tb";
import { errorNotification } from "src/store/actions";
import { API_URL_ADMIN_DOWNLOAD_DASHBOARD_STATISTICS } from "src/common/constants";

class DownloadStatistics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      headers: {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
        responseType: "blob",
      },
      spinner: {
        id: "",
        active: false,
      },
      dropdown: {
        id: "",
        active: false,
      },
      activeWidgets: [
        {
          value: "all",
          title: "All",
        }
      ],
    };
  }

  handleClickDownloadStats = (id, name) => {
    if (this.state.spinner.active) return;

    this.setState({
      spinner: {
        id: `download-file-${id}`,
        active: true,
      },
    });

    axios
      .get(
        `${API_URL_ADMIN_DOWNLOAD_DASHBOARD_STATISTICS}${
          this.props.filterQuery ? "?" + this.props.filterQuery : ""
        }`,
        this.state.headers
      )
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", `dashboard-${Date.now()}.xlsx`);

        document.body.appendChild(link);

        link.click();
        link.parentNode.removeChild(link);

        this.setState({
          spinner: {
            id: "",
            active: false,
          },
        });
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;

          this.props.errorNotification({
            code: data.error || "unknown_error",
            message: this.props.t(data.message || data.title),
          });
        }

        this.setState({
          spinner: {
            id: "",
            active: false,
          },
        });
      });
  };

  render() {
    return (
      <>
        <div style={{ textAlign: "end" }}>
          <div
            style={{
              display: "inline-flex",
              position: "relative",
              textAlign: "left",
            }}
          >
            <UncontrolledDropdown>
              <DropdownToggle
                color="primary"
                onClick={() => {
                  this.handleClickDownloadStats(0, "all");
                }}
              >
                {this.state.spinner.active ? (
                  <TbLoader />
                ) : (
                  this.props.t("Download")
                )}
              </DropdownToggle>
            </UncontrolledDropdown>
          </div>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { token } = state.Login;

  return { token };
};

const mapDispachToProps = (dispach) => {
  return {
    errorNotification: (error) => dispach(errorNotification(error)),
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, mapDispachToProps)(DownloadStatistics))
);
