import { withNamespaces } from "react-i18next";

import DateUtils from "src/services/utils/DateUtils";

import { 
    INTERNATIONAL_DATE_FORMAT
} from 'src/common/constants';

import { useQuery } from "@tanstack/react-query";

import { toast } from "react-toastify";

import {
    AvatarGroup,
    AvatarItem
} from "src/modules/3rd-party-management/components/avatar-group";

import React, { useEffect, useState } from "react";

import { Col, Row } from "reactstrap";

import OverviewHeaderContent from "src/modules/3rd-party-management/components/overview-header";

import SupplierService from "src/modules/3rd-party-management/apis/thirdparty/SupplierService";

import {
    createSVGFromText
} from "src/modules/3rd-party-management/helpers/common";

import {
    SuppliersStatuses
} from "src/modules/3rd-party-management/constants";

const OverviewHeader = ({
    t,
    className
}) => {
    const [ supplier, setSupplier ] = useState(null);

    const dateUtils = new DateUtils();

    const handleFetchSupplierOverviewContentQuery = useQuery({
		queryKey: ['3rd-party-management-supplier-overview-content'],
		queryFn: async () => {
			const service = SupplierService.getInstance();

            return await service.fetchOverviewContent();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching overview content.'), {
				type: 'error',
			});
		},
	});

    const renderLoadingSection = () => {
        return (
            <Row>
                <Col sm="12">
                    <div className="loading-section p-5">
                        <Row className="mb-5">
                            <Col sm="12" md="3">
                                <div className="dt-field dt-skeleton mb-1" style={{width: '40%'}}></div>
                                <div className="dt-field dt-skeleton" style={{width: '70%'}}></div>
                            </Col>
                            <Col sm="12" md="3">
                                <div className="dt-field dt-skeleton mb-1" style={{width: '40%'}}></div>
                                <div className="dt-field dt-skeleton" style={{width: '70%'}}></div>
                            </Col>
                            <Col sm="12" md="3">
                                <div className="dt-field dt-skeleton mb-1" style={{width: '40%'}}></div>
                                <div className="dt-field dt-skeleton" style={{width: '70%'}}></div>
                            </Col>
                            <Col sm="12" md="3">
                                <div className="dt-field dt-skeleton mb-1" style={{width: '40%'}}></div>
                                <div className="dt-field dt-skeleton" style={{width: '70%'}}></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" md="4">
                                <div className="dt-field dt-skeleton mb-1" style={{width: '100%'}}></div>
                            </Col>
                            <Col sm="12" md="4">
                                <div className="dt-field dt-skeleton mb-1" style={{width: '100%'}}></div>
                            </Col>
                            <Col sm="12" md="4">
                                <div className="dt-field dt-skeleton mb-1" style={{width: '100%'}}></div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }

    const renderAssignedAnalysts = () => {
        const analyst = supplier?.assignedAnalyst || {};

        let src = null;

        if(analyst.avatar){
            src = analyst.avatar
        }
        else{
            var xml = (new XMLSerializer()).serializeToString(
                createSVGFromText(`${(analyst?.firsName || '').charAt(0)}${(analyst?.lastName || '').charAt(0)}`)
            )
            src = `data:image/svg+xml;charset=utf-8,${xml}`;
        }

        const result = <AvatarItem id={`overview-analyst-avatar`} title={`${analyst?.firsName} ${analyst?.lastName}`} src={src} alt="" />;

        return (
            <AvatarGroup max={4}>
                { result }
            </AvatarGroup>
        );
    }

    const getItems = () => {
        const items = [
            [
                {
                    title   :   'ID',
                    value   :   supplier.id
                },
                {
                    title   :   'Name',
                    value   :   supplier.name
                },
                {
                    title   :   'Created on',
                    value   :   dateUtils.convertDateToDate(
                        supplier.createdAt,
                        INTERNATIONAL_DATE_FORMAT
                    )
                },
                {
                    title   :   'Last Update',
                    value   :   dateUtils.convertDateToDate(
                        supplier.createdAt,
                        INTERNATIONAL_DATE_FORMAT
                    )
                },
                {
                    title   :   'Status',
                    value   :   (
                        <span className="badge text-dark p-2" style={{
                            backgroundColor     :   supplier.status.color
                        }}>
                            { supplier.status.label }
                        </span>
                    )
                }
            ]
        ];

        if(supplier?.assignedAnalyst){
            items.push([
                {
                    title   :   'Assigned Analyst',
                    value   :   renderAssignedAnalysts()
                }
            ])
        }

        return items;
    }

    useEffect(() => {
        if(handleFetchSupplierOverviewContentQuery.data){
            const currentStatus = {
                value   :   handleFetchSupplierOverviewContentQuery.data.status,
                label   :   t(SuppliersStatuses[handleFetchSupplierOverviewContentQuery.data.status]?.title),
                color   :   SuppliersStatuses[handleFetchSupplierOverviewContentQuery.data.status]?.color
            };

            setSupplier({
                ...handleFetchSupplierOverviewContentQuery.data,
                status  :   currentStatus
            });
        }
    }, [handleFetchSupplierOverviewContentQuery.data]);

    const displayLoading = handleFetchSupplierOverviewContentQuery.isFetching || handleFetchSupplierOverviewContentQuery.isLoading || !supplier;

    return (
        <React.Fragment>
            {
                displayLoading ? (
                    <>{ renderLoadingSection() }</>
                ) : (
                    <OverviewHeaderContent className={className} items={getItems()} />
                )
            }
        </React.Fragment>
    );
};

export default withNamespaces()(OverviewHeader);