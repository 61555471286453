import { matchPath } from "react-router-dom";

export default class UrlUtils {
	getQueryStringFromObject(data) {
		let queryString = "";

		if (typeof data === "string") {
			return data;
		}

		if (!data || typeof data !== "object") {
			return queryString;
		}

		const keys = Object.keys(data);

		for (let i = 0; i < keys.length; i++) {
			const key = keys[i];
			queryString += key + "=" + encodeURIComponent(data[key]);

			if (i < keys.length - 1) {
				queryString += "&";
			}
		}

		return queryString;
	}

	getBearerAuthorization(token) {
		if (token) {
			return "Bearer " + token;
		}

		return "";
	}

	parseQueryString(str) {
		const ret = Object.create(null);

		if (!str) {
			return ret;
		}

		str = str.trim().replace(/^(\?|#|&)/, "");

		if (!str) {
			return ret;
		}

		str.split("&").forEach(function (param) {
			const parts = param.replace(/\+/g, " ").split("=");
			let key = parts.shift();
			let val = parts.length > 0 ? parts.join("=") : undefined;

			if (key) {
				key = decodeURIComponent(key);
			}

			val = val && decodeURIComponent(val);

			if (key && val) {
				ret[key] = val;
			}
		});

		return ret;
	}

	isSameRoute(path, comparePath) {
		return (
			path === comparePath ||
			!!matchPath(path, {
				path: comparePath,
				exact: true,
				strict: false,
			})
		);
	}

	isPathInRoutes(path, routes) {
		for (let i = 0; i < routes.length; i++) {
			const compareRoute = routes[i].path;

			if (this.isSameRoute(path, compareRoute)) {
				return true;
			}
		}

		return false;
	}

	getOrganizationFromUrl() {
		const org = {
			id: -1,
			name: "",
			country_code: "",
		};

		const pathname = window.location.pathname;
		const pathnameList = pathname.split("/");

		if (pathnameList.length > 2) {
			const locale = pathnameList[1];
			const orgInfo = pathnameList[2];
			const orgId = orgInfo.split("-").pop();
			const orgIdLength = orgId ? orgId.length : 0;

			org.name = orgInfo.substring(0, orgInfo.length - orgIdLength - 1);
			org.country_code = locale;

			if (orgId) {
				org.id = parseInt(orgId);
			}
		}

		return org;
	}

	getOrganizationId() {
		const pathname = window.location.pathname;
		const pathnameList = pathname.split("/");

		if (pathnameList.length > 2) {
			const orgInfo = pathnameList[2];
			return orgInfo.split("-").pop() || "";
		}

		return "";
	}

	getOrganizationName() {
		const pathname = window.location.pathname;
		// console.log("pathname", pathname);
		const pathnameList = pathname.split("/");

		if (pathnameList.length > 2) {
			return pathnameList[2];
			//   return orgInfo.split("-").shift() || "";
		}

		return "";
	}

	getChatId() {
		const queryParams = new URLSearchParams(window.location.search);

		const chatId = queryParams.get("chatId");

		if (chatId) {
			return chatId;
		}
	}

	getUrlParam() {
		const pathname = window.location.pathname;
		const pathnameList = pathname.split("/");

		if (pathnameList.length > 2) {
			const locale = pathnameList[1];
			const orgInfo = pathnameList[2];
			const orgId = orgInfo.split("-").pop();
			const orgIdLength = orgId ? orgId.length : 0;
			return "/" + locale + "/" + orgInfo.substring(0, orgInfo.length - orgIdLength - 1);
		}

		return "";
	}

	getUrlParamThirdParty() {
		const pathname = window.location.pathname;
		const pathnameList = pathname.split("/");

		if (pathnameList.length > 2) {
			const locale = pathnameList[3];
			return locale;
		}

		return "";
	}

	getAppRootPath(orgId, url) {
		return url + "-" + orgId;
	}

	getOrgId() {
		const pathName = window.location.pathname;
		const pathNameList = pathName.split("/");

		if (pathNameList.length > 2) {
			const orgInfo = pathNameList[2];
			return orgInfo.split("-").pop() || "";
		}

		return "";
	}

	getOrgName() {
		const pathName = window.location.pathname;
		const pathNameList = pathName.split("/");

		if (pathNameList.length > 2) {
			return pathNameList[2];
		}

		return "";
	}
}
