import { Fragment, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import DateUtils from 'src/services/utils/DateUtils';
import { convertToHTML } from 'draft-convert';
import { ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromHTML } from 'draft-js';
import { TbLoader } from 'react-icons/tb';
import { Button, Card, CardHeader, Col, Container, Input, InputGroup, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';
import SupplierTaskAssessmentService from "src/modules/3rd-party-management/apis/SupplierTaskAssessmentService";
import {
	DESCRIPTION_IS_REQUIRED,
	UPDATE_CATEGORY_SUCCESSFUL,
	UPDATE_CATEGORY_FAILED,
	UPDATE_DESCRIPTION_SUCCESSFUL,
	UPDATE_DESCRIPTION_FAILED,
	UPDATE_OWNER_SUCCESSFUL,
	UPDATE_OWNER_FAILED,
	UPDATE_STATUS_SUCCESSFUL,
	UPDATE_STATUS_FAILED,
	UPDATE_PRIORITY_SUCCESSFUL,
	UPDATE_PRIORITY_FAILED,
	UPDATE_DEADLINE_SUCCESSFUL,
	UPDATE_DEADLINE_FAILED,
	FETCH_TASK_FAILED,
	INTERNATIONAL_DATE_FORMAT,
	INVALID_DEADLINE_DATE,
	FETCH_COMMENTS_FAILED,
	CATEGORY_IS_REQUIRED,
} from 'src/common/constants';
import Select from 'react-select';

import DownloadAttachments from '../../attachments';

import  {
	API_BASE_URL,
	API_URL_TASK_DETAILS,
	API_URL_TASK_GET_COMMENTS
} from '../../../../constants';

import CommentsComponent from '../../comments';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import {
    TasksStatuses,
    TasksPriorities
} from "src/modules/3rd-party-management/constants";
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';

const TaskDetails = function (props) {
	const dateUtils = useMemo(() => {
		return new DateUtils();
	}, []);
    
	const {
		t,
        token,
        user,

        taskId,
        isTaskOwnerEmployee
	} = props;

	const [ task, setTask ] = useState(null);
	const [ comments, setComments ] = useState(null);
    const [ categories, setCategories ] = useState([]);
    const [ owners, setOwners ] = useState([]);

	const [selectedCategory, setSelectedCategory] = useState(null);
	const [categoryOtherError, setCategoryOtherError] = useState(null);
	const [selectedSubCategoryOtherString, setSelectedSubCategoryOtherString] = useState('');
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [description, setDescription] = useState(EditorState.createEmpty());
	const [descriptionError, setDescriptionError] = useState(null);
	const [selectedOwner, setSelectedOwner] = useState(null);
	const [selectedPriority, setSelectedPriority] = useState(null);
	const [selectedDeadline, setSelectedDeadline] = useState('');

	const [loadingTask, setLoadingTask] = useState(null);
	const [loadingCategory, setLoadingCategory] = useState(false);
	const [loadingStatus, setLoadingStatus] = useState(false);
	const [loadingDescription, setLoadingDescription] = useState(false);
	const [loadingOwner, setLoadingOwner] = useState(false);
	const [loadingPriority, setLoadingPriority] = useState(false);
	const [loadingDeadline, setLoadingDeadline] = useState(false);
	const [loadingComments, setLoadingComments] = useState(false);

    const priorities = [];
    const taskStatuses = [];

    for(const priorityKey in TasksPriorities){
        const priority = TasksPriorities[priorityKey];
        priorities.push({
            value       : priorityKey,
            baseLabel   : priority.title,
            label       : (
                <>
                    <span style={{
                            color	:	`${priority.color}`
                        }}>
                        { priority.icon }
                    </span>
                    <span>
                        {t(priority.title)}
                    </span>
                </>
            ),
        });
    }

    for(const statusKey in TasksStatuses){
        const status = TasksStatuses[statusKey];
        taskStatuses.push({
            value       :   statusKey,
            label       :   t(status.title),
            baseLabel   :   status.title
        });
    }

    const handleFetchTasksCategories = useQuery({
		queryKey: ['fetch-tasks-categories'],
		queryFn: async () => {
			const service = SupplierTaskAssessmentService.getInstance();

            return await service.fetchCategories();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching categories.'), {
				type: 'error',
			});
		}
	});

    const handleFetchTasksOwners = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-tasks-owners'],
		queryFn: async () => {
			const service = SupplierTaskAssessmentService.getInstance();

            return await service.fetchOwners();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching owners.'), {
				type: 'error',
			});
		}
	});

    const handleFetchTask = async () => {
        setLoadingTask(true);

        try {
            const result = await axios.get(`${API_URL_TASK_DETAILS}/task/${taskId}/show`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (result.status === 200) {
                setTask(result.data.data);
            } 
            else {
                toast(t(FETCH_TASK_FAILED), {
                    type: 'error',
                });
            }
        } 
        catch (error) {
            toast(t(FETCH_TASK_FAILED), {
                type: 'error',
            });
        }

        setLoadingTask(false);
    }

    const handleSubmitChangeCategory = async (category, subCategory) => {
        if (!category && !subCategory) {
            setCategoryOtherError(t(CATEGORY_IS_REQUIRED));
            return;
        } 
        else {
            setCategoryOtherError(null);
        }

        setLoadingCategory(true);

        const data = !subCategory ? { category: category } : { category: null, otherCategory: subCategory };

        try {
            const result = await axios.post(
                `${API_BASE_URL}/task/${taskId}/change_category`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                },
            );

            if (result.status === 200) {
                setTask({
                    ...task,
                    category    :   {
                        ...task.category,
                        is_other_category   :   data.otherCategory ? true : false,
                        id                  :   data.otherCategory ? selectedCategory.value : data.category,
                        name                :   data.otherCategory
                    }
                });

                toast(t(UPDATE_CATEGORY_SUCCESSFUL), {
                    type: 'success',
                });
            } 
            else {
                toast(t(UPDATE_CATEGORY_FAILED), {
                    type: 'error',
                });
            }
        } 
        catch (error) {
            toast(t(UPDATE_CATEGORY_FAILED), {
                type: 'error',
            });
        }
        setLoadingCategory(false);
    }

	const handleChangeCategory = (category) => {
        if (category.baseLabel.toLowerCase() !== 'other') {
            handleSubmitChangeCategory(category.value, null);
        }
        else{
            setSelectedCategory(category);
        }
    }

    const deleteTask = () => {
        return;
    }

    const handleChangeStatus = async (status) => {
        const prevStatus = Object.create(selectedStatus);

        setLoadingStatus(true);

        try {
            if (status.value === 'DELETED') {
                const result = await deleteTask(taskId);
                if (!result) setSelectedStatus(prevStatus);
            } 
            else {
                const result = await axios.post(
                    `${API_BASE_URL}/task/${taskId}/change_status`,
                    {
                        status: status.value,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    },
                );

                if (result.status === 200) {
                    setTask({
                        ...task,
                        status  :   status.value
                    });

                    toast(t(UPDATE_STATUS_SUCCESSFUL), {
                        type: 'success',
                    });
                } else {
                    toast(t(UPDATE_STATUS_FAILED), {
                        type: 'error',
                    });
                }
            }
        } 
        catch (error) {
            toast(t(UPDATE_STATUS_FAILED), {
                type: 'error',
            });
        }

        setLoadingStatus(false);
    }

	const handleDescriptionSubmit = async () => {
		try {
			if (!description.getCurrentContent().hasText()) {
				setDescriptionError(DESCRIPTION_IS_REQUIRED);
				return;
			} 
            else {
				setDescriptionError(null);
			}

			setLoadingDescription(true);

			const result = await axios.post(
				`${API_BASE_URL}/task/${taskId}/update`,
				{
					description: convertToHTML(description.getCurrentContent()),
					descriptionAttachments: [],
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					}
				},
			);

			if (result.status === 200) {
                toast(t(UPDATE_DESCRIPTION_SUCCESSFUL), {
                    type: 'success',
                });
			} 
            else {
                toast(t(UPDATE_DESCRIPTION_FAILED), {
                    type: 'error',
                });
			}
		} 
        catch (error) {
            toast(t(UPDATE_DESCRIPTION_FAILED), {
                type: 'error',
            });
		}

		setLoadingDescription(false);
	};

	const handleChangeOwner = async (owner) => {
		try {
			setLoadingOwner(true);

			const result = await axios.post(
				`${API_BASE_URL}/task/${taskId}/assign_analyst`,
				{ analyst: owner.value },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					}
				},
			);

			if (result.status === 200) {
                setTask({
                    ...task,
                    owner   :   owner.baseLabel
                });

                toast(t(UPDATE_OWNER_SUCCESSFUL), {
                    type: 'success',
                });
			} 
            else {
                toast(t(UPDATE_OWNER_FAILED), {
                    type: 'error',
                });
			}
		} 
        catch (error) {
            toast(t(UPDATE_OWNER_FAILED), {
                type: 'error',
            });
		}

		setLoadingOwner(false);
	};

	const handleChangePriority = async (priority) => {
		setLoadingPriority(true);

		try {
			const result = await axios.post(
				`${API_BASE_URL}/task/${taskId}/change_priority`,
				{
					priority: priority.value,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (result.status === 200) {
                setTask({
                    ...task,
                    priority    :   priority.value
                });

                toast(t(UPDATE_PRIORITY_SUCCESSFUL), {
                    type: 'success',
                });
			} 
            else {
                toast(t(UPDATE_PRIORITY_FAILED), {
                    type: 'error',
                });
			}
		} 
        catch (error) {
            toast(t(UPDATE_PRIORITY_FAILED), {
                type: 'error',
            });
		}

		setLoadingPriority(false);
	};

	const handleChangeDeadline = async (deadline) => {
		try {
			let date = new Date();
			date = date.toISOString().split('T')[0];
			if (Number(deadline.replaceAll('-', '')) < Number(date.replaceAll('-', ''))) {
                toast(t(INVALID_DEADLINE_DATE), {
                    type: 'error',
                });

				return false;
			}

			setLoadingDeadline(true);

			const result = await axios.post(
				`${API_BASE_URL}/task/${taskId}/change_deadline`,
				{ endedAt: deadline },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (result.status === 200) {
                toast(t(UPDATE_DEADLINE_SUCCESSFUL), {
                    type: 'success',
                });
			} 
            else {
                toast(t(UPDATE_DEADLINE_FAILED), {
                    type: 'error',
                });
			}
		} 
        catch (error) {
            toast(t(UPDATE_DEADLINE_FAILED), {
                type: 'error',
            });
		}

		setLoadingDeadline(false);
	};

	const handleFetchComments = async () => {
        setComments(null);
        setLoadingComments(true);

        try {
            const result = await axios.get(
                 `${API_URL_TASK_GET_COMMENTS}/task/${taskId}/comments`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                },
            );

            if (result.status === 200) {
                setComments(result.data.data);
            } 
            else {
                toast(t(FETCH_COMMENTS_FAILED), {
                    type: 'error',
                });
            }
        } 
        catch (error) {
            toast(t(FETCH_COMMENTS_FAILED), {
                type: 'error',
            });
        }

        setLoadingComments(false);
    };

	const handleUpdateAttachmentName = (name, id) => {
		const index = task.description_attachments.findIndex((i) => i.id === id);

		if (index > -1) {
			const description_attachments = Array.from(task.description_attachments);
			description_attachments[index].origin_name = name;

			setTask({
				...task,
				description_attachments,
			});
		}
	};

    const findAndUpdateTaskCategory = () => {
        if(!categories.length) return;

        if (task.category.is_other_category) {
            const category = categories.find((c) => c.baseLabel.toLowerCase() === 'other');
            
            setSelectedCategory({
                ...category,
                label   :   t(category.baseLabel)
            });

            setSelectedSubCategoryOtherString(task.category.name);
        } 
        else {
            const category = categories.find((c) => c.value === task.category.id);

            setSelectedCategory({
                ...category,
                label   :   t(category.baseLabel)
            });
            
            setSelectedSubCategoryOtherString('');
        }
    }

    const findAndUpdateTaskStatus = () => {
        if(!taskStatuses.length) return;

        const status = taskStatuses.find((s) => s.value === task.status);
        setSelectedStatus(status);
    }

    const findAndUpdateTaskOwner = () => {
        if(!owners.length) return;

        const owner = owners.find((o) => o.label === task.owner);
        setSelectedOwner(owner);
    }

    const findAndUpdateTaskPriority = () => {
        if(!priorities.length) return;

        const priority = priorities.find((o) => o.value === task.priority);
        setSelectedPriority(priority);
    }

    useEffect(() => {
        if(task){
            findAndUpdateTaskCategory();
            findAndUpdateTaskStatus();
            findAndUpdateTaskOwner();
            findAndUpdateTaskPriority();

            const deadline = dateUtils.convertTimestampToOnlyDate(task.ended_at)
                .toString()
                .split('/').reverse()
                .join('-');
            
            setSelectedDeadline(deadline);

            const blocksFromHTML = task.description ? convertFromHTML(task.description) : '';
            const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

            setDescription(EditorState.createWithContent(state));
        }
    }, [ task, categories, owners, t ]);

    useEffect(() => {
        if(handleFetchTasksCategories.data){
            setCategories(handleFetchTasksCategories.data.map((item) => {
                return {
                    value       :   item.id,
                    baseLabel   :   item.name,
                    label       :   t(item.name),
                };
            }));
        }
    }, [handleFetchTasksCategories.data]);

    useEffect(() => {
        if(handleFetchTasksOwners.data){
            setOwners(handleFetchTasksOwners.data.map((item) => {
                return {
                    value       :   item.id,
                    label       :   `${item.first_name} ${item.last_name}`,
                    baseLabel   :   `${item.first_name} ${item.last_name}`
                };
            }));
        }
    }, [handleFetchTasksOwners.data]);

	useEffect(() => {
		if (taskId) {
			setTask(null);
			handleFetchTask();
			setComments(null);
			handleFetchComments();
		}
	}, [ taskId ]);

	return (
		<Container fluid>
			<Row>
				<Col lg='8' md='8' sm='12'>
					<Row>
						<Col sm='12' md='6' lg='3'>
							<Label className='form-label'>{`${t('Title')} `}</Label>
							<br />
							{!loadingTask && task ? (
								<Label className='form-label text-secondary '>{task.name}</Label>
							) : (
								<div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
							)}
						</Col>

						<Col
							sm='12'
							md={selectedCategory && selectedCategory.baseLabel.toLowerCase() === 'other' ? 3 : 4}
							lg={selectedCategory && selectedCategory.baseLabel.toLowerCase() === 'other' ? 3 : 4}
						>
							<Label className='form-label'>{`${t('Category')} `}</Label>
							<br />

							{!loadingCategory && !loadingTask && categories && task ? (
								<Select
									name='category'
									classNamePrefix='select2-selection'
									options={categories.map((c) => {
										return {
											value: c.value,
											baseLabel: c.baseLabel,
											label: t(c.baseLabel),
										};
									})}
									value={selectedCategory}
									onChange={(e) => {
										handleChangeCategory(e);
									}}
									menuPortalTarget={document.body}
									isDisabled={isTaskOwnerEmployee || task.status==="CLOSED" ? true : false}
								/>
							) : (
								<div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
							)}
						</Col>

						{/* subcategory */}
						<Col sm='12' md='3' lg='3' hidden={!selectedCategory || selectedCategory.baseLabel.toLowerCase() !== 'other'}>
							<Label className='form-label text-dark'>{`${t('Subcategory')} `}</Label>
							<InputGroup>
								<Input
									name='sub-cat'
									type='text'
									className='form-control'
									value={selectedSubCategoryOtherString}
									validate={{ required: { value: true } }}
									onChange={(e) => {
										setSelectedSubCategoryOtherString(e.target.value);
									}}
									disabled={isTaskOwnerEmployee ? true : false}
								/>
								{!isTaskOwnerEmployee ? (
									<Button
										color='primary'
										onClick={(e) => {
											handleSubmitChangeCategory(null, selectedSubCategoryOtherString);
										}}
									>
										{t('Save')}
									</Button>
								) : null}
							</InputGroup>
							<br />
							<p className='text-danger'>{categoryOtherError ? t(categoryOtherError) : ''}</p>
						</Col>

						{/* status */}
						<Col
							sm='12'
							md={selectedCategory && selectedCategory.baseLabel.toLowerCase() === 'other' ? 3 : 4}
							lg={selectedCategory && selectedCategory.baseLabel.toLowerCase() === 'other' ? 3 : 4}
						>
							<Label className='form-label'>{`${t('Status')} `}</Label>
							<br />
							{!loadingStatus && !loadingTask && taskStatuses && task ? (
								<Select
									required
									name='status'
									classNamePrefix='select2-selection'
									options={isTaskOwnerEmployee ? taskStatuses.filter((s) => s.taskOwner) : taskStatuses}
									value={selectedStatus}
									onChange={(status) => {
										handleChangeStatus(status);
									}}
									menuPortalTarget={document.body}
									isDisabled={task?.status === "CLOSED"}
								></Select>
							) : (
								<div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
							)}
						</Col>
					</Row>

					<Row className='mt-5'>
						{/* description */}
						<Col sm='12' md='12' lg='12'>
							<Label className='form-label'>{`${t('Description')} `}</Label>
							{task && task.description ? (
								isTaskOwnerEmployee ? (
									<p dangerouslySetInnerHTML={{ __html: task.description }}></p>
								) : (
									<Fragment>
										<br />
										<Editor
											editorState={description}
											toolbarClassName='toolbarClassName'
											wrapperClassName='wrapperClassName'
											editorClassName='editorClassName'
											onEditorStateChange={(e) => {
												setDescription(e);
											}}
											placeholder={t('Add a description...')}
											editorStyle={{
												height: '10rem',
												overflowY: 'scroll',
												borderColor: '#c9c9c9',
												paddingRight: '2em',
											}}
											toolbar={{
												options: ['inline', 'blockType', 'fontSize', 'fontFamily'],
												inline: {
													options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
													bold: { className: 'bordered-option-classname' },
													italic: { className: 'bordered-option-classname' },
													underline: { className: 'bordered-option-classname' },
													strikethrough: {
														className: 'bordered-option-classname',
													},
													code: { className: 'bordered-option-classname' },
												},
												blockType: {
													className: 'bordered-option-classname',
												},
												fontSize: {
													className: 'bordered-option-classname',
												},
												fontFamily: {
													className: 'bordered-option-classname',
												},
											}}
										/>
										<br />
										<p className='text-danger'>{descriptionError ? t(descriptionError) : ''}</p>
									</Fragment>
								)
							) : null}
						</Col>
					</Row>

					<br />

					<Row>
						{task && task.description && !isTaskOwnerEmployee ? (
							<Col lg='2' md='2' sm='6' className=''>
								<Button className='bg-primary' style={{ display: 'block', width: '100%' }} onClick={(e) => handleDescriptionSubmit()}>
									{loadingDescription ? <TbLoader /> : t('Save')}
								</Button>
							</Col>
						) : null}

						{task && task.description_attachments && task.description_attachments.length > 0 ? (
							<Col lg='10' md='10' sm='6' hidden={!(task && task.description_attachments && task.description_attachments.length > 0)}>
								<DownloadAttachments
									type={'task'}
									style={{ display: 'block', width: '100%' }}
									showAttachments={false}
									files={task.description_attachments}
								/>
							</Col>
						) : null}
					</Row>
				</Col>

				<Col lg='4' md='4' sm='12'>
					<Card
						outline
						body
						className='border border-primary rounded'
						style={{
							width: 'auto',
						}}
					>
						<CardHeader>{t('Details')}</CardHeader>
						<br></br>
						<ListGroup flush>
							<ListGroupItem className='border-bottom border-primary'>
								<Row>
									<Col>
										<p>{t('Task Manager')}</p>
									</Col>
									<Col>
										{task ? (
											<span className='badge bg-success text-white rounded p-2' style={{ marginBottom: 0, display: 'block' }}>
												{task.manager}
											</span>
										) : (
											<div className='dt-field dt-skeleton' style={{ height: 27 }}></div>
										)}
									</Col>
								</Row>
							</ListGroupItem>

							{/* owners */}
							<ListGroupItem className='border-bottom border-primary '>
								<Row>
									<Col>
										<p>{t('Task owner')}</p>
									</Col>
									<Col>
										{task ? (
											isTaskOwnerEmployee ? (
												<span className='badge bg-success text-white rounded p-2' style={{ marginBottom: 0, display: 'block' }}>
													{t('Yourself')}
												</span>
											) : !loadingOwner && owners ? (
												<Select
													required
													name='owner'
													classNamePrefix='select2-selection'
													options={owners}
													isDisabled={`${user?.first_name} ${user?.last_name}` !== task.manager}
													value={selectedOwner}
													onChange={(e) => {
														handleChangeOwner(e);
													}}
												/>
											) : (
												<div className='dt-field dt-skeleton dt-select-list'></div>
											)
										) : (
											<div className='dt-field dt-skeleton dt-select-list'></div>
										)}
									</Col>
								</Row>
							</ListGroupItem>

							{/* priority */}
							<ListGroupItem className='border-bottom border-primary'>
								<Row>
									<Col>
										<p>{t('Priority')}</p>
									</Col>
									<Col>
										{!loadingPriority && priorities && task ? (
											<Select
												required
												name='priority'
												classNamePrefix='select2-selection'
												options={priorities}
												value={selectedPriority}
												onChange={(e) => {
													handleChangePriority(e);
												}}
												isDisabled={isTaskOwnerEmployee ? true : false}
											/>
										) : (
											<div className='dt-field dt-skeleton dt-select-list'></div>
										)}
									</Col>
								</Row>
							</ListGroupItem>

							{/* created at */}
							<ListGroupItem className='border-bottom border-primary'>
								<Row>
									<Col>
										<p>{t('Created')}</p>
									</Col>
									<Col>
										{task ? (
											<Label style={{ marginBottom: 0, lineHeight: '26px' }}>
												{dateUtils.convertTimeStampToDate(task.created_at, INTERNATIONAL_DATE_FORMAT)}
											</Label>
										) : (
											<div className='dt-field dt-skeleton' style={{ height: 27 }}></div>
										)}
									</Col>
								</Row>
							</ListGroupItem>

							{/* started at */}
							<ListGroupItem className='border-bottom border-primary'>
								<Row>
									<Col>
										<p>{t('Start Date')}</p>
									</Col>
									<Col>
										{task ? (
											<Label style={{ marginBottom: 0, lineHeight: '26px' }}>
												{dateUtils.convertTimeStampToDate(task.started_at, INTERNATIONAL_DATE_FORMAT)}
											</Label>
										) : (
											<div className='dt-field dt-skeleton' style={{ height: 27 }}></div>
										)}
									</Col>
								</Row>
							</ListGroupItem>

							{/* deadline */}
							<ListGroupItem>
								<Row>
									<Col>
										<p>{t('Deadline')}</p>
									</Col>
									<Col>
										{!loadingDeadline && task ? (
											<Input
												name='deadlineDate'
												min={dateUtils.convertTimestampToOnlyDate(task.started_at).toString().split('/').reverse().join('-')}
												type='date'
												value={selectedDeadline}
												onChange={(e) => handleChangeDeadline(e.target.value)}
												disabled={isTaskOwnerEmployee ? true : false}
											/>
										) : (
											<div className='dt-field dt-skeleton dt-select-list'></div>
										)}
									</Col>
								</Row>
							</ListGroupItem>
						</ListGroup>
					</Card>
				</Col>
			</Row>

            <CommentsComponent
                useCase={'task'}
                id={taskId}
                owner={task?.owner}
				manager={task?.manager}
				descriptionAttachments={task?.description_attachments}
                successDeletion={(fileId) => {
                    setTask({
						...task,
						description_attachments: task.description_attachments.filter((f) => f.id !== fileId),
					});
                }}
                creationDate={task ? dateUtils.convertTimestampToOnlyDate(task.created_at) : null}
                comments={comments}
                fetchComments={() => handleFetchComments()}
                loadingComments={loadingComments}
                updateAttachmentName={(name, id) => {
                    handleUpdateAttachmentName(name, id);
                }}
                isReadOnly={task?.status==="CLOSED" ? true : false} 
            />
		</Container>
	);
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    return {
        user            :   state.Login.user,
        token
    };
};

export default withNamespaces()(
    connect(
        mapStatetoProps,
        {}
    )(TaskDetails)
);
