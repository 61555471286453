import React, { useEffect, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Dropdown, DropdownToggle, DropdownMenu, Button } from "reactstrap";

import Form from "react-bootstrap/Form";
import { Nav, NavItem, NavLink } from "reactstrap";

import { icons } from "./constants";

import { useQuery } from "@tanstack/react-query";

import Spinner from "react-bootstrap/Spinner";

import { toast } from "react-toastify";
import { connect } from "react-redux";
import CommonService from "src/modules/data-protection/apis/CommonService";

const TableFilter = ({ t, onOkButtonClicked, defaultValues, statuses }) => {
  const [filters, setFilters] = useState({
    status: defaultValues.status || null,
    flag: defaultValues.flag || null,
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Status");
  const flags = [
    { value: true, baseLabel: t("Flag On") },
    { value: false, baseLabel: t("Flag Off") },
  ];

  const handleFetchOwners = useQuery({
    queryFn: async () => {
      const service = CommonService.getInstance();

      return await service.analystAdminsAndAnalysts();
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(t("An error occurred while fetching users."), {
        type: "error",
      });
    },
  });

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const renderOwners = () => {
    if (handleFetchOwners.isFetching || handleFetchOwners.isLoading) {
      return (
        <div className="text-center">
          <Spinner animation="border" variant="primary" size="md" />
        </div>
      );
    }

    return (handleFetchOwners.data || []).map((item) => {
      return (
        <div className="filter-item" key={item.id}>
          <Form.Check
            type="radio"
            id={`analysts-${item.id}-filter`}
            checked={filters?.owner === item.id}
            onChange={() => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                owner: item.id,
              }));
            }}
            label={`${item.first_name + " " + item.last_name}`}
          />
        </div>
      );
    });
  };

  const renderStatusFilterList = () => {
    const result = [];

    for (const key in statuses) {
      if (statuses.hasOwnProperty(key)) {
        const value = statuses[key];
        result.push(
          <div className="filter-item" key={key}>
            <Form.Check
              type="radio"
              id={`status-${key}-filter`}
              checked={filters.status === key}
              onChange={() => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  status: key,
                }));
              }}
              label={t(value)}
            />
          </div>
        );
      }
    }

    return result;
  };

  const renderFlagFilterList = () => {
    const result = flags.map((f) => {
      const r =
        f.value === true ? (
          <div className="filter-item" key={"f-1"}>
            <Form.Check
              type="radio"
              id={`status-${"f-1"}-filter`}
              checked={filters.flag}
              onChange={() => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  flag: true,
                }));
              }}
              label={t(f.baseLabel)}
            />
          </div>
        ) : (
          <div className="filter-item" key={"f-2"}>
            <Form.Check
              type="radio"
              id={`status-${"f-2"}-filter`}
              checked={filters.flag === false}
              onChange={() => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  flag: false,
                }));
              }}
              label={t(f.baseLabel)}
            />
          </div>
        );
      return r;
    });
    return result;
  };

  const renderNavContent = () => {
    switch (activeTab) {
      case "Status":
        return renderStatusFilterList();

      case "Flag":
        return renderFlagFilterList();

      case "Owner":
        return renderOwners();

      default:
        return null;
    }
  };

  return (
    <div className="custom-table-filter">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret color="secondary" outline>
          <i className="ri-filter-3-fill"></i>
          <span>{t("Filter")}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <div className="filters-container">
            <div className="navs-container d-flex justify-content-center">
              <Nav tabs>
                <NavItem
                  onClick={() => setActiveTab("Status")}
                  className={`${activeTab === "Status" && "active"}`}
                >
                  <NavLink>
                    <img
                      src={
                        activeTab === "Status"
                          ? icons.Status.active
                          : icons.Status.default
                      }
                      alt="status-filter"
                    />
                    <span>{t("Status")}</span>
                  </NavLink>
                </NavItem>

                <NavItem
                  onClick={() => setActiveTab("Flag")}
                  className={`${activeTab === "Flag" && "active"}`}
                >
                  <NavLink>
                    <img
                      src={
                        activeTab === "Flag"
                          ? icons.Flag.active
                          : icons.Flag.default
                      }
                      alt="flag-filter"
                    />
                    <span>{t("Flag")}</span>
                  </NavLink>
                </NavItem>

                <NavItem
                  onClick={() => {
                    setActiveTab("Owner");
                  }}
                  className={`${activeTab === "Owner" && "active"}`}
                >
                  <NavLink>
                    <img
                      src={
                        activeTab === "Owner"
                          ? icons.Analyst.active
                          : icons.Analyst.default
                      }
                      alt="analyst-filter"
                    />
                    <span>{t("Owner")}</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <div className="navs-content-container">{renderNavContent()}</div>

            <div className="actions-container d-flex align-items-center gap-2 justify-content-end">
              <Button
                color="secondry"
                size="sm"
                onClick={() => {
                  setFilters({
                    status: null,
                    // category: null,
                    // risk: null,
                    // analystAssigned: null,
                  });
                }}
              >
                {t("Reset")}
              </Button>

              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  onOkButtonClicked && onOkButtonClicked(filters);
                  toggle();
                }}
              >
                {t("Ok")}
              </Button>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { App, Feature, CMFeature } = state;

  const activeModule = localStorage.getItem("module");

  return {
    token,
    Feature:
      activeModule === "wb"
        ? Feature
        : activeModule === "cm"
        ? CMFeature
        : null,
  };
};

export default withNamespaces()(connect(mapStatetoProps)(TableFilter));
