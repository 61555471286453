import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap'
import Select from 'react-select'
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation'
import { INTERNATIONAL_DATE_FORMAT } from 'src/components/constants';
import DatePicker from "react-datepicker"
import moment from 'moment'

const CreateAndEditModal = ({
    t,
    show,
    toggle,
    onSubmit,
    countries,
    onClosed,
    shareholder = null
}) => {
    const avFormRef = React.useRef()
    const [selectedCountry, setSelectedCountry] = React.useState(null)
    const [individualTitleDrop, setIndividualTitleDrop] = React.useState({
        show    :   false,
        value   :   'Mr.'
    });
    const [selectedType, setSelectedType] = React.useState('company')

    const [selectedDate, setSelectedDate] = React.useState()

    React.useEffect(() => {
        if (shareholder) {
            const country = countries.find((c) => c.value === shareholder[shareholder.type === 'company' ? 'country' : 'residenceCountry'])

            setSelectedCountry(country)

            if(shareholder.type === 'individual'){
                setIndividualTitleDrop({
                    show    : false,
                    value   : shareholder.title
                })
            }

            setSelectedType(shareholder.type)

            setSelectedDate(new Date(shareholder.type === 'company' ? shareholder.foundingDate : shareholder?.birthDay))
        } else {
            avFormRef?.current && avFormRef.current.reset()
            setSelectedCountry(null)
            setSelectedType('company')
            setSelectedDate(null)
        }
    }, [show])

    return (
        <Modal size='lg' scrollable isOpen={show} backdrop='static' onClosed={onClosed && onClosed}>
            <AvForm
                className='needs-validation'
                onValidSubmit={(e, values) => {
                    onSubmit &&
                        onSubmit(
                            selectedType,
                            {
                                ...values[selectedType],
                                index : shareholder?.index
                            },
                            shareholder ? 'edit' : 'create'
                        )
                }}
                ref={avFormRef}
            >
                <ModalHeader toggle={toggle}>
                    {shareholder ? (
                        <React.Fragment>{t('Edit Shareholder')}</React.Fragment>
                    ) : (
                        <React.Fragment>{t('Add Shareholder')}</React.Fragment>
                    )}
                </ModalHeader>

                <ModalBody>
                    <Row className='mb-3'>
                        <Col sm='12' md='6'>
                            <Label
                                className='form-label'
                                htmlFor='shareholder-type'
                            >
                                {t('Type')}:
                            </Label>
                            <select value={selectedType} className='form-control' id='shareholder-type' onChange={(e) => {
                                    avFormRef.current.reset();
                                    setSelectedType(e.target.value);
                                }}>
                                <option value={'company'}>
                                    {t('Company')}
                                </option>
                                <option value={'individual'}>
                                    {t('Individual')}
                                </option>
                            </select>
                        </Col>
                    </Row>
                    
                    {selectedType === 'company' ? (
                        <Row>
                            <Col sm='12'>
                                <Row className='mb-3'>
                                    <Col sm='12' md='6'>
                                        <Label
                                            className='form-label'
                                            htmlFor='shareholder-company-fullname'
                                        >
                                            {t('Full Name')}:
                                        </Label>
                                        <AvField
                                            name='company[companyName]'
                                            type='text'
                                            errorMessage={t('This field cannot be blank')}
                                            className='form-control'
                                            validate={{
                                                required: {
                                                    value: true
                                                }
                                            }}
                                            id='shareholder-company-fullname'
                                            defaultValue={shareholder?.companyName}
                                        />
                                    </Col>
                                    <Col sm='12' md='6'>
                                        <Label
                                            className='form-label'
                                            htmlFor='shareholder-company-fullname-in-local'
                                        >
                                            {t('Full Name In Local Language')}:
                                        </Label>
                                        <AvField
                                            name='company[localLanguageCompanyName]'
                                            type='text'
                                            errorMessage={t('This field cannot be blank')}
                                            className='form-control'
                                            validate={{
                                                required: {
                                                    value: true
                                                }
                                            }}
                                            id='shareholder-company-fullname-in-local'
                                            defaultValue={shareholder?.localLanguageCompanyName}
                                        />
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col sm='12' md='6'>
                                        <Label
                                            className='form-label'
                                            htmlFor='shareholder-company-founding-date'
                                        >
                                            {t('Founding Date')}:
                                        </Label>
                                        <DatePicker 
                                            dateFormat={INTERNATIONAL_DATE_FORMAT}
                                            className='dt-datepicker'
                                            selected={selectedDate}
                                            onChange={(date) => {
                                                setSelectedDate(date)
                                            }}
                                            id='shareholder-company-founding-date'
                                        /> 
                                        <AvField
                                            name='company[foundingDate]'
                                            type='hidden'
                                            errorMessage={t('This field cannot be blank')}
                                            validate={{
                                                required: {
                                                    value: true
                                                }
                                            }}
                                            value={selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : null}
                                        />
                                    </Col>
                                    <Col sm='12' md='6'>
                                        <Label
                                            className='form-label'
                                            htmlFor='shareholder-company-country'
                                        >
                                            {t('Country')}:
                                        </Label>
                                        <Select
                                            id='shareholder-company-country'
                                            placeholder={
                                                t('Country') +
                                                '...'
                                            }
                                            classNamePrefix='select2-selection'
                                            options={countries}
                                            menuPortalTarget={
                                                document.body
                                            }
                                            width='150px'
                                            onChange={(e) => {
                                                const input = avFormRef.current._inputs['company[country]']
                                                if (input) {
                                                    input.value = e.value
                                                    input.validate()
                                                }
                                            }}
                                            styles={{
                                                menuPortal: (
                                                    base
                                                ) => ({
                                                    ...base,
                                                    zIndex: 9999
                                                }),
                                                control: (
                                                    base
                                                ) => ({
                                                    ...base,
                                                    minWidth:
                                                        '160px'
                                                })
                                            }}
                                            defaultValue={selectedCountry}
                                        />
                                        <AvField
                                            type='hidden'
                                            name='company[country]'
                                            errorMessage={t(
                                                'This field cannot be blank'
                                            )}
                                            validate={{
                                                required: {
                                                    value: true
                                                }
                                            }}
                                            defaultValue={selectedCountry?.value}
                                        />
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col sm='12' md='6'>
                                        <Label
                                            className='form-label'
                                            htmlFor='shareholder-company-percentage'
                                        >
                                            {t('Percentage')}:
                                        </Label>
                                        <AvField
                                            name='company[percentage]'
                                            type='number'
                                            errorMessage={t('This field cannot be blank')}
                                            className='form-control'
                                            validate={{
                                                required: {
                                                    value: true
                                                }
                                            }}
                                            id='shareholder-company-percentage'
                                            defaultValue={shareholder?.percentage}
                                        />
                                    </Col>
                                    <Col sm='12' md='6' className='d-flex align-items-end'>
                                        <AvGroup check>
                                            <Label check>
                                                <AvInput type="checkbox" defaultValue={shareholder?.isUbo} name="company[isUbo]" /> 
                                                {t('Ultimate Parent')}
                                            </Label>
                                        </AvGroup>
                                    </Col>
                                </Row>      
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col sm='12'>
                                <Row className='mb-3'>
                                    <Col sm='12' md='6'>
                                        <Label
                                            className='form-label'
                                            htmlFor='shareholder-individual-name'
                                        >
                                            {t('Name')}:
                                        </Label>

                                        <div className="d-flex flex-wrap position-relative">

                                            <ButtonDropdown className="position-absolute" 
                                                isOpen={ individualTitleDrop.show } 
                                                toggle={() => {
                                                    setIndividualTitleDrop((current) => {
                                                        return {
                                                            ...current,
                                                            show : !current.show
                                                        }
                                                    })
                                                }} 
                                                style={{
                                                    left: "0px",
                                                    top: "0px",
                                                    width: "74px",
                                                    height: "33px"
                                                }}>
                                                    <DropdownToggle 
                                                        outline 
                                                        split 
                                                        style={{ border: 'none', borderRight: '1px solid rgb(206, 212, 218)' }}>
                                                        <span style={{marginRight: '8px'}}>
                                                            { t(individualTitleDrop.value) }
                                                        </span>
                                                    </DropdownToggle>

                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => setIndividualTitleDrop((current) => {
                                                            return {
                                                                ...current,
                                                                value : 'Mr.'
                                                            }
                                                        })}>
                                                            { t('Mr.') }
                                                        </DropdownItem>

                                                        <DropdownItem onClick={() => setIndividualTitleDrop((current) => {
                                                            return {
                                                                ...current,
                                                                value : 'Mrs.'
                                                            }
                                                        })}>
                                                            { t('Mrs.') }
                                                        </DropdownItem>

                                                        <DropdownItem onClick={() => setIndividualTitleDrop((current) => {
                                                            return {
                                                                ...current,
                                                                value : 'Mx.'
                                                            }
                                                        })}>
                                                            { t('Mx.') }
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                            </ButtonDropdown>

                                            <AvInput 
                                                name={`individual[title]`}
                                                type="hidden"
                                                value={individualTitleDrop.value}
                                                defaultValue={individualTitleDrop.value}
                                            />

                                            <AvField
                                                name='individual[firstName]'
                                                type='text'
                                                errorMessage={t('This field cannot be blank')}
                                                className='form-control'
                                                validate={{
                                                    required: {
                                                        value: true
                                                    }
                                                }}
                                                id='shareholder-individual-name'
                                                style={{
                                                    paddingLeft: '85px'
                                                }}
                                                groupAttrs={{
                                                    className: 'w-100'
                                                }}
                                                defaultValue={shareholder?.firstName}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm='12' md='6'>
                                        <Label
                                            className='form-label'
                                            htmlFor='shareholder-individual-lastname'
                                        >
                                            {t('Surname')}:
                                        </Label>
                                        <AvField
                                            name='individual[lastName]'
                                            type='text'
                                            errorMessage={t('This field cannot be blank')}
                                            className='form-control'
                                            validate={{
                                                required: {
                                                    value: true
                                                }
                                            }}
                                            id='shareholder-individual-lastname'
                                            defaultValue={shareholder?.lastName}
                                        />
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col sm='12' md='6'>
                                        <Label
                                            className='form-label'
                                            htmlFor='shareholder-individual-name-in-local'
                                        >
                                            {t('First Name In Local Language')}:
                                        </Label>
                                        <AvField
                                            name='individual[localLanguageFirstName]'
                                            type='text'
                                            errorMessage={t('This field cannot be blank')}
                                            className='form-control'
                                            validate={{
                                                required: {
                                                    value: true
                                                }
                                            }}
                                            id='shareholder-individual-name-in-local'
                                            defaultValue={shareholder?.localLanguageFirstName}
                                        />
                                    </Col>
                                    <Col sm='12' md='6'>
                                        <Label
                                            className='form-label'
                                            htmlFor='shareholder-individual-lastname-in-local'
                                        >
                                            {t('Surname In Local Language')}:
                                        </Label>
                                        <AvField
                                            name='individual[localLanguageLastName]'
                                            type='text'
                                            errorMessage={t('This field cannot be blank')}
                                            className='form-control'
                                            validate={{
                                                required: {
                                                    value: true
                                                }
                                            }}
                                            id='shareholder-individual-lastname-in-local'
                                            defaultValue={shareholder?.localLanguageLastName}
                                        />
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col sm='12' md='6'>
                                        <Label
                                            className='form-label'
                                            htmlFor='shareholder-individual-birthdate'
                                        >
                                            {t('Birthday')}:
                                        </Label>
                                        <DatePicker 
                                            dateFormat={INTERNATIONAL_DATE_FORMAT}
                                            className='dt-datepicker'
                                            selected={selectedDate}
                                            onChange={(date) => {
                                                setSelectedDate(date)
                                            }}
                                            id='shareholder-individual-birthdate'
                                        /> 
                                        <AvField
                                            name='individual[birthDay]'
                                            type='hidden'
                                            errorMessage={t('This field cannot be blank')}
                                            validate={{
                                                required: {
                                                    value: true
                                                }
                                            }}
                                            value={selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : null}
                                        />
                                    </Col>
                                    <Col sm='12' md='6'>
                                        <Label
                                            className='form-label'
                                            htmlFor='shareholder-individual-country'
                                        >
                                            {t('Resident In Country')}:
                                        </Label>
                                        <Select
                                            id='shareholder-individual-country'
                                            placeholder={
                                                t('Country') +
                                                '...'
                                            }
                                            classNamePrefix='select2-selection'
                                            options={countries}
                                            menuPortalTarget={
                                                document.body
                                            }
                                            width='150px'
                                            onChange={(e) => {
                                                const input = avFormRef.current._inputs['individual[residenceCountry]']
                                                if (input) {
                                                    input.value = e.value
                                                    input.validate()
                                                }
                                            }}
                                            styles={{
                                                menuPortal: (
                                                    base
                                                ) => ({
                                                    ...base,
                                                    zIndex: 9999
                                                }),
                                                control: (
                                                    base
                                                ) => ({
                                                    ...base,
                                                    minWidth:
                                                        '160px'
                                                })
                                            }}
                                            defaultValue={selectedCountry}
                                        />
                                        <AvField
                                            type='hidden'
                                            name='individual[residenceCountry]'
                                            errorMessage={t(
                                                'This field cannot be blank'
                                            )}
                                            validate={{
                                                required: {
                                                    value: true
                                                }
                                            }}
                                            defaultValue={selectedCountry?.value}
                                        />
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col sm='12' md='6'>
                                        <Label
                                            className='form-label'
                                            htmlFor='shareholder-individual-percentage'
                                        >
                                            {t('Percentage')}:
                                        </Label>
                                        <AvField
                                            name='individual[percentage]'
                                            type='number'
                                            errorMessage={t('This field cannot be blank')}
                                            className='form-control'
                                            validate={{
                                                required: {
                                                    value: true
                                                }
                                            }}
                                            id='shareholder-individual-percentage'
                                            defaultValue={shareholder?.percentage}
                                        />
                                    </Col>
                                    <Col sm='12' md='6' className='d-flex align-items-end'>
                                        <AvGroup check>
                                            <Label check>
                                                <AvInput type="checkbox" defaultValue={shareholder?.isUbo} name="individual[isUbo]" /> 
                                                {t('UBO')}
                                            </Label>
                                        </AvGroup>
                                    </Col>
                                </Row>      
                            </Col>
                        </Row>
                    )}

                    <ModalFooter>
                        <Button
                            color='primary'
                            outline
                            className='waves-effect waves-light'
                            type='button'
                            onClick={toggle}
                        >
                            {t('Cancel')}
                        </Button>

                        <Button
                            color='primary'
                            type='submit'
                            className='waves-effect waves-light'
                        >
                            {t('Save')}
                        </Button>
                    </ModalFooter>
                </ModalBody>
            </AvForm>
        </Modal>
    )
}

export default withNamespaces()(CreateAndEditModal)
