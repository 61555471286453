import { withNamespaces } from "react-i18next";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import Select from "react-select";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import DataProtectionSoftwareService from "src/modules/data-protection/apis/DataProtectionSoftwareService";
import CommonService from "src/modules/data-protection/apis/CommonService";
import { toast } from "react-toastify";
import MultiSelect from "src/modules/data-protection/components/MultiSelect";

const FirstRow = ({
  // General
  t,
  assetId,
  handleFetchSoftwareDetailsQuery,
  // Analysts
  selectedAssignedAnalyst,
  setSelectedAssignedAnalyst,
  users,
  // Statuses
  selectedStatus,
  setSelectedStatus,
  statuses,
  // Priorities
  selectedPriority,
  setSelectedPriority,
  priorities,
  // Entities
  changeSelectedEntity,
  selectedLegalEntities,
  legalEntitiesOptions,
  fetchLegalEntitiesOptions,
}) => {
  // Statuses
  const [updatingSelectedStatus, setUpdatingSelectedStatus] = useState(false);

  // Analysts
  const [updatingSelectedAssignedAnalyst, setUpdatingSelectedAssignedAnalyst] =
    useState(false);

  // Priorities
  const [updatingSelectedPriority, setUpdatingSelectedPriority] =
    useState(false);

  // Statuses
  const changeStatusMutation = useMutation({
    mutationFn: async ({ status }) => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.changeStatus({
        softwareId: Number(assetId),
        status: status.value.toUpperCase(),
      });
    },
    onSuccess: () => {
      toast(t("Status successfully changed."), {
        type: "success",
      });
    },
    onError: (error) => {
      if (error?.response && error?.response?.status === 400) {
        toast(t("Please select a responsible person before approving the software"), {
          type: "error",
        });
      } else {
        toast(t("An error occurred while changing status."), {
          type: "error",
        });
      }
    },
    onMutate: () => {
      setUpdatingSelectedStatus(true);
    },
    onSettled: () => {
      setUpdatingSelectedStatus(false);
    },
  });

  const changeStatus = (newStatus) => {
    changeStatusMutation.mutate(
      {
        status: newStatus,
      },
      {
        onSuccess: () => {
          setSelectedStatus(newStatus);
        },
      }
    );
  };

  // Analysts
  const changeAssignedAnalystMutation = useMutation({
    mutationFn: async ({ analyst }) => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.changeAssignedAnalyst({
        softwareId: Number(assetId),
        analystId: Number(analyst.value),
      });
    },
    onSuccess: () => {
      toast(t("Assigned analyst successfully changed."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while changing analyst."), {
        type: "error",
      });
    },
    onMutate: () => {
      setUpdatingSelectedAssignedAnalyst(true);
    },
    onSettled: () => {
      setUpdatingSelectedAssignedAnalyst(false);
    },
  });

  const changeAssignedAnalyst = (newAnalyst) => {
    changeAssignedAnalystMutation.mutate(
      {
        analyst: newAnalyst,
      },
      {
        onSuccess: () => {
          console.log(typeof setSelectedAssignedAnalyst); // Add this line
          setSelectedAssignedAnalyst(newAnalyst);
        },
        onError: (e) => {
          console.log(e);
        },
      }
    );
  };

  // Priorities
  const changePriorityMutation = useMutation({
    mutationFn: async ({ priority }) => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.changePriority({
        softwareId: Number(assetId),
        priority: priority.value,
      });
    },
    onSuccess: () => {
      toast(t("Priority successfully changed."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while changing priority."), {
        type: "error",
      });
    },
    onMutate: () => {
      setUpdatingSelectedPriority(true);
    },
    onSettled: () => {
      setUpdatingSelectedPriority(false);
    },
  });

  const changeSelectedPriority = (newPriority) => {
    changePriorityMutation.mutate(
      {
        priority: newPriority,
      },
      {
        onSuccess: () => {
          setSelectedPriority(newPriority);
        },
      }
    );
  };

  // Use Effects
  

  return (
    <Card
      className="mt-3"
      style={{
        borderRadius: "12px",
        border: "1px solid rgba(130, 130, 130, 1)",
      }}
    >
      <CardBody>
        <Row>
          <Label>{t("Asset ID")}</Label>
          <p>{assetId}</p>
        </Row>

        <Row>
          {/* Status */}
          <Col>
            <Label>{t("Status")}</Label>
            <Select
              className="mt-1 text-capitalize"
              placeholder={t("Select") + "..."}
              menuPortalTarget={document.body}
              id="statuses"
              name="statuses"
              value={selectedStatus}
              onChange={changeStatus}
              options={statuses}
              isLoading={
                handleFetchSoftwareDetailsQuery.isLoading ||
                updatingSelectedStatus
              }
              isDisabled={
                handleFetchSoftwareDetailsQuery.isLoading ||
                updatingSelectedStatus
              }
            />
          </Col>

          {/* Assigned ANalyst */}
          <Col>
            <Label>{t("Assigned Analyst")}</Label>
            <Select
              className="mt-1 text-capitalize"
              placeholder={t("Select") + "..."}
              menuPortalTarget={document.body}
              id="assignedAnalyst"
              name="assignedAnalyst"
              isError={handleFetchSoftwareDetailsQuery?.isError}
              isLoading={
                handleFetchSoftwareDetailsQuery?.isLoading ||
                handleFetchSoftwareDetailsQuery?.isFetching ||
                updatingSelectedAssignedAnalyst
              }
              isDisabled={
                handleFetchSoftwareDetailsQuery?.isLoading ||
                handleFetchSoftwareDetailsQuery?.isFetching ||
                updatingSelectedAssignedAnalyst
              }
              options={users}
              value={selectedAssignedAnalyst}
              onChange={changeAssignedAnalyst}
            />
          </Col>

          {/* Priority */}
          <Col>
            <Label>{t("Priority")}</Label>
            <Select
              className="mt-1 text-capitalize"
              placeholder={t("Select") + "..."}
              menuPortalTarget={document.body}
              id="priorities"
              name="statprioritiesuses"
              value={selectedPriority}
              options={priorities}
              isLoading={
                handleFetchSoftwareDetailsQuery.isLoading ||
                handleFetchSoftwareDetailsQuery.isFetching ||
                updatingSelectedPriority
              }
              isDisabled={
                handleFetchSoftwareDetailsQuery.isLoading ||
                handleFetchSoftwareDetailsQuery.isFetching ||
                updatingSelectedPriority
              }
              onChange={changeSelectedPriority}
            />
          </Col>

          <Col>
 
              <Label for="entities">{t("Legal Entities") + ":"}</Label>
              <MultiSelect
              placeholder={t("Select") + "..."}
              selectedValues={selectedLegalEntities}
              options={legalEntitiesOptions}
              isDisabled={handleFetchSoftwareDetailsQuery.isLoading || handleFetchSoftwareDetailsQuery.isFetching || updatingSelectedPriority || fetchLegalEntitiesOptions.isLoading || fetchLegalEntitiesOptions.isFetching}
              onChange={changeSelectedEntity}
              />
 
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default withNamespaces()(FirstRow);
