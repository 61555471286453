import React, { Component } from "react";
import axios from "axios";
import classnames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import {
  Container,
  Row,
  Col,
  Input,
  Card,
  Label,
  Button,
  CardBody,
  Nav,
  TabContent,
  NavLink,
  NavItem,
  TabPane,
  Form,
  FormGroup,
  Table,
} from "reactstrap";
import Select from "react-select";
import Breadcrumbs from "src/components/Common/Breadcrumb";
import { successNotification } from "src/store/actions";
import { errorNotification } from "src/store/actions";
import { isFeatureEnabledForActiveModule } from 'src/helpers/module_helper';

import {
    API_URL_FETCH_RISK_MATRIX,
    API_URL_RISK_COSTOMIZATION,
    API_URL_RISK_RESTORE_COSTOMIZATION,
    API_URL_FETCH_RISK_LIKELIHOODS
} from 'src/modules/3rd-party-management/constants';

import {
  API_URL_GET_QR_CODE,
  DATA_UPDATED_SUCCESSFULLY,
  DATA_NOT_UPDATED_SUCCESSFULLY,
  DATA_RESTORED,
  DATA_NOT_RESTORED,
} from "src/common/constants";

import {
    API_URL_GET_NOTIFICATION_SETTINGS,
    API_URL_UPDATE_NOTIFICATION_LIST,
} from 'src/modules/3rd-party-management/constants';

import { fetch, getCurrentLanguage } from "src/i18n";
import { TbLoader } from "react-icons/tb";
import ModuleContext from "src/contexts/ModuleContext";

class Settings extends Component {
  static contextType = ModuleContext;

  constructor(props) {
    super(props);

    this.state = {
      header: {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      },
      breadcrumbItems: [
        { title: "SIP", link: "/" },
        { title: this.props.t("Settings"), link: "#" },
      ],

      // Notifications
      notificationList: [],
      enableNotifications: [],
      updatingNotifications: false,

      case_assign: true,
      report_read: true,
      change_role: true,
      not_read_case: true,
      comment_report: true,
      not_closed_case: true,
      new_chat_message: true,
      new_case_reported: true,
      status_case_report: true,
      new_meeting_request: true,
      remind_unanswer_chat: true,
      new_meeting_requested: true,
      new_case_confident_submitted: true,
      new_task_created: true,
      task_status_new: true,
      task_status_in_progress: true,
      task_status_review: true,
      task_status_close: true,
      task_status_delete: true,
      task_priority_low: true,
      task_priority_medium: true,
      task_priority_high: true,
      task_comment: true,
      task_overdue: true,
      task_change_deadline: true,
      task_change_description: true,
      task_before_overdue: true,
      risk_rating: true,
      risk_change_description: true,
      new_task: true,
      risk_assign: true,
      risk_comment: true,
      readonly_access: true,
      voice_not_deleted: true,
      qrcode: null,
      consequences: [],
      matrix: [],
      likelihoods: [],
      options: [],
      activeTabJustify: isFeatureEnabledForActiveModule(
        "email-notifications"
      )
        ? "1"
        : isFeatureEnabledForActiveModule("qr-code")
        ? "2"
        : "3",
      ratings: [],
      topRatings: [],
      reload: false,
      likLabelError: "",
      likLabelErrorId: -1,
      lang: getCurrentLanguage(),
      likelihoodsInputs: [],
      currency: "",
      currencyOptions: [
        { value: "USD", label: "USD" },
        { value: "EUR", label: "EUR" },
        { value: "GBP", label: "GBP" },
      ],
      defCurrency: "",
      errConsIndx: -1,
      errCons: false,
      errLikIndx: -1,
      errLik: false,
    };

    this.consequenceRef = React.createRef();
    this.likelihoodsRef = React.createRef();
  }

  // Tabs
  toggleDetailTab = (tab) => {
    if (this.state.activeTabJustify !== tab) {
      this.setState({
        activeTabJustify: tab,
      });
    }
  };

  handleSwitch = (e) => {
    let newNotificationList = [];

    newNotificationList = this.state.notificationList.map((obj) => {
      if (obj.value == e.value) {
        return { ...obj, active: !e.active };
      }

      return obj;
    });

    this.setState(
      {
        notificationList: newNotificationList,
      },
      () => {
        if (e.active) {
          this.setState({
            enableNotifications: this.state.enableNotifications.filter(
              (i) => i !== e.value
            ),
          });
        } else {
          if (!this.state.enableNotifications.includes(e.value)) {
            this.setState({
              enableNotifications: [...this.state.enableNotifications, e.value],
            });
          }
        }
      }
    );
  };

  handleSubmit = (e) => {
    if (this.state.updatingNotifications) return;
    this.setState({ updatingNotifications: true });
    e.preventDefault();

    const data = {
      enableNotifications: this.state.enableNotifications,
    };

    axios
      .post(API_URL_UPDATE_NOTIFICATION_LIST, data, this.state.header)
      .then((response) => {
        const res = response.data;

        if (res.ok) {
          this.props.successNotification({
            code: "saved",
            message: this.props.t("Data was saved successfully"),
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;

          this.props.errorNotification({
            code: data.error || "unknown_error",
            message: this.props.t(data.message || data.title),
          });
        }
      })
      .finally(() => {
        this.setState({ updatingNotifications: false });
      });
  };

  fetchQRCode = () => {
    axios
      .get(API_URL_GET_QR_CODE, {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        const href = URL.createObjectURL(response.data);

        this.setState({
          qrcode: href,
        });
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;

          this.props.errorNotification({
            code: data.error || "unknown_error",
            message: this.props.t(data.message || data.title),
          });
        }
      });
  };

  fetchNotifications = () => {
    axios
      .get(API_URL_GET_NOTIFICATION_SETTINGS, {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((res) => {
        if (res.data.ok) {
          const notificationList = res.data.data;

          if (Array.isArray(notificationList) && notificationList.length) {
            const enableNotifications = [];

            notificationList.map((item) => {
              if (item.active) {
                enableNotifications.push(item.value);
              }
            });

            this.setState({
              notificationList,
              enableNotifications,
            });
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;

          this.props.errorNotification({
            code: data.error || "unknown_error",
            message: this.props.t(data.message || data.title),
          });
        }
      });
  };

  fetchMatrix = () => {
    const url = API_URL_FETCH_RISK_MATRIX;
    const { header } = this.state;
    axios
      .get(url, header)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            matrix: [],
            likelihoods: [],
            ratings: [],
            consequences: [],
            topRatings: [],
          });
          const { data } = response.data;
          const newConsequencesData = data.consequences.map((cons) => {
            return {
              id: cons.id,
              label: this.props.t(cons.label),
              title: cons.title,
              number: cons.number,
            };
          });

          const newLikelihoodsData = data.likelihoods.map((lik) => {
            return {
              id: lik.id,
              label: this.props.t(lik.label),
              title: lik.title,
              number: lik.number,
            };
          });

          const newRatingsData = data.ratings.map((rate) => {
            return {
              id: rate.id,
              name: this.props.t(rate.name),
              number: rate.number,
            };
          });
          this.setState(
            {
              consequences: newConsequencesData,
              likelihoods: newLikelihoodsData,
              matrix: data.matrix,
              currency: data.currency,
              // ratings: data.ratings,
            },
            () => {
              const defCurr = this.state.currencyOptions.filter(
                (c) => c.label === this.state.currency
              )[0];
              this.setState({ defCurrency: defCurr });
            }
          );
          const rates = newRatingsData.map((r) => {
            return {
              id: r.id,
              label: this.props.t(r.name),
              number: r.number,
            };
          });

          const topRates = newRatingsData.map((r) => {
            return {
              id: r.id,
              label: this.props.t(r.name),
              number: r.number,
            };
          });
          this.setState({ ratings: rates, topRatings: topRates }, () =>
            console.log(this.state)
          );
        }
      })
      .catch((err) => console.log(err));
  };

  fetchLikelihoods = () => {
    const url = API_URL_FETCH_RISK_LIKELIHOODS;
    axios
      .get(url, this.state.header)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            likelihoodsInputs: [],
          });
          const { data } = response.data;
          const result = data.map((item) => {
            return {
              value: item.id,
              label: this.props.t(item.label),
              title: this.props.t(item.title),
              number: item.number,
            };
          });
          this.setState({
            likelihoodsInputs: result,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  onRatingChange = (e, number) => {
    const { topRatings } = this.state;
    const result = topRatings.filter((rate) => rate.number === number);
    result[0].label = e.target.value;
    this.setState({
      topRatings: topRatings,
    });
  };
  onSubmit = (e, id) => {
    const url = API_URL_RISK_COSTOMIZATION;
    const {
      // ratings,
      topRatings,
      header,
      consequences,
      // likelihoods,
      matrices,
      likelihoodsInputs,
    } = this.state;
    const ratings = topRatings;
    this.setState({
      errCons: false,
      errConsIndx: -1,
      errLik: false,
      errLikIndx: -1,
    });
    let consErrFlag = false;
    let likelihoodsErrFlag = false;
    const newConsArr = consequences.map((item, i) => {
      if (consErrFlag) {
        return null;
      }
      const { number, ...rest } = item; // using destructuring to remove the 'number' property
      if (
        consequences[i - 1] &&
        Number(consequences[i].title) < Number(consequences[i - 1].title)
      ) {
        this.setState({
          errCons: true,
          errConsIndx: i,
        });
        return (consErrFlag = true);
      } else {
        return rest;
      }
    });

    if (consErrFlag) {
      this.props.errorNotification({
        message: this.props.t(DATA_NOT_UPDATED_SUCCESSFULLY),
      });
      return this.consequenceRef.current.scrollIntoView();
    }
    const result = ratings.map((c) => {
      return {
        id: c.id,
        name: c.label,
      };
    });

    const likelihoodsResult = likelihoodsInputs.map((lik, i) => {
      if (likelihoodsErrFlag) {
        return null;
      }

      if (
        likelihoodsInputs[i - 1] &&
        Number(likelihoodsInputs[i].label) <
          Number(likelihoodsInputs[i - 1].label)
      ) {
        this.setState({
          errLik: true,
          errLikIndx: i,
        });
        return (likelihoodsErrFlag = true);
      } else {
        return {
          id: lik.value,
          title: lik.title,
          label: lik.label,
        };
      }
    });

    if (likelihoodsErrFlag) {
      this.props.errorNotification({
        message: this.props.t(DATA_NOT_UPDATED_SUCCESSFULLY),
      });
      return this.likelihoodsRef.current.scrollIntoView();
    }

    const data =
      id === "currency"
        ? {
            consequences: [],
            likelihoods: [],
            matrices: [],
            ratings: [],
            currency: e.label,
          }
        : {
            consequences: newConsArr,
            likelihoods: likelihoodsResult,
            matrices: matrices,
            ratings: result,
            currency: this.state.currency,
          };
    axios
      .post(url, data, header)
      .then(async (response) => {
        if (response.status === 200) {
          await fetch();
          // this.fetchMatrix();
          this.fetchLikelihoods();
          if (id === "currency") {
            this.setState({ currency: e.label });
          }
          this.props.successNotification({
            code: "Success",
            message: this.props.t(DATA_UPDATED_SUCCESSFULLY),
          });
        } else {
          this.props.errorNotification({
            code: response.code,
            message: this.props.t(DATA_NOT_UPDATED_SUCCESSFULLY),
          });
        }
      })
      .catch((err) => {
        this.props.errorNotification({
          code: err.code,
          message: this.props.t(DATA_NOT_UPDATED_SUCCESSFULLY),
        });
      });
  };

  onConsequencesSubmit = () => {
    const url = API_URL_RISK_COSTOMIZATION;
    const { consequences, header } = this.state;
    const result = consequences.map((c) => {
      return {
        id: c.id,
        label: c.label,
        title: c.title,
      };
    });
    const data = {
      consequences: result,
      likelihoods: [],
      matrices: [],
      ratings: [],
    };
    axios
      .post(url, data, header)
      .then((response) => {
        if (response.status === 200) {
          this.props.successNotification({
            code: "Success",
            message: this.props.t(DATA_UPDATED_SUCCESSFULLY),
          });
        } else {
          this.props.errorNotification({
            code: response.code,
            message: this.props.t(DATA_NOT_UPDATED_SUCCESSFULLY),
          });
        }
      })
      .catch((err) => {
        this.props.errorNotification({
          code: err.code,
          message: this.props.t(DATA_NOT_UPDATED_SUCCESSFULLY),
        });
      });
  };

  onConsequenceLabelChange = (e, number) => {
    const { consequences } = this.state;
    const result = consequences.filter((c) => c.number === number);
    result[0].label = e.target.value;
    this.setState({
      consequences: consequences,
    });
  };

  onConsequenceTitleChange = (e, number) => {
    const { consequences } = this.state;
    this.setState({
      errCons: false,
      errConsIndx: -1,
    });
    const result = consequences.filter((c) => c.number === number);
    result[0].title = e;
    this.setState({
      consequences: consequences,
    });
  };

  onLikelihoodsSubmit = () => {
    const url = API_URL_RISK_COSTOMIZATION;
    const { likelihoodsInputs, header } = this.state;
    const result = likelihoodsInputs.map((c) => {
      return {
        id: c.id,
        label: c.label,
        title: c.title,
      };
    });
    const data = {
      consequences: [],
      likelihoods: result,
      matrices: [],
      ratings: [],
    };
    axios
      .post(url, data, header)
      .then((response) => {
        if (response.status === 200) {
          this.props.successNotification({
            code: "Success",
            message: this.props.t(DATA_UPDATED_SUCCESSFULLY),
          });
        } else {
          this.props.errorNotification({
            code: response.code,
            message: this.props.t(DATA_NOT_UPDATED_SUCCESSFULLY),
          });
        }
      })
      .catch((err) => {
        this.props.errorNotification({
          code: err.code,
          message: this.props.t(DATA_NOT_UPDATED_SUCCESSFULLY),
        });
      });
  };

  onLikelihoodsLabelChange = (e, number) => {
    this.setState({
      errLik: false,
      errLikIndx: -1,
    });
    if (Number(e.target.value) >= 100) {
      return this.setState({
        likLabelError: "Maximum allowed value is" + " 99",
        likLabelErrorId: number,
      });
    } else {
      this.setState({
        likLabelError: "",
        likLabelErrorId: -1,
      });
      const { likelihoodsInputs } = this.state;
      const result = likelihoodsInputs.filter((c) => c.number === number);
      result[0].label = e.target.value;

      this.setState({
        likelihoodsInputs: likelihoodsInputs,
      });
    }
  };

  onLikelihoodsTitleChange = (e, number) => {
    const { likelihoodsInputs } = this.state;
    const result = likelihoodsInputs.filter((c) => c.number === number);
    result[0].title = e.target.value;
    this.setState({
      likelihoodsInputs: likelihoodsInputs,
    });
  };

  onMatricesSubmit = () => {};

  onMatricesChange = (e, matrice) => {
    const url = API_URL_RISK_COSTOMIZATION;
    const { matrix, header } = this.state;
    const result = matrix.map((m) => {
      return {
        id: m.id,
        name: m.name,
        color: m.color,
      };
    });
    const data = {
      consequences: [],
      likelihoods: [],
      matrices: [{ id: matrice.id, ratingId: e.id }],
      ratings: [],
      currency: this.state.currency,
    };
    axios
      .post(url, data, header)
      .then((response) => {
        if (response.status === 200) {
          this.fetchMatrix();
          this.props.successNotification({
            code: "Success",
            message: this.props.t(DATA_UPDATED_SUCCESSFULLY),
          });
        } else {
          this.props.errorNotification({
            code: response.code,
            message: this.props.t(DATA_NOT_UPDATED_SUCCESSFULLY),
          });
        }
      })
      .catch((err) => {
        this.props.errorNotification({
          code: err.code,
          message: this.props.t(DATA_NOT_UPDATED_SUCCESSFULLY),
        });
      });
  };

  restore = () => {
    const url = API_URL_RISK_RESTORE_COSTOMIZATION;
    const { header } = this.state;
    axios
      .post(url, {}, header)
      .then((response) => {
        this.fetchMatrix();
        this.fetchLikelihoods();
        this.setState({
          reload: true,
          activeTabJustify: 3,
          matrix: response.data.data,
        });
        this.forceUpdate();
        this.props.successNotification({
          message: this.props.t(DATA_RESTORED),
        });
      })
      .catch((err) => {
        this.props.errorNotification({
          message: this.props.t(DATA_NOT_RESTORED),
        });
      });
  };

  render() {
    const { consequences, likelihoods, matrix, ratings, likelihoodsInputs } =
      this.state;
    if (
      !isFeatureEnabledForActiveModule("email-notifications") &&
      !isFeatureEnabledForActiveModule("qr-code") &&
      !isFeatureEnabledForActiveModule("risk-assessment")
    ) {
      return (
        <div className="page-content">
          {this.props.t("Please upgrade your plan to access this feature")}.
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="page-content" style={{ userSelect: "none" }}>
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Settings")}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <Nav tabs>
                      {isFeatureEnabledForActiveModule("email-notifications") && (
                        <NavItem>
                          <NavLink
                            style={{
                              cursor: "pointer",
                            }}
                            className={classnames({
                              active: this.state.activeTabJustify === "1",
                            })}
                            onClick={() => {
                              this.toggleDetailTab("1");
                            }}
                          >
                            <span className="d-block">{`${this.props.t(
                              "Notifications"
                            )}`}</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {/* {isFeatureEnabledForActiveModule(
                        "qr-code"
                      ) && (
                        <NavItem>
                          <NavLink
                            style={{
                              cursor: "pointer",
                            }}
                            className={classnames({
                              active: this.state.activeTabJustify === "2",
                            })}
                            onClick={() => {
                              this.toggleDetailTab("2");
                            }}
                          >
                            <span className="d-block">{`${this.props.t(
                              "QR Code"
                            )}`}</span>
                          </NavLink>
                        </NavItem>
                      )} */}
                      {isFeatureEnabledForActiveModule(
                        "risk-custom"
                      ) && (
                        <NavItem>
                          <NavLink
                            style={{
                              cursor: "pointer",
                            }}
                            className={classnames({
                              active: this.state.activeTabJustify === "3",
                            })}
                            onClick={() => {
                              this.toggleDetailTab("3");
                            }}
                          >
                            <span className="d-block">
                              {this.props.t("Risk Customization")}
                            </span>
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>

                    <TabContent activeTab={this.state.activeTabJustify}>
                      {isFeatureEnabledForActiveModule(
                        "email-notifications"
                      ) && (
                        <TabPane tabId="1" className="pt-4 pb-4">
                          <AvForm
                            className="needs-validation"
                            onValidSubmit={this.handleSubmit}
                          >
                            <div>
                              <div className="mb-3">
                                <h4 className="card-title">{`${this.props.t(
                                  "Email"
                                )} ${this.props.t("Notifications")}:`}</h4>
                              </div>
                              <div className="mb-4">
                                {this.state.notificationList.map(
                                  (item, index) => (
                                    <div
                                      className="form-check form-switch mb-3"
                                      dir="ltr"
                                      key={index}
                                    >
                                      <Input
                                        id={`option-${index}`}
                                        type="checkbox"
                                        className="form-check-input"
                                        defaultChecked={item.active}
                                        onClick={() => this.handleSwitch(item)}
                                      />
                                      <Label
                                        htmlFor={`option-${index}`}
                                        className="form-check-label"
                                      >
                                        {this.props.t(item.label)}
                                      </Label>
                                    </div>
                                  )
                                )}
                              </div>

                              {this.state.notificationList &&
                              this.state.notificationList.length ? (
                                <Button color="primary" type="submit">
                                  {!this.state.updatingNotifications ? (
                                    this.props.t("Save")
                                  ) : (
                                    <TbLoader />
                                  )}
                                </Button>
                              ) : null}
                            </div>
                          </AvForm>
                        </TabPane>
                      )}
                      {/* {isFeatureEnabledForActiveModule(
                        "qr-code"
                      ) && (
                        <TabPane tabId="2" className="pt-4 pb-4">
                          <div className="mb-3">
                            <h4 className="card-title">{`${this.props.t(
                              "QR Code"
                            )}:`}</h4>
                          </div>
                          <div className="mb-3">
                            <img src={this.state.qrcode} alt="qrcode" />
                          </div>
                        </TabPane>
                      )} */}
                      {isFeatureEnabledForActiveModule(
                        "risk-assessment"
                      ) && (
                        // this.props.Feature.filter(
                        //   (f) => f.title === "risk-assessment"
                        // )[0].flag &&
                        <TabPane tabId="3" className="pt-4 pb-4">
                          {/* <div className="mb-3"> */}
                          {/* <Row>
                              <Col lg="11">
                                <h4 className="card-title pt-2">{`${this.props.t(
                                  "Risk"
                                )}:`}</h4>
                              </Col>
                            </Row>
                          </div> */}
                          <div className="mb-3">
                            <Form inline>
                              <fieldset
                                style={{
                                  border: "1px solid #ced4da",
                                }}
                                className="p-3"
                              >
                                {/* *********************** RATINGS ********************* */}
                                {this.state.defCurrency !== "" ? (
                                  <Row
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <Col lg="11" md="10" sm="5" xs="9">
                                      <Label>{this.props.t("Ratings")}</Label>
                                    </Col>
                                    <Col lg="1" md="2" sm="2" xs="1">
                                      <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={this.state.currencyOptions}
                                        placeholder={"Currency"}
                                        defaultValue={this.state.defCurrency}
                                        onChange={(e) =>
                                          this.onSubmit(e, "currency")
                                        }
                                      />
                                    </Col>
                                  </Row>
                                ) : (
                                  ""
                                )}
                                <Row>
                                  <Col lg="10">
                                    {/* <h5>{this.props.t("Name")}</h5> */}
                                    <Row className="mb-3">
                                      {ratings.map((rate, i) => {
                                        const { id, label, number } = rate;
                                        return (
                                          <Col>
                                            <FormGroup>
                                              <Label for={`cl${i + 1}}`}>
                                                {this.props.t("Rating") +
                                                  " " +
                                                  (i + 1)}
                                              </Label>
                                              <Input
                                                type="text"
                                                name={`rate${i + 1}}`}
                                                id={`rate${i + 1}}`}
                                                defaultValue={this.props.t(
                                                  label
                                                )}
                                                onChange={(e) =>
                                                  this.onRatingChange(e, number)
                                                }
                                              />
                                            </FormGroup>{" "}
                                          </Col>
                                        );
                                      })}
                                    </Row>
                                  </Col>
                                  {/* <Col
                                    lg="2"
                                    md="2"
                                    // style={{ top: "11vh", left: "10vh" }}
                                  >
                                    <Button
                                      onClick={this.onRatingsSubmit}
                                      color="primary"
                                      // size="lg"
                                      style={{
                                        position: "relative",
                                        marginTop: "12%",
                                      }}
                                    >
                                      {this.props.t("Save")}
                                    </Button>
                                  </Col> */}
                                </Row>
                                <hr></hr>
                                {/* *********************** CONSEQUENCES ********************* */}
                                <Label>
                                  {this.props.t("Damage in") +
                                    ` ${this.state.currency}`}
                                </Label>
                                <Row>
                                  <Col lg="10">
                                    {/* <h5>{this.props.t("Label")}</h5> */}
                                    <span ref={this.consequenceRef}></span>
                                    <Row className="mb-3">
                                      {consequences.map((cons, i) => {
                                        const { id, label, number } = cons;
                                        return (
                                          <Col>
                                            <FormGroup>
                                              <Label for={`cl${i + 1}}`}>
                                                {this.props.t("Damage") +
                                                  " " +
                                                  (i + 1)}
                                              </Label>
                                              <Input
                                                type="text"
                                                // step="any"
                                                name={`cl${i + 1}`}
                                                id={`cl${i + 1}`}
                                                defaultValue={this.props.t(
                                                  label
                                                )}
                                                onChange={(e) => {
                                                  this.onConsequenceLabelChange(
                                                    e,
                                                    number
                                                  );
                                                }}
                                              />
                                            </FormGroup>{" "}
                                          </Col>
                                        );
                                      })}
                                    </Row>

                                    {/* <h5>{this.props.t("Title")}</h5> */}
                                    <Row className="mb-3">
                                      {consequences.map((cons, i) => {
                                        const { title, number } = cons;
                                        return (
                                          <Col>
                                            <FormGroup>
                                              <Label for={`cl${i + 1}`}>
                                                {this.props.t("Damage") +
                                                  " " +
                                                  (i + 1) +
                                                  " >="}
                                              </Label>
                                              <Input
                                                type="text"
                                                name={`cl${i + 1}`}
                                                id={`cl${i + 1}`}
                                                defaultValue={Number(
                                                  title
                                                ).toLocaleString(
                                                  this.state.lang
                                                )}
                                                style={{
                                                  textAlign: "right",
                                                  border:
                                                    this.state.errCons &&
                                                    this.state.errConsIndx === i
                                                      ? "2px solid red"
                                                      : null,
                                                }}
                                                onKeyDown={(e) => {
                                                  // Allow only numeric and control keys (e.g., backspace, delete)
                                                  if (
                                                    !(
                                                      /[0-9.\b]/.test(e.key) ||
                                                      (e.key === "ArrowLeft" ||
                                                        e.key === "ArrowRight" ||
                                                        e.key === "Delete" ||
                                                        e.key === "Backspace")
                                                    )
                                                  ) {
                                                    e.preventDefault();
                                                  }
                                                }}
                                                onChange={(e) => {
                                                  const val =
                                                    e.target.value.replace(
                                                      /[,\.]/g,
                                                      ""
                                                    );
                                                  const formattedValue = Number(
                                                    val
                                                  ).toLocaleString(
                                                    this.state.lang
                                                  );
                                                  e.target.value =
                                                    formattedValue;
                                                  this.onConsequenceTitleChange(
                                                    Number(val),
                                                    number
                                                  );
                                                }}
                                              />
                                              <p
                                                className="text-danger"
                                                hidden={
                                                  !(
                                                    this.state.errCons &&
                                                    this.state.errConsIndx === i
                                                  )
                                                }
                                              >
                                                {this.props.t(
                                                  "The value of this field should be greater than the previous one"
                                                )}
                                              </p>
                                            </FormGroup>{" "}
                                          </Col>
                                        );
                                      })}
                                    </Row>
                                  </Col>
                                  {/* <Col
                                    lg="2"
                                    md="2"
                                    // style={{ top: "11vh", left: "10vh" }}
                                  >
                                    <Button
                                      onClick={this.onConsequencesSubmit}
                                      color="primary"
                                      // size="lg"
                                      style={{
                                        position: "relative",
                                        marginTop: "30%",
                                      }}
                                    >
                                      {this.props.t("Save")}
                                    </Button>
                                  </Col> */}
                                </Row>
                                <hr></hr>
                                <span ref={this.likelihoodsRef}></span>
                                {/******************************** LIKELIHOODS ***************************/}
                                <Label>{this.props.t("Likelihood")}</Label>
                                <Row>
                                  <Col lg="10">
                                    {/* <h5>{this.props.t("Title")}</h5> */}
                                    <Row className="mb-3">
                                      {likelihoodsInputs.map((lik, i) => {
                                        // console.log(lik);
                                        const { title, number } = lik;
                                        const id = lik.value;
                                        return (
                                          <Col>
                                            <FormGroup>
                                              <Label for={`lt${i + 1}`}>
                                                {this.props.t("Likelihood") +
                                                  " " +
                                                  (i + 1 == 1
                                                    ? "A"
                                                    : i + 1 == 2
                                                    ? "B"
                                                    : i + 1 == 3
                                                    ? "C"
                                                    : i + 1 == 4
                                                    ? "D"
                                                    : "E")}
                                              </Label>
                                              <Input
                                                type="text"
                                                name={`lt${i + 1}`}
                                                id={`lt${i + 1}`}
                                                defaultValue={this.props.t(
                                                  title
                                                )}
                                                onChange={(e) =>
                                                  this.onLikelihoodsTitleChange(
                                                    e,
                                                    number
                                                  )
                                                }
                                              />
                                            </FormGroup>{" "}
                                          </Col>
                                        );
                                      })}
                                    </Row>
                                    {/* <h5>{this.props.t("Label")}</h5> */}
                                    <Row className="mb-3">
                                      {likelihoodsInputs.map((lik, i) => {
                                        const { label, number } = lik;
                                        const id = lik.value;
                                        return (
                                          <Col>
                                            <FormGroup>
                                              <Label for={`ll${i + 1}`}>
                                                {this.props.t("Likelihood") +
                                                  " " +
                                                  (i + 1 == 1
                                                    ? "A"
                                                    : i + 1 == 2
                                                    ? "B"
                                                    : i + 1 == 3
                                                    ? "C"
                                                    : i + 1 == 4
                                                    ? "D"
                                                    : "E") +
                                                  " >=" +
                                                  " (%)"}
                                              </Label>
                                              <Input
                                                type="number"
                                                min="0"
                                                max="99"
                                                name={`ll${i + 1}`}
                                                id={`ll${i + 1}`}
                                                defaultValue={label}
                                                style={{
                                                  textAlign: "right",
                                                  border:
                                                    this.state.errLik &&
                                                    this.state.errLikIndx === i
                                                      ? "2px solid red"
                                                      : null,
                                                }}
                                                onChange={(e) => {
                                                  this.onLikelihoodsLabelChange(
                                                    e,
                                                    number
                                                  );
                                                }}
                                              />
                                              <p
                                                className="text-danger"
                                                hidden={
                                                  id !==
                                                  this.state.likLabelErrorId
                                                }
                                              >
                                                {this.state.likLabelError
                                                  ? this.props.t(
                                                      this.state.likLabelError
                                                    )
                                                  : ""}
                                              </p>
                                              <p
                                                className="text-danger"
                                                hidden={
                                                  !(
                                                    this.state.errLik &&
                                                    this.state.errLikIndx === i
                                                  )
                                                }
                                              >
                                                {this.props.t(
                                                  "The value of this field should be greater than the previous one"
                                                )}
                                              </p>
                                            </FormGroup>{" "}
                                          </Col>
                                        );
                                      })}
                                    </Row>
                                  </Col>
                                  {/* <Col lg="2" md="2">
                                    <Button
                                      onClick={this.onLikelihoodsSubmit}
                                      color="primary"
                                      // size="lg"
                                      style={{
                                        position: "relative",
                                        marginTop: "30%",
                                      }}
                                    >
                                      {this.props.t("Save")}
                                    </Button>
                                  </Col> */}
                                </Row>
                                <Row>
                                    <Col>
                                        <Button
                                        className="me-1"
                                        onClick={this.onSubmit}
                                        color="primary"
                                        >
                                            {this.props.t("Save")}
                                        </Button>
                                    </Col>
                                </Row>
                                <hr></hr>
                                {/******************************** MATRICES ***************************/}
                                {/* <Row className="mb-2">
                                  <Col lg="11">
                                    <h5>{this.props.t("Matrix")}</h5>
                                  </Col>
                                  <Col lg="1">
                                    <Button
                                      onClick={this.restore}
                                      color="primary"
                                    >
                                      {this.props.t("Restore")}
                                    </Button>
                                  </Col>
                                </Row> */}
                                <Row
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <h5>{this.props.t("Damage")}</h5>
                                </Row>
                                <Row>
                                  <Table borderless responsive>
                                    <thead
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      <th></th>
                                      <th
                                        style={{
                                          border:
                                            "1px solid rgba(0, 0, 0, 0.1)",
                                        }}
                                      >
                                        {"#"}
                                      </th>
                                      {consequences.map((item) => {
                                        return (
                                          <th
                                            style={{
                                              border:
                                                "1px solid rgba(0, 0, 0, 0.1)",
                                            }}
                                          >
                                            {this.props.t(item.label)}
                                          </th>
                                        );
                                      })}
                                    </thead>

                                    <tbody
                                      style={{
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      {matrix.map((item, i) => {
                                        return (
                                          <>
                                            <tr>
                                              <th
                                                style={{
                                                  width: "1%",
                                                  // height: "1%",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    display: "inline-block",
                                                    textAlign: "center",
                                                    transform: "rotate(-90deg)",
                                                  }}
                                                  hidden={i !== 2}
                                                >
                                                  {this.props.t("Likelihood")}
                                                </span>
                                              </th>
                                              <th
                                                style={{
                                                  border:
                                                    "1px solid rgba(0, 0, 0, 0.1)",
                                                }}
                                              >
                                                {this.props.t(
                                                  likelihoods[i].label
                                                )}
                                              </th>
                                              {item.map((m) => {
                                                const { id, name, color } = m;
                                                const def = ratings.filter(
                                                  (r) =>
                                                    this.props.t(r.label) ===
                                                    this.props.t(name)
                                                );
                                                if (def[0]) {
                                                  return (
                                                    <td
                                                      style={{
                                                        background: `#${color}`,
                                                        border:
                                                          "1px solid rgba(0, 0, 0, 0.1)",
                                                      }}
                                                    >
                                                      <Select
                                                        name="matr"
                                                        styles={{
                                                          control: (
                                                            styles
                                                          ) => ({
                                                            ...styles,
                                                            backgroundColor:
                                                              "white",
                                                          }),
                                                          option: (
                                                            styles,
                                                            {
                                                              data,
                                                              isDisabled,
                                                              isFocused,
                                                              isSelected,
                                                            }
                                                          ) => {
                                                            return {
                                                              ...styles,
                                                              backgroundColor:
                                                                isFocused
                                                                  ? "#4aa3ff"
                                                                  : "white",
                                                              color: "black",
                                                            };
                                                          },
                                                        }}
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        options={ratings}
                                                        menuPortalTarget={
                                                          document.body
                                                        }
                                                        defaultValue={def[0]}
                                                        onChange={(e) =>
                                                          this.onMatricesChange(
                                                            e,
                                                            m
                                                          )
                                                        }
                                                      />
                                                    </td>
                                                  );
                                                }
                                              })}
                                            </tr>
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </Row>
                                <Row>
                                  <Col lg="3" md="3" sm="12">
                                    <Button
                                      onClick={this.restore}
                                      color="primary"
                                    >
                                      {this.props.t("Restore")}
                                    </Button>
                                  </Col>
                                  <Col lg="1" md="1" sm="12"></Col>
                                </Row>
                              </fieldset>
                            </Form>
                          </div>
                        </TabPane>
                      )}
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  componentDidMount() {
    if (this.state.reload) {
      this.setState(
        {
          activeTabJustify: "3",
        },
        () => {
          this.setState({
            reload: false,
          });
        }
      );
    }

    this.fetchQRCode();
    this.fetchNotifications();
    this.fetchMatrix();
    this.fetchLikelihoods();
    // this.fetchRatings();
  }

  componentDidUpdate() {
    if (this.state.reload) {
      this.setState(
        {
          activeTabJustify: "3",
        },
        () => {
          this.setState({
            reload: false,
          });
        }
      );
    }
    if (getCurrentLanguage() !== this.state.lang) {
      this.setState(
        {
          lang: getCurrentLanguage(),
          consequences: [],
          matrix: [],
          likelihoods: [],
          options: [],
          ratings: [],
        },
        () => {
          this.fetchMatrix();
          this.fetchLikelihoods();
          this.forceUpdate();
        }
      );
    }
    // this.fetchQRCode();
    // this.fetchNotifications();
    // this.fetchMatrix();
  }
}

const mapStatetoProps = (state) => {
    const { token } = state.Login;
	const organization = state.Organization;
	const App = state.App;

	return { organization, token, App };
};

const mapDispachToProps = (dispach) => {
  return {
    successNotification: (message) => dispach(successNotification(message)),
    errorNotification: (message) => dispach(errorNotification(message)),
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, mapDispachToProps)(Settings))
);
