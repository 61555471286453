import { Fragment, memo, useEffect, useState, useMemo } from "react";
import axios from "axios";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Row,
} from "reactstrap";
import risk_ai from "../../../../assets/images/AI pic for SIP.png";
import risk_ai_processing from "../../../../assets/images/AI_process.png";
import { GoTag } from "react-icons/go";
import { CiFlag1 } from "react-icons/ci";
import ButtonsGroup from "./ButtonsGroup";
import {
  API_URL_RISK_AI_GET_ADDITIONAL_DETAILS,
  API_URL_RISK_AI_TRANSLATION,
  CREATE_RISK_FAILED,
  CREATE_RISK_SUCCESSFUL,
} from "../../../../common/constants";
import "./AIRiskAssessment.scss";
import AIRiskModal from "./AIRiskModal";
import AIRiskRightModal from "./AIRiskRightModal";
import AIRiskDetailsModal from "./AIRiskDetailsModal";
import i18next from "i18next";

const AIBasedRiskAssessment = function (props) {
  AIBasedRiskAssessment.displayName =
    "Memorized Component - AI Risk Assessment Modal";
  const { t, isOpen, reportId, token, errorNotification, successNotification } =
    props;

  const [AIRisks, setAIRisks] = useState(null);
  const [explanation, setExplanation] = useState(null);
  const [collapse, setCollapse] = useState(0);
  const [regenerate, setRegenrate] = useState(false);
  const [showProcessModal, setShowProcessingModal] = useState(false);
  const [showRightModal, setShowRightModal] = useState(false);
  const [showDetailstModal, setShowDetailsModal] = useState(false);
  const [selectedRiskDetails, setSelectedRiskDetails] = useState({});
  const [createAIProgress, setCreateAIProgress] = useState(false);
  const [storedLang, setStoredLang] = useState(
    localStorage.getItem("i18nextLng")
  );
  const [changeGetLoading, setChangeGetLoading] = useState(false); // API_URL_RISK_AI_TRANSLATION

  // Function to handle the modal open and close actions
  const handleRightModal = () => setShowRightModal(!showRightModal);
  const toggleDetailsModal = () => {
    if (showDetailstModal) {
      setSelectedRiskDetails({});
    }
    setShowDetailsModal(!showDetailstModal);
  };

  const module = localStorage.getItem("module");

  const [flaggedRisks, setFlaggedRisks] = useState([]);
  const [flaggedMitTasks, setFlaggedMitTasks] = useState([]);
  const [translationFlag, setTranslationFlag] = useState(false);

  const riskColor = (riskType) => {
    return t(riskType.toLowerCase()) === t("Strategic Risk".toLowerCase())
      ? "rgba(146, 219, 184, 1)"
      : t(riskType.toLowerCase()) === t("Operational Risk".toLowerCase())
      ? "rgba(184, 148, 212, 1)"
      : t(riskType.toLowerCase()) === t("Financial Risk".toLowerCase())
      ? "rgba(224, 152, 143, 1)"
      : t(riskType.toLowerCase()) ===
        t("Employees and Management Risk".toLowerCase())
      ? "rgba(208, 150, 125, 1)"
      : "rgba(140, 147, 208, 1)";
  };

  const subTaskColor = (riskType) => {
    return t(riskType.toLowerCase()) === t("Strategic Risk".toLowerCase())
      ? "rgba(146, 219, 184, 0.5)"
      : t(riskType.toLowerCase()) === t("Operational Risk".toLowerCase())
      ? "rgba(184, 148, 212, 0.5)"
      : t(riskType.toLowerCase()) === t("Financial Risk".toLowerCase())
      ? "rgba(224, 152, 143, 0.5)"
      : t(riskType.toLowerCase()) ===
        t("Employees and Management Risk".toLowerCase())
      ? "rgba(208, 150, 125, 1, 0.5)"
      : "rgba(140, 147, 208, 0.5)";
  };

  const groupRisksByType = (risks) => {
    return risks.reduce((acc, risk) => {
      acc[risk.riskType] = acc[risk.riskType] || [];
      acc[risk.riskType].push(risk);
      return acc;
    }, {});
  };

  const getTranslation = async () => {
    const translationUrl = API_URL_RISK_AI_TRANSLATION;
    const language = localStorage.getItem("i18nextLng");
    const responseTr = await axios.post(
      translationUrl,
      {
        report_case_id: reportId || localStorage.getItem("caseId"),
        // regenerate_risk_ai: regenerate,
        language: language ? language : undefined,
      },
      {
        headers: {
          Authorization: `Bearer ${
            token || localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN")
          }`,
        },
      }
    );
    if (responseTr.status === 200 && "risks" in responseTr.data.data) {
      setAIRisks(Object.values(responseTr.data.data.risks));
      setChangeGetLoading(false);
      // getRisksTranslation(responseTr.data.data.risks);
      setExplanation(responseTr.data.data.explanation);
      setRegenrate(false);
      // setTranslationFlag(false);
    } else {
      setChangeGetLoading(false);
    }
  };

  const getHelpFromAI = async (reportId, token) => {
    try {
      setChangeGetLoading(true);
      const language = localStorage.getItem("i18nextLng");
      const response = await axios.post(
        `${process.env.REACT_APP_CHATBOT_API_ENDPOINT}api/risks-associated.ai`,
        {
          report_case_id: reportId || localStorage.getItem("caseId"),
          regenerate_risk_ai: regenerate,
          language: language ? language : undefined,
        },
        {
          headers: {
            Authorization: `Bearer ${
              token || localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN")
            }`,
          },
        }
      );
      if (response.status === 200) {
        // setTranslationFlag(true);
        const translationUrl = API_URL_RISK_AI_TRANSLATION;
        const responseTr = await axios.post(
          translationUrl,
          {
            report_case_id: reportId || localStorage.getItem("caseId"),
            // regenerate_risk_ai: regenerate,
            language: language ? language : undefined,
          },
          {
            headers: {
              Authorization: `Bearer ${
                token || localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN")
              }`,
            },
          }
        );
        if (responseTr.status === 200 && "risks" in responseTr.data.data) {
          if (regenerate) {
            setRegenrate(false);
            return getTranslation();
          } else {
            setAIRisks(Object.values(responseTr.data.data.risks));
            setChangeGetLoading(false);
            // getRisksTranslation(responseTr.data.data.risks);
            setExplanation(responseTr.data.data.explanation);
            setRegenrate(false);
            // setTranslationFlag(false);
          }
        } else {
          setChangeGetLoading(false);
        }
      }
    } catch (error) {
      props.back();
      // setTranslationFlag(false);
      setChangeGetLoading(false);
      return errorNotification({
        message: t("Failed to fetch risks."),
      });
    }

    return true;
  };

  const onSubmitClick = async () => {
    if (!flaggedRisks?.length) {
      return errorNotification({
        message: t("Please select at least one risk."),
      });
    }
    setCreateAIProgress(true);
    const selectedDetailsRisk = [];
    flaggedRisks.map((r) => selectedDetailsRisk.push({ riskId: r }));

    const deatilsUrl = API_URL_RISK_AI_GET_ADDITIONAL_DETAILS;

    const dataForNextStep = axios
      .post(deatilsUrl, selectedDetailsRisk, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res) {
          const url = `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/risk_rate/create_ai`;
          // module === "wb"
          //   ? `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/risk_rate/create_ai`
          //   : `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/cm/risk_rate/create_ai`;
          const selectedRisks = [];
          const bodyData = flaggedRisks.map((selectedRiskId) => {
            const r = AIRisks.filter(
              (risk) => risk.riskId === selectedRiskId
            )[0];

            const selectedTasks = r.mitigation_tasks.map((task) => {
                return {
                    name            :   task.title,
                    description     :   task.description,
                    isTemporary     :   !flaggedMitTasks.includes(task.id) ? 1 : undefined,
                    impactOn        :   task?.impactOn || "both",
                    effectiveness   :   task?.effectiveness || 0.0
                }
            });

            res?.data?.data.map((risk, i) => {
              const { likelihood, damage, location, category } = risk;
              const foundRiskIfExists = selectedRisks.filter(
                (risk) => risk.id === r.riskId
              );

              !foundRiskIfExists.length &&
                selectedRisks.push({
                  description: r.risks,
                  likelihood: likelihood,
                  damage: damage,
                  location: location,
                  category: category,
                  tasks: selectedTasks,
                  id: r.riskId,
                });
            });
          });

          const selectedRisksWithoutId = selectedRisks.map(
            ({ id, ...rest }) => rest
          );

          const data = {
            reportCase: localStorage.getItem("caseId"),
            risks: selectedRisksWithoutId,
          };

          const response = axios
            .post(url, data, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              if (res) {
                props.back();
                successNotification({
                  message: t(CREATE_RISK_SUCCESSFUL),
                });
              }
              setCreateAIProgress(false);
            })
            .catch((error) => {
              setCreateAIProgress(false);
              return errorNotification({
                message: t(CREATE_RISK_FAILED),
              });
            });
        }
      })
      .catch((error) => {
        setCreateAIProgress(false);
        return errorNotification({
          message: t(CREATE_RISK_FAILED),
        });
      });
  };

  const toggle = (id) => {
    setCollapse(id);
  };

  useEffect(() => {
    if (isOpen) {
      getHelpFromAI(reportId, token);
    } else {
      setTimeout(() => {
        setAIRisks(null);
      }, 300);
    }
  }, [isOpen]);

  useEffect(() => {
    if (regenerate) {
      setAIRisks(null);
      getHelpFromAI();
    }
  }, [regenerate]);

  useEffect(async () => {
    if (storedLang !== i18next.language && !changeGetLoading) {
      try {
        setChangeGetLoading(true);
        setTranslationFlag(true);
        const language = localStorage.getItem("i18nextLng");
        const translationUrl = API_URL_RISK_AI_TRANSLATION;
        const responseTr = await axios.post(
          translationUrl,
          {
            report_case_id: reportId || localStorage.getItem("caseId"),
            // regenerate_risk_ai: regenerate,
            language: language ? language : undefined,
          },
          {
            headers: {
              Authorization: `Bearer ${
                token || localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN")
              }`,
            },
          }
        );
        if (responseTr.status === 200 && "risks" in responseTr.data.data) {
          setAIRisks(Object.values(responseTr.data.data.risks));
          // getRisksTranslation(responseTr.data.data.risks);
          setExplanation(responseTr.data.data.explanation);
          setRegenrate(false);
          setTranslationFlag(false);
          setStoredLang(i18next.language);
          setChangeGetLoading(false);
        } else {
          setTranslationFlag(false);
          setChangeGetLoading(false);
        }
      } catch (error) {
        return setTranslationFlag(false);
        setChangeGetLoading(false);
      }
    }
  }, [t]);

  const groupedAIRisks = useMemo(
    () => groupRisksByType(AIRisks || []),
    [AIRisks]
  );

  const onFlagRisk = (risk) => {
    const id = risk.riskId;
    setFlaggedRisks((prevFlaggedRisks) => {
      const foundRisk = prevFlaggedRisks.includes(id);
      if (!foundRisk) {
        return [...prevFlaggedRisks, id];
      } else {
        return prevFlaggedRisks.filter((item) => item !== id);
      }
    });
  };

  const onFlagMitTask = (mitTask, riskId) => {
    const id = mitTask.id;
    if (!flaggedRisks.includes(riskId)) {
      return errorNotification({
        message: t("Please select the risk"),
      });
    } else {
      setFlaggedMitTasks((prevFlaggedMitTasks) => {
        const foundTask = prevFlaggedMitTasks.includes(id);
        if (!foundTask) {
          return [...prevFlaggedMitTasks, id];
        } else {
          return prevFlaggedMitTasks.filter((item) => item !== id);
        }
      });
    }
  };

  const onRegenerateClick = () => {
    setRegenrate(true);
  };

  // Function to truncate HTML text
  const truncateHtmlText = (html, maxChars = 250) => {
    return html?.length > maxChars ? html.slice(0, maxChars) + "..." : html;
  };

  // Truncated explanation
  const truncatedExplanation = truncateHtmlText(explanation);

  const processHtmlContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    // Convert h1 to h2, h2 to h3, h3 to h4
    doc.querySelectorAll("h1, h2, h3").forEach((header) => {
      switch (header.tagName) {
        case "H1":
          header.outerHTML = `<h2>${header.innerHTML}</h2>`;
          break;
        case "H2":
          header.outerHTML = `<h3>${header.innerHTML}</h3>`;
          break;
        case "H3":
          header.outerHTML = `<h4>${header.innerHTML}</h4>`;
          break;
        default:
          break;
      }
    });

    return doc.body.innerHTML;
  };

  const currentRisk = useMemo(() => AIRisks?.at(0), [AIRisks]);

  return (
    <Fragment>
      {showRightModal && explanation && (
        <AIRiskRightModal
          t={t}
          handleModal={handleRightModal}
          showModal={showRightModal}
          explanation={explanation}
        />
      )}

      {
        <AIRiskModal
          t={t}
          modal={translationFlag}
          toggle={() => setTranslationFlag(!translationFlag)}
          imageSrc={risk_ai_processing}
        />
      }

      {showDetailstModal && (
        <AIRiskDetailsModal
          t={t}
          errorNotification={errorNotification}
          handleModal={toggleDetailsModal}
          showModal={showDetailstModal}
          explanation={explanation}
          selectedRiskDetails={selectedRiskDetails}
          flaggedRisks={flaggedRisks}
          onFlagRisk={onFlagRisk}
          flaggedMitTasks={flaggedMitTasks}
          onFlagMitTask={onFlagMitTask}
          rColor={riskColor(selectedRiskDetails?.riskType)}
          subTColor={subTaskColor(selectedRiskDetails?.riskType)}
        />
      )}
      <Row className="sticky-row">
        <Col lg="10" md="6" sm="12">
          <h2 className="mt-4">{t("Risk Assesment Support by AI")}</h2>
        </Col>
        <Col lg="2" md="6" sm="12">
          <ButtonsGroup
            t={t}
            onSubmitClick={onSubmitClick}
            onRegenerateClick={onRegenerateClick}
            onProgress={createAIProgress}
          />
        </Col>
      </Row>
      {AIRisks ? (
        <div className="ai-risks-list">
          <Row>
            <Col lg={3} md={3} sm={6}>
              <img
                src={risk_ai}
                alt=""
                style={{
                  display: "block",
                  display: "flex",
                  justifyContent: "center",
                  maxWidth: "60%",
                }}
                className="pt-3 p-3"
              />
              {
                <div className="darkBlueText" style={{ maxHeight: "30rem" }}>
                  {explanation && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: processHtmlContent(truncatedExplanation),
                      }}
                    />
                  )}

                  <Button className="bg-primary" onClick={handleRightModal}>
                    {t("Learn More")}
                  </Button>
                </div>
              }
            </Col>
            <Col lg={9}>
              <Row style={{ maxHeight: "50rem", overflowY: "scroll" }}>
                {Object.keys(groupedAIRisks).map((riskType, index) => {
                  return (
                    <>
                      <Col
                        lg={AIRisks.length > 2 ? 4 : 6}
                        md={4}
                        key={index}
                        className="mb-5"
                        style={{
                          height: "35rem",
                          overflowY: "scroll",
                          // border:"10px solid yellow"
                        }}
                      >
                        {groupedAIRisks[riskType].map((risk) => {
                          const riskType = risk?.riskType
                            ? risk.riskType
                            : risk?.title?.split(" ").slice(0, 2).join(" ");
                          const { riskId, title } = risk;
                          const rColor = riskColor(riskType);
                          const subTColor = subTaskColor(riskType);

                          return (
                            <>
                              <Card
                                style={{
                                  backgroundColor: "#fafafa",
                                  // height: "40rem",
                                  // overflowY: "scroll",
                                }}
                              >
                                <CardBody className="cardBody">
                                  <CardTitle
                                    tag="h4"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <span
                                      style={{ backgroundColor: rColor }}
                                      className="category"
                                    >
                                      <GoTag /> {t(riskType)}
                                    </span>
                                    <span
                                      className={
                                        flaggedRisks.includes(riskId)
                                          ? "selectedFlag"
                                          : "flag"
                                      }
                                      onClick={() => onFlagRisk(risk)}
                                    >
                                      <CiFlag1 />
                                    </span>
                                  </CardTitle>
                                  <hr></hr>
                                  <CardSubtitle
                                    className="riskSubHeader mb-2 text-muted"
                                    tag="h5"
                                  >
                                    <span className="darkBlueText">
                                      {t("Risks") + ":"}
                                    </span>

                                    <Button
                                      className="bg-primary"
                                      onClick={() => {
                                        setSelectedRiskDetails(risk);
                                        setShowDetailsModal(true);
                                      }}
                                      size="sm"
                                    >
                                      {t("Learn More")}
                                    </Button>
                                  </CardSubtitle>
                                  <CardText>
                                    <p>{t(risk?.description)}</p>
                                    <p>{t(risk?.risks)}</p>

                                    <div>
                                      <h5>{t("Mitigation tasks") + ":"}</h5>
                                      {risk.mitigation_tasks.map(
                                        (mitTask, index) => {
                                          const taskId = mitTask.id;
                                          return (
                                            <Card
                                              style={{ marginBottom: "1rem" }}
                                              key={index}
                                            >
                                              <div>
                                                <CardHeader
                                                  onClick={() =>
                                                    toggle(mitTask.id)
                                                  }
                                                  data-event={index}
                                                  style={{
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    backgroundColor: subTColor,
                                                  }}
                                                >
                                                  <span>
                                                    {t(mitTask.title)}
                                                  </span>

                                                  <span
                                                    className={
                                                      flaggedMitTasks.includes(
                                                        taskId
                                                      )
                                                        ? "selectedFlag"
                                                        : "mitFlag"
                                                    }
                                                    onClick={() =>
                                                      onFlagMitTask(
                                                        mitTask,
                                                        riskId
                                                      )
                                                    }
                                                  >
                                                    <CiFlag1 />
                                                  </span>
                                                </CardHeader>
                                              </div>
                                              <Collapse
                                                isOpen={collapse === mitTask.id}
                                              >
                                                <CardBody>
                                                  {t(mitTask.description)}
                                                </CardBody>
                                              </Collapse>
                                            </Card>
                                          );
                                        }
                                      )}
                                    </div>
                                  </CardText>
                                </CardBody>
                              </Card>
                              <hr></hr>
                            </>
                          );
                        })}
                      </Col>
                      {index > 1 && <hr></hr>}
                    </>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <AIRiskModal
          t={t}
          modal={true}
          // title={
          //   t("Hi, I'm Botty, the AI assistant from DISS-CO. I select the best results from millions of sources. Screening and scoring can take up to 2 minutes. Please wait...")
          //   // translationFlag
          //   //   ? t("Please wait. Our AI is translating the results...")
          //   //   : t("Please wait. Our AI is searching and scoring...")
          // }
          toggle={() => setShowProcessingModal(!showProcessModal)}
          imageSrc={risk_ai_processing}
        />
      )}
    </Fragment>
  );
};

export default memo(AIBasedRiskAssessment);
