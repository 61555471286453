import {
    ACTION_HANDLE_START_FETCHING_NOTIFICATIONS,
    ACTION_HANDLE_STOP_FETCHING_NOTIFICATIONS,
    ACTION_MARK_NOTIFICATION_AS_READ,
    ACTION_HANDLE_UPDATE_NOTIFICATIONS_LIST,
    ACTION_MARK_NOTIFICATION_AS_READ_IN_LIST,
    ACTION_MARK_ALL_NOTIFICATIONS_AS_READ,
    ACTION_HANDLE_EMPTY_NOTIFICATIONS_LIST,
    ACTION_HANDLE_LOAD_MORE_NOTIFICATIONS,
    ACTION_HANDLE_UPDATE_IS_LOADING
} from './actionTypes';

export const startFetchingNotifications = () => ({
    type: ACTION_HANDLE_START_FETCHING_NOTIFICATIONS
});

export const loadMoreNotifications = () => ({
    type: ACTION_HANDLE_LOAD_MORE_NOTIFICATIONS
});

export const stopFetchingNotifications = () => ({
    type: ACTION_HANDLE_STOP_FETCHING_NOTIFICATIONS
});

export const markNotificationAsRead = ({
    notificationId
}) => ({
    type: ACTION_MARK_NOTIFICATION_AS_READ,
    payload: {
        notificationId
    }
});

export const markAllNotificationsAsRead = () => ({
    type: ACTION_MARK_ALL_NOTIFICATIONS_AS_READ
});

export const markNotificationAsReadLocalList = ({
    notificationId
}) => ({
    type: ACTION_MARK_NOTIFICATION_AS_READ_IN_LIST,
    payload: {
        notificationId
    }
});

export const updateNotificationsList = ({
    totalCount,
    pageIndex,
    pageSize,
    list
}) => ({
    type: ACTION_HANDLE_UPDATE_NOTIFICATIONS_LIST,
    payload: {
        totalCount,
        pageIndex,
        pageSize,
        list
    }
});

export const emptyNotificationsList = () => ({
    type: ACTION_HANDLE_EMPTY_NOTIFICATIONS_LIST
});

export const updateIsLoadingStatus = ({
    status
}) => ({
    type: ACTION_HANDLE_UPDATE_IS_LOADING,
    payload: {
        status: status
    }
});