import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { useState } from "react";

const VerticalMenuContent = (props) => {
	const {
		menu,
		t
	} = props;

	const [activeMenuIndex, setActiveMenuIndex] = useState(-1);

	return (
		<ul className="metismenu list-unstyled" id="side-menu">
            {
				menu.map((menuItem, menuItemIndex) => {
					const subMenuItems = menuItem.items || [];
					const hasSubMenu = subMenuItems.length > 0;

					return (
						<li key={menuItemIndex} className={`afc-dropdown ${activeMenuIndex === menuItemIndex ? 'active' : ''}`}
							onClick={() => setActiveMenuIndex(menuItemIndex === activeMenuIndex ? -1 : menuItemIndex)}>
							{
								menuItem.linkType === 'react-router' ? (
									<>
									{menuItem?.path ? 
									<Link to={menuItem.path}
										className={`${hasSubMenu ? "has-arrow" : null} waves-effect`}>
											
										<i className={menuItem.icon}></i>
										
										<span className="ms-1">
											{t(menuItem.titleKey)}
										</span>
									</Link>
									: <Link 
									className={`${hasSubMenu ? "has-arrow" : null} waves-effect`}>
										
									<i className={menuItem.icon}></i>
									
									<span className="ms-1">
										{t(menuItem.titleKey)}
									</span>
								</Link>}
									</>
								) : (
									<a href={menuItem.path}
										className="waves-effect" target='_new'>
											
										<i className={menuItem.icon}></i>
										
										<span className="ms-1">
											{t(menuItem.titleKey)}
										</span>
									</a>
								)
							}
							

							{
								hasSubMenu ? (
									<ul className="sub-menu">
										{subMenuItems.map((subMenuItem, subMenuItemIndex) => {
											return (
												<li key={subMenuItemIndex}>
													{
														subMenuItem.linkType === 'react-router' ? (
															<Link to={subMenuItem.path}>
																{t(subMenuItem.titleKey)}
															</Link>
														) : (
															<a href={subMenuItem.path} target="_new">
																{t(subMenuItem.titleKey)}
															</a>
														)
													}
												</li>
											);
										})}
									</ul>
								) : null
							}
						</li>
					);
				})
			}
        </ul>
	);
}

export default withNamespaces()(VerticalMenuContent);