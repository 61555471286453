import { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import darkLogo from "../../../assets/images/dark-logo.svg";

class OrganizationHeader extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const defaultLogo = "";
		const organization = this.props.Organization;
		const logo = organization.primaryLogo ? organization.primaryLogo : defaultLogo;

		// console.log(organization)

		return (
			<div className="text-center">
				<Link to="/">
					{logo ? (
						<img src={logo} alt="" style={{ maxWidth: 240, maxHeight: 120 }} />
					) : (
						<div>
							<img src={darkLogo} alt="" width="98" className="auth-logo logo-dark mx-auto" />
							<img src={darkLogo} alt="" width="98" className="auth-logo logo-light mx-auto" />
						</div>
					)}
				</Link>
				<h4 className="font-size-18 mt-4">{this.props.t("Welcome Back")}</h4>
				<p className="text-muted">{this.props.t("Log in to the Smart Integrity Platform")}</p>
			</div>
		);
	}
}

const mapStatetoProps = (state) => {
	const { Organization } = state;

	return {
		Organization,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default withNamespaces()(connect(mapStatetoProps, mapDispatchToProps)(OrganizationHeader));
