import React, { useEffect, useState, useCallback } from "react";
import { withNamespaces } from "react-i18next";
import { Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { toast } from "react-toastify";

const InfoModal = ({ t, modal, setModal, toggle }) => {


  return (
    <Modal size={'xl'} isOpen={modal} toggle={toggle} className="keyboard-modal-main-content-data-request">
      <ModalHeader toggle={toggle}>{t("Categories Description")}</ModalHeader>
      <ModalBody>
        <div className="main-div">

        <div className="d-flex flex-column ">
          <Label>{t("Right to Access")}:</Label>
          <span>{t("Individuals have the right to know whether their data is being processed, where, and for what purpose. They can also request a copy of their personal data, free of charge, in an electronic format.")}</span>
          </div>

          <div className="d-flex flex-column ">
          <Label>{t("Right to be Informed")}:</Label>
          <span>{t("This includes the need for transparency over how personal data is used. Organizations must provide clear information about their data processing activities.")}</span>
          </div>

          <div className="d-flex flex-column ">
          <Label>{t("Right to Rectification")}:</Label>
          <span>{t("Individuals have the right to have inaccurate personal data corrected and incomplete personal data completed.")}</span>
          </div>
        
          <div className="d-flex flex-column ">
          <Label>{t("Right to Erasure (Right to be Forgotten)")}:</Label>
          <span>{t("In certain circumstances, individuals can request the deletion or removal of personal data where there is no compelling reason for its continued processing.")}</span>
          </div>

          <div className="d-flex flex-column ">
          <Label>{t("Right to Restrict Processing")}:</Label>
          <span>{t("Individuals have the right to 'block' or suppress processing of their personal data in certain circumstances.")}</span>
          </div>

          <div className="d-flex flex-column ">
          <Label>{t("Right to Data Portability")}:</Label>
          <span>{t("This allows individuals to obtain and reuse their personal data for their own purposes across different services, allowing them to move, copy, or transfer personal data easily.")}</span>
          </div>


          <div className="d-flex flex-column ">
          <Label>{t("Right to Object")}:</Label>
          <span>{t("Individuals have the right to object to processing based on legitimate interests or the performance of a task in the public interest/exercise of official authority (including profiling), direct marketing (including profiling), and processing for purposes of scientific/historical research and statistics.")}</span>
          </div>

          <div className="d-flex flex-column ">
          <Label>{t("Rights in relation to automated decision-making and profiling")}:</Label>
          <span>{t("GDPR provides safeguards for individuals against the risk that a potentially damaging decision is made without human intervention. This includes profiling, which can be part of an automated decision-making process.")}</span>
          </div>

        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          size="sm"
          onClick={() => setModal()}
        >
          {t("Ok")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default withNamespaces()(InfoModal);
