import { withNamespaces } from "react-i18next";
import { memo, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Col, Container, NavItem, NavLink, Row } from "reactstrap";
import { EditorState } from "draft-js";

import CompanyDetails from "./components/CompanyDetails";
import ContactPerson from "./components/ContactPerson";
import ReviewReport from "./components/ReviewReport";
import FactorySitesAndProducts from "./components/FactorySitesAndProducts";
import SuccessModal from "./components/SuccessModal";

import { convertToHTML, convertFromHTML } from "draft-convert";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';

import {
  confirmPromptMakeReadyToShow,
  confirmPromptHide,
  confirmPromptUpdateAlertProps
} from 'src/store/actions';

import ThirdpartyUsersService from "src/modules/3rd-party-management/apis/ThirdpartyUsersService";
import PageHeader from "src/modules/3rd-party-management/components/page-header";

const thirdPartyService = new ThirdpartyUsersService();

const NewThirdParty = ({ t, user, organization, exitConfirmPromptOptions, makeConfirmPromptReadyToShow, hideConfirmPrompt, updateConfirmPromptAlertProps }) => {
  // General 
  const [countries, setCountries] = useState([]);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  // Company Details Tab
  const [companyName, setCompanyName] = useState("");
  const [industry, setIndustry] = useState(null);
  const [companySize, setCompanySize] = useState(null);
  const [website, setWebsite] = useState("");
  const [description, setDescription] = useState(EditorState.createEmpty());

  // Factory Sites And Products Tab
  // Office Location
  const [officeAddressCount, setOfficeAddressCount] = useState(1);
  const [officeAddresses, setOfficeAddresses] = useState([]);
  // Factory Location
  const [factoryAddressCount, setFactoryAddressCount] = useState(1);
  const [factoryAddresses, setFactoryAddresses] = useState([]);
  // List of products
  const [addedProducts, setAddedProducts] = useState([]);
  const [addedProductsCount, setAddedProductsCount] = useState(0);

  // Contact
  const [contactCount, setContactCount] = useState(1);
  const [contacts, setContacts] = useState([]);

  const [ dataShouldBeSaved, setDataShouldBeSaved ] = useState(false);

  // TABS
  const [tabs, setTabs] = useState([
    {
      id: 1,
      name: "company-details",
      title: t("Company Details"),
      completed: false,
      isCurrent: true,
    },
    {
      id: 2,
      name: "factory-sites-and-products",
      title: t("Factory Sites and Products"),
      completed: false,
      isCurrent: false,
    },
    {
      id: 3,
      name: "contact-person",
      title: t("Contact Person"),
      completed: false,
      isCurrent: false,
    },
    {
      id: 4,
      name: "finalize",
      title: t("Finalize"),
      completed: false,
      isCurrent: false,
    },
  ]);

  // Tabs
  const [activeTab, setActiveTab] = useState(tabs.find((t) => t.isCurrent));

  // Render Tabs
  const renderStepsWizard = () => {
    const currentActiveTabIndex = tabs.findIndex((t) => t.isCurrent);

    return (
      <Row>
        <Col sm={12}>
          <div className="bg-white wizard-steps-container mb-4">
            <div className="twitter-bs-wizard">
              <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                {tabs.map((tab, index) => {
                  return (
                    <NavItem
                      key={index}
                      className={tab.completed ? "completed" : ""}
                    >
                      <NavLink
                        className={
                          (index <= currentActiveTabIndex ? "active " : "") +
                          (tab.isCurrent ? "current" : "")
                        }
                      >
                        <span className="step-number">
                          {tab.completed ? (
                            <i className="fa fa-check"></i>
                          ) : (
                            index + 1
                          )}
                        </span>

                        <span className="step-title">{t(tab.title)}</span>
                      </NavLink>
                    </NavItem>
                  );
                })}
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  // SET CURRENT TAB COMPLETED
  const setCurrentTabComplete = (name) => {
    const selectedTab = tabs.filter((t) => t.name === name)[0];
    const selectedTabId = selectedTab?.id;
    selectedTab.completed = true;
    selectedTab.isCurrent = false;
    goToNextTab(selectedTabId + 1);
  };

  // GO TO THE NEXT TAB
  const goToNextTab = (id) => {
    const selectedTab = tabs.filter((t) => t.id === id)[0];
    selectedTab.completed = false;
    selectedTab.isCurrent = true;
    setActiveTab(tabs.find((t) => t.isCurrent));
  };

  // GO TO THE PREVIOUES TAB
  const goToPrevTab = (id) => {
    const selectedTab = tabs.filter((t) => t.id === id)[0];
    selectedTab.completed = false;
    selectedTab.isCurrent = true;
    setActiveTab(tabs.find((t) => t.isCurrent));
  };

  // GO TO SPECIFIEC TAB
  const goToSpecificTab = (id) => {
    // First, reset 'isCurrent' for all tabs to ensure only one tab is marked as current.
    const updatedTabs = tabs.map((tab) => {
      if (tab.id === id) {
        return { ...tab, isCurrent: true, completed: false }; // Set the target tab as current and not completed.
      } else {
        return { ...tab, isCurrent: false }; // Ensure other tabs are not marked as current.
      }
    });

    // Now, find the tab that has been set as current and update the state.
    const newActiveTab = updatedTabs.find((tab) => tab.isCurrent);
    setActiveTab(newActiveTab); // Update the active tab state.
    setTabs(updatedTabs); // Uncomment this if you have a state for tabs.
  };

  // Fetch Countries
  const fetchCountries = () => {
    return thirdPartyService.fetchCountries().then((res) => {
      const options = res.map((country) => {
        return {
          value: country.id,
          label: t(country.name),
          code: country.code,
        };
      });
      setCountries(options);
    });
  };
  
  // On Complete Submit
  const onFormSubmit = () => {
    const products = addedProducts.map((p) => {
      return {
        name: p.productName.value,
        description: p.description,
      };
    });

    const supplierLocations = [];

    const mapOfficeToLocation = officeAddresses.forEach((loc) => {
      supplierLocations.push({
        type: "office",
        country: loc?.country?.value,
        city: loc?.city,
        address: loc?.extra,
      });
    });

    const mapFactoryToLocation = factoryAddresses.forEach((loc) => {
      if(loc?.country){
        supplierLocations.push({
          type: "manufacture",
          country: loc?.country?.value,
          city: loc?.city,
          address: loc?.extra
        });
      }
    });

    const contactPersons = contacts.map((c) => {
      return {
        title       : c?.title,
        name        : c?.firstName,
        lastName    : c?.lastName,
        email       : c?.email,
        phoneNumber : c?.phoneNumber,
        position    : c?.position,
        language    : c?.language?.value,
      };
    });

    const languages = organization?.languages?.map(l => {
      return {
        name: l?.name,
        direction: l?.direction,
        language: l?.language?.id,
        isDefault: l?.is_default,
        module: l?.module?.id
      }
    });
    
    const data = {
      token: localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN"),
      name: companyName,
      languages: languages,
      products: products,
      supplierLocations: supplierLocations,
      contactPersons: contactPersons,
      industry: industry?.value,
      companySize: companySize?.value,
      websiteUrl: website,
      description: description.getCurrentContent().hasText()
        ? convertToHTML(description.getCurrentContent())
        : "",
      isPartner: 0,
      partnerAnalysts: [String(user?.id)],
      partner: Number(organization?.customerId),
      hasDashboard: 0,
      countryCode: officeAddresses[0]?.country?.code,
      subscriptionType: ""
    };

    handleCreateThirdPartyMutation.mutate(data)
  };

  const handleCreateThirdPartyMutation = useMutation({
    mutationFn: async (payload) => {
      const service = ThirdpartyUsersService.getInstance();

      return await service.create(payload);
    },
    onSuccess: () => {
      removeDataFromStorage();
      hideConfirmPrompt();
      setOpenSuccessModal(true);
    },
    onError: () => {
      toast(t("An error occurred while creating third party."), {
        type: "error",
      });
    },
  });

  const saveDataOnTheStorage = () => {
    return new Promise((res, rej) => {
      updateConfirmPromptAlertProps({
        ...exitConfirmPromptOptions.alertProps,
        confirmBtn  : {
          ...exitConfirmPromptOptions.alertProps.confirmBtn,
          title   : (
            <>
              <Spinner className="me-2" animation="border" variant="white" size="sm"/>Saving...
            </>
          ),
          loading : true
        }
      });

      try{
        const generalInfosData = {
          companyName : companyName,
          industry    : industry,
          companySize : companySize,
          website     : website,
          description : description.getCurrentContent().hasText() ? convertToHTML(description.getCurrentContent()) : "",
        };
  
        localStorage.setItem('MODULE_TPDD_NEW_THIRDPARTY_SAVED_DATA', JSON.stringify({
          generalInfosData  : generalInfosData,
          officeAddresses   : (officeAddresses && officeAddresses.length > 0) ? officeAddresses : undefined,
          factoryAddresses  : (factoryAddresses && factoryAddresses.length > 0) ? factoryAddresses : undefined,
          products          : (addedProducts && addedProducts.length > 0) ? addedProducts : undefined
        }));

        res();
      }
      catch(error){
        rej();
      }
    });
  }

  const removeDataFromStorage = () => {
    localStorage.removeItem('MODULE_TPDD_NEW_THIRDPARTY_SAVED_DATA');
  }

  useEffect(() => {
    if (!countries.length) {
      fetchCountries();
    }

    const prevData = localStorage.getItem('MODULE_TPDD_NEW_THIRDPARTY_SAVED_DATA');

    if(prevData){
      try{
        const parsedData = JSON.parse(prevData);

        if(parsedData?.generalInfosData){
          setCompanyName(parsedData.generalInfosData?.companyName || '');
          setIndustry(parsedData.generalInfosData?.industry || null);
          setCompanySize(parsedData.generalInfosData?.companySize || null);
          setWebsite(parsedData.generalInfosData?.website || '');
          setDescription(EditorState.createWithContent(convertFromHTML(parsedData.generalInfosData?.description || '')));
        }

        if(parsedData?.factoryAddresses && Array.isArray(parsedData.factoryAddresses)){
          setFactoryAddresses(parsedData.factoryAddresses);
          setFactoryAddressCount(parsedData.factoryAddresses.length);
        }

        if(parsedData?.officeAddresses && Array.isArray(parsedData.officeAddresses)){
          setOfficeAddresses(parsedData.officeAddresses);
          setOfficeAddressCount(parsedData.officeAddresses.length);
        }

        if(parsedData?.products && Array.isArray(parsedData.products)){
          setAddedProducts(parsedData.products);
          setAddedProductsCount(parsedData.products.length);
        }
      }
      catch(error){
        removeDataFromStorage();
      }
    }
  }, []);

  useEffect(() => {
    if(exitConfirmPromptOptions.show){
      const continueAction = () => {
        exitConfirmPromptOptions.callbackFunc && exitConfirmPromptOptions.callbackFunc(true);
        hideConfirmPrompt();
      }

      if(exitConfirmPromptOptions.confirmStatus){
        saveDataOnTheStorage()
          .then(() => continueAction())
          .catch(() => {
            toast(t("An error occurred while saving data."), {
              type: "error",
            });

            continueAction();
          });
      }
      else{
        removeDataFromStorage();

        setTimeout(() => {
          continueAction();
        }, 250);
      }
    }
  }, [ exitConfirmPromptOptions.confirmStatus ]);

  useEffect(() => {
    if(dataShouldBeSaved && !exitConfirmPromptOptions.readyToShow){
      makeConfirmPromptReadyToShow({
        title       : t('As you are leaving the page'),
        message     : t('Do you want to save the current state to continue later?'),
        alertProps  : {
          ...exitConfirmPromptOptions.alertProps,
          confirmBtn  : {
            ...exitConfirmPromptOptions.alertProps.confirmBtn,
            enabled : true,
            title : t('Yes, save the current state')
          },
          cancelBtn  : {
            ...exitConfirmPromptOptions.alertProps.cancelBtn,
            enabled : true,
            title : t('No')
          }
        }
      });
    }
  }, [ dataShouldBeSaved ]);
  
  return (
    <div className="page-content new-thirdparty-page">
      {openSuccessModal && <SuccessModal setModal={setOpenSuccessModal} modal={openSuccessModal} />}
      <Container fluid>
        <PageHeader title={t('New Third Party')} />
        {/* TABS */}
        {renderStepsWizard()}

        {/* TAB CONTENTS */}
        {activeTab.name === "company-details" ? (
          <CompanyDetails
            setCompanyName={setCompanyName}
            setIndustry={setIndustry}
            setCompanySize={setCompanySize}
            setDescription={setDescription}
            setWebsite={setWebsite}
            companyName={companyName}
            website={website}
            industry={industry}
            companySize={companySize}
            description={description}
            setCompanyDetailsTabCompleted={(payload) => {
              setCurrentTabComplete(payload);
              setDataShouldBeSaved(true);
            }}
          />
        ) : activeTab.name === "factory-sites-and-products" ? (
          <FactorySitesAndProducts
            industryId={ industry.value }
            countries={countries}
            setCompanyDetailsTabCompleted={setCurrentTabComplete}
            goToPrevTab={goToPrevTab}
            officeAddressCount={officeAddressCount}
            setOfficeAddressCount={setOfficeAddressCount}
            officeAddresses={officeAddresses}
            setOfficeAddresses={setOfficeAddresses}
            factoryAddressCount={factoryAddressCount}
            setFactoryAddressCount={setFactoryAddressCount}
            factoryAddresses={factoryAddresses}
            setFactoryAddresses={setFactoryAddresses}
            addedProducts={addedProducts}
            setAddedProducts={setAddedProducts}
            addedProductsCount={addedProductsCount}
            setAddedProductsCount={setAddedProductsCount}
          />
        ) : activeTab.name === "contact-person" ? (
          <ContactPerson
            setCompanyDetailsTabCompleted={setCurrentTabComplete}
            goToPrevTab={goToPrevTab}
            contacts={contacts}
            setContacts={setContacts}
            contactCount={contactCount}
            setContactCount={setContactCount}
          />
        ) : activeTab.name === "finalize" ? (
          <ReviewReport
            goToSpecificTab={goToSpecificTab}
            goToPrevTab={goToPrevTab}
            onFormSubmit={onFormSubmit}
            isLoading={handleCreateThirdPartyMutation.isLoading}
            // Company Details
            companyName={companyName}
            industry={industry}
            companySize={companySize}
            website={website}
            description={description}
            // Factory sites and products
            officeAddresses={officeAddresses}
            factoryAddresses={factoryAddresses}
            addedProducts={addedProducts}
            // Contact Person
            contacts={contacts}
          />
        ) : null}
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { Organization } = state;
  return {
    user: state.Login.user,
    token,
    organization: Organization,
    exitConfirmPromptOptions : state.ExitConfirmPrompt
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, {
    makeConfirmPromptReadyToShow  : (payload)  =>  confirmPromptMakeReadyToShow(payload),
    hideConfirmPrompt             : ()  =>  confirmPromptHide(),
    updateConfirmPromptAlertProps : (payload)  =>  confirmPromptUpdateAlertProps(payload)
  })(memo(NewThirdParty)))
);
