import { useCallback, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { 
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from 'reactstrap';

const RiskMatrix = function (props) {
    const { matrix, consequences, currency, likelihoods, t, showSupplierId = true, isLoading = false } = props;

    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    const history = useHistory();

    const [ colsDrops, setColdsDrops ] = useState([]);

    const renderRisksDrop = (colId, risks) => {
        return risks?.length ? (
            <Dropdown direction="left" isOpen={!!colsDrops[colId]} toggle={() => toggleColDrop(colId)}>
                <DropdownToggle className="else-risks-drop-btn">
                    <i className='ri-arrow-down-s-line'></i>
                </DropdownToggle>
                <DropdownMenu>
                    {
                        risks.map((risk, index) => {
                            return (
                                <DropdownItem onClick={() => history.push(`/admin/3rd-party/suppliers/${risk.supplierId}/risks/${risk.id}/details`)} key={ index }>
                                    { `R-${showSupplierId ? (risk.supplierId + '-') : ''}${risk.secondaryId}` }
                                </DropdownItem>
                            )
                        })
                    }
                </DropdownMenu>
            </Dropdown>
        ) : null;
    }

    const toggleColDrop = (colId) => {
		setColdsDrops((drops) => {
			const newDrops = {...drops};
			newDrops[colId] = !newDrops[colId];
			return newDrops;
		});
	}

    const renderRiskBullets = useCallback(
        (risks) => {
            const result = risks.map((r, index) =>
                <span key={index} className='dt-matrix-risk-bullet'></span>
            );

            if(risks.length){
                result.push(
                <div key={21} className="dt-matrix-risk-bullet-else">
                    {risks.length}
                </div>
                );
            }

            return result;
            },
        []
    );

    const renderMatrixTable = () => {
        return (
            <table className="risk-managment-matrix ">
                <tbody>
                    {matrix ? (
                        <tr>
                        <td></td>
                        <td></td>
                        <td
                            className="dt-matrix-x-axis-label py-1"
                            colSpan={matrix.length}
                        >
                            <span className="fw-normal">{t("Damage")}</span>
                        </td>
                        </tr>
                    ) : null}

                    {/* consequences */}
                    {consequences ? (
                        <tr>
                        <td></td>
                        <td></td>
                        {consequences.map((i) => (
                            <td
                            key={i.id}
                            className="dt-matrix-info dt-matrix-x-axis py-0"
                            >
                            <div className="py-1">
                                <span
                                hidden={isMobile}
                                className="dt-matrix-x-axis-value "
                                style={{ fontSize: "13px", fontWeight: 500 }}
                                >
                                {"> " +
                                    (Number(i?.title) / 1000).toLocaleString(
                                    "en-US"
                                    ) +
                                    "K"}
                                </span>
                            </div>
                            </td>
                        ))}
                        </tr>
                    ) : null}

                    {matrix
                        ? matrix.map((row, rowIndex) => {
                            return (
                            <>
                                <tr key={row.rowIndex}>
                                {rowIndex === 0 ? (
                                    <td
                                    style={{
                                        // width: '4%',
                                        textAlign: "center",
                                    }}
                                    className="dt-matrix-y-axis-label"
                                    rowSpan={matrix.length + 1}
                                    >
                                    <span
                                        hidden={isMobile}
                                        style={{
                                        display: "inline-block",
                                        transform: "rotate(270deg)",
                                        fontWeight: 500,
                                        marginLeft: "-18px",
                                        }}
                                    >
                                        {t("Likelihood")}
                                    </span>
                                    </td>
                                ) : null}

                                {/* likelihoods */}
                                {likelihoods ? (
                                    <>
                                    <td
                                        // style={{ width: '6%' }}
                                        className="dt-matrix-y-axis"
                                    >
                                        <span className="dt-matrix-y-axis-value">
                                        {"> " + likelihoods[rowIndex].label + "%"}
                                        </span>
                                    </td>
                                    </>
                                ) : null}

                                {/* columns */}
                                {row.map((col, colIndex) => {
                                    return (
                                    <td
                                        key={col.id}
                                        style={{
                                        backgroundColor: `#${col.color}`,
                                        // width: `calc(80% / ${matrix.length} )`,
                                        width: "156px",
                                        }}
                                        className="dt-matrix-column"
                                    >
                                        <div>
                                        {col?.risks && col?.risks?.length > 0 
                                            ? renderRiskBullets(col.risks)
                                            : null}
                                        </div>
                                        <span>
                                        { 
                                            col?.risks && renderRisksDrop(
                                            col.id,
                                            col.risks
                                            ) 
                                        }
                                        </span>
                                    </td>
                                    );
                                })}
                                </tr>
                            </>
                            );
                        })
                    : null}
                </tbody>
            </table>
        );
    }

    const renderLoadingSection = () => {
        return (
            <table className="risk-managment-matrix">
                <tbody>
                {/* x axis label */}
                <tr>
                    <td></td>
                    <td></td>
                    <td className="dt-matrix-x-axis-label" colSpan="5">
                    <div>
                        <span className="fw-normal">{t("Consequence")}</span>
                    </div>
                    </td>
                </tr>

                <tr>
                    <td
                    style={{ width: "4%" }}
                    className="dt-matrix-y-axis-label"
                    rowSpan="6"
                    >
                    <span
                        hidden={isMobile}
                        style={{
                        display: "inline-block",
                        transform: "rotate(270deg)",
                        fontWeight: 500,
                        marginLeft: "-18px",
                        }}
                    >
                        {t("Likelihood")}
                    </span>
                    </td>
                    <td style={{ width: "6%" }} className="dt-matrix-y-axis">
                    <div>
                        <span className="dt-matrix-y-axis-value dt-skeleton"></span>
                    </div>
                    </td>

                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                </tr>

                <tr>
                    <td style={{ width: "6%" }} className="dt-matrix-y-axis">
                    <div>
                        <span className="dt-matrix-y-axis-value dt-skeleton"></span>
                    </div>
                    </td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                </tr>
                <tr>
                    <td style={{ width: "6%" }} className="dt-matrix-y-axis">
                    <div>
                        <span className="dt-matrix-y-axis-value dt-skeleton"></span>
                    </div>
                    </td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                </tr>
                <tr>
                    <td style={{ width: "6%" }} className="dt-matrix-y-axis">
                    <div>
                        <span className="dt-matrix-y-axis-value dt-skeleton"></span>
                    </div>
                    </td>

                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                </tr>
                <tr>
                    <td style={{ width: "6%" }} className="dt-matrix-y-axis">
                    <div>
                        <span className="dt-matrix-y-axis-value dt-skeleton"></span>
                    </div>
                    </td>

                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                    <td
                    style={{ width: "16%" }}
                    className="dt-matrix-column dt-skeleton"
                    ></td>
                </tr>
                </tbody>
            </table>
        );
    }

    return (
        <div>
            {
                isLoading ? (
                <>{ renderLoadingSection() }</>
                ) : (
                <>{ renderMatrixTable() }</>
                )
            }
        </div>
    )
};

export default withNamespaces()(
    RiskMatrix
);