import React from "react";
import Select, { components } from "react-select";
import { FaCaretDown } from "react-icons/fa";
import { IoFilterSharp } from "react-icons/io5";
import "./Style.css";

const Filter = ({ options, onChange, t }) => {
  // Custom Control component to include filter icon
  const Control = ({ children, ...props }) => {
    return (
      <components.Control {...props}>
        <IoFilterSharp
          style={{ marginRight: "8px", marginLeft: "4px", alignSelf: "center" }}
        />

        {children}
      </components.Control>
    );
  };

  // Custom Dropdown Indicator to use a custom arrow icon
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <FaCaretDown />
      </components.DropdownIndicator>
    );
  };

  return (
    <Select
      options={options}
      onChange={onChange}
      components={{ Control, DropdownIndicator }}
      placeholder={t("Filter")}
      classname="filter"
    />
  );
};

export default Filter;
