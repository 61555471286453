import { withNamespaces } from "react-i18next";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useLocation, useParams, useHistory } from "react-router-dom";
// styles
import "./style.scss";
// components
import Tabs from "./tabs";

// Contents
import DataProtectionSoftwareList from "src/modules/data-protection/pages/software/list";
import { withRouter } from "react-router-dom";
import PageHeader from "../../components/page-header";
import PageBody from "../../components/page-body";
import PageContent from "../../components/page-content";
import Navigator from "../../components/navigator";
import DataProtectionSoftwareService from "../../apis/DataProtectionSoftwareService";
import { useQuery } from "@tanstack/react-query";

const DataProtectionSoftwareMainPage = (props) => {
  const [assetId, setAssetId] = useState(null);
  const { softwareId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [detailPage, setDetailPage] = useState(null);

  const [navigatorOptions, setNavigatorOptions] = useState({
    enable: true,
    backButtonOptions: {
       // enable  :   true,
        onClick :   () => {
            const searchParams = new URLSearchParams(location.search);
            searchParams.delete('page');
        }
    },
    breadCrumbs: [
      {
        title: "Software Assets",
        link: `/admin/data-protection/softwares?tab=all-software-assets`,
      },
    ],
  });

  const [totalCount, setTotalCount] = useState(0);
  const [deletedCount, setDeletedCount] = useState(0);

  const handleFetchCount = useQuery({
    queryKey: ["report-count"],
    queryFn: async () => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.getCount();
      }
  });

  useEffect(() => {
    const counts = handleFetchCount?.data?.data;
    setTotalCount(counts?.all);
    setDeletedCount(counts?.deleted);
  }, [handleFetchCount.data]);

  const { t } = props;

  const renderDetailsContent = () => {
    switch (detailPage?.type) {
      case "risk":
        return (
          <div className="p-4">
            {/* <RiskDetails
              {...detailPage?.data}
              back={() => setDetailPage(null)}
            /> */}
          </div>
        );

      case "task":
        return (
          <div className="p-4">
            {/* <TaskDetails
              {...detailPage?.data}
              back={() => setDetailPage(null)}
            /> */}
          </div>
        );

      default:
        return null;
    }
  };

  const renderContent = () => {
    const displayAnyItemDetails = renderDetailsContent();

    if (displayAnyItemDetails) {
      return displayAnyItemDetails;
    }
    const searchParams = new URLSearchParams(location.search);

    let result = null;

    switch (searchParams.get("tab")) {
      case "all-software-assets":
        result = <DataProtectionSoftwareList setAssetId={setAssetId} />;
        break;

      case "deletion-protocol": 
        result = <DataProtectionSoftwareList setAssetId={setAssetId}/>
        break;
      default:
        break;
    }

    return (
      <React.Fragment>
        <Tabs t={t} totalCount={totalCount} deletedCount={deletedCount} />

        {result}
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (!new URLSearchParams(location.search).get("page")) {
      setDetailPage(null);
    }
  }, [location.search]);

  return (
    <PageContent classNames="data-protection-software-details-page software-details-page">
      <Container fluid>
        {/* page header */}
        <Row>
          {navigatorOptions.enable && (
            <Col lg="12" className="mb-4">
              <Navigator
                backButtonOptions={(props) => {
                  return {
                    ...props,
                    ...navigatorOptions.backButtonOptions,
                  };
                }}
                breadCrumbs={navigatorOptions.breadCrumbs}
              />
            </Col>
          )}
        </Row>

        {/* page body */}
        <PageBody classNames="p-0">
          <div className="details-content-wrapper">{renderContent()}</div>
        </PageBody>
      </Container>
    </PageContent>
  );
};

export default withNamespaces()(
  withRouter(memo(DataProtectionSoftwareMainPage))
);
