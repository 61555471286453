import { withNamespaces } from 'react-i18next';
import { AvForm, AvField, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import { useEffect, useState } from "react";

import { Row, Col, Tooltip, Label, Button } from "reactstrap";

import Password from '../../../../components/Fields/Password';

import { API_URL_GENERATE_RANDOM_USERNAME, API_URL_SIGN_IN, API_URL_CREATE_THIRD_PARTY_ACCOUNT, API_URL_VERIFY_OTP } from '../../../../common/constants';

import { LOCAL_STORAGE_KEY_ACCESS_TOKEN } from "../../../../services/constants/LocalStorage";

import axios from 'axios';

import { connect } from "react-redux";

import {
    errorNotification,
    successNotification,
    checkLogin,
    setUserAccessToken,
    appError,
    appLoadStatusChanged,
    authCurrentUser,
    logoutUser,
    loginUserSuccessful,
    changeWorkspace
  } from "../../../../store/actions";

const FollowUpForm = (props) => {
    const {
        t, 
        user, 
        token, 
        settings, 
        onBackButtonClicked, 
        onValidSubmit, 
        otp, 
        setOtp, 
        otpSent, 
        setOtpSent, 
        username,
        password,
        setUsername,
        setPassword
    } = props;
    
    const [authFormIsSubmiting, setAuthFormIsSubmiting] = useState(false);
    const [usernameTooltipOpen, setUsernameTooltipOpen] = useState(false);
    const [passwordTooltipOpen, setPasswordTooltipOpen] = useState(false);

    const [selectedAuthType, setSelecedAuthType] = useState("register"); 

    // const [username, setUsername] = useState("");
    // const [password, setPassword] = useState("");
    const [repassword, setRepassword] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
        if(selectedAuthType === "login"){
            setUsername("");
        }
    }, [selectedAuthType]);

    useEffect(() => {
        if(user && token){
            onValidSubmit(user);
        }
    }, [user]);

    const generateRandomUsername = () => {
        setUsername("");

        axios.get(API_URL_GENERATE_RANDOM_USERNAME + localStorage.getItem("i18nextLng"))
        .then((response) => {
            if (response.status === 200) {
                const { data : generatedUserName } = response.data;
                setUsername(generatedUserName);
            }
        });    
    };

    const renderAuthForm = () => {
        const emailIsRequired = selectedAuthType === "login" ? false : !!(settings?.inputs?.email?.required);

        return (
            <Row>
                <Col sm="12" className="mb-3">
                    <AvRadioGroup
                        defaultValue={ selectedAuthType }
                        name="auth_type"
                        validate={{ required: { value: true } }}
                        errorMessage={t("This field cannot be blank")}>
                        <Row>
                            <Col sm="12" md="6">
                                <AvRadio
                                    label={t("Log In")}
                                    onChange={() => setSelecedAuthType("login") }
                                    value="login"
                                />
                            </Col>

                            <Col sm="12" md="6">
                                <AvRadio
                                    label={t("Create account")}
                                    onChange={() => setSelecedAuthType("register") }
                                    value="register"
                                />
                            </Col>
                        </Row>
                    </AvRadioGroup>
                </Col>

                <Col sm="12" className="mb-3">
                    <Label className="form-label" htmlFor="username">
                        {`${t("Username")}`}
                        <span className="ms-2">
                            <i className="fa fa-info-circle"
                                aria-hidden="true"
                                id="infoIconU" />
                            <Tooltip
                                placement="top"
                                isOpen={ usernameTooltipOpen }
                                target="infoIconU"
                                toggle={() => setUsernameTooltipOpen(!usernameTooltipOpen) }>
                                {t(
                                    "Please choose a username that doesnt contain your name or any kind of email address"
                                )}
                            </Tooltip>
                        </span>

                        <Button
                            outline
                            hidden={selectedAuthType === "login"}
                            className="btn-sm color-primary ms-2"
                            onClick={ generateRandomUsername }>
                                {t("Random")}
                        </Button>
                    </Label>
                    
                    <AvField name="username"
                        placeholder=""
                        type="text"
                        className="form-control"
                        errorMessage={t("This field cannot be blank")}
                        onChange={(e) => setUsername(e.target.value)}
                        validate={{
                            required: { value: true },
                            pattern: {
                                value: /^[^@\s]+$/,
                                errorMessage: t("Please enter a valid username"),
                            },
                        }}
                        value={ username }
                        id="username" />
                </Col>

                <Col sm="12" className="mb-3">
                    <Label className="form-label" htmlFor="password">
                        {`${t("Password")}`}
                        <span className="ms-2">
                            <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                                id="infoIcon" />
                            <Tooltip
                                placement="top"
                                isOpen={ passwordTooltipOpen }
                                target="infoIcon"
                                toggle={() => setPasswordTooltipOpen(!passwordTooltipOpen)}>
                                    {t(
                                    "Please choose a password at least 10 characters contains 1 capital letter,1 digit, and 1 special character contains"
                                    ) +
                                    ": ! @ # $ % ^ & * ( ) _ [ ]+={ } ' \" ; : / ? . , < > |  -"}
                            </Tooltip>
                        </span>
                    </Label>

                    <Password
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder=""
                        errorMessage={t("This field cannot be blank")}
                        validate={{ required: { value: true } }}
                        id="password"
                        value={ password } />
                </Col>
                
                {
                    selectedAuthType === 'register' && (
                        <Col sm="12" className="mb-3">
                            <Label className="form-label" htmlFor="repeatpasword">
                                {`${t("Repeat password")}`}
                            </Label>

                            <Password
                                name="repeat_pasword"
                                placeholder=""
                                onChange={(e) => setRepassword(e.target.value)}
                                className="form-control"
                                value={ repassword }
                                errorMessage={t("This field cannot be blank")}
                                validate={{ required: { value: true } }}
                                id="repeatpasword"
                            />
                        </Col>
                    )
                }

                <Col sm="12" className="mb-3">
                    <Label className="form-label" htmlFor="email">
                        {`${t("Email")} ${!emailIsRequired ? t("(Optional)") : ''}`}
                    </Label>

                    <AvField
                        name="email"
                        placeholder=""
                        type="email"
                        className="form-control"
                        validate={{ required: { value: emailIsRequired }, email: true }}
                        errorMessage={ t("This field is invalid") }
                        id="email"
                        value={ email }
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Col>
            </Row>
        );
    }

    const loginUser = (credentials) => {
        return axios.post(API_URL_SIGN_IN, credentials)
        .then((response) => {
            

            const authedUserInfos = response.data.data;
            
            localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, authedUserInfos.token);

            if(selectedAuthType === "register") {
                return setOtpSent(true);
            }

            props.setUserAccessToken(authedUserInfos.token);

            props.loginUserSuccessful(authedUserInfos);
        })
        .catch(({ response : { data : errorResponse } }) => {
            props.errorNotification({
                code: errorResponse.code,
                message: errorResponse.message,
            })
        })
        .finally(() => {
            setAuthFormIsSubmiting(false);
        });
    }

    const startAuthentication = () => {
        if(user){
            onValidSubmit();
        }
        else{
            setAuthFormIsSubmiting(true);
            if(selectedAuthType === 'login'){
                loginUser({
                    organization: props.Organization.id,
                    username: username,
                    password: password,
                    isThirdPartyUser: true,
                    "2fa": selectedAuthType === "register" ? true : false
                });
            }
            else{
                const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\[\]+={}'";:/?.,<>|\\-]).{10,100}$/;

                if (!passwordRegex.test(password)) {
                    setAuthFormIsSubmiting(false);
                    return props.errorNotification({
                        code: "passwords-not-valid",
                        message: t(
                            "Password should be at least 10 characters and should contain at least one uppercase letter, one lowercase letter, one number and one special character"
                        ),
                    });
                } 
                else if (password !== repassword) {
                    setAuthFormIsSubmiting(false);
                    return props.errorNotification({
                        code: "password_not_equal",
                        message: t(
                            "Password and repeat password are not the same"
                        )
                    });
                } 
                else {
                    axios.post(API_URL_CREATE_THIRD_PARTY_ACCOUNT, {
                            organization: props.Organization.id,
                            username: username,
                            password: password,
                            email: email || null,
                            isThirdPartyUser: true,
                            privacyPolicyAccepted: true,
                            userPolicyAccepted: true,
                    }).then(() => {
                                                
                        loginUser({
                            organization: props.Organization.id,
                            username: username,
                            password: password,
                            isThirdPartyUser: true,
                            "2fa": selectedAuthType === "register" ? true : false
                        });
                    }).catch((error) => {
                       
                        if (error.response && error.response?.data?.message?.email === "validation.email.already_used") {
                          
                            return props.errorNotification({
                                code: "validation_error",
                                message: t("Email is already in use. Please try a different email."
                                )
                            });
                          } 

                        for (var key in error.response.data.message) {
                            if (error.response.data.message.hasOwnProperty(key)) {
                                return props.errorNotification({
                                    code: "validation_error",
                                    message: t(
                                        error.response.data.message[key]
                                    )
                                });
                            }
                        }
                    }).finally(() => {
                        setAuthFormIsSubmiting(false);
                    });
                }
            }
        }
    }


    // const verifyOTP = () => {
    //     axios.post(API_URL_VERIFY_OTP, { otp }, {
    //         headers: {
    //             Authorization: `Bearer ${props.user.tempToken}`,
    //         },
    //     }).then((response) => {
    //         const data = response.data;
    //         if (data.ok === false) {
    //             return props.errorNotification({
    //                 code: "invalid_otp",
    //                 message: t("Invalid OTP"),
    //             });
    //         }
    //         localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, props.user.tempToken);
    //         props.setUserAccessToken(props.user.tempToken);
    //         setOtpSent(false);
    //         onValidSubmit();
    //     }).catch((err) => {
    //         props.errorNotification({
    //             code: "invalid_otp",
    //             message: t("Invalid OTP"),
    //         });
    //     });
    // };


    return (
        <AvForm className="needs-validation"
            onValidSubmit={ startAuthentication }
            // onValidSubmit={otpSent ? verifyOTP : startAuthentication}
            >
            {
                <Row>
                    <Col sm="12" md="6" lg="4">
                        <h5>{t("Follow-Up")}</h5>
                        <p>
                            {t(
                            "If you would like a follow-up about your report in addition to being notified by your email, input your available times for a callback. Please make sure that the correct phone number is saved in your account settings."
                            )}
                        </p>
                    </Col>
                    <Col sm="12" md="6" lg="8">
                        <Row>
                            { !user && renderAuthForm() }
                        </Row>
                    </Col>
                </Row> 
            }

            <Row>
                <Col sm="12" className="d-flex justify-content-between align-items-center col-sm-12">
                    <Button
                        disabled={ authFormIsSubmiting }
                        onClick={() => {
                            if(onBackButtonClicked){
                                onBackButtonClicked();
                            }
                        }}
                        color="btn btn-outline-light"
                        type="button">
                        {t(settings?.buttons?.back?.title || "Back")}
                    </Button>

                    <Button
                        disabled={ authFormIsSubmiting }
                        color="btn btn-primary"
                        type="submit">
                        {selectedAuthType === "register" ? t("Verify") : t(settings?.buttons?.confirm?.title || "Confirm")}
                    </Button>
                </Col>
            </Row>

            {/* {
                selectedAuthType === 'register' && otpSent && 
                (
                    <Row>
                        <Col sm="12" className="mb-3">
                            <Label className="form-label" htmlFor="otp">
                                {t("OTP Code")}
                            </Label>
                            <AvField
                                name="otp"
                                placeholder=""
                                type="text"
                                className="form-control"
                                errorMessage={t("This field cannot be blank")}
                                onChange={(e) => setOtp(e.target.value)}
                                validate={{ required: { value: true } }}
                                value={otp}
                                id="otp"
                            />
                        </Col>
                    </Row>
                )
            }             */}

        </AvForm>
    )
}

const mapStateToProps = (state) => {
    const { token, user } = state.Login;
    const { Organization, App } = state;

    return { token, Organization, user, App };
};

export default withNamespaces()(
    connect(mapStateToProps, {
        logoutUser,
        errorNotification,
        successNotification,
        checkLogin,
        loginUserSuccessful,
        authCurrentUser,
        changeWorkspace,
        appError,
        appLoadStatusChanged,
        setUserAccessToken,
    })(FollowUpForm)
);