import {
    ACTION_HANDLE_UPDATE_NOTIFICATIONS_LIST,
    ACTION_MARK_NOTIFICATION_AS_READ_IN_LIST,
    ACTION_HANDLE_EMPTY_NOTIFICATIONS_LIST,
    ACTION_HANDLE_UPDATE_IS_LOADING
} from "./actionTypes";

const initialState = {
    isLoading   :   false,
    totalCount  :   0,
    pageIndex   :   1,
    pageSize    :   10,
    list        :   []
};

export const AppNotification = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_HANDLE_EMPTY_NOTIFICATIONS_LIST:
        return {...initialState};
    case ACTION_HANDLE_UPDATE_NOTIFICATIONS_LIST:
        return {
            ...state,
            totalCount  :   action.payload.totalCount,
            pageIndex   :   action.payload.pageIndex || 1,
            list        :   action.payload.list || []
        }
    case ACTION_MARK_NOTIFICATION_AS_READ_IN_LIST:
        return {
            ...state,
            list        :   state.list.map((notification) => {
                return {
                    ...notification,
                    isRead: notification.id === action.payload.notificationId
                }
            })
        }
    case ACTION_HANDLE_UPDATE_IS_LOADING:
        return {
            ...state,
            isLoading  :   action.payload.status
        }
    default:
      return state;
  }
};
