import { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Card, CardBody, Button, Container } from 'reactstrap';
import backgroundImage from "src/modules/3rd-party-management/assets/images/3rd-party-home-Background.png";
import NewQuestionnaireSVG from "src/modules/3rd-party-management/assets/images/NewQuestionnaire.svg";
import ExistingQuestionnaireSVG from "src/modules/3rd-party-management/assets/images/ExistingQuestionnaire.svg";

import {
    ROUTE_PATH_THIRDPARTY_FILL_QUESTIONNAIRE
} from "src/modules/3rd-party-management/constants";

const Home = ({
    t, 
    selectedLanguage,
    Organization
}) => {
    const [translation, setTranslation] = useState({});

    useEffect(() => {
        if (Organization.introTranslations && Organization.introTranslations.length > 0) {
            setTranslation(Organization.introTranslations.find(item => item.lang === selectedLanguage) || {});
          }
    }, [Organization.introTranslations, selectedLanguage]);

    return (
        <>
            <div className="third-party-home-page">
                <div className='background-image-container'>
                    <img src={backgroundImage} alt='background' />
                </div>

                <div className='actions'>
                    <div className='action-button-container'>
                        <img src={ExistingQuestionnaireSVG} alt='' />
                        <Link to={ROUTE_PATH_THIRDPARTY_FILL_QUESTIONNAIRE}>
                            <Button color="btn btn-primary">
                                {t("Start")}
                            </Button>
                        </Link>
                    </div>
                </div>

                <div className='translation-content mt-3'>
                    <Container>
                        <Row>
                            <Col sm='12'>
                                <div className='introText' dangerouslySetInnerHTML={{ __html: translation?.intro }} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
};

const mapStatetoProps = (state) => {
	const { App, Organization, Modules : modules } = state;

	return {
		App,
		Organization,
		token: state.Login.token,
        modules
	};
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
})(Home)));