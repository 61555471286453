import { Route } from 'react-router-dom';
import BeforeEachRoute from './beforeEach';

const AppRoute = ({ 
	component: Component, 
	layout: Layout, 
	isAuthProtected, 
	type: Type,
	routeModules,
	...rest 
}) => {
	return <Route
		{ ...rest }
		render={(props) => (
			<BeforeEachRoute 
				layout={ Layout } 
				isAuthProtected={ isAuthProtected } 
				component={ Component } 
				componentProps={ props } 
				type={ Type }
				routeModules={ routeModules }
			/>
		)}
	/>
};

export default AppRoute;