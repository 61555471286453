import { memo, useEffect, useState } from 'react';
import { Col, Row, Label, Button, Card, Tooltip, Progress} from 'reactstrap';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { withNamespaces } from 'react-i18next';
import axios from 'axios';
import { 
	errorNotification, 
	successNotification
} from 'src/store/actions';
import { connect } from 'react-redux';

import { AvField } from 'availity-reactstrap-validation';

import {
    API_BASE_URL
} from '../../../../constants';

import { toast } from "react-toastify";

const FileUploader = (props) => {
    const {
        questionIndex,
        id, 
        name,
        title, 
        key,
        acceptedFiles,
        userToken,
        defaultValue,
        elRefIdPrefix,
        helpText,
        onNewFileUploaded,
        onFileDeleted
    } = props;

    const [uploadedFiles, setUploadedFiles] = useState([]);

    const [helpTooltipTextStatus, setHelpTooltipTextStatus] = useState(false);

    const onUploadProgress = (e, file, setUploadedFiles) => {
        const {
            total,
            loaded,
            lengthComputable
        } = e;

        if(lengthComputable){
            const uploadedPercent = loaded / total * 100;

            setUploadedFiles((uploadedFiles) => {
                const index = uploadedFiles.findIndex((f) => f.preview === file.preview);

                if(index > -1){
                    uploadedFiles[index].uploadedPercent = uploadedPercent;

                    return [
                        ...uploadedFiles
                    ]
                }

                return [
                    ...uploadedFiles
                ]
            });
        }
    }

    const handleAcceptedFiles = (files, id) => {
        const headers = {
            headers : {},
            onUploadProgress : null
        };

        if(userToken){
            headers.headers.Authorization = `Bearer ${userToken}`
        }

        files.map((file) => {
            const formData = new FormData();

            formData.append("file", file);

            file = {
                ...file,
                id              :   null,
                status          :   'uploading',
                name            :   file.name,
                type            :   file.type,
                questionId      :   id,
                uploadedPercent :   0,
                preview         :   URL.createObjectURL(file),
                formattedSize   :   file.size
            }

            setUploadedFiles((uploadedFiles) => {
                return [
                    ...uploadedFiles,
                    {...file}
                ]
            });

            axios.post(`${API_BASE_URL}/report.uploadAttachment`, formData, {
                ...headers,
                onUploadProgress : (e) => {
                    onUploadProgress(e, file, setUploadedFiles);
                }
            })
            .then((response) => {
                setUploadedFiles((uploadedFiles) => {
                    const index = uploadedFiles.findIndex((f) => f.preview === file.preview);
    
                    if(index > -1){
                        if (response.status === 200) {
                            const fileData = response.data.data;

                            uploadedFiles[index].status = 'upload_succeed';

                            uploadedFiles[index].id = fileData.id;

                            onNewFileUploaded && onNewFileUploaded(uploadedFiles[index]);
                        }
                        else{
                            uploadedFiles[index].status = 'upload_failed';
                        }
    
                        return [
                            ...uploadedFiles
                        ]
                    }
    
                    return [
                        ...uploadedFiles
                    ]
                });
            })
            .catch((error) => {

                setUploadedFiles((uploadedFiles) => {
                    const index = uploadedFiles.findIndex((f) => f.preview === file.preview);
    
                    if(index > -1){
                        uploadedFiles[index].status = 'upload_failed';
    
                        return [
                            ...uploadedFiles
                        ]
                    }
    
                    return [
                        ...uploadedFiles
                    ];
                });

                toast(props.t(error.response.data.message), {
                    type: 'error'
                });
            });
        });
    };

    const handleClickDeleteFiles = (file) => {
        const url = `${API_BASE_URL}/attachment/${file.id}/delete`;

        const headers = {
            headers : {}
        };

        if(userToken){
            headers.headers.Authorization = `Bearer ${userToken}`
        }

        const deletedFile = {
            file: file.name,
            report_case_id: file.id,
        };

        setUploadedFiles((uploadedFiles) => {
            const index = uploadedFiles.findIndex((f) => f.id === file.id);
    
            if(index > -1){
                uploadedFiles[index].status = 'deleting';

                return [
                    ...uploadedFiles
                ]
            }

            return [
                ...uploadedFiles
            ];
        });

        axios.post(url, deletedFile, headers)
        .then(() => {
            setUploadedFiles([
                ...uploadedFiles.filter((f) => {
                    return f.id !== file.id
                })
            ]);

            onFileDeleted && onFileDeleted(file);

            toast(props.t("Data was deleted successfully"), {
                type: 'success'
            });
        })
        .catch((error) => {
            toast(props.t(error.response.data.error), {
                type: 'error'
            });
        });
    }

    useEffect(() => {
        if(defaultValue && Array.isArray(defaultValue)){
            setUploadedFiles([
                ...defaultValue.map((preUploadedFile) => {
                    return {
                        questionId  :   id,
                        id          :   preUploadedFile.title,
                        name        :   preUploadedFile.name
                    }
                })
            ]);
        }
    }, [defaultValue]);

    return (
        <Row key={key}>
            <Col sm="12">
                <div className="mb-3">
                    <Label
                        className="form-label"
                        htmlFor={`question-${id}`}>
                        {
                            parseInt(questionIndex) >= 0 ? (questionIndex + 1 + '. ') : ''
                        } {props.t(title)}

                        {
                            helpText && (
                                <span className="ms-2 tooltip-container">
                                    <i
                                        className="fa fa-info-circle help-text-icon"
                                        aria-hidden="true"
                                        id={`${elRefIdPrefix}-question-${id}-help-text`}
                                    />
                                    <Tooltip
                                        style={{
                                            backgroundColor: '#899ba1',
                                            fontWeight: 'bold'
                                        }}
                                        placement="top"
                                        isOpen={ helpTooltipTextStatus }
                                        target={`${elRefIdPrefix}-question-${id}-help-text`}
                                        toggle={() =>
                                            setHelpTooltipTextStatus(!helpTooltipTextStatus)
                                        }>
                                            {
                                                <p>
                                                    {props.t(helpText)}
                                                </p>
                                            }
                                    </Tooltip>
                                </span>
                            )
                        }
                    </Label>
                    <div style={{ position: "relative" }}>
                        <Dropzone
                            onDrop={(acceptedFiles) =>
                                handleAcceptedFiles(acceptedFiles, id)
                            }
                            multiple={true}
                            accept={acceptedFiles.accepted}>
                        {({
                            getRootProps,
                            getInputProps,
                        }) => (
                            <div className="dropzone tpdd p-3">
                                <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <button type="button" className="btn select-files-btn">
                                        {props.t('Select files...')}
                                    </button>
                                    <h4>
                                        {props.t(
                                            "Drop files here or click to upload"
                                        )}
                                    </h4>
                                </div>
                                
                            </div>
                        )}
                        </Dropzone>
                    </div>
                    <div className="dropzone-previews tpdd">
                        {uploadedFiles.map(
                            (f, i) => {
                                return (
                                    <Card className="mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + "-file"}>
                                        <div className="p-3">
                                            <Row className="align-items-center">
                                                <AvField
                                                    name={(name ? name : `question[${id}]`) + `[${i}][id]`}
                                                    type="text"
                                                    className="d-none"
                                                    value={f.id}
                                                />
                                                <AvField
                                                    name={(name ? name : `question[${id}]`) + `[${i}][name]`}
                                                    type="text"
                                                    className="d-none"
                                                    value={f.name}
                                                />
                                                <Col className="col-auto">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Link
                                                        to="#"
                                                        className="file-name">
                                                        {f.name} {f.uploaded}
                                                    </Link>
                                                    <p className="upload-details-container">
                                                        <span className='file-size'>
                                                            {(f.formattedSize / 1024).toFixed(2)} KB
                                                        </span>
                                                        {
                                                            f.status === 'upload_succeed' && (
                                                                <span className="upload-status-message succeed">
                                                                    {props.t("File(s) successfully uploaded.")}
                                                                </span>
                                                            )
                                                        }
                                                        {
                                                            f.status === 'upload_failed' && (
                                                                <span className="upload-status-message failed">
                                                                    {props.t("File(s) failed to upload.")}
                                                                </span>
                                                            )
                                                        }
                                                    </p>
                                                </Col>
                                                <Col xs={2} className="actions-container">
                                                        {
                                                            f.status === 'uploading' && (
                                                                <span className="uploaded-percent">
                                                                    { Math.ceil(f.uploadedPercent) }%
                                                                </span>
                                                            )
                                                        }
                                                        
                                                        <button type="button" className="btn" onClick={() =>
                                                            (f.status !== 'uploading' && f.status !== 'deleting') && handleClickDeleteFiles(f)
                                                        }>
                                                            {
                                                                (f.status === 'uploading' || f.status === 'deleting') ? (
                                                                    <i className="ri-forbid-line font-size-20"></i>
                                                                ) : (
                                                                    <i className="ri-close-line font-size-20"></i>
                                                                )
                                                            }
                                                        </button>
                                                </Col>
                                            </Row>
                                        </div>
                                        {
                                            f.status === 'uploading' && (
                                                <Progress value={ Math.ceil(f.uploadedPercent) } />
                                            )
                                        }
                                    </Card>
                                );
                            }
                        )}
                    </div>
                </div>
            </Col>
        </Row>
	);
}

export default withNamespaces()(
	connect(null, {
		errorNotification,
		successNotification
	})(
		memo(FileUploader)
	)
);