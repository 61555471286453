import React, { Component } from 'react';
import { Container, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { getCurrentLanguage } from '../../../i18n';
import balance from "../../../assets/images/balance.png";
import feedback from "../../../assets/images/feedback.png";
import { errorNotification, saveOrganizationActiveModuleOnRemote } from "../../../store/actions";
import WBHome from './wb';
import CMHome from './cm';
import GDPRHome from 'src/modules/data-protection/pages/thirdparty/home';
import TPDDHome from 'src/modules/3rd-party-management/pages/thirdparty/home';

import ShieldIcon from "src/modules/data-protection/assets/images/shield.svg";
import TPDDIcon from "src/modules/3rd-party-management/assets/images/tpdd.svg";

const modulesData = {
    wb : {
        title   :   'Misconduct',
        desc    :   'Misconduct refers to improper or unacceptable behavior or actions, especially by someone in a position of authority or trust. This could include activities that break the law, violate company policies, or could be unethical behaviour.',
        icon    :   balance,
        Home    :   WBHome
    },
    cm : {
        title   :   'Complaint',
        desc    :   'A complaint is a statement or expression of dissatisfaction or a grievance. In a workplace, a complaint might be about poor quality of products and services, unfair treatment, unsafe work conditions, or poor management.',
        icon    :   feedback,
        Home    :   CMHome
    },
    gd : {
        title   :   'Data Protection',
        desc    :   'Report a data breach incident or send other requests related to your personal data. A data breach is a security event where sensitive, protected, or confidential data is accessed, disclosed, or stolen without proper authorization.',
        icon    :   ShieldIcon,
        Home    :   GDPRHome
    },
    tp : {
        title   :   'TPDD',
        desc    :   '',
        icon    :   TPDDIcon,
        Home    :   TPDDHome
    }
}

class ThirdpartyHome extends Component {

  	constructor(props) {
		super(props);
		this.state = {
			breadcrumbItems: [
				{ title: 'SIP', link: '/' },
				{ title: 'Home', link: '#' },
			],
			selectedLanguage: getCurrentLanguage(),
			displayLanding: true
		};
	}

    handleRenderModuleBox = (module) => {
        if(!modulesData[module.domain]) return null;
        const isModuleClickable = module.hasModule && !this.props.modules?.isLoading;

        return (
            <div 
				hidden={!module.hasModule}
                className="option-item"
                onClick={() => {
                    if(isModuleClickable){
                        if(this.props.modules?.active !== module.domain){
                            this.props.saveOrgModuleOnRemote(module.domain);
                        }
                        else{
                            this.props.history.push('/third-party/home?landing=false');
                        }
                    }
                }}
                style={{
                    cursor: isModuleClickable ? 'pointer' : 'not-allowed'
                }}
                key={module.name}>

                <img src={modulesData[module.domain]?.icon} alt="" />

                <span style={{
                    cursor: isModuleClickable ? 'pointer' : 'not-allowed'
                }} className="option-item-button">
                    {
                        this.props.modules?.isLoading ? (
                            <Spinner className="me-2" animation="border" variant="danger" size="sm" type="grow"/>
                        ) : (
                            <>{this.props.t(modulesData[module.domain]?.title)}</>
                        )
                    }
                </span>

                <p className="option-item-description">
                    {this.props.t(modulesData[module.domain]?.desc)}
                </p>
            </div>
        );
    }

	renderHomePage(){
        if(!this.props.modules.active || !modulesData[this.props.modules.active]) return null;

        const ModuleData = modulesData[this.props.modules.active];

        return (
            <ModuleData.Home selectedLanguage={this.state.selectedLanguage} />
        )
	}

	renderModulesSelectionContent(){
		return (
            <div className='page-content'>
                <Container fluid>
                    <div className="start-page-third-party pt-0">
                        <p className="start-page-title">
                            {this.props.t("What would you like to report?")}
                        </p>
            
                        <div className="options" style={{display:'flex', flexWrap:'wrap'}}>
                            {(this.props.modules.allModules || []).map((module) => {
                                return (
                                    <div key={module.name}>
                                        {this.handleRenderModuleBox(module)}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Container>
            </div>
		);
	}

	render() {
		return (
			<React.Fragment>
                {
                    this.state.displayLanding && this.props.modules?.list?.length > 1 ? (
                        <>{ this.renderModulesSelectionContent() }</>
                    ) : (
                        <>{ this.renderHomePage() }</>
                    )
                }
			</React.Fragment>
		);
	}

	clearCache = () => {
		if ('caches' in window) {
			// Clear all caches
			caches.keys().then(function (cacheNames) {
				cacheNames.forEach(function (cacheName) {
					caches.delete(cacheName);
				});
				console.log('Browser cache cleared!');
			});
		}

		if ('caches' in window) {
			caches.keys().then(function (cacheNames) {
				if (cacheNames.length === 0) {
					console.log('Browser cache was cleared successfully!');
				} else {
					console.log('Browser cache was not cleared!');
				}
			});
		}

		//   // Reload the page to ensure all cached resources are cleared
		//   // window.location.reload(true);
	};

	clearCoockies = () => {
		// Clear the cookies
		document.cookie.split(';').forEach(function (cookie) {
			var eqPos = cookie.indexOf('=');
			var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
		});
		console.log('Cookies cleared!');

		if (document.cookie === '') {
			console.log('Cookies were cleared successfully!');
		} else {
			console.log('Cookies were not cleared!');
		}
	};

	componentDidMount() {
		const params = new URLSearchParams(this.props.location.search);
		
		this.setState({
			displayLanding: !params.has('landing') || params.get('landing') === 'true'
		});

		this.clearCache();
		this.clearCoockies();
	}

	componentDidUpdate(prevProps) {
		if ((!prevProps.App.loaded && this.props.App.loaded) || this.props.t !== prevProps.t) {
			this.setState({
				selectedLanguage: getCurrentLanguage(),
			});
		}

		if(prevProps.location.search !== this.props.location.search){
			const params = new URLSearchParams(this.props.location.search);
		
			this.setState({
				displayLanding: !params.has('landing') || params.get('landing') === 'true'
			});
		}

        if(prevProps.modules?.active !== this.props.modules?.active){
            this.props.history.push('/third-party/home?landing=false');
        }
	}
}

const mapStatetoProps = (state) => {
	const { App, Organization, Modules : modules } = state;

	return {
		App,
		Organization,
		token: state.Login.token,
        modules
	};
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
	errorNotification,
    saveOrgModuleOnRemote  : (module) => saveOrganizationActiveModuleOnRemote(module)
})(ThirdpartyHome)));
