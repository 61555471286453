import { useState, memo } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Collapse, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';

const ReportTranslations = function (props) {
	ReportTranslations.displayName = 'Memorized Component - Report Translations';
	if (process.env.NODE_ENV === 'development') console.log('---- rendering memorized component >>> ReportTranslations');

	const [isOpen, setIsOpen] = useState(false);

	/**
	 * this method translates a word.
	 * @param {String} word the word you want to get translation
	 * @param {String[]} mainTranslation all words.
	 * @returns {String} translated word
	 */
	const handleTranslateWord = (word, mainTranslation) => {
		let result;
		for (const i in mainTranslation) {
			if (typeof word === 'object') {
				if (i == word.title) {
					result = mainTranslation[i];
				} else {
					if (word && word.title.toLowerCase() == i) {
						result = mainTranslation[i];
					}
				}
			} else {
				if (i == word) {
					result = mainTranslation[i];
				} else {
					if (word && word.toString().toLowerCase() == i) {
						result = mainTranslation[i];
					}
				}
			}
		}
		if (result) {
			return result;
		} else {
			return typeof word === 'object' ? word.title : word;
		}
	};

	/**
	 * this methods renders a select-list that existing the languages for translation.
	 * @param {Object[]} languages organization languages.
	 * @param {Boolean} isDeleted deletion status of the case.
	 * @param {String} organizationId the ID of the organization you want to show translation select-list for.
	 * @param {String} token authorization token.
	 * @param {Boolean} loading loading status.
	 * @returns
	 */
	const renderLanguageOrganization = (languages, isDeleted, organizationId, token, loading) => {
		return (
			<Row>
				<Col sm='12' md='6'>
					<div className='mb-3'>
						<Label className='form-label text-dark'>{props.t('Language translator')}:</Label>
						{!loading ? (
							<Select
								id='lang-select'
								classNamePrefix='select2-selection'
								options={languages}
								onChange={(val) => props.handleChangeTranslation(props.id, val.value, organizationId, token)}
								isDisabled={isDeleted}
								placeholder={props.t('Select')}
							/>
						) : (
							<div className='dt-field dt-skeleton dt-select-list'></div>
						)}
					</div>
				</Col>
			</Row>
		);
	};

	return (
		<Row>
			<Col sm='12'>
				<Card className='mb-1 shadow-none'>
					{/* title */}
					<Link
						to='#'
						onClick={() => {
							setIsOpen(!isOpen);
						}}
						style={{ cursor: 'pointer' }}
						className='text-dark'
					>
						<CardHeader id='headingOne' className='bg-primary'>
							<h6 className='m-0 text-white font-14'>
								{props.t('Content translator')}
								<i className={`float-end accor-plus-icon mdi ${isOpen ? 'mdi-minus' : 'mdi-plus'}`}></i>
							</h6>
						</CardHeader>
					</Link>

					{/* body */}
					<Collapse isOpen={isOpen}>
						{/* This section is going to be substitude */}
						<CardBody style={{ paddingLeft: '2px' }}>
							{props.isAnalystOrAnalystAdmin &&
								props.organizationLanguages &&
								renderLanguageOrganization(
									props.organizationLanguages,
									props.isDeleted,
									props.organizationId,
									props.authToken,
									props.organizationLanguagesLoading,
								)}

							{!props.translatedAnswersLoading ? (
								<Row>
									<Col sm='12'>
										<div hidden={!isOpen}>
											<div>
												<Row>
													<Col sm='12'>
														{props.translatedAnswers &&
															props.translatedAnswers
																.sort((a, b) => {
																	return a.sort - b.sort;
																})
																.map((item, index) => {
																	const title = item.title || '';
																	const answer = item.answer;
																	return (
																		<div key={index}>
																			<div className='mb-3'>
																				{/* title */}
																				<Label className='form-label'>{props.t(title)}</Label>

																				{/* description */}
																				<p className='text-answers'>
																					{typeof answer === 'object' ? (
																						// items with multiple answers
																						<ul
																							style={{
																								paddingTop: '6px',
																								paddingLeft: 20,
																							}}
																						>
																							{answer.length
																								? answer.map((item, index) => (
																										<li key={index}>
																											<span
																												style={{
																													marginRight: 27,
																												}}
																											>
																												{handleTranslateWord(item, props.mainTranslation)}
																											</span>
																										</li>
																								  ))
																								: null}
																						</ul>
																					) : (
																						// items with single answer
																						<span>{handleTranslateWord(answer, props.mainTranslation)}</span>
																					)}
																				</p>
																			</div>
																		</div>
																	);
																})}
													</Col>
												</Row>
											</div>
										</div>
									</Col>
								</Row>
							) : (
								<>
									<Row>
										<Col sm='12' lg='12'>
											<div className='mb-3'>
												{/* title */}
												<Label className='dt-field dt-skeleton dt-content-title mb-1'></Label>

												{/* description */}
												<div
													className='dt-field dt-skeleton dt-content-description'
													style={{
														margin: 0,
													}}
												></div>
											</div>
										</Col>
									</Row>
									<Row>
										<Col sm='12' lg='12'>
											<div className='mb-3'>
												{/* title */}
												<Label className='dt-field dt-skeleton dt-content-title mb-1'></Label>

												{/* description */}
												<div
													className='dt-field dt-skeleton dt-content-description'
													style={{
														margin: 0,
													}}
												></div>
											</div>
										</Col>
									</Row>
									<Row>
										<Col sm='12' lg='12'>
											<div className='mb-3'>
												{/* title */}
												<Label className='dt-field dt-skeleton dt-content-title mb-1'></Label>

												{/* description */}
												<div
													className='dt-field dt-skeleton dt-content-description'
													style={{
														margin: 0,
													}}
												></div>
											</div>
										</Col>
									</Row>
								</>
							)}
						</CardBody>
					</Collapse>
				</Card>
			</Col>
		</Row>
	);
};

export default memo(ReportTranslations);
