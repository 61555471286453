import {    
    ORGANIZATION_MODULES_LOADED_SUCCESSFULLY,
    CHANGE_ORGANIZATION_ACTIVE_MODULE_ON_REDUX,
    ORGANIZATION_DOEST_HAVE_ACTIVE_MODULE_ERROR,
    ACTIVE_MODULE_FEATURES_LOADED_SUCCESSFULLY,
    SET_MODULE_LOADING_STATUS
} from "./actionTypes";

const initialState = {
    isLoading       :   true,
    allModules      :   [],
    list            :   [], //refers to organization activated modules
    active          :   localStorage.getItem('module') || null
};

export const Modules = (state = initialState, action) => {
    switch (action.type) {
        case ORGANIZATION_MODULES_LOADED_SUCCESSFULLY:
            return {
                ...state,
                allModules  :   action.payload.allModules,
                list        :   action.payload.organizationModules
            }
        case CHANGE_ORGANIZATION_ACTIVE_MODULE_ON_REDUX:
            return {
                ...state,
                active  :   action.payload
            }
        case ORGANIZATION_DOEST_HAVE_ACTIVE_MODULE_ERROR:
            return {
                ...state,
                active  :   -1
            }
        case ACTIVE_MODULE_FEATURES_LOADED_SUCCESSFULLY:
            const activeModuleIndex = state.allModules.findIndex((m) => m.domain === action.payload.module);

            const newModules = [...state.allModules];

            if(activeModuleIndex > -1){
                newModules[activeModuleIndex].features = action.payload.features;
            }

            return {
                ...state,
                allModules: newModules
            }
        case SET_MODULE_LOADING_STATUS:
            return {
                ...state,
                isLoading   :   action.payload.status
            }
        default:
            return {
                ...state
            }
    }
};