import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { successNotification, errorNotification } from "src/store/actions.js";
import Navigator from "src/modules/3rd-party-management/components/navigator";

import RiskDetails from "src/modules/3rd-party-management/components/RisksAndTasks/risks/details";
import { useQuery } from "@tanstack/react-query";
import SupplierService from "src/modules/3rd-party-management/apis/SupplierService";
import { toast } from "react-toastify";

const SupplierRiskDetailsPage = ({
    t
}) => {
    const { supplierId, riskId } = useParams();
    const history = useHistory();

    const handleFetchSupplierBasicInformation = useQuery({
		queryKey: ['3rd-party-management-supplier-details-basic-infos'],
		queryFn: async () => {
			const service = SupplierService.getInstance();

            return await service.fetchBasicInfos(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching supplier basic information.'), {
				type: 'error',
			});
		}
	});

    const supplierName = handleFetchSupplierBasicInformation?.data?.name ? `${handleFetchSupplierBasicInformation.data.name}-ID: ${supplierId}` : '...';

    return (
        <div className='page-content'>
            <Container fluid>
                <Row>
                    <Col lg="12" className="mb-4">
                        <Navigator backButtonOptions={(props) => {
                            return {
                                ...props,
                                enable  :   true
                            }
                        }} breadCrumbs={[
                            {
                                title   :   'Management',
                                link    :   `/admin/3rd-party/manager?tab=suppliers`
                            },
                            {
                                title   :   `${handleFetchSupplierBasicInformation?.data?.name || '...'}`,
                                link    :   `/admin/3rd-party/suppliers/${supplierId}/details`
                            },
                            {
                                title   :   'Risk Assessment',
                                link    :   `/admin/3rd-party/suppliers/${supplierId}/details?tab=risk-assessment`
                            },
                            {
                                title   :   'Details',
                                link    :   '#'
                            }
                        ]}
                        pageTitle={supplierName}
                        />
                    </Col>

                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <RiskDetails 
                                    riskId              =   { riskId }
                                    supplierId          =   { supplierId }
                                    onMitigationTaskClicked={ (taskId) => {
                                        history.push(`/admin/3rd-party/suppliers/${supplierId}/tasks/${taskId}/details`)
                                    } }
                                    taskCreationSettings = {{
                                        enabled :   true,
                                        creationPayload :   {
                                            supplier    :   supplierId
                                        }
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    const { Organization } = state;
    return {
        user            :   state.Login.user,
        token,
        organization    :   Organization
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps,
        {
            successNotification,
            errorNotification
        }
    )(SupplierRiskDetailsPage))
);