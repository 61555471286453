import { withNamespaces } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter, ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem, Alert } from "reactstrap";
import { connect } from 'react-redux';
import Select from "react-select";

import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';

import SupplierContactPersonService from "src/modules/3rd-party-management/apis/SupplierContactPersonService";
import CommonService from "src/modules/3rd-party-management/apis/CommonService";
import { AvFeedback, AvField, AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";

import PhoneInput from "react-phone-input-2";
import { EMPTY_LIST } from "src/common/constants";

const ContactPeople = ({
    t,
    supplierId,
    Organization
}) => {
    const [ people, setPeople ] = useState([]);
    const [ creationFormInProcess, setCreationFormInProcess ] = useState(false);
    const [ personToDelete, setPersonToDelete ] = useState(null);
    const [ responsiblePersonTitleDrop, setResponsiblePersonTitleDrop ] = useState(false);
    const [ responsiblePerson, setResponsiblePerson ] = useState({});
    const [ emailIsValid, setEmailIsValid ] = useState(true);

    const [ displayModal, setDisplayModal ] = useState(false);

    const avFormRef = useRef();

    const handleFetchPeopleList = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-contact-people-list'],
		queryFn: async () => {
			const service = SupplierContactPersonService.getInstance();

            return await service.list(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching contact people list.'), {
				type: 'error',
			});
		},
	});

    const handleCreatePersonMutation = useMutation({
        mutationFn: async (payload) => {
            const service = SupplierContactPersonService.getInstance();

            setCreationFormInProcess(true);

            return await service.create(payload);
        },
        onSuccess: () => {
            handleFetchPeopleList.refetch();
            setDisplayModal(false);

            toast(t("New contact person created successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while creating contact person."), {
                type: "error",
            });
        },
        onSettled: () => {
            setCreationFormInProcess(false);
        }
    });

    const handleDeletePersonMutation = useMutation({
        mutationFn: async ({
            id
        }) => {
            const service = SupplierContactPersonService.getInstance();

            return await service.delete(supplierId, id);
        },
        onSuccess: (response, person) => {
            setPeople((currentList) => {
                return currentList.filter((per) => per.id !== person.id)
            });

            toast(t("Contact person deleted successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while deleting contact person."), {
                type: "error",
            });
        },
        onSettled: () => {
            setPersonToDelete(null);
        }
    });

    const {
        mutate      :   checkUserExistenceMutation,
        isLoading   :   checkUserExistenceMutationInProcess   
    } = useMutation({
        mutationFn: async (payload) => {
          const service = CommonService.getInstance();
    
          return await service.checkUserExistence(payload);
        },
        onError: () => {
            setEmailIsValid(false)
        },
        onSuccess: () => {
            setEmailIsValid(true);
        }
    });

    useEffect(() => {
        setPeople(handleFetchPeopleList?.data?.contactPersons || []);
    }, [ handleFetchPeopleList.data ]);

    const toggleModal = () => setDisplayModal(!displayModal);

    const getLanguageName = (id) => ((Organization?.languages || []).find((lng) => lng?.language?.id === id))?.name

    const handleCreationModalSubmit = (e, values) => {
        if(!emailIsValid || checkUserExistenceMutationInProcess) return;

        handleCreatePersonMutation.mutate({
            ...values['responsible_person'],
            'supplier'      :   supplierId
        });
    }

    const rendeCreationModal = () => {
        const languages = (Organization?.languages || []).map((lng) => {
            return {
                value   :   lng?.language?.id,
                label   :   t(lng.name),
                code    :   lng.language_code,
            }
        });
        
        return (
            <Modal onClosed={() => {
                setEmailIsValid(true)
            }} isOpen={ displayModal } backdrop="static" keyboard={ false } size="xl" className="contact-person-creation-modal">
                
                <AvForm className="needs-validation m-2"
                onValidSubmit={ handleCreationModalSubmit } ref={ avFormRef }>
                    <ModalHeader toggle={ toggleModal }>
                        {t("Add A New Contact Person")}
                    </ModalHeader>

                    <ModalBody>
                        <Row className="mb-3">
                            <Col md="4" sm="12">
                                <Label for="responsible-person-firstname">
                                    {t("First Name")}
                                </Label>

                                <div className="d-flex flex-wrap position-relative">
                                    <ButtonDropdown className="position-absolute" 
                                        isOpen={ responsiblePersonTitleDrop } 
                                        toggle={() => {
                                            setResponsiblePersonTitleDrop(!responsiblePersonTitleDrop)
                                        }} 
                                        style={{
                                            left: "0px",
                                            top: "0px",
                                            width: "74px",
                                            height: "33px"
                                        }}>
                                            <DropdownToggle outline split style={{ border: 'none', borderRight: '1px solid rgb(206, 212, 218)' }}>
                                                <span style={{marginRight: '8px'}}>
                                                    { t(responsiblePerson.title || 'Mr.') }
                                                </span>
                                            </DropdownToggle>

                                            <DropdownMenu>
                                                <DropdownItem onClick={() => { 
                                                    setResponsiblePerson({
                                                        ...responsiblePerson,
                                                        title   :   'Mr.'
                                                    });
                                                }}>
                                                    { t('Mr.') }
                                                </DropdownItem>

                                                <DropdownItem onClick={() => { 
                                                    setResponsiblePerson({
                                                        ...responsiblePerson,
                                                        title   :   'Mrs.'
                                                    });
                                                }}>
                                                    { t('Mrs.') }
                                                </DropdownItem>

                                                <DropdownItem onClick={() => { 
                                                    setResponsiblePerson({
                                                        ...responsiblePerson,
                                                        title   :   'Mx.'
                                                    });
                                                }}>
                                                    { t('Mx.') }
                                                </DropdownItem>
                                            </DropdownMenu>
                                    </ButtonDropdown>

                                    <AvInput 
                                        id={`responsible-person-title`}
                                        name={`responsible_person[title]`}
                                        type="hidden"
                                        value={ responsiblePerson.title || 'Mr.'}
                                    />

                                    <AvField
                                        id={`responsible-person-firstname`}
                                        name={`responsible_person[name]`}
                                        type="text"
                                        errorMessage={t("This field cannot be blank")}
                                        className="form-control"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        style={{
                                            paddingLeft: '85px'
                                        }}
                                        groupAttrs={{
                                            className: 'w-100'
                                        }}
                                    />
                                </div>
                            </Col>

                            <Col md="4" sm="12">
                                <Label for="responsible-person-lastname">
                                    {t("Last Name")}
                                </Label>
                                <AvField
                                    name={`responsible_person[lastName]`}
                                    type="text"
                                    errorMessage={t("This field cannot be blank")}
                                    className="form-control"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    id='responsible-person-lastname'
                                />
                            </Col>

                            <Col md="4" sm="12">
                                <Label for="responsible-person-position">
                                    {t("Position")}
                                </Label>
                                <AvField
                                    name={`responsible_person[position]`}
                                    type="text"
                                    errorMessage={t("This field cannot be blank")}
                                    className="form-control"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    id='responsible-person-position'
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md="4" sm="12">
                                <Label for="responsible-person-language">
                                    {t("Language")}
                                </Label>
                                <Select
                                    placeholder={t("Select") + "..."}
                                    classNamePrefix="select2-selection"
                                    id='responsible-person-language'
                                    options={ languages }
                                    menuPortalTarget={document.body}
                                    onChange={(e) => {
                                        setResponsiblePerson({
                                            ...responsiblePerson,
                                            language    :   e
                                        });
                                    }}
                                    value={ responsiblePerson?.language }
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                                <AvField
                                    name={`responsible_person[language]`}
                                    type="hidden"
                                    errorMessage={t("This field cannot be blank")}
                                    validate={{
                                        required: { value: true },
                                    }}
                                    value={ responsiblePerson?.language?.value }
                                />
                            </Col>

                            <Col md="4" sm="12">
                                <Label for="responsible-person-email">
                                    {t("Email")}
                                </Label>
                                <AvGroup>
                                    <AvField
                                        name={`responsible_person[email]`}
                                        type="email"
                                        className="form-control"
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: t("This field cannot be blank"),
                                            },
                                            email: { errorMessage: t("Your email is invalid") },
                                        }}
                                        onBlur={(e) => {
                                            if(!e.target.value){
                                                return setEmailIsValid(true);
                                            }

                                            checkUserExistenceMutation({
                                                email : e.target.value
                                            });
                                        }}
                                        disabled={checkUserExistenceMutationInProcess}
                                        id={`responsible-person-email`}
                                    />
                                    {!checkUserExistenceMutationInProcess && !emailIsValid && (
                                        <AvFeedback valid={false} className='d-block'>
                                            {t('Email already exists')}
                                        </AvFeedback>
                                    )}
                                    {checkUserExistenceMutationInProcess && (
                                        <AvFeedback className='d-block text-info'>
                                            <Spinner className="me-1" animation="border" variant="info" size="sm"/>
                                            {t('Validating email address.please wait...')}
                                        </AvFeedback>
                                    )}
                                </AvGroup>
                            </Col>

                            <Col md="4" sm="12">
                                <Label for="responsible-person-phone">
                                    {t("Phone Number")}
                                </Label>

                                <PhoneInput
                                    inputClass="w-100"
                                    country={ "us" }
                                    inputStyle={{
                                        height  :   '38px'
                                    }}
                                    disableInitialCountryGuess={ false }
                                    disableCountryGuess={ false }
                                    onChange={ (e) => {
                                        const input = avFormRef.current?._inputs['responsible_person[phoneNumber]'];
                                        if(input){
                                            input.value = e;
                                            input.validate();
                                        }
                                    }}
                                />

                                <AvField
                                    name={`responsible_person[phoneNumber]`}
                                    type="hidden"
                                    errorMessage={t("This field cannot be blank")}
                                    validate={{
                                        required: { value: true },
                                    }}
                                />
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={ toggleModal } color="primary" type="button" outline>
                            { t('Cancel') }
                        </Button>

                        <Button disabled={ creationFormInProcess } color="primary" type="submit" size="md">
                            {
                                creationFormInProcess ? (
                                    <Spinner className="me-2" animation="border" variant="white" size="sm"/>
                                ) : (
                                    <i className="ri-add-line align-middle me-1"></i>
                                )
                                
                            }
                            { t('Add') }
                        </Button>
                    </ModalFooter>
                </AvForm>
            </Modal>
        )
    }

    const renderDeletePersonConfirmModal = () => {
        const deleteInProcess = handleDeletePersonMutation.isLoading || handleDeletePersonMutation.isFetching;

        return (
            <Modal isOpen={ !!personToDelete } backdrop="static" size="md">
                
                <ModalHeader toggle={() => setPersonToDelete(null)}>
                    {t("Confirmation Form")}
                </ModalHeader>

                <ModalBody>
                    <p>
                        {t('Do you want to delete the contact person?')}
                    </p>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={() => handleDeletePersonMutation.mutate(personToDelete)} disabled={ deleteInProcess } color="danger" type="button" size="md">
                        {
                            deleteInProcess && (
                                <Spinner className="me-2" animation="border" variant="white" size="sm"/>
                            )
                        }
                        { t('Delete') }
                    </Button>

                    <Button onClick={() => setPersonToDelete(null)} disabled={ deleteInProcess } color="secondary" type="button" outline>
                        { t('Cancel') }
                    </Button>
                </ModalFooter>
            </Modal>
        ) 
    }

    return (
        <div className="contact-people pt-0">
            <div className="list">
                {
                    !people.length ? (
                        <Alert color='warning'>
                            {t(EMPTY_LIST)}
                        </Alert>
                    ) : (
                        <>
                            {people.map((person) => {
                                return (
                                    <div className="item mb-3">
                                        <Row className="mb-2">
                                            <Col sm="12" md="4">
                                                <Label className="form-label">
                                                    {t('Full Name')}:
                                                </Label>
                                                <p>
                                                    { `${person.name} ${person.lastName || ''}` }
                                                </p>
                                            </Col>

                                            <Col sm="12" md="4">
                                                <Label className="form-label">
                                                    {t('Position')}:
                                                </Label>
                                                <p>
                                                    { person.position }
                                                </p>
                                            </Col>

                                            <Col sm="12" md="4">
                                                <Label className="form-label">
                                                    {t('Language')}:
                                                </Label>
                                                <p>
                                                    { t(getLanguageName(person.language)) }
                                                </p>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm="12" md="4">
                                                <Label className="form-label">
                                                    {t('Email')}:
                                                </Label>
                                                <p>
                                                    { person.email }
                                                </p>
                                            </Col>

                                            <Col sm="12" md="4">
                                                <Label className="form-label">
                                                    {t('Phone Number')}:
                                                </Label>
                                                <p>
                                                    { person.phoneNumber }
                                                </p>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm="12" className="d-flex justify-content-end">
                                                <Button disabled={person.deletionInProcess} onClick={() => {
                                                    setPersonToDelete(person)
                                                }} color="danger" type="button" outline>
                                                    {t('Delete Contact')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })}
                        </>
                    )
                }
            </div>

            <Button onClick={() => { setDisplayModal(true) }} 
                color="primary" 
                disabled={ false } 
                outline>
                    <i className="ri-add-line align-middle me-2 font-size-20"></i>
                    {t('Add A Contact Person')}
            </Button>

            { rendeCreationModal() }

            { renderDeletePersonConfirmModal() }
        </div>
    );
}

const mapStatetoProps = (state) => {
	const { Organization } = state;

	return {
		Organization
	};
};

export default withNamespaces()(connect(mapStatetoProps, null)(ContactPeople));