import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";

// components
import Tabs from "./tabs";

import Navigator from "src/modules/3rd-party-management/components/navigator";

import Overview from "./tabs-content/overview";
import BasicInformation from "./tabs-content/basic-information";
import Findings from "./tabs-content/findings";
import Reports from "./tabs-content/reports";
import Communication from "./tabs-content/communication";
import InternalCommunication from "./tabs-content/internal-communication";
import RiskAssessment from "./tabs-content/risk-assessment";
import TaskMananger from "./tabs-content/task-manager";
import HistoryLog from "./tabs-content/history-log";
import FindingDetails from "./tabs-content/findings/components/details";
import { useQuery } from "@tanstack/react-query";
import SupplierService from "src/modules/3rd-party-management/apis/SupplierService";
import { toast } from "react-toastify";
import PageHeader from "src/modules/3rd-party-management/components/page-header";

const detailsPageNavigatorOptions = {
    enable  :   true,
    backButtonOptions   :   {
        enable  :   true
    },
};

const SupplierDetails = (props) => {
    const { t } = props;
    const location = useLocation();
    const { id } = useParams();
    const history = useHistory();

    const [ detailPage, setDetailPage ] = useState(null);

    const [ navigatorOptions, setNavigatorOptions ] = useState({
        enable              :   false,
        backButtonOptions   :   {},
        breadCrumbs         :   []    
    });

    const handleFetchSupplierBasicInformation = useQuery({
		queryKey: ['3rd-party-management-supplier-details-basic-infos'],
		queryFn: async () => {
			const service = SupplierService.getInstance();

            return await service.fetchBasicInfos(id, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching supplier basic information.'), {
				type: 'error',
			});
		}
	});

    const renderDetailsContent = () => {
        switch(detailPage?.type){
            case 'finding':
                return (
                    <div className="p-4">
                        <FindingDetails
                            finding={{...detailPage?.data}}
                            supplierId={id}
                        />
                    </div>
                );
            
            default:
                return null;
        }
    }

    const renderContent = () => {
        const displayAnyItemDetails = renderDetailsContent();

        if(displayAnyItemDetails){
            return displayAnyItemDetails;
        }

        const searchParams = new URLSearchParams(location.search);

        let result = null;

        switch(searchParams.get('tab')){
            case 'overview':
                    result = <Overview supplierId={id} onFindingItemSelectedToShow={(findingItem) => setDetailPage({
                        type    :   'finding',
                        data    :   findingItem
                    })} />;
                break;
            case 'basic-information':
                    result = <BasicInformation supplierId={id} />;
                break;
            case 'findings':
                    result = <Findings supplierId={id} onItemSelectedToShow={(findingItem) => setDetailPage({
                        type    :   'finding',
                        data    :   findingItem
                    })} />;
                break;
            case 'reports':
                    result = <Reports supplierId={id} />;
                break;
            case 'communication':
                    result = <Communication supplierId={id}/>;
                break;
            case 'internal-communication':
                    result = <InternalCommunication supplierId={id}/>;
                break;
            case 'risk-assessment':
                    result = <RiskAssessment supplierId={id} />;
                break;
            case 'task-manager':
                    result = <TaskMananger supplierId={id} />;
                break;
            case 'history-log':
                    result = <HistoryLog supplierId={ id } />
                break;
            default : break;
        }

        return (
            <React.Fragment>
                <Tabs t={t} />

                { result }
            </React.Fragment>
        )
    }

    useEffect(() => {
        if(detailPage){
            const searchParams = new URLSearchParams(location.search);
            searchParams.set('page', 'details');

            history.push({
                search : searchParams.toString()
            });

            const supplierName = handleFetchSupplierBasicInformation?.data?.name ? `${handleFetchSupplierBasicInformation.data.name}-ID: ${id}` : '...';

            switch(detailPage?.type){
                case 'finding':
                        setNavigatorOptions({
                            ...detailsPageNavigatorOptions,
                            breadCrumbs :   [
                                {
                                    title   :   'Management',
                                    link    :   '/admin/3rd-party/manager?tab=suppliers'
                                },
                                {
                                    title   :   `${handleFetchSupplierBasicInformation?.data?.name || '...'}`,
                                    link    :   `/admin/3rd-party/suppliers/${id}/details`
                                },
                                {
                                    title   :   'Findings',
                                    link    :   `/admin/3rd-party/suppliers/${id}/details?tab=findings`
                                },
                                {
                                    title   :   'Detail',
                                    link    :   '#'
                                }
                            ],
                            pageTitle: supplierName
                        });
                    break;

                default:
                    return;
            }
        }
    }, [detailPage, handleFetchSupplierBasicInformation.data]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        if(searchParams.has('page')){
            return;
        }

        setDetailPage(null);

        const breads = [
            {
                title   :   'Management',
                link    :   '/admin/3rd-party/manager?tab=suppliers'
            },
            {
                title   :   `${handleFetchSupplierBasicInformation?.data?.name || '...'}`,
                link    :   `/admin/3rd-party/suppliers/${id}/details?tab=overview`
            }
        ];

        switch(searchParams.get('tab')){
            case 'basic-information':
                    breads.push({
                        title   :   t('Basic Information'),
                        link    :   '#'
                    });
                break;
            case 'findings':
                    breads.push({
                        title   :   t('Findings'),
                        link    :   '#'
                    });
                break;
            case 'reports':
                    breads.push({
                        title   :   t('Reports'),
                        link    :   '#'
                    });
                break;
            case 'communication':
                    breads.push({
                        title   :   t('External Communication'),
                        link    :   '#'
                    });
                break;
            case 'internal-communication':
                    breads.push({
                        title   :   t('Internal Communication'),
                        link    :   '#'
                    });
                break;
            case 'risk-assessment':
                    breads.push({
                        title   :   t('Risk Assessment'),
                        link    :   '#'
                    });
                break;
            case 'task-manager':
                    breads.push({
                        title   :   t('Task Management'),
                        link    :   '#'
                    });
                break;
            case 'history-log':
                    breads.push({
                        title   :   t('History Log'),
                        link    :   '#'
                    });
                break;
            default : 
                breads.push({
                    title   :   t('Overview'),
                    link    :   '#'
                });
                break;
        }

        const supplierName = handleFetchSupplierBasicInformation?.data?.name ? `${handleFetchSupplierBasicInformation.data.name}-ID: ${id}` : '...';

        setNavigatorOptions({
            ...detailsPageNavigatorOptions,
            breadCrumbs :   breads,
            pageTitle   :   supplierName
        });
    }, [ location.search, handleFetchSupplierBasicInformation.data ]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        if(searchParams.has('page') && !detailPage){
            searchParams.delete('page');

            history.push({
                search : searchParams.toString()
            });
        }
    }, []);

    return (
        <div className='page-content supplier-details-page'>
            <Container fluid>
                <Row>
                    {
                        navigatorOptions.enable && (
                            <Col lg="12" className="mb-4">
                                <Navigator backButtonOptions={(props) => {
                                    return {
                                        ...props,
                                        ...navigatorOptions.backButtonOptions
                                    }
                                }} breadCrumbs={navigatorOptions.breadCrumbs} pageTitle={navigatorOptions.pageTitle}/>
                            </Col>
                        )
                    }

                    <Col lg="12" className="mb-4">
                        <div className="details-content-wrapper">
                            { renderContent() }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    const { Organization } = state;
    return {
      user          :   state.Login.user,
      token         :   token,
      organization  :   Organization
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps,
        {}
    )(memo(SupplierDetails)))
);