import { Fragment } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import UserUtils from 'src/services/utils/UserUtils';
import DateUtils from 'src/services/utils/DateUtils';

import {
    INTERNATIONAL_DATE_TIME_FORMAT,

    API_URL_GET_USER_DETAIL
} from 'src/common/constants';

import { 
    markNotificationAsRead, 
    changeWorkspace, 
    loginUserSuccessful,
    appLoadStatusChanged,
    apiError,
    getInfoOrganization
} from 'src/store/actions';
import axios from 'axios';
import UrlUtils from 'src/services/utils/UrlUtils';

const Handler = ({
    notifications,
    user,
    t,
    organization,
    token,

    markAsRead,
    getInfoOrganizationAction
}) => {
    const userUtils = new UserUtils();
    const dateUtils = new DateUtils();
    const urlUtils = new UrlUtils();

    const handleTranslateOnType = (notification) => {
		const notificationType = notification.message_type;
		const secondaryTaskId = notification.data?.secondaryTaskId;
		const secondaryCaseId = notification.data?.secondaryId;
		const secondaryRiskId = notification.data?.secondaryRiskId;
		const riskRate = notification.data?.riskRate;

		switch (notificationType) {
			case 'new_chat': {
				return t('You have received a new live chat request');
			}
			case 'new_comment': {
                return (
					<Fragment>
						<span style={{ display: 'block' }}>
							{t('Your case has a new comment')}.
						</span>
						{secondaryCaseId ? '\n' + t('Case ID') + ': ' + secondaryCaseId : ''}
					</Fragment>
				);
			}
			case 'new_internal_comment': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('Your case has a new internal comment')}.</span>
						<span style={{ display: 'block' }}>
							{t('Case ID')}: {secondaryCaseId}
						</span>
					</Fragment>
				);
			}
			case 'open': {
				return t('Your case has been received and read');
			}
			case 'in_progress': {
				return t('Your case status changed to In Progress');
			}
			case 'closed': {
				return t('Your case status changed to close');
			}
			case 'deleted': {
				return t('Your case has been deleted');
			}
			case 'pending_delete': {
				return t('Your case has been deleted');
			}
			case 'new': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('Your request or report has been submitted.')}</span>
						{secondaryCaseId ? '\n' + t('ID') + ': ' + secondaryCaseId : ''}
					</Fragment>
				);
			}
			case 'new_case_reported': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('You have received a new case')}.</span>
						{secondaryCaseId ? '\n' + t('Case ID') + ': ' + secondaryCaseId : ''}
					</Fragment>
				);
			}
			case 'assigned': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('Your case has been assigned')}.</span>
					</Fragment>
				);
			}
			case 'new_case_assigned': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('A case was assigned to you')}.</span>
						<span style={{ display: 'block' }}>{secondaryCaseId ? '\n' + t('Case ID') + ': ' + secondaryCaseId : ''}</span>
					</Fragment>
				);
			}
			case 'new_attachment': {
				return t('You have received an update on your case');
			}
			case 'case_had_been_read': {
				return t('Your case has been read');
			}
			case 'case_in_progress': {
				return t('An Analyst is working on your case');
			}
			case 'case_had_been_closed': {
				return t('Your case has been closed');
			}
			case 'sip_system_update': {
				return 'Smart Integrity Platform ' + notification?.data?.caseId ? t('Case update') : t('System update');
			}
			case 'risk_assign': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('A new risk has been assigned to you.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryRiskId}
						</span>
					</Fragment>
				);
			}
			case 'risk_comment': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('A comment was added to your risk.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryRiskId}
						</span>
					</Fragment>
				);
			}
			case 'new_task_created': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('A new task has been assigned to you.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
					</Fragment>
				);
			}
			case 'task_status_new': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The status of your task has been changed.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
						<span style={{ display: 'block' }}>
							{t('New status')}: {t('New')}
						</span>
					</Fragment>
				);
			}
			case 'task_status_in_progress': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The status of your task has been changed.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
						<span style={{ display: 'block' }}>
							{t('New status')}: {t('In Progress')}
						</span>
					</Fragment>
				);
			}
			case 'task_status_review': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The status of your task has been changed.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
						<span style={{ display: 'block' }}>
							{t('New status')}: {t('Review')}
						</span>
					</Fragment>
				);
			}
			case 'task_status_delete': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The status of your task has been changed.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
						<span style={{ display: 'block' }}>
							{t('New status')}: {t('Deleted')}
						</span>
					</Fragment>
				);
			}
			case 'task_status_close': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The status of your task has been changed.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
						<span style={{ display: 'block' }}>
							{t('New status')}: {t('Closed')}
						</span>
					</Fragment>
				);
			}
			case 'task_priority_low': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The priority of your task has been changed.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
						<span style={{ display: 'block' }}>
							{t('New priority')}: {t('Low')}
						</span>
					</Fragment>
				);
			}
			case 'task_priority_medium':
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The priority of your task has been changed.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
						<span style={{ display: 'block' }}>
							{t('New priority')}: {t('Medium')}
						</span>
					</Fragment>
				);
			case 'task_priority_high':
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The priority of your task has been changed.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
						<span style={{ display: 'block' }}>
							{t('New priority')}: {t('High')}
						</span>
					</Fragment>
				);
			case 'task_comment':
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('A comment was added to your task')}.</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
					</Fragment>
				);
			case 'task_before_overdue': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The deadline for the task is soon.')}</span>
						<span style={{ display: 'block' }}>
							{notification?.data?.secondaryTaskId
								? '\n' + t('Task ID') + ': ' + notification.data.secondaryTaskId
								: notification?.data?.taskId
								? '\n' + t('Task ID') + ': ' + notification?.data?.taskId
								: ''}
						</span>
					</Fragment>
				);
			}
			case 'new_task': {
				return <span style={{ display: 'block' }}>{t('You created a new task')}.</span>;
			}
			case 'task_change_deadline': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('Deadline of your task was changed')}.</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
					</Fragment>
				);
			}
			case 'task_change_description': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The description of your task has been changed.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
					</Fragment>
				);
			}
			case 'task_overdue': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The deadline for your task is today.')}</span>
						<span style={{ display: 'block' }}>
							{notification?.data?.secondaryTaskId
								? '\n' + t('Task ID') + ': ' + notification.data.secondaryTaskId
								: notification?.data?.taskId
								? '\n' + t('Task ID') + ': ' + notification?.data?.taskId
								: ''}
						</span>
					</Fragment>
				);
			}
			case 'risk_rating': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The rating of your risk has been changed.')}</span>
						<span style={{ display: 'block' }}>
							{t('New rating')}: {riskRate}
						</span>
					</Fragment>
				);
			}
			case 'risk_change_description': {
				return <span style={{ display: 'block' }}>{t('Description of your risk was changed')}.</span>;
			}
			case 'status_update': {
				return t('The status of your case has been updated: {{status}}');
			}
			case 'readonly_access': {
				return t('You have read-only access to a case.') + (secondaryCaseId ? '\n' + t('Case') + ': ' + secondaryCaseId : null);
			}
			case 'comment_mention': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('You have been mentioned in a comment')}.</span>
						<span style={{ display: 'block' }}>
							{t('Case ID')}: {secondaryCaseId}
						</span>
					</Fragment>
				);
			}
			case 'task_owner_role_removed': {
				return <span style={{ display: 'block' }}>{t('Your task owner role has been removed by the admin')}.</span>;
			}
			case 'task_owner_role_added': {
				return <span style={{ display: 'block' }}>{t('You have been assigned to the Task Owner role')}.</span>;
			}
			case 'voice_not_deleted': {
				return t('Please check the case and delete the voice file if a retention is not required.') + (secondaryCaseId ? '\n' + t('Case ID') + ': ' + secondaryCaseId : null);
			}


			default: {
				return t(notificationType);
			}
		}
	}

    const authUserInNewOrganization = async (orgId) => {
        return new Promise((resolve, reject) => {
            axios.post(
				API_URL_GET_USER_DETAIL,
				{
					organizationId  :   orgId,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					}
				}
			).then((response) => {
                if (response.data.data.settings?.direction) {
					localStorage.setItem('lang_dir', response.data.data.settings.direction);
				}
				loginUserSuccessful({ ...response.data.data });
				changeWorkspace(userUtils.getWorkspace(response.data.data));
				appLoadStatusChanged(true);
				apiError(null);

                resolve();
            }).catch(reject)
        });
    }

    const handleChangeOrganization = async ({
        orgId,
        orgName,
        orgUrl
    }) => {
        try{
            await authUserInNewOrganization(orgId);

            localStorage.setItem('org', 
                urlUtils.getAppRootPath(orgId, orgName.replace(/\s+/g, '-').toLowerCase())
            );

            getInfoOrganizationAction({
                id	:	orgId,
                url	:	orgUrl
            });
        }
        catch(error){
            console.log(error)
        }
    }

    const handleRenderNotification = (notification, index) => {
		let link = '#';

		if (typeof notification.id !== 'undefined') {
			switch (notification.message_type) {
				case 'new_chat': {
					link = 'livechat';
					break;
				}
				case 'new_task_created':
				case 'task_status_new':
				case 'task_status_in_progress':
				case 'task_status_review':
				case 'task_status_close':
				case 'task_status_delete':
				case 'task_priority_low':
				case 'task_priority_medium':
				case 'task_priority_high':
				case 'task_comment':
				case 'task_overdue':
				case 'task_change_deadline':
				case 'task_change_description':
				case 'task_before_overdue': {
					if (userUtils.isTaskOwner(user)) {
						link = `tasks/${notification.data.taskId}`;
					} else {
						link = `report/task/${notification.data.taskId}`;
					}
					break;
				}
				case 'risk_assign':
				case 'risk_comment':
				case 'risk_rating':
				case 'risk_change_description': {
					link = `report/risk/${notification.data.riskId}`;
					break;
				}
				default: {
					link = `report/detail`;
					break;
				}
			}
		}

        if(user &&
			user.roles &&
			user.roles.includes('ROLE_PARTNER') &&
			notification &&
			notification.data &&
			notification.data.organizationData &&
			organization?.url !== notification.data.organizationData.domainUrl){

            let organizationID = notification.data.organizationData.id;
            let organizationURL = notification.data.organizationData.domainUrl;
            let organizationName = notification.data.organizationData.name;

			return (
				<div role='button'
					onClick={() => {
                        markAsRead(notification.id);

						localStorage.setItem('taskId', notification.data.taskId ? notification.data.taskId : '');
						localStorage.setItem('riskId', notification.data.riskId ? notification.data.riskId : '');
						localStorage.setItem('caseId', notification.data.caseId);
						localStorage.setItem('caseDetailsLink', `/${window.location.pathname.split('/', 4)[3]}/${link}`);
						
						if (notification.data.secondaryId){
                            localStorage.setItem('secondaryCaseId', notification.data.secondaryId);
                        }

                        handleChangeOrganization({
                            orgId   :   organizationID,
                            orgName :   organizationName,
                            orgUrl  :   organizationURL
                        });
					}}
					className='text-reset notification-item'>
					<div className='d-flex'>
						<div className='avatar-xs me-3'>
							<span className='avatar-title bg-info rounded-circle font-size-16'>
								<i className='ri-notification-3-line'></i>
							</span>
						</div>
						<div className='flex-1'>
							<h6 className='mt-0 mb-1'>
								Smart Integrity Platform {notification?.data?.caseId ? t('Case update') : t('System update')}:
							</h6>
							<div className='font-size-12 text-muted'>
								<p className='mb-1'>
									{handleTranslateOnType(notification)} {t('from')} {organizationName}
								</p>
								<p className='mb-0'>
									<i className='mdi mdi-clock-outline'></i>
									{` ${dateUtils.convertTimeStampToDate(notification.created_at, INTERNATIONAL_DATE_TIME_FORMAT)}`}
								</p>
							</div>
						</div>
					</div>
				</div>
			);
        }

		return (
            <Link
                onClick={() => {
                    markAsRead(notification.id);

                    localStorage.setItem('taskId', notification.data.taskId ? notification.data.taskId : '');
                    localStorage.setItem('riskId', notification.data.riskId ? notification.data.riskId : '');
                    localStorage.setItem('caseId', notification.data.caseId);

                    if (notification.data.secondaryId) {
                        localStorage.setItem('secondaryCaseId', notification.data.secondaryId);
                    }
                }}
                to={`/${window.location.pathname.split('/', 4)[3]}/${link}`}
                className='text-reset notification-item'
            >
                <div className='d-flex'>
                    <div className='avatar-xs me-3'>
                        <span className='avatar-title bg-info rounded-circle font-size-16'>
                            <i className='ri-notification-3-line'></i>
                        </span>
                    </div>
                    <div className='flex-1'>
                        <h6 className='mt-0 mb-1'>
                            Smart Integrity Platform {notification?.data?.caseId ? t('Case update') : t('System update')}:
                        </h6>
                        <div className='font-size-12 text-muted'>
                            <p className='mb-1'>
                                {handleTranslateOnType(notification)}
                            </p>
                            <p className='mb-0'>
                                <i className='mdi mdi-clock-outline'></i>
                                {` ${dateUtils.convertTimeStampToDate(notification.created_at, INTERNATIONAL_DATE_TIME_FORMAT)}`}
                            </p>
                        </div>
                    </div>
                </div>
            </Link>
        );
	};

    return (
        <>
            {(notifications?.list || []).map((notification, index) => {
                return (
                    <div key={notification.id}
                        style={{
                            animationName: notification.hasMotion ? 'newNotificationAnimation' : '',
                            animationDuration: '0.6s',
                            animationIterationCount: 1,
                            overflow: 'hidden',
                            transition: 'opacity 0.3s ease 0s, max-height 0.3s ease 0.3s',
                            maxHeight: notification.isRead ? '0px' : '1000px',
							opacity: notification.isRead ? '0' : '1',
                        }}
                    >
                        {handleRenderNotification(notification, index)}
                    </div>
                );
            })}
        </>
    );
}

const mapStatetoProps = (state) => {
	return {
        organization    :   state.Organization,
        user            :   state.Login?.user,
        token           :   state.Login?.token,
		notifications   :   state.AppNotification,
        modules         :   state.Modules
	};
};

const mapDispachToProps = (dispatch) => {
	return {
        markAsRead                  :   (notificationId) => dispatch(markNotificationAsRead({
            notificationId
        })),
        changeWorkspace             :   (workspace) => dispatch(changeWorkspace(workspace)),
		loginUserSuccessful         :   (user) => dispatch(loginUserSuccessful(user)),
        appLoadStatusChanged        :   (status) => dispatch(appLoadStatusChanged(status)),
        apiError                    :   (error) => dispatch(apiError(error)),
        getInfoOrganizationAction   :	(organization)	=>	dispatch(getInfoOrganization(organization)),
    };
};

export default connect(
    mapStatetoProps, mapDispachToProps
)(withNamespaces()(
    Handler
));