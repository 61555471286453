import { useCallback, useEffect, useRef, useState } from "react";
import { withNamespaces } from 'react-i18next';
import { connect } from "react-redux";
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Input,
    Row,
    Col,
    Label,
    Button
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { CgLink } from "react-icons/cg";
import { TbLoader } from "react-icons/tb";
import { convertToHTML } from "draft-convert";

import {
    FETCH_RISKS_CATEGORIES_FAILED,
    UPLOAD_ATTACHMENT_FAILED,
    CREATE_TASK_SUCCESSFUL,
    CREATE_TASK_FAILED,
} from "src/common/constants";

import {
    API_BASE_URL,
    API_URL_TASK_UPLOAD_FILES,
    API_URL_TASK_UPLOAD_FILE
} from 'src/modules/3rd-party-management/constants';

import DateUtils from "src/services/utils/DateUtils";
import Select from "react-select";
import axios from "axios";
import FileUploader from "../../uploader";
import { toast } from "react-toastify";

const CreateTaskModal = function ({
    t,
    user,
    token,

    isOpen,
    owners,
    initialTaskStatus,
    priorities,

    creationRequestPayload = {},

    successCreation,
    closeModal
}) {
    const dateUtils = new DateUtils();

    const [categories, setCategories] = useState(null);
    const categoryHTMLRef = useRef(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categoryError, setCategoryError] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);

    const titleHTMLRef = useRef(null);
    const [title, setTitle] = useState(null);
    const [titleError, setTitleError] = useState(null);

    const startDateHTMLRef = useRef(null);
    const [startDate, setStartDate] = useState(null);
    const [startDateError, setStartDateError] = useState(null);

    const deadlineDateHTMLRef = useRef(null);
    const [deadlineDate, setDeadlineDate] = useState(null);
    const [deadlineDateError, setDeadlineDateError] = useState(null);

    const ownerHTMLRef = useRef(null);
    const [selectedOwner, setSelectedOwner] = useState(null);
    const [ownerError, setOwnerError] = useState(null);

    const [selectedTaskStatus, setSelectedTaskStatus] = useState(null);

    const priorityHTMLRef = useRef(null);
    const [selectedPriority, setSelectedPriority] = useState(null);
    const [priorityError, setPriorityError] = useState(null);

    const descriptionHTMLRef = useRef(null);
    const [description, setDescription] = useState(EditorState.createEmpty());
    const [descriptionError, setDescriptionError] = useState(null);

    const [attachmentsList, setAttachmentsList] = useState([]);
    const [showAttachmentsList, setShowAttachmentsList] = useState(false);
    const [showAttachmentsProgress, setShowAttachmentsProgress] = useState(false);

    const [comment, setComment] = useState(EditorState.createEmpty());

    const [showCommentAttachmentsList, setShowCommentAttachmentsList] =
    useState(false);

    const [showCommentAttachmentsProgress, setShowCommentAttachmentsProgress] =
    useState(false);

    const [commentAttachmentsList, setCommentAttachmentsList] = useState([]);

    const [showSubmitProgress, setShowSubmitProgress] = useState(false);

    const handleFormValidations = () => {
        let isValid = true;

        if (!selectedCategory) {
            isValid = false;
            setCategoryError("Please Select Category");
        }

        if (!title.trim()) {
            isValid = false;
            setTitleError("Please Select Title");
        }

        if (!startDate) {
            isValid = false;
            setStartDateError("Please Select Start Date");
        }

        if (!deadlineDate) {
            isValid = false;
            setDeadlineDateError("Please Select Deadline");
        }

        if (!selectedOwner) {
            isValid = false;
            setOwnerError("Please Select Owner");
        }

        if (!selectedPriority) {
            isValid = false;
            setPriorityError("Please Select Priority");
        }

        if (!description.getCurrentContent().hasText()) {
            isValid = false;
            setDescriptionError("Please type description");
        }

        if (!isValid) categoryHTMLRef.current.scrollIntoView();

        return isValid;
    };

    const handleSubmit = async () => {
        setShowSubmitProgress(true);

        try {
            if (handleFormValidations()) {
                const data = {
                    name                    :   title,
                    description             :   convertToHTML(description.getCurrentContent()),
                    startedAt               :   startDate,
                    descriptionAttachments  :   attachmentsList.map((f) => String(f.file.id)),
                    analyst                 :   selectedOwner.value,
                    endedAt                 :   deadlineDate,
                    priority                :   selectedPriority.value,
                    relatedTasks            :   [],
                    comments                :   (
                        !comment.getCurrentContent().hasText() ? [] : [
                            {
                                content     : convertToHTML(comment.getCurrentContent()),
                                attachments :
                                commentAttachmentsList.length > 0
                                    ? commentAttachmentsList.map((f) => String(f.file.id))
                                    : []
                            }
                        ]
                    ),
                    category                :   selectedCategory.label === "Other" ? null : selectedCategory.value,
                    otherCategory           :   selectedCategory.label === "Other" ?    selectedSubCategory : null,
                    ...(creationRequestPayload || {})
                };

                const result = await axios.post( `${API_BASE_URL}/task/create`,
                    data,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    }
                );

                if (result.status === 200) {
                    toast(t(CREATE_TASK_SUCCESSFUL), {
                        type: 'success',
                    });

                    handleCloseModal();
                    
                    successCreation();
                } 
                else {
                    toast(t(CREATE_TASK_FAILED), {
                        type: 'error',
                    });
                }
            }
        } 
        catch (error) {
            toast(t(CREATE_TASK_FAILED), {
                type: 'error',
            });
        }

        setShowSubmitProgress(false);
    };

    const handleCloseModal = () => {
        setSelectedCategory(null);
        setCategoryError(null);
        setSelectedSubCategory(null);
        setTitle(null);
        setTitleError(null);
        setStartDate(null);
        setStartDateError(null);
        setDeadlineDate(null);
        setDeadlineDateError(null);
        setSelectedOwner(null);
        setOwnerError(null);
        setSelectedTaskStatus(null);
        setSelectedPriority(null);
        setPriorityError(null);
        setDescription(EditorState.createEmpty());
        setDescriptionError(null);
        setAttachmentsList([]);
        setShowAttachmentsList(false);
        setShowAttachmentsProgress(false);
        setComment(EditorState.createEmpty());
        setCommentAttachmentsList([]);
        setShowCommentAttachmentsList(false);
        setShowCommentAttachmentsProgress(false);
        setShowSubmitProgress(false);

        closeModal();
    };

    const handleUploadAttachments = (fs) => {
        try {
            setShowAttachmentsProgress(true);
            const files = [];
            const formData = new FormData();

            for (const f in fs.target.files) {
                if (fs.target.files.hasOwnProperty(f)) {
                    files.push(fs.target.files[f]);
                }
            }

            files.map(async (file) => {
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: file.size,
                });
                formData.append("file", file);

                const result = await axios.post(
                    API_URL_TASK_UPLOAD_FILE,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    }
                );

                if (result.status === 200) {
                    const fileData = result.data.data;
                    setAttachmentsList((oldArray) => [
                        ...oldArray,
                        {
                            id: fileData.id,
                            file: fileData,
                            name: file.name,
                            preview: file.preview,
                            formattedSize: file.formattedSize,
                        }
                    ]);

                    if (file === files[files.length - 1])

                    setShowAttachmentsProgress(false);
                } 
                else {
                    setShowAttachmentsProgress(false);

                    toast(t(UPLOAD_ATTACHMENT_FAILED), {
                        type: 'error',
                    });
                }
            });
        } 
        catch (error) {
            toast(t(UPLOAD_ATTACHMENT_FAILED), {
                type: 'error',
            });
        }
    };

    const handleRemoveAttachment = (id) => {
        const index = attachmentsList.findIndex((i) => i.id === id);
        if (index > -1) {
            const oldArray = Array.from(attachmentsList);
            oldArray.splice(index, 1);
            setAttachmentsList(oldArray);
        }
    };

    const handleUploadCommentAttachments = async (fs) => {
        try {
            setShowCommentAttachmentsProgress(true);
            const files = [];
            const formData = new FormData();

            for (const f in fs.target.files) {
                if (fs.target.files.hasOwnProperty(f)) {
                    files.push(fs.target.files[f]);
                }
            }

            files.map(async (file) => {
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: file.size,
                });
                formData.append("files[]", file);
            });

            const result = await axios.post(
                API_URL_TASK_UPLOAD_FILES,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            );

            if (result.status === 200) {
                const fileData = result.data.data;

                // eslint-disable-next-line array-callback-return
                fileData.map((fd, i) => {
                    setCommentAttachmentsList((oldArray) => [
                        ...oldArray,
                        {
                            id: fd.id,
                            file: fd,
                            name: files[i].name,
                            preview: files[i].preview,
                            formattedSize: files[i].formattedSize,
                        },
                    ]);
                });

                setShowCommentAttachmentsProgress(false);
            } 
            else {
                setShowCommentAttachmentsProgress(false);

                toast(t(UPLOAD_ATTACHMENT_FAILED), {
                    type: 'error',
                });
            }
        } 
        catch (error) {
            toast(t(UPLOAD_ATTACHMENT_FAILED), {
                type: 'error',
            });
        }
    };

    const handleRemoveCommentAttachment = (id) => {
        const index = commentAttachmentsList.findIndex((i) => i.id === id);
        if (index > -1) {
            const oldArray = Array.from(commentAttachmentsList);
            oldArray.splice(index, 1);
            setCommentAttachmentsList(oldArray);
        }
    };

    const handleFetchCategories = useCallback(
        async () => {
            try {
                const result = await axios.get(
                    `${API_BASE_URL}/task/categories`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                    }
                );

                if (result.status === 200) {
                    const categories = result.data.data.map((item) => {
                        return {
                            value       : item.id,
                            baseLabel   : item.name,
                            label       : item.name,
                        };
                    });

                    setCategories(categories);
                } 
                else {
                    toast(t(FETCH_RISKS_CATEGORIES_FAILED), {
                        type: 'error',
                    });
                }
            } 
            catch (error) {
                toast(t(FETCH_RISKS_CATEGORIES_FAILED), {
                    type: 'error',
                });
            }
        },
        [t]
    );

    useEffect(() => {
        if (isOpen && !categories) handleFetchCategories(token);
    }, [isOpen, token, categories, handleFetchCategories]);

    useEffect(() => {
        if (isOpen) setSelectedTaskStatus(initialTaskStatus);
    }, [isOpen, initialTaskStatus]);

    return (
        <Modal 
            size="lg"
            scrollable={true}
            isOpen={isOpen}
            toggle={handleCloseModal}
            backdrop="static">
                
            <ModalHeader toggle={handleCloseModal}>{t("Create a task")}</ModalHeader>

            <ModalBody>
                <AvForm className="needs-validation"
                    onValidSubmit={() =>
                        handleFormValidations()
                        ? handleSubmit()
                        : null
                    }>
                    <Row className="mb-3" style={{ zIndex: 10, position: "relative" }}>

                        <Col sm="12" md="3" lg="3">
                            <Label className="form-label text-dark">{`${t(
                            "Creation Date"
                            )}: `}</Label>
                            <Input
                            name="creationDate"
                            type="date"
                            value={dateUtils.getCurrentDate()}
                            readOnly
                            disabled
                            />
                        </Col>

                        <Col sm="12"
                            md={
                            !selectedCategory ||
                            (selectedCategory && t(selectedCategory.label) !== t("Other"))
                                ? "5"
                                : "3"
                            }
                            lg={
                            !selectedCategory ||
                            (selectedCategory && t(selectedCategory.label) !== t("Other"))
                                ? "5"
                                : "3"
                            }
                        >
                            <span ref={categoryHTMLRef}></span>
                            <Label className="form-label text-dark">{`${t(
                            "Category"
                            )}: `}</Label>
                            {categories ? (
                            <>
                                <Select
                                name="category"
                                classNamePrefix="select2-selection"
                                options={categories.map((c) => {
                                    return {
                                    value: c.value,
                                    baseLabel: c.baseLabel,
                                    label: t(c.baseLabel),
                                    };
                                })}
                                placeholder={t("Select")}
                                onChange={(e) => setSelectedCategory(e)}
                                />
                                <p className="text-danger">
                                {!selectedCategory ? categoryError : ""}
                                </p>
                            </>
                            ) : (
                            <div
                                className="dt-field dt-skeleton dt-select-list"
                                style={{ marginBottom: 16 }}
                            ></div>
                            )}
                        </Col>

                        <Col sm="12"
                            md="3"
                            lg="3"
                            hidden={
                            !selectedCategory || selectedCategory.baseLabel !== "Other"
                            }
                        >
                            <Label className="form-label text-dark">{`${t(
                            "Subcategory"
                            )}: `}</Label>
                            <AvField
                            name="sub-cat"
                            type="text"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                                required: {
                                value:
                                    selectedCategory &&
                                    selectedCategory.baseLabel === "Other",
                                },
                            }}
                            onChange={(e) => setSelectedSubCategory(e.target.value)}
                            />
                        </Col>

                        <Col sm="12"
                            md={
                            !selectedCategory ||
                            (selectedCategory && selectedCategory.label !== "Other")
                                ? "4"
                                : "3"
                            }
                            lg={
                            !selectedCategory ||
                            (selectedCategory && selectedCategory.label !== "Other")
                                ? "4"
                                : "3"
                            }
                        >
                            <span ref={titleHTMLRef}></span>
                            <Label className="form-label text-dark">{`${t(
                            "Task Title"
                            )}: `}</Label>
                            <AvField
                            name="title"
                            type="text"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            onChange={(e) => setTitle(e.target.value)}
                            validate={{
                                required: {
                                value: title,
                                },
                            }}
                            />
                            <p className="text-danger">{!title ? titleError : ""}</p>
                        </Col>
                    </Row>

                    <Row className="mb-3" style={{ zIndex: 9, position: "relative" }}>
                        <span ref={startDateHTMLRef}></span>
                        <Col sm="12" md="6" lg="6">
                            <Label className="form-label text-dark">{`${t(
                            "Start Date"
                            )}: `}</Label>
                            <Input
                            name="startDate"
                            type="date"
                            min={dateUtils.getCurrentDate()}
                            max={deadlineDate}
                            onChange={(e) => {
                                setStartDate(e.target.value);
                            }}
                            />
                            <p className="text-danger">{!startDate ? startDateError : ""}</p>
                        </Col>

                        <Col sm="12" md="6" lg="6">
                            <span ref={deadlineDateHTMLRef}></span>
                            <Label className="form-label text-dark">{`${t(
                            "Deadline"
                            )}: `}</Label>
                            <Input
                            name="deadlineDate"
                            min={startDate}
                            type="date"
                            onChange={(e) => setDeadlineDate(e.target.value)}
                            disabled={startDate ? false : true}
                            />
                            <p className="text-danger">
                            {!deadlineDate ? deadlineDateError : ""}
                            </p>
                        </Col>
                    </Row>

                    <Row className="mb-3" style={{ zIndex: 8, position: "relative" }}>
                        <Col sm="12" md="6" lg="6">
                            <Label className="form-label text-dark">
                                {`${t(
                            "Task Manager"
                            )}: `}
                            </Label>
                            <Input name="manager"
                                type="text"
                                defaultValue={`${user?.first_name} ${user?.last_name}`}
                                readOnly
                                disabled 
                            />
                        </Col>

                        <Col sm="12" md="6" lg="6">
                            <span ref={ownerHTMLRef}></span>
                            <Label className="form-label text-dark">
                            {t("Task owner")}:{" "}
                            </Label>
                            {owners ? (
                            <>
                                <Select
                                required
                                name="owner"
                                classNamePrefix="select2-selection"
                                options={owners}
                                value={selectedOwner}
                                onChange={(e) => setSelectedOwner(e)}
                                placeholder={t("Select")}
                                />
                                <p className="text-danger">
                                {!selectedOwner ? ownerError : ""}
                                </p>
                            </>
                            ) : (
                            <div
                                className="dt-field dt-skeleton dt-select-list"
                                style={{ marginBottom: 16 }}
                            ></div>
                            )}
                        </Col>
                    </Row>

                    <Row className="mb-3" style={{ zIndex: 7, position: "relative" }}>
                        <Col sm="12" md="6" lg="6">
                            <Label className="form-label text-dark">{`${t(
                            "Status"
                            )}: `}</Label>
                            <Select
                            name="status"
                            value={selectedTaskStatus}
                            classNamePrefix="select2-selection"
                            options={[initialTaskStatus]}
                            placeholder={t("Select")}
                            readOnly
                            isDisabled
                            />
                        </Col>

                        <Col sm="12" md="6" lg="6">
                            <span ref={priorityHTMLRef}></span>
                            <Label className="form-label text-dark">{`${t(
                            "Priority"
                            )}: `}</Label>
                            <Select
                            required
                            name="priority"
                            classNamePrefix="select2-selection"
                            options={priorities}
                            value={selectedPriority}
                            onChange={(e) => {
                                setSelectedPriority(e);
                            }}
                            placeholder={t("Select")}
                            />
                            <p className="text-danger">
                            {!selectedPriority ? priorityError : ""}
                            </p>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col sm="12" md="12">
                            <span ref={descriptionHTMLRef}></span>
                            <Label className="form-label text-dark">{`${t(
                            "Description"
                            )}: `}</Label>
                            <Editor
                            editorState={description}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => setDescription(e)}
                            toolbar={{
                                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'],
                                inline: {
                                options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                                bold: { className: 'bordered-option-classname' },
                                italic: { className: 'bordered-option-classname' },
                                underline: { className: 'bordered-option-classname' },
                                strikethrough: { className: 'bordered-option-classname' },
                                code: { className: 'bordered-option-classname' },
                                },
                                blockType: {
                                className: 'bordered-option-classname',
                                },
                                fontSize: {
                                className: 'bordered-option-classname',
                                },
                            }}
                            />
                            <p className="text-danger">
                            {!description.getCurrentContent().hasText()
                                ? descriptionError
                                : ""}
                            </p>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col>
                            <Button
                            color="primary"
                            onClick={() => setShowAttachmentsList(!showAttachmentsList)}
                            outline
                            >
                            <CgLink />
                                {t("Attach")}
                            </Button>
                        </Col>
                    </Row>

                    <Row className="mb-3" hidden={!showAttachmentsList}>
                        <Col>
                            <FileUploader
                            uploadedFiles={attachmentsList}
                            handleAcceptedFiles={(e) =>
                                handleUploadAttachments(e, token)
                            }
                            showProg={showAttachmentsProgress}
                            handleClickDeleteFiles={(e) => handleRemoveAttachment(e.id)}
                            />
                        </Col>
                    </Row>

                    <br />

                    <Row className="mb-3">
                        <Col sm="12" md="12">
                            <Label className="form-label text-dark">{`${t(
                            "Comments"
                            )}: `}</Label>
                            <Editor
                            editorState={comment}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => setComment(e)}
                            />
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col>
                            <Button
                            color="primary"
                            onClick={() =>
                                setShowCommentAttachmentsList(!showCommentAttachmentsList)
                            }
                            outline
                            >
                            <CgLink />
                                {t("Attach")}
                            </Button>
                        </Col>
                    </Row>

                    <Row hidden={!showCommentAttachmentsList}>
                        <Col>
                            <FileUploader
                                uploadedFiles={commentAttachmentsList}
                                handleAcceptedFiles={(e) =>
                                    handleUploadCommentAttachments(e, token)
                                }
                                showProg={showCommentAttachmentsProgress}
                                handleClickDeleteFiles={(e) =>
                                    handleRemoveCommentAttachment(e.id)
                                }
                            />
                        </Col>
                    </Row>

                    <ModalFooter>
                        <Button color="primary"
                            className="waves-effect waves-light"
                            type="submit">
                            {showSubmitProgress ? <TbLoader /> : t("Save")}
                        </Button>
                    </ModalFooter>
                </AvForm>
            </ModalBody>
        </Modal>
    );
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    return {
        user            :   state.Login.user,
        token
    };
};

export default withNamespaces()(
    connect(
        mapStatetoProps,
        {}
    )(CreateTaskModal)
);