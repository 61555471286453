import { memo, useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
  Label,
  Button,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import {
  API_URL_CREATE_RISK,
  CM_API_URL_CREATE_RISK,
  API_URL_RISK_UPLOAD_FILE,
  CM_API_URL_RISK_UPLOAD_FILE,
  CREATE_RISK_SUCCESSFUL,
  CREATE_RISK_FAILED,
  UPLOAD_ATTACHMENT_FAILED,
} from "../../../../common/constants";
import { ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { CgLink } from "react-icons/cg";
import { TbLoader } from "react-icons/tb";
import { convertFromHTML, convertToHTML } from "draft-convert";
import Select from "react-select";
import axios from "axios";
import UploadedFiles from "../../CommonForTaskAndRisk/uploadFiles";
import AIBasedRiskAssessment from "./AIRiskAssessment";
import { IoIosArrowBack } from "react-icons/io";
// import AIRiskAssessment from "./AIRiskAssessment";

const CreateRiskModal = function (props) {
  CreateRiskModal.displayName = "Memorized Component - Create Risk Modal";
  if (process?.env?.NODE_ENV === "development")
    console.log("---- rendering memorized component >>> CreateRiskModal");

  const {
    t,
    close,
    errorNotification,
    successNotification,
    updateRisksList,
    isOpen,
    reportId,
    authToken,
    categories,
    owners,
    locations,
    damages,
    likelihoods,
    handleSetReportRiskField,
    module,
    aiBasedRiskAssessmentModalStatus,
    setAiBasedRiskAssessmentModalStatus,
    reportLocation,
    reportCategories,
    route,
    back
  } = props;

  const categoryHTMLRef = useRef(null);
  const otherCategoryHTMLRef = useRef(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryOtherString, setSelectedCategoryOtherString] =
    useState(null);
  const [categoryError, setCategoryError] = useState(null);
  const [categoryOtherError, setCategoryOtherError] = useState(null);

  const subCategoryHTMLRef = useRef(null);
  const otherSubCategoryHTMLRef = useRef(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedSubCategoryOtherString, setSelectedSubCategoryOtherString] =
    useState(null);
  const [subCategoryError, setSubCategoryError] = useState(null);
  const [subCategoryOtherError, setSubCategoryOtherError] = useState(null);

  const locationHTMLRef = useRef(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationError, setLocationError] = useState(null);

  const ownerHTMLRef = useRef(null);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [ownerError, setOwnerError] = useState(null);

  const damageHTMLRef = useRef(null);
  const [selectedDamage, setSelectedDamage] = useState(null);
  const [damageError, setDamageError] = useState(null);

  const likelihoodHTMLRef = useRef(null);
  const [selectedLikelihood, setSelectedLikelihood] = useState(null);
  const [likelihoodError, setLikelihoodError] = useState(null);

  const entetiesHTMLRef = useRef(null);
  const [selectedLegalEntity, setSelectedLegalEntity] = useState("");
  const [legalEntityError, setLegalEntityError] = useState(null);

  const descriptionHTMLRef = useRef(null);
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [descriptionError, setDescriptionError] = useState(null);

  const [showAttachmentsList, setShowAttachmentsList] = useState(false);
  const [showAttachmentsProgress, setShowAttachmentsProgress] = useState(false);
  const [showSubmitProgress, setShowSubmitProgress] = useState(false);
  const [attachmentsList, setAttachmentsList] = useState([]);

  const [showAIOverlay, setShowAIOverlay] = useState(false);

  /**
   * this method toggles modal status.
   */
  const handleToggleModal = () => {
    handleCloseModal();
  };

  /**
   * this method handles set new category.
   * @param {Object} category the new category.
   */
  const handleChangeCategory = (category) => {
    if (t(category.label.toLowerCase()) !== t("Other".toLowerCase())) {
      setSelectedCategoryOtherString("");
    }

    setSelectedCategory(category);

    if (category.subCategories.length === 1) {
      handleChangeSubCategory(category.subCategories[0]);
    } else {
      handleChangeSubCategory(null);
    }
  };

  /**
   * this method handles change sub category.
   * if current category is equal to other, subcategory will set to null, otherwise the sub category will set to incoming subcategory.
   * @param {String} subCategory sub category string.
   */
  const handleChangeSubCategory = (subCategory) => {
    if (
      subCategory &&
      t(subCategory.label.toLowerCase()) !== t("Other".toLowerCase())
    ) {
      setSelectedSubCategoryOtherString(null);
    }

    setSelectedSubCategory(subCategory);
  };

  /**
   * this method updates selected category.
   * @param {String} value selected category.
   */
  const handleChangeOtherCategoryString = (value) => {
    setSelectedCategoryOtherString(value);
  };

  /**
   * this method updates sub category.
   * @param {String} value sub category string
   */
  const handleChangeOtherSubCategoryString = (value) => {
    setSelectedSubCategoryOtherString(value);
  };

  /**
   * this method resets the form and calls the close prop.
   */
  const handleCloseModal = () => {
    setSelectedCategory(null);
    setSelectedCategoryOtherString(null);
    setCategoryError(null);
    setCategoryOtherError(null);
    setSelectedSubCategory(null);
    setSelectedSubCategoryOtherString(null);
    setSubCategoryError(null);
    setSubCategoryOtherError(null);
    setSelectedLocation(null);
    setLocationError(null);
    setSelectedOwner(null);
    setOwnerError(null);
    setSelectedDamage(null);
    setDamageError(null);
    setSelectedLikelihood(null);
    setLikelihoodError(null);
    setSelectedLegalEntity("");
    setLegalEntityError(null);
    setDescription(EditorState.createEmpty());
    setDescriptionError(null);
    setAttachmentsList([]);
    setShowAttachmentsList(false);
    setShowAttachmentsProgress(false);
    setShowSubmitProgress(false);

    close();
  };

  /**
   * this method uploads selected files to the server.
   * @param {Object[]} fs files.
   * @param {String} token authorization token.
   */
  const handleUploadAttachments = (fs, token) => {
    try {
      setShowAttachmentsProgress(true);
      const files = [];
      const formData = new FormData();

      for (const f in fs.target.files) {
        if (fs.target.files.hasOwnProperty(f)) {
          files.push(fs.target.files[f]);
        }
      }

      files.map(async (file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: file.size,
        });

        formData.append("file", file);

        const result = await axios.post(
          module === "wb"
            ? API_URL_RISK_UPLOAD_FILE
            : CM_API_URL_RISK_UPLOAD_FILE,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (result.status === 200) {
          const fileData = result.data.data;
          setAttachmentsList((oldArray) => [
            ...oldArray,
            {
              id: fileData.id,
              file: fileData,
              name: file.name,
              preview: file.preview,
              formattedSize: file.formattedSize,
            },
          ]);

          if (file === files[files.length - 1])
            setShowAttachmentsProgress(false);
        } else {
          setShowAttachmentsProgress(false);
          errorNotification({
            message: t(UPLOAD_ATTACHMENT_FAILED),
          });
        }
      });
    } catch (error) {
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while uploading attachments:",
          error
        );
      }

      errorNotification({
        message: t(UPLOAD_ATTACHMENT_FAILED),
      });
    }
  };

  /**
   * this method removes the selected file according to its ID from the uploaded files.
   * @param {String} id file id
   */
  const handleRemoveAttachment = (id) => {
    const index = attachmentsList.findIndex((i) => i.id === id);
    if (index > -1) {
      const oldArray = Array.from(attachmentsList);
      oldArray.splice(index, 1);
      setAttachmentsList(oldArray);
    }
  };

  /**
   * this method validates the create risk form.
   * @returns returns true if the validation result was currect, otherwise returns false.
   */
  const handleFormValidations = () => {
    let isValid = true;

    if (!selectedCategory) {
      isValid = false;
      setCategoryError("Please Select Category");
    }

    if (
      selectedCategory &&
      selectedCategory.baseLabel === "Other" &&
      !selectedCategoryOtherString
    ) {
      isValid = false;
      setCategoryOtherError("Please Select Other Category");
    }

    if (!selectedSubCategory) {
      isValid = false;
      setSubCategoryError("Please Select Subcategory");
    }

    if (
      selectedSubCategory &&
      selectedSubCategory.baseLabel === "Other" &&
      !selectedSubCategoryOtherString
    ) {
      isValid = false;
      setSubCategoryOtherError("Please Select Other Subcategory");
    }

    if (!selectedLocation) {
      isValid = false;
      setLocationError("Please Select Location");
    }

    if (!selectedOwner) {
      isValid = false;
      setOwnerError("Please Select Owner");
    }

    if (!selectedDamage) {
      isValid = false;
      setDamageError("Please Select Damage");
    }

    if (!selectedLikelihood) {
      isValid = false;
      setLikelihoodError("Please Select Likelihood");
    }

    if (!selectedLegalEntity) {
      isValid = false;
      setLegalEntityError("Please Select Legal Entity");
    }

    if (!description.getCurrentContent().hasText()) {
      isValid = false;
      setDescriptionError(t("Please type description"));
    }

    if (!isValid) categoryHTMLRef.current.scrollIntoView();
    return isValid;
  };

  /**
   * this method submits create risk request to the server.
   * @param {String} caseId the ID of the case you want to create risk for.
   * @param {String} token authorization token.
   */
  const handleSubmit = async (caseId, token) => {
    setShowSubmitProgress(true);

    try {
      if (handleFormValidations()) {
        const data = {
          reportCase: caseId,
          description: convertToHTML(description.getCurrentContent()),
          category:
            t(selectedCategory.label.toLowerCase()) === t("Other".toLowerCase())
              ? null
              : t(selectedSubCategory.label.toLowerCase()) ===
                t("Other".toLowerCase())
              ? selectedCategory.value
              : selectedSubCategory.value,
          otherCategory: selectedCategoryOtherString
            ? selectedCategoryOtherString
            : null,
          otherSubCategory: selectedSubCategoryOtherString
            ? selectedSubCategoryOtherString
            : null,
          location: selectedLocation,
          involvedEntities: selectedLegalEntity,
          damage: selectedDamage,
          likelihood: selectedLikelihood,
          descriptionAttachments: attachmentsList.map((f) => String(f.file.id)),
          owner: selectedOwner ? selectedOwner : null,
        };

        const result = await axios.post(
          module === "wb" ? API_URL_CREATE_RISK : CM_API_URL_CREATE_RISK,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (result.status === 200) {
          successNotification({
            message: t(CREATE_RISK_SUCCESSFUL),
          });
          updateRisksList();
          handleSetReportRiskField(result.data.data.reportRiskRate);
          handleCloseModal();
        } else {
          errorNotification({
            message: t(CREATE_RISK_FAILED),
          });
        }
      }
    } catch (error) {
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while creating risk:",
          error
        );
      }

      errorNotification({
        message: t(CREATE_RISK_FAILED),
      });
    }

    setShowSubmitProgress(false);
  };

  useEffect(() => {
    if (isOpen) {
      if (aiBasedRiskAssessmentModalStatus) {
        setShowAIOverlay(true);
      } else {
        setTimeout(() => {
          setShowAIOverlay(false);
        }, 300);
      }
    } else {
      setShowAIOverlay(false);
    }
  }, [isOpen, aiBasedRiskAssessmentModalStatus]);

  const getOtherCategory = () => {
    const otherCategory = categories.find((c) => c.baseLabel === "Other");
    if (otherCategory) {
      return {
        value: otherCategory.value,
        baseLabel: otherCategory.baseLabel,
        label: t(otherCategory.baseLabel),
        subCategories: otherCategory.subCategories,
      };
    }

    return null;
  };

  const getOtherSubCategory = () => {
    const otherCategory = getOtherCategory();
    if (otherCategory) {
      return otherCategory.subCategories[0];
    }

    return null;
  };

  const createRiskFromAIResult = (category, description, mitigationTasks) => {
    setSelectedCategory(getOtherCategory());
    setSelectedSubCategory(getOtherSubCategory());

    setSelectedCategoryOtherString(category);

    const state = ContentState.createFromText(
      `${description}\n\nMitigation tasks:\n- ${mitigationTasks.join("\n- ")}`
    );
    setDescription(EditorState.createWithContent(state));

    setAiBasedRiskAssessmentModalStatus(false);
    setTimeout(() => {
      setShowAIOverlay(false);
    }, 300);
  };

  return route === "riskAi" ? (
    <>
      {/* arrow */}
      <span
        style={{ cursor: "pointer" }}
        color="bg-secondary"
        onClick={() => {
          back();
        }}
      >
        <span className="h3">
          <IoIosArrowBack />
        </span>
      </span>

      {/* title */}
      <span
        style={{
          display: "inline-block",
          position: "relative",
          top: "-0.1rem",
          cursor: "pointer",
        }}
        onClick={() => {
          back();
        }}
      >
        {t("Back")}
        {/* {`${props.secondaryId}/R${risk.secondary_id}`} */}
      </span>

      <AIBasedRiskAssessment
        t={props.t}
        isOpen={aiBasedRiskAssessmentModalStatus}
        successNotification={props.successNotification}
        errorNotification={props.errorNotification}
        categories={categories}
        locations={locations}
        createRiskFromAIResult={createRiskFromAIResult}
        reportLocation={reportLocation}
        reportCategories={reportCategories}
        token={authToken}
        reportId={reportId}
        back={back}
      />
    </>
  ) : (
    <Modal
      isOpen={isOpen}
      size="lg"
      toggle={handleToggleModal}
      backdrop="static"
      scrollable={true}
      className="create-risk-modal"
    >
      <ModalHeader toggle={handleToggleModal}>{t("Create a risk")}</ModalHeader>
      <ModalBody className="create-risk-modal-body">
        {showAIOverlay ? (
          <div
            className="ai-overlay"
            style={{ opacity: aiBasedRiskAssessmentModalStatus ? "1" : "0" }}
          >
            {/* ai-based risk assessment modal */}
            <AIBasedRiskAssessment
              t={props.t}
              isOpen={aiBasedRiskAssessmentModalStatus}
              successNotification={props.successNotification}
              errorNotification={props.errorNotification}
              categories={categories}
              locations={locations}
              createRiskFromAIResult={createRiskFromAIResult}
              reportLocation={reportLocation}
              reportCategories={reportCategories}
              token={authToken}
              reportId={reportId}
            />
          </div>
        ) : null}
        <AvForm
          style={{
            opacity: aiBasedRiskAssessmentModalStatus ? "0" : "1",
            transition: "all ease 0.3s",
          }}
          className="needs-validation"
          onValidSubmit={() => handleSubmit(reportId, authToken)}
        >
          <Row className="mb-3">
            {/* category */}
            <Col
              sm="12"
              md={
                selectedCategory && selectedCategory.baseLabel === "Other"
                  ? 3
                  : 6
              }
              lg={
                selectedCategory && selectedCategory.baseLabel === "Other"
                  ? 3
                  : 6
              }
            >
              <span ref={categoryHTMLRef}></span>
              <Label className="form-label text-dark">{`${t(
                "Risk category"
              )}: `}</Label>

              {categories ? (
                <>
                  <Select
                    name="category"
                    classNamePrefix="select2-selection"
                    options={categories.map((c) => {
                      return {
                        value: c.value,
                        baseLabel: c.baseLabel,
                        label: t(c.baseLabel),
                        subCategories: c.subCategories,
                      };
                    })}
                    value={selectedCategory}
                    onChange={handleChangeCategory}
                    placeholder={t("Select...")}
                  />
                  <p className="text-danger">
                    {!selectedCategory ? t(categoryError) : ""}
                  </p>
                </>
              ) : (
                <div
                  className="dt-field dt-skeleton dt-select-list"
                  style={{ marginBottom: 16 }}
                ></div>
              )}
            </Col>

            {/* other category */}
            <Col
              sm="12"
              md={3}
              lg={3}
              hidden={
                !selectedCategory ||
                (selectedCategory && selectedCategory.baseLabel !== "Other")
              }
            >
              <span ref={otherCategoryHTMLRef}></span>
              <Label className="form-label text-dark">{`${t(
                "Other category"
              )}: `}</Label>
              <AvField
                errorMessage={t("This field cannot be blank")}
                className="form-control"
                name="otherCat"
                type="text"
                value={selectedCategoryOtherString}
                hidden={
                  selectedCategory && selectedCategory.baseLabel !== "Other"
                }
                onChange={(e) =>
                  handleChangeOtherCategoryString(e.target.value)
                }
              />
              <p className="text-danger">
                {!selectedCategoryOtherString ? t(categoryOtherError) : ""}
              </p>
            </Col>

            {/* sub category */}
            <Col
              sm="12"
              md={
                selectedSubCategory && selectedSubCategory.baseLabel === "Other"
                  ? 3
                  : 6
              }
              lg={
                selectedSubCategory && selectedSubCategory.baseLabel === "Other"
                  ? 3
                  : 6
              }
            >
              <span ref={subCategoryHTMLRef}></span>
              <Label className="form-label text-dark">{`${t(
                "Risk subcategory"
              )}: `}</Label>

              {categories ? (
                <>
                  <Select
                    name="categorsubcategory"
                    classNamePrefix="select2-selection"
                    placeholder={t("Select...")}
                    value={selectedSubCategory}
                    options={
                      selectedCategory
                        ? selectedCategory.subCategories.map((c) => {
                            return {
                              value: c.value,
                              baseLabel: c.baseLabel,
                              label: t(c.baseLabel),
                            };
                          })
                        : []
                    }
                    isDisabled={selectedCategory ? false : true}
                    onChange={handleChangeSubCategory}
                  />
                  <p className="text-danger">
                    {!selectedSubCategory ? t(subCategoryError) : ""}
                  </p>
                </>
              ) : (
                <div
                  className="dt-field dt-skeleton dt-select-list"
                  style={{ marginBottom: 16 }}
                ></div>
              )}
            </Col>

            {/* other subcategory */}
            <Col
              sm="12"
              md={3}
              lg={3}
              hidden={
                !selectedSubCategory ||
                selectedSubCategory.baseLabel !== "Other"
              }
            >
              <span ref={otherSubCategoryHTMLRef}></span>
              <Label className="form-label text-dark">{`${t(
                "Other subcategory"
              )}: `}</Label>
              <AvField
                errorMessage={t("This field cannot be blank")}
                className="form-control"
                name="otherCat"
                type="text"
                onChange={(e) =>
                  handleChangeOtherSubCategoryString(e.target.value)
                }
              />
              <p className="text-danger">
                {!selectedSubCategoryOtherString
                  ? t(subCategoryOtherError)
                  : ""}
              </p>
            </Col>
          </Row>

          <Row className="mb-3">
            {/* locations */}
            <Col sm="12" md="6" lg="6">
              <span ref={locationHTMLRef}></span>
              <Label className="form-label text-dark">{`${t(
                "Location of risk "
              )}: `}</Label>
              {locations ? (
                <>
                  <Select
                    name="location"
                    classNamePrefix="select2-selection"
                    options={locations.map((l) => {
                      return {
                        value: l.value,
                        baseLabel: l.label,
                        label: t(l.label),
                      };
                    })}
                    placeholder={t("Select...")}
                    onChange={(e) => {
                      setSelectedLocation(e.value);
                    }}
                  />
                  <p className="text-danger">
                    {!selectedLocation ? t(locationError) : ""}
                  </p>
                </>
              ) : (
                <div
                  className="dt-field dt-skeleton dt-select-list"
                  style={{ marginBottom: 16 }}
                ></div>
              )}
            </Col>

            {/* owners */}
            <Col sm="12" md="6" lg="6">
              <span ref={ownerHTMLRef}></span>
              <Label className="form-label text-dark">{`${t(
                "Risk owner"
              )}: `}</Label>
              {owners ? (
                <>
                  <Select
                    name="owner"
                    classNamePrefix="select2-selection"
                    options={owners}
                    onChange={(e) => {
                      setSelectedOwner(e.value);
                    }}
                    placeholder={t("Select...")}
                  />
                  <p className="text-danger">
                    {!selectedOwner ? t(ownerError) : ""}
                  </p>
                </>
              ) : (
                <div
                  className="dt-field dt-skeleton dt-select-list"
                  style={{ marginBottom: 16 }}
                ></div>
              )}
            </Col>
          </Row>

          <Row className="mb-3">
            {/* damages */}
            <Col sm="12" md="6" lg="6">
              <span ref={damageHTMLRef}></span>
              <Label className="form-label text-dark">{`${t(
                "Damage"
              )}: `}</Label>
              {damages ? (
                <>
                  <Select
                    name="damage"
                    classNamePrefix="select2-selection"
                    options={damages}
                    onChange={(e) => {
                      setSelectedDamage(e.value);
                    }}
                    placeholder={t("Select...")}
                  />
                  <p className="text-danger">
                    {!selectedDamage ? t(damageError) : ""}
                  </p>
                </>
              ) : (
                <div
                  className="dt-field dt-skeleton dt-select-list"
                  style={{ marginBottom: 16 }}
                ></div>
              )}
            </Col>

            {/* likelihoods */}
            <Col sm="12" md="6" lg="6">
              <span ref={likelihoodHTMLRef}></span>
              <Label className="form-label text-dark">{`${t(
                "Likelihood"
              )}: `}</Label>
              {likelihoods ? (
                <>
                  <Select
                    name="likelihood"
                    classNamePrefix="select2-selection"
                    options={likelihoods.map((l) => {
                      return {
                        value: l.value,
                        label: t(l.label),
                      };
                    })}
                    placeholder={t("Select...")}
                    onChange={(e) => {
                      setSelectedLikelihood(e.value);
                    }}
                  />
                  <p className="text-danger">
                    {!selectedLikelihood ? t(likelihoodError) : ""}
                  </p>
                </>
              ) : (
                <div
                  className="dt-field dt-skeleton dt-select-list"
                  style={{ marginBottom: 16 }}
                ></div>
              )}
            </Col>
          </Row>

          <Row>
            {/* involved entities */}
            <Col sm="12" md="6" lg="6">
              <span ref={entetiesHTMLRef}></span>
              <Label className="form-label text-dark">{`${t(
                "Involved entities"
              )}: `}</Label>
              <AvField
                errorMessage={t("This field cannot be blank")}
                className="form-control"
                name="legalEntities"
                type="text"
                onChange={(e) => setSelectedLegalEntity(e.target.value)}
              />
              <p className="text-danger">
                {!selectedLegalEntity ? t(legalEntityError) : ""}
              </p>
            </Col>
          </Row>

          <Row>
            {/* description */}
            <Col sm="12" md="12" lg="12">
              <span ref={descriptionHTMLRef}></span>
              <Label className="form-label text-dark">{`${t(
                "Description"
              )}: `}</Label>

              <Editor
                editorState={description}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => setDescription(e)}
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "list",
                    "textAlign",
                    "colorPicker",
                    "link",
                    "remove",
                    "history",
                  ],
                  inline: {
                    options: [
                      "bold",
                      "italic",
                      "underline",
                      "strikethrough",
                      "monospace",
                    ],
                    bold: { className: "bordered-option-classname" },
                    italic: { className: "bordered-option-classname" },
                    underline: { className: "bordered-option-classname" },
                    strikethrough: { className: "bordered-option-classname" },
                    code: { className: "bordered-option-classname" },
                  },
                  blockType: {
                    className: "bordered-option-classname",
                  },
                  fontSize: {
                    className: "bordered-option-classname",
                  },
                }}
              />
              <p className="text-danger">
                {descriptionError ? descriptionError : ""}
              </p>
            </Col>
          </Row>

          <Row className="mb-3">
            {/* show attachments button */}
            <Col>
              <Button
                color="primary"
                onClick={() => setShowAttachmentsList(!showAttachmentsList)}
                outline
              >
                <CgLink />
                {t("Attach")}
              </Button>
            </Col>
          </Row>

          <Row hidden={!showAttachmentsList}>
            <Col>
              <UploadedFiles
                t={t}
                uploadedFiles={attachmentsList}
                handleAcceptedFiles={(e) =>
                  handleUploadAttachments(e, authToken)
                }
                showProg={showAttachmentsProgress}
                handleClickDeleteFiles={(e) => handleRemoveAttachment(e.id)}
              />
            </Col>
          </Row>
          <ModalFooter>
            <Button
              color="primary"
              className="waves-effect waves-light"
              type="submit"
            >
              {showSubmitProgress ? <TbLoader /> : t("Save")}
            </Button>
          </ModalFooter>
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

export default memo(CreateRiskModal);
