import { AvField, AvForm } from "availity-reactstrap-validation";
import { withNamespaces } from "react-i18next";
import { Card, CardBody, Col, Input, Label, Row, Button } from "reactstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Accordion } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import AssetDeatilsImage from "src/modules/data-protection/assets/images/basic-info-gdpr.svg";
import { IoMdAttach } from "react-icons/io";
import { useMutation, useQuery } from "@tanstack/react-query";
import DataProtectionSoftwareService from "src/modules/data-protection/apis/DataProtectionSoftwareService";
import debounce from "lodash/debounce";
import { FaFile } from "react-icons/fa";
import DragandDrop from "src/modules/data-protection/assets/images/drag-drop-line.svg";
import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";
import DragDropArea from "src/modules/data-protection/components/MessageBox/components/DragDropArea";
import { API_BASE_URL } from "../../../../../constants";
import axios from "axios";
import { toast } from "react-toastify";
import AttachmentCard from "src/modules/data-protection/components/MessageBox/components/AttachmentCard";

const SecondRow = ({
  // General
  t,
  assetId,
  handleFetchSoftwareDetailsQuery,
  // Provider
  selectedProvider,
  setSelectedProvider,
  // Asset Type
  selectedAssetType,
  setSelectedAssetType,
  // Version
  version,
  // Service
  service,
  // Modules
  selectedModules,
  // Responsible Person
  users,
  // Attachments
  attachments,
  // Company
  selectedCompany,
  // Position
  selectedPosition,
  // Phone Number
  selectedPhoneNumber,
  // Email
  selectedEmail,
  // Department
  selectedDepartment,
  // Responsible Person
  selectedResponsiblePerson
}) => {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [providers, setProviders] = useState([]);
  const [assetTypes, setAssetTypes] = useState([]);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [dragFiles, setDragFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  // Attachments
  const onDrop = useCallback((acceptedFiles) => {
    setDragFiles(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });

  const onUploadProgress = (e, file, setUploadedFiles) => {
    const { total, loaded, lengthComputable } = e;

    if (lengthComputable) {
      const uploadedPercent = (loaded / total) * 100;

      setUploadedFiles((uploadedFiles) => {
        const index = uploadedFiles.findIndex(
          (f) => f.preview === file.preview
        );

        if (index > -1) {
          uploadedFiles[index].uploadedPercent = uploadedPercent;

          return [...uploadedFiles];
        }

        return [...uploadedFiles];
      });
    }
  };

  const handleAcceptedFiles = (files, id) => {
    const headers = {
      headers: {},
      onUploadProgress: null,
    };
    const userToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
    if (userToken) {
      headers.headers.Authorization = `Bearer ${userToken}`;
    }

    files.map((file) => {
      const formData = new FormData();

      formData.append("file", file);

      file = {
        ...file,
        id: null,
        status: "uploading",
        name: file.name,
        type: file.type,
        questionId: id,
        uploadedPercent: 0,
        preview: URL.createObjectURL(file),
        formattedSize: file.size,
      };

      setUploadedFiles((uploadedFiles) => {
        return [...uploadedFiles, { ...file }];
      });

      axios
        .post(`${API_BASE_URL}/software/${assetId}/add_attachment`, formData, {
          ...headers,
          onUploadProgress: (e) => {
            onUploadProgress(e, file, setUploadedFiles);
          },
        })
        .then((response) => {
          setUploadedFiles((uploadedFiles) => {
            const index = uploadedFiles.findIndex(
              (f) => f.preview === file.preview
            );
            handleFetchSoftwareDetailsQuery();
            if (index > -1) {
              if (response.status === 200) {
                const fileData = response.data.data;

                uploadedFiles[index].status = "upload_succeed";
                if(index === files.length - 1) {
                  toast(t("Files are uploaded successfully"))
                }

                uploadedFiles[index].id = fileData.id;
              } else {
                uploadedFiles[index].status = "upload_failed";
              }

              return [...uploadedFiles];
            }
            
            return [...uploadedFiles];
          });
        })
        .catch((error) => {
          toast(t("Error while uploading files"))
          setUploadedFiles((uploadedFiles) => {
            const index = uploadedFiles.findIndex(
              (f) => f.preview === file.preview
            );

            if (index > -1) {
              uploadedFiles[index].status = "upload_failed";

              return [...uploadedFiles];
            }

            return [...uploadedFiles];
          });

          toast(t(error?.response?.data?.message), {
            type: "error",
          });
        });
        
    });
  };

//   const handleDownloadFile = async (attachmentId, fileName) => {
//     axios
//       .post(
//         `${API_BASE_URL}/supplier/attachment/${attachmentId}/download`,
//         null,
//         {
//           responseType: "blob",
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       )
//       .then((blob) => {
//         const url = window.URL.createObjectURL(
//           new Blob([blob.data], { type: "*" })
//         );
//         const link = document.createElement("a");

//         link.href = url;
//         link.setAttribute("download", `${fileName.split(".")[0]}.zip`);

//         document.body.appendChild(link);

//         link.click();
//         link.remove();
//       })
//       .catch(() => {
//         toast(t("An error occurred while downloading the file."), {
//           type: "error",
//         });
//       });
//   };

  const handleClickDeleteFiles = (file) => {
    const url = `${API_BASE_URL}/software/delete_attachment/${file.id}`;

    const headers = {
      headers: {},
    };

    const userToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
    if (userToken) {
      headers.headers.Authorization = `Bearer ${userToken}`;
    }

    // const deletedFile = {
    //   file: file.name,
    //   report_case_id: file.id,
    // };

    setUploadedFiles((uploadedFiles) => {
      const index = uploadedFiles.findIndex((f) => f.id === file.id);

      if (index > -1) {
        uploadedFiles[index].status = "deleting";

        return [...uploadedFiles];
      }

      return [...uploadedFiles];
    });

    axios
      .delete(url, headers)
      .then(() => {
        handleFetchSoftwareDetailsQuery();
        setUploadedFiles([
          ...uploadedFiles.filter((f) => {
            return f.id !== file.id;
          }),
        ]);
        toast("File is removed successfully")
      })
      .catch((error) => {

        setUploadedFiles([
          ...uploadedFiles.filter((f) => {
            return f.id !== file.id;
          }),
        ]);
        toast(t("Error while removing the file"), {
          type: "error",
        });
      });
  };


  // Modules
  // Fetch
  const handelFetchModulesListQuery = useQuery({
    queryKey: ["data-protection-modules-list", moduleOptions],
    queryFn: async () => {
      const service = DataProtectionSoftwareService.getInstance();

      const response = await service.modules();
      const data =
        response && response.length
          ? response.map((r) => ({
              label: r.name,
              value: r.id,
            }))
          : [];
      setModuleOptions(data);
      return data;
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(t("An error occurred while fetching asset types."), {
        type: "error",
      });
    },
  });

  // Add to mdules
  const handleCreateModuleMutation = useMutation({
    mutationFn: async (payload) => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.addModule(payload);
    },
    onSuccess: () => {
      handleFetchSoftwareDetailsQuery();
      handelFetchModulesListQuery.refetch();
      toast(t("New module added successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while adding new module."), {
        type: "error",
      });
    },
    // onSettled: () => {
    //     setCreationFormInProcess(false);
    // }
  });

  const onCreateModuleOption = (label) => {
    handleCreateModuleMutation.mutate({
      software: Number(assetId),
      module: label,
    });
  };
  // Remove Module
  const handleRemoveModuleMutation = useMutation({
    mutationFn: async (payload) => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.removeModule(payload);
    },
    onSuccess: () => {
      handleFetchSoftwareDetailsQuery();
      handelFetchModulesListQuery.refetch();
      toast(t("New module removed successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while removing new module."), {
        type: "error",
      });
    },
  });

  const handleModuleChange = (selectedOptions, actionMeta) => {
    if (actionMeta.action === "remove-value") {
      const { removedValue } = actionMeta;
      handleRemoveModuleMutation.mutate({
        software: Number(assetId),
        module: Number(removedValue?.value),
      });
    } else {
      const { option } = actionMeta;
      handleCreateModuleMutation.mutate({
        software: Number(assetId),
        module: option?.label,
      });
    }
  };

  // Department
  const handleCreateDepartmentMutation = useMutation({
    mutationFn: async (payload) => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.changeDepartment(payload);
    },
    onSuccess: () => {
      handleFetchSoftwareDetailsQuery();
      toast(t("Department changed successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while changing department."), {
        type: "error",
      });
    },
  });

  const debouncedHandleDepartmentChange = debounce((event) => {
    const newDepartment = event.target.value;
    handleCreateDepartmentMutation.mutate({
      software: Number(assetId),
      department: newDepartment,
    });
  }, 500);

  // Phone Number
  const handleCreatePhoneNumberMutation = useMutation({
    mutationFn: async (payload) => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.changePhoneNumber(payload);
    },
    onSuccess: () => {
      handleFetchSoftwareDetailsQuery();
      toast(t("Phone number changed successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while changing phone number."), {
        type: "error",
      });
    },
  });

  const debouncedHandlePhoneNumberChange = debounce((event) => {
    const newData = event.target.value;
    handleCreatePhoneNumberMutation.mutate({
      software: Number(assetId),
      phoneNumber: newData,
    });
  }, 500);

  // Email
  const handleCreateEmailMutation = useMutation({
    mutationFn: async (payload) => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.changeEmail(payload);
    },
    onSuccess: () => {
      handleFetchSoftwareDetailsQuery();
      toast(t("Email changed successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while changing email."), {
        type: "error",
      });
    },
  });

  const debouncedHandleEmailChange = debounce((event) => {
    const newData = event.target.value;
    handleCreateEmailMutation.mutate({
      software: Number(assetId),
      email: newData,
    });
  }, 500);

  // Responsible Person
  const handleCreateResponsiblePersonMutation = useMutation({
    mutationFn: async (payload) => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.changeResponsiblePerson(payload);
    },
    onSuccess: () => {
      handleFetchSoftwareDetailsQuery();
      toast(t("Responsible person changed successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while changing responsible person."), {
        type: "error",
      });
    },
  });

  const debouncedHandleResponsiblePersonChange = debounce((event) => {
    handleCreateResponsiblePersonMutation.mutate({
      software: Number(assetId),
      user: String(event.value),
    });
  }, 500);

  // Company
  const handleCompanyMutation = useMutation({
    mutationFn: async (payload) => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.changeCompany(payload);
    },
    onSuccess: () => {
      handleFetchSoftwareDetailsQuery();
      toast(t("Company changed successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while changing company."), {
        type: "error",
      });
    },
  });

  const debouncedHandleCompanyChange = debounce((event) => {
    const newData = event.target.value;
    handleCompanyMutation.mutate({
      software: Number(assetId),
      company: newData,
    });
  }, 500);

  // Position
  const handlePositionMutation = useMutation({
    mutationFn: async (payload) => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.changePosition(payload);
    },
    onSuccess: () => {
      handleFetchSoftwareDetailsQuery();
      toast(t("Position changed successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while changing position."), {
        type: "error",
      });
    },
  });

  const debouncedHandlePositionChange = debounce((event) => {
    const newData = event.target.value;
    handlePositionMutation.mutate({
      software: Number(assetId),
      position: newData,
    });
  }, 500);

  // useEffect(() => {
  //   const { data } = handleFetchProvidersListQuery;

  //   if (data && data.providers) {
  //     setProviders(
  //       data.providers.map((provider) => {
  //         return {
  //           value: provider.id,
  //           label: provider.name,
  //         };
  //       })
  //     );
  //   }
  // }, [handleFetchProvidersListQuery.data]);

  useEffect(() => {
    if (handelFetchModulesListQuery.data) {
      const gotModules = handelFetchModulesListQuery.data;
      if (gotModules && Array.isArray(gotModules)) {
        setAssetTypes(
          gotModules.map((gotModule) => {
            return {
              label: gotModule,
              value: gotModule,
            };
          })
        );
      }
    }
  }, [handelFetchModulesListQuery.data]);

  return (
    <Accordion className="mt-4" defaultActiveKey={activeAccordion}>
      <Accordion.Item
        onClick={() => setActiveAccordion("asset-details")}
        className={activeAccordion === "asset-details" ? "active" : ""}
        eventKey={"asset-details"}
      >
        <Accordion.Header>
          <img src={AssetDeatilsImage} alt="" />
          <span>{t("Asset Details")}</span>
        </Accordion.Header>

        <Accordion.Body>
          <Row className="mb-2">
            <Row>
              {/* Software Name */}
              <Col sm="12" md={3}>
                <Label className="form-label">{t("Software Name")}:</Label>
                <Input
                  name="softwareName"
                  type="text"
                  className="form-control disabled"
                  value={service}
                  id="softwareName"
                  disabled
                />
              </Col>

              {/* Service Provider */}
              <Col sm="12" md={3}>
                <Label className="form-label">{t("Service Provider")}:</Label>
                <Input
                  name="serviceProvider"
                  type="text"
                  className="form-control disabled"
                  value={t(selectedProvider?.label)}
                  id="serviceProvider"
                  disabled
                />
              </Col>

              {/* Version */}
              <Col sm="12" md={3}>
                <Label className="form-label">{t("Version")}:</Label>
                <Input
                  name="version"
                  type="text"
                  className="form-control disabled"
                  value={t(version)}
                  id="version"
                  disabled
                />
              </Col>

              {/* Type */}
              <Col sm="12" md={3}>
                <Label className="form-label">{t("Type")}:</Label>
                <Input
                  name="version"
                  type="text"
                  className="form-control disabled"
                  value={t(selectedAssetType?.label)}
                  id="version"
                  disabled
                />
              </Col>
            </Row>

            <Row className="mt-3 mb-2">
              <Col sm="12" md={3}>
                <Label className="form-label">{t("Software module")}:</Label>
                <CreatableSelect
                  className="mt-1 text-capitalize"
                  placeholder={t("Select") + "..."}
                  options={moduleOptions}
                  menuPortalTarget={document.body}
                  isMulti
                  onCreateOption={onCreateModuleOption}
                  onChange={handleModuleChange}
                  value={selectedModules}
                />
              </Col>
            </Row>
          </Row>

          {/* <Row className="mb-2">
            <Col sm="12" md="10">
              <Label className="form-label">{t("Privacy Statement")}:</Label>
              <Input placeholder={t("Add URL")} />
            </Col>
            <Col sm="12" md="2">
              <p className="pt-5">
                {t("Or Attach a File")}:
                {
                  <Label className="form-label">
                    <IoMdAttach />
                  </Label>
                }
              </p>
            </Col>
          </Row> */}

          {/* <div className="d-flex gap-4 align-items-start">
            <Dropzone
              onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
            >
              {({ getRootProps, getInputProps }) => (
                <>
                <div {...getRootProps()} className="dropzone tpdd">
                  
                  <input {...getInputProps()} />
                  <Button
                    style={{
                      background: "#fff",
                      border: "none",
                      fontSize: "20px",
                      paddingRight: 0,
                    }}
                    color="#505D69"
                  >
                    {t("Attach your files")}
                    <i className="ri-attachment-line ms-2 pt-1"></i>
                  </Button>
                  
                </div>
                
                </>
              )}
            </Dropzone>
          </div>

          {attachments.length > 0 ? (
              <Col xs={12} className="p-3">
                <div className="d-flex flex-wrap gap-2">
                  {attachments.map((file, index) => (
                    <AttachmentCard
                      key={index}
                      attachment={file}
                      handleClickDeleteFile={handleClickDeleteFiles}
                      // downloadUrl={downloadUrl}
                      t={t}
                    />
                  ))}
                </div>
              </Col>
            ) : null} */}

          <div className="mb-4">
              <Label>{t("Attach your files") + ":"}</Label>
              <Dropzone
                onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="dropzone tpdd">
                    <input {...getInputProps()} />
                    <Button
                      style={{
                        background: "#fff",
                        border: "none",
                        fontSize: "20px",
                        paddingRight: 0,
                      }}
                      color="#505D69"
                    >
                      <i className="ri-attachment-line me-4"></i>
                    </Button>
                  </div>
                )}
              </Dropzone>

              {uploadedFiles.length > 0 ? (
                <Col xs={12} className="p-3">
                  <div className="d-flex flex-wrap gap-2">
                    {uploadedFiles.map((file, index) => (
                      <AttachmentCard
                        key={index}
                        attachment={file}
                        handleClickDeleteFile={handleClickDeleteFiles}
                        //   downloadUrl={downloadUrl}
                        t={t}
                      />
                    ))}
                  </div>
                </Col>
              ) : null}
            </div>


          {/* <DragDropArea isDragActive={isDragActive} t={t} /> */}

          {/* <div className="drag-main-div">
            <div className="drag-item-div">
              <img
                src={DragandDrop}
                style={{ maxWidth: "50px", maxHeight: "50px" }}
                alt="Drag-Drop"
              />
              <p>{t("Drag and drop files here to upload")}</p>
            </div>
          </div> */}

          {/* <Card
            className="mb-0 p-0 shadow-none dz-processing dz-image-preview dz-success dz-complete"
            key={index + "-file"}
            style={{
              color: isSender ? "#74788d" : "#fff",
              background: isSender ? "#eff2f7" : "#5664d2",
            }}
            style={{
              color: "#74788d",
              background: "#eff2f7",
            }}
          >
            <div className="px-2">
              <Row className="align-items-center">
                <Col className="col-auto px-1">
                  <FaFile size={32} />
                </Col>
                <Col>
                  <span
                    onClick={() =>
                      handleDownloadFile(attachment.id, attachment.name)
                    }
                    className="file-name"
                  >
                    {"attachment.name"}
                  </span>
                  <p className="upload-details-container">
                    <span className="file-size">
                      {(attachment.size / 1024).toFixed(2)} KB
                      {(1024).toFixed(2)} KB
                    </span>
                    <span className="upload-status-message">
                      {t("File(s) successfully uploaded.")}
                    </span>
                  </p>
                </Col>
              </Row>
            </div>
          </Card> */}

          <Row className="mb-2">
            <Row>
              {/* Department */}
              <Col sm="12" md={3}>
                <Label className="form-label">{t("Department")}:</Label>
                <Input
                  name="department"
                  type="text"
                  className="form-control"
                  onChange={debouncedHandleDepartmentChange}
                  id="department"
                  defaultValue={selectedDepartment}
                />
              </Col>

              {/* Responsible Person */}
              <Col sm="12" md={3}>
                <Label className="form-label">{t("Responsible Person")}:</Label>
                <Select
                  className="mt-1 text-capitalize"
                  placeholder={t("Select") + "..."}
                  menuPortalTarget={document.body}
                  id="assignedAnalyst"
                  name="assignedAnalyst"
                  isError={handleFetchSoftwareDetailsQuery?.isError}
                  value={selectedResponsiblePerson}
                  // isLoading={
                  //   handleFetchSoftwareDetailsQuery?.isLoading ||
                  //   handleFetchSoftwareDetailsQuery?.isFetching ||
                  //   updatingSelectedAssignedAnalyst
                  // }
                  // isDisabled={
                  //   handleFetchSoftwareDetailsQuery?.isLoading ||
                  //   handleFetchSoftwareDetailsQuery?.isFetching ||
                  //   updatingSelectedAssignedAnalyst
                  // }
                  options={users}
                  // value={selectedAssignedAnalyst}
                  onChange={debouncedHandleResponsiblePersonChange}
                />
              </Col>

              {/* Company */}
              <Col sm="12" md={3}>
                <Label className="form-label">{t("Company")}:</Label>
                <Input
                  name="company"
                  type="text"
                  className="form-control"
                  onChange={debouncedHandleCompanyChange}
                  id="company"
                  defaultValue={selectedCompany}
                />
              </Col>

              {/* Position */}
              <Col sm="12" md={3}>
                <Label className="form-label">{t("Position")}:</Label>
                <Input
                  name="position"
                  type="text"
                  className="form-control"
                  onChange={debouncedHandlePositionChange}
                  id="position"
                  defaultValue={selectedPosition}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              {/* Email */}
              <Col sm="12" md={3}>
                <Label className="form-label">{t("Email")}:</Label>
                <AvForm>
                  <AvField
                    name="email"
                    placeholder=""
                    type="email"
                    className="form-control"
                    validate={{ required: { value: false }, email: true }}
                    errorMessage={t("This field is invalid")}
                    id="email"
                    //   value={email}
                    onChange={debouncedHandleEmailChange}
                    value={selectedEmail}
                  />
                </AvForm>
              </Col>

              {/* Phone Number */}
              <Col sm="12" md={3}>
                <Label className="form-label">{t("Phone Number")}:</Label>
                <AvForm>
                  <AvField
                    name={`phone`}
                    errorMessage={t("This field cannot be blank")}
                    type="number"
                    // validate={{
                    //   required: { value: true },
                    // }}
                    // id={`phone${i}`}
                    onChange={debouncedHandlePhoneNumberChange}
                    value={selectedPhoneNumber}
                    
                  />
                </AvForm>
              </Col>
            </Row>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default withNamespaces()(SecondRow);
