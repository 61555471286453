import React from "react";
import { Document, Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";

import { withNamespaces } from "react-i18next";

import { TRANSLATION_KEY_REPORT_INFO as KEY_REPORT_INFO, TRANSLATION_KEY_COMMENT_INFO as KEY_COMMENT_INFO, TRANSLATION_KEY_USER_INFO as KEY_USER_INFO, FONT_OPEN_SANS_BOLD, FONT_OPEN_SANS_REGULAR, REPORT_FIELDS, FIELD_TYPE_TEXT, FIELD_TYPE_TEXT_AREA, FIELD_TYPE_REPORT_UPLOAD_FILE, FIELD_TYPE_MULTI_RADIO, DATE_FORMAT_DAY_OF_WEEK, FIELD_TYPE_MULTI_CHECKBOX, FIELD_TYPE_SELECT, IMAGE_USER_CIRCLE, FIELD_REPORT_ATTACHMENTS, REPORT_TYPE_ANONYMOUS, REPORT_PDF_DOWNLOAD_OPTIONS, ROLE_ANALYST_ADMIN, ROLE_ANALYST, ROLE_EMPLOYEE, REPORT_TYPE_CONFIDENT, REPORT_TYPE_MEETING } from "src/components/constants";
import DateUtils from "src/services/utils/DateUtils";
import ReportUtils from "src/services/utils/ReportUtils";
import UserUtils from "src/services/utils/UserUtils";
import { parseDocument } from "htmlparser2";
import { isTag, Element, Node, isText, DataNode } from "domhandler";
import { INTERNATIONAL_DATE_FORMAT } from "src/common/constants";

Font.register({
	family: "Open Sans",
	fonts: [
		{
			src: FONT_OPEN_SANS_REGULAR,
			fontWeight: 400,
			fontStyle: "normal",
		},
		{
			src: FONT_OPEN_SANS_REGULAR,
			fontWeight: 400,
			fontStyle: "italic",
		},
		{
			src: FONT_OPEN_SANS_BOLD,
			fontWeight: 600,
			fontStyle: "normal",
		},
		{
			src: FONT_OPEN_SANS_BOLD,
			fontWeight: 600,
			fontStyle: "italic",
		},
	],
});

const styles = StyleSheet.create({
	header: {
		display: "flex",
		flexDirection: "column",
		textAlign: "center",
		marginBottom: 30,
		marginTop: 40,
	},
	logo: {
		maxWidth: 100,
		height: 40,
		objectFit: "contain",
		position: "absolute",
		left: 0,
		top: 0,
	},
	headerTop: {
		position: "relative",
	},
	headerBottom: {
		marginTop: 10,
		textTransform: "uppercase",
	},
	page: {
		flexDirection: "column",
		fontFamily: "Open Sans",
		fontSize: 11,
		paddingLeft: 40,
		paddingRight: 40,
		paddingBottom: 60,
	},
	section: {
		marginBottom: 30,
		display: "flex",
		flexDirection: "column",
	},
	title: {
		fontSize: 16,
		textTransform: "uppercase",
		marginBottom: 10,
		color: "#507099",
	},
	badge: {
		padding: 10,
		backgroundColor: "#E0E0E0",
		marginBottom: 20,
		fontSize: 12,
	},
	mb20: {
		marginBottom: 20,
	},
	mb10: {
		marginBottom: 10,
	},
	label: {
		fontWeight: 600,
	},
	rowLeft: {
		flex: 1,
		paddingRight: 10,
	},
	rowRight: {
		flex: 1,
		paddingLeft: 10,
	},
	row: {
		flexDirection: "row",
		marginBottom: 20,
	},
	commentRow: {
		flexDirection: "row",
		marginBottom: 20,
		alignItems: "flex-start",
		justifyContent: "flex-start",
		width: "100%",
	},
	subText: {
		fontSize: 12,
		color: "#828282",
	},
	commentAvatar: {
		width: 20,
		marginRight: 10,
		objectFit: "contain",
	},
	commentContent: {
		flexDirection: "column",
		flex: 1,
	},
	commentTime: {
		color: "#828282",
		fontSize: 10,
	},
	ownerName: {
		fontWeight: 600,
		marginRight: 10,
	},
	textBold: {
		fontWeight: 600,
	},
	textUnderline: {
		textDecoration: "underline",
	},
	textItalic: {
		fontStyle: "italic",
	},
	textUppercase: {
		textTransform: "uppercase",
	},
	ownerRow: {
		flexDirection: "row",
		display: "flex",
		alignItems: "center",
		marginBottom: 5,
	},
	list: {
		marginLeft: 20,
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "flex-start",
	},
	listItem: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},
	dotPrefix: {
		fontSize: 20,
		fontWeight: 600,
	},
	pageNumber: {
		position: "absolute",
		bottom: 40,
		right: 30,
		left: 30,
		textAlign: "right",
	},
});

const ReportDetailPDF = (props) => {
	const { report, comments, internalComments, user, logo, downloadId, categories, orgQuestions, t } = props;
	const dateUtils = new DateUtils();
	const reportUtils = new ReportUtils();
	const userUtils = new UserUtils();
	
	const renderFormValue = (name, fields) => {
		if (!fields) {
			return "";
		}
		const foundField = fields.find((field) => field.name === name);

		if (foundField) {
			const type = foundField.type;
			const options = foundField.options;
			const value = foundField.value;

			switch (type) {
				case FIELD_TYPE_REPORT_UPLOAD_FILE:
					return t("No files uploaded.");
				case FIELD_TYPE_MULTI_CHECKBOX:
					if (value && value.length > 0) {
						return (
							<div>
								{value.map((option, index) => {
									const titleKey = option.titleKey;
									const value = option.value;
									const key = titleKey + value + index;

									return (
										<span className="badge badge-gray-5 mr10 mb20" key={key}>
											{t(titleKey)}
										</span>
									);
								})}
							</div>
						);
					}

					return null;
				case FIELD_TYPE_TEXT_AREA:
				case FIELD_TYPE_TEXT:
					return value;
				case FIELD_TYPE_SELECT:
					if (options && options.length > 0) {
						const selectedOption = options.find((option) => option.value === value.value);

						if (selectedOption) {
							return t(selectedOption.titleKey);
						}
					}

					return null;
				case FIELD_TYPE_MULTI_RADIO:
					if (options && options.length > 0 && value) {
						const selectedOption = options.find((option) => option.value === value.value);

						if (selectedOption) {
							const childValue = selectedOption.childValue;

							if (childValue) {
								return dateUtils.convertTimeStampToDate(childValue, DATE_FORMAT_DAY_OF_WEEK);
							}

							return t(selectedOption.titleKey);
						}
					}

					return null;
				default:
					return null;
			}
		}

		return null;
	};

	const getQuestionsAnswers = () => {
		
		const userAnswers = report.question_answers;
		const renderedAnswers = [];
		userAnswers?.map((userAnswer) => {
			const questionId = userAnswer.id;
			if (Array.isArray(orgQuestions)) {
				for (let i = 0; i < orgQuestions.length; i++) {
					const orgQuestion = orgQuestions[i];
					
					if (orgQuestion && orgQuestion.id === questionId) {
						let item = {
							id: questionId,
							title: orgQuestion.title,
							type: orgQuestion.type,
						};
						
						if (orgQuestion.type === "checkbox") {
							const subAnswers = [];
							if (orgQuestion.sub_question && userAnswer.answer) {
								if (!Array.isArray(userAnswer.answer)) {
									userAnswer.answer = [userAnswer.answer];
								}
								userAnswer.answer.map((answerId) => {
									for (let j = 0; j < orgQuestion.sub_question.length; j++) {
										const subQuestion = orgQuestion.sub_question[j];
										if (subQuestion.id === answerId) {
											subAnswers.push({
												id: answerId,
												label: subQuestion.label,
											});
											break;
										}
									}
								});
							}
							item.answer = subAnswers;
						} else if (orgQuestion.type === "radiobox") {
							if (userAnswer.answer && userAnswer.answer.length) {
								if (typeof userAnswer.answer[0] === "object") {
									item.answer = userAnswer.answer[0].date || null;
								} else {
									item.answer = t("I don’t remember");
								}
							} else {
								item.answer = t("I don’t remember");
							}
						} else {
							const subAnswers = [];
							if (userAnswer.answers) {
								if (!Array.isArray(userAnswer.answers)) {
									userAnswer.answer = [userAnswer.answers];
								}
								userAnswer.answers.map((answer) => {
									// for (let j = 0; j < orgQuestion.sub_question.length; j++) {
									// 	const subQuestion = orgQuestion.sub_question[j];
									// 	if (subQuestion.id === answerId) {
									// 		subAnswers.push({
									// 			id: answerId,
									// 			label: subQuestion.label,
									// 		});
									// 		break;
									// 	}
									// }
									subAnswers.push({
										// id: answer?.value,
										label: answer?.title,
									})
								});
							}
							
							item.answer = subAnswers;
						}

						renderedAnswers.push(item);
						break;
					}
				}
			}
		});
		return renderedAnswers;
	};

	const renderReportQuestions = () => {
		const reportEnteredLocations = report.locations && Array.isArray(report.locations) ? report.locations : [];
		return (
			<View style={styles.section}>
				<Text style={styles.title}>B. Content</Text>

				<View style={styles.mb10}>
					<Text style={styles.label}>{t("Brief Description of Incident")}</Text>

					<Text>{report?.admin_description}</Text>
				</View>

				<View style={styles.mb10}>
					<Text style={styles.label}>{t("Which location(s) is / are affected?")}</Text>

					<Text>
						{reportEnteredLocations
							.map((location) => {
								return t(location);
							})
							.join(", ")}
					</Text>
				</View>
				{getQuestionsAnswers(report)?.map((question, index) => {
					const id = question.id;
					const title = question.title || "";
					const type = question.type;
					const key = id + index;

					if (type === "checkbox" || type === "category") {
						return (
							<View style={styles.mb10} key={key}>
								<Text style={styles.label}>{t(title)}</Text>
								<Text>{question.answer.map((item) => t(item.label)).join(", ")}</Text>
							</View>
						);
					}

					if(type === "radiobox") {
						return (
							<View style={styles.mb10} key={key}>
								<Text style={styles.label}>{t(title)}</Text>
								<Text>{t(question.answer)}</Text>
							</View>
						);
					}

					return (
						<View style={styles.mb10} key={key}>
							<Text style={styles.label}>{t(title)}</Text>
							{ 
								Array?.isArray(question.answer) ? 
								question?.answer?.map(answer => <Text key={key}>{t(answer.label)}</Text>) : 
								<Text>{t(question.answer)}</Text> 
							}
						</View>
					);
				})}
			</View>
		);
	};

	const renderNodeData = (node, keys) => {
		const data = node.data;

		return <Text key={keys.toString()}>{data}</Text>;
	};

	const getTagClassName = (className) => {
		switch (className) {
			case "ql-indent-1":
				return { paddingLeft: 15 };
			case "ql-indent-2":
				return { paddingLeft: 30 };
			case "ql-indent-3":
				return { paddingLeft: 45 };
			case "ql-indent-4":
				return { paddingLeft: 60 };
			case "ql-indent-5":
				return { paddingLeft: 75 };
			case "ql-indent-6":
				return { paddingLeft: 90 };
			case "ql-indent-7":
				return { paddingLeft: 105 };
			case "ql-indent-8":
				return { paddingLeft: 120 };
			case "ql-indent-9":
				return { paddingLeft: 135 };
			case "ql-indent-10":
				return { paddingLeft: 150 };
			default:
				return {};
		}
	};

	const renderTag = (tag, keys) => {
		const name = tag.name;
		const children = tag.children;
		const className = getTagClassName(tag.attribs.className);

		switch (name) {
			case "br":
				return (
					<View key={keys.toString()} style={className}>
						{renderNodes(children, keys)}
					</View>
				);
			case "p":
				if (Array.isArray(children) && children.length > 0) {
					const firstNode = children[0];

					if (isText(firstNode)) {
						return (
							<View key={keys.toString()} style={className}>
								<Text>{renderNodes(children, keys)}</Text>
							</View>
						);
					}
				}

				return (
					<View key={keys.toString()} style={className}>
						{renderNodes(children, keys)}
					</View>
				);
			case "b":
			case "strong":
				return (
					<Text key={keys.toString()} style={{ fontWeight: 600 }}>
						{renderNodes(children, keys)}
					</Text>
				);

			case "em":
				return (
					<Text key={keys.toString()} style={{ fontStyle: "italic" }}>
						{renderNodes(children, keys)}
					</Text>
				);
			case "u":
				return (
					<Text key={keys.toString()} style={{ textDecoration: "underline" }}>
						{renderNodes(children, keys)}
					</Text>
				);
			case "ul":
				return (
					<View style={[styles.list, className]} key={keys.toString()}>
						{renderListTag(children, keys, false)}
					</View>
				);
			case "ol":
				return (
					<View style={[styles.list, className]} key={keys.toString()}>
						{renderListTag(children, keys, true)}
					</View>
				);
			default:
				return null;
		}
	};

	const renderListTag = (nodes, keys, isOrderedNumber) => {
		const renderListPrefix = (index) => {
			if (isOrderedNumber) {
				return <Text>{index + 1 + ". "}</Text>;
			}

			return <Text style={styles.dotPrefix}>{"· "}</Text>;
		};

		return nodes.map((node, index) => {
			if (isTag(node)) {
				keys.push(index);
				const children = node.children;
				const className = getTagClassName(node.attribs.class);

				return (
					<View style={[styles.listItem, className]} key={keys.toString()}>
						{renderListPrefix(index)}

						<View>{renderNodes(children, keys)}</View>
					</View>
				);
			}

			return null;
		});
	};

	const renderNodes = (nodes, keys) => {
		if (!nodes || nodes.length === 0) {
			return null;
		}

		const newKeys = keys ? keys : [];

		return (
			<View>
				{nodes.map((node, index) => {
					newKeys.push(index);

					if (isTag(node)) {
						return renderTag(node, newKeys);
					}

					if (isText(node)) {
						return renderNodeData(node, newKeys);
					}

					return null;
				})}
			</View>
		);
	};

	const renderHTMLContent = (content) => {
		const domContent = parseDocument(content);
		const children = domContent.children;

		return renderNodes(children);
	};

	const renderCommentList = (comments) => {
		if (!comments || comments.length === 0) {
			return null;
		}
		const sortedComments = reportUtils.sortCommentsByCreatedAt(comments);

		return sortedComments.map((comment, index) => {
			const content = comment.content;
			const id = comment.id;
			const owner = comment.owner;
			const key = id + content + index;
			const createdDate = dateUtils.convertTimeStampToDate(comment.created_at, INTERNATIONAL_DATE_FORMAT);

			return (
				<View key={key} style={styles.commentRow}>
					<Image src={IMAGE_USER_CIRCLE} style={styles.commentAvatar} />

					<View style={styles.commentContent}>
						<View style={styles.ownerRow}>
							<Text style={styles.ownerName}>{userUtils.getName(owner) || t("Anonymous")}</Text>

							<Text style={styles.commentTime}>{createdDate}</Text>
						</View>

						<View>{renderHTMLContent(content)}</View>
					</View>
				</View>
			);
		});
	};

	const renderComments = () => {
		return (
			<View style={styles.section}>
				<Text style={styles.title}>{"E. " + t("Messages")}</Text>

				{renderCommentList(comments)}
			</View>
		);
	};

	const renderInternalComments = () => {
		if (!isInternalInvestigationDownload()) {
			return null;
		}

		return (
			<View style={styles.section}>
				<Text style={styles.title}>{"F. " + t("Internal Comments")}</Text>

				{renderCommentList(internalComments)}
			</View>
		);
	};

	const renderHeader = () => {
		// const organization = this.props.Organization;
		// const orgLogo = organization.logo ? organization.logo : null;

		return (
			<View style={styles.header} fixed>
				<View style={styles.headerTop}>
					{/* <Image source={{ url: logo, method: 'GET', headers: {}, body: '' }} style={styles.logo} /> */}
					{/* <Image src={logo} style={styles.logo} /> */}
					{/* <Image src={{uri:logo, method: 'GET', headers:{}}} style={styles.logo}/> */}

					{logo ? <Image src={logo} style={styles.logo} /> : null}
					<View>
						<Text style={styles.textBold}>{t("Smart Integrity Platform")}</Text>
					</View>
					<View>
						<Text>{t("Case report")}</Text>
					</View>
				</View>
				<View style={styles.headerBottom}>
					<Text>{t("STRICTLY CONFIDENTIAL – FOR INTERNAL USE ONLY")}</Text>
				</View>
			</View>
		);
	};

	const renderGeneralSection = () => {
		const analystName = reportUtils.getAssignedAnalyst(report);
		const typeKey = reportUtils.getReportTypeKey(report); // ANONYMOUS || CONFIDENTIAL || MEETING
		const categoryKeys = reportUtils.getCategoryKeys(report.category, categories);
		const categoryList = categoryKeys?.map((titleKey, index) => t(titleKey)).join(", ");

		return (
			<View style={styles.section}>
				<Text style={styles.title}>{"A. " + t("General information")}</Text>
				<Text>{t("Case ID") + ": " + report.secondary_case_id}</Text>
				<Text>{t("Download date, time") + ": " + reportUtils.getCurrentDateTime()}</Text>
				<Text>{t("Downloaded by") + ": " + userUtils.getName(user)}</Text>
				<Text>
					{t("Case type") + ": "}
					<Text style={styles.textUppercase}>{typeKey ? t(typeKey) : ""}</Text>
				</Text>
				<Text>{t("Case submitted on") + ": " + dateUtils.convertTimeStampToDate(report.submission_date, "DD/MM/YYYY")}</Text>
				<Text>{t("Category") + ": " + categoryList}</Text>
				<Text>{t("Assigned to") + ": " + analystName}</Text>
			</View>
		);
	};

	const renderAttachedFiles = () => {
		const attachments = report.attachments || [];
		const attachedFiles = attachments.filter((attachment) => !attachment.is_internal);
		return (
			<View style={styles.section}>
				<Text style={styles.title}>{"C. " + t("List of attached files")}</Text>
				{attachedFiles.map((file) => {
					return <Text key={file.id}>{file.name}</Text>;
				})}
			</View>
		);
	};

	const showMoreContactDetails = () => {
		return report.type === REPORT_TYPE_CONFIDENT || report.type === REPORT_TYPE_MEETING;
	};

	const showContactEmail = () => {
		const roles = user?.roles || [];
		if (report.type === REPORT_TYPE_ANONYMOUS && roles.length > 0) {
			if (roles.indexOf(ROLE_ANALYST) > -1 || roles.indexOf(ROLE_ANALYST_ADMIN) > -1 || roles.indexOf(ROLE_EMPLOYEE) > -1) {
				return false;
			}
		}

		return true;
	};

	const renderContactDetails = () => {
		const ownerInfo = report?.owner_info;
		const email = ownerInfo?.email || report.email || "";
		const phoneAvalableTimeKeys = reportUtils.getPhoneAvailabaleKeys(report);
		const phoneAvalableTimeList = phoneAvalableTimeKeys.map((titleKey, index) => t(titleKey)).join(", ");
		const name = ownerInfo?.name || "";
		const phone = ownerInfo?.phone || "";
		const position = ownerInfo?.position ? ownerInfo?.position : report?.position;
		const company = ownerInfo?.company || "";
		return (
			<View style={styles.section}>
				<Text style={styles.title}>{"D. " + t("Contact Details")}</Text>
				{showContactEmail() ? <Text>{t("Email address") + ": " + email}</Text> : null}
				{showMoreContactDetails() ? (
					<View>
						<Text>{t("Name") + ": " + name}</Text>
						<Text>{t("Phone number") + ": " + phone}</Text>
						<Text>{t("Position") + ": " + position}</Text>
						<Text>{t("Company") + ": " + company}</Text>
						<Text>{t("Available times for callback") + ": " + phoneAvalableTimeList}</Text>
					</View>
				) : null}
			</View>
		);
	};

	const renderInternalFiles = () => {
		if (!isInternalInvestigationDownload()) {
			return null;
		}
		const attachments = report.attachments || [];
		const internalFiles = attachments.filter((attachment) => attachment.is_internal);
		return (
			<View style={styles.section}>
				<Text style={styles.title}>{"G. " + t("List of internal files")}</Text>
				{internalFiles.map((file) => {
					return <Text key={file.id}>{file.name}</Text>;
				})}
			</View>
		);
	};

	const isInternalInvestigationDownload = () => {
		return downloadId === REPORT_PDF_DOWNLOAD_OPTIONS[0].id;
	};

	if (!report) {
		return (
			<Document>
				<Page size="A4" style={styles.page}></Page>
			</Document>
		);
	}

	return (
		<Document>
			<Page size="A4" style={styles.page}>
				{renderHeader()}

				{renderGeneralSection()}

				{renderReportQuestions()}

				{renderAttachedFiles()}

				{renderContactDetails()}

				{renderComments()}

				{renderInternalComments()}

				{renderInternalFiles()}

				<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `Page ${pageNumber} / ${totalPages}`} fixed />
			</Page>
		</Document>
	);
};

export default withNamespaces()(ReportDetailPDF);
