import { Editor } from "draft-js";
import { withNamespaces } from "react-i18next";
import { MdOutlineModeEdit } from "react-icons/md";
import { TbLoader } from "react-icons/tb";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import PhoneInput from "react-phone-input-2";

const ReviewReport = ({
  t,
  goToSpecificTab,
  goToPrevTab,
  onFormSubmit,
  isLoading,
  //   Company Details
  companyName,
  industry,
  companySize,
  website,
  description,
  // Factory sites and products
  officeAddresses,
  factoryAddresses,
  addedProducts,
  // Contacts
  contacts,
}) => {
  const handleBack = () => {
    goToPrevTab(3);
  };

  // some bugs make this to happen.delete this after fix the bug
  const validFactoryAddresses = factoryAddresses.filter((add) => !!add.country && !!add.city)

  return (
    <>
      <div className="confirm-form-sections-container">
        {/* Company Detials */}
        <div className="section">
          <div
            className="section-header"
            style={{
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "space-between",
            }}
          >
            <span>{`1. ${t("Company Details")}`}</span>
            <Button color="primary" onClick={() => goToSpecificTab(1)} outline className="edit-section-btn">
              <MdOutlineModeEdit />
            </Button>
          </div>
          <div className="section-body">
            <Row>
              <Col md="3" sm="12">
                <Label for="companyName">{t("Company Name")}: </Label>
                <p>{companyName}</p>
              </Col>

              <Col md="3" sm="12">
                <Label for="industry">{t("Industry")}: </Label>
                <p>{t(industry.label)}</p>
              </Col>

              <Col md="3" sm="12">
                <Label for="companySize">{t("Company Size")}: </Label>
                <p>{t(companySize.label)}</p>
              </Col>

              <Col md="3" sm="12">
                <Label for="website">{t("Website")}: </Label>
                <p>{website}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Label for="description">{t("Description")}: </Label>
                <Editor
                  editorState={description}
                  readOnly={true}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                />
              </Col>
            </Row>
          </div>
        </div>

        {/* Factory Sites and Products */}
        <div className="section">
          <div
            className="section-header"
            style={{
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "space-between",
            }}
          >
            <span>{`2. ${t("Factory Sites and Products")}`}</span>
            <Button color="primary" onClick={() => goToSpecificTab(2)} outline className="edit-section-btn">
              <MdOutlineModeEdit />
            </Button>
          </div>
          <div className="section-body">
            {/* Office Address */}
            <label>{t("Office Address")}</label>
            <ol>
              {officeAddresses.map((item, i) => {
                return (
                  <li key={item.id}>
                    <Row className="mb-2">
                      {/* Country */}
                      <Col md="3" sm="12">
                        <Label for="country">{t("Country")}: </Label>
                        <p>{t(officeAddresses[i]?.country?.label)}</p>
                      </Col>

                      {/* City */}
                      <Col md="3" sm="12">
                        <Label for="city">{t("City")}: </Label>
                        <p>{t(officeAddresses[i]?.city)}</p>
                      </Col>

                      {/* Extra */}
                      <Col md="5" sm="12">
                        <Label for="extra">{t("Extra")}: </Label>
                        <p>{t(officeAddresses[i]?.extra)}</p>
                      </Col>
                      {/* <hr></hr> */}
                    </Row>
                  </li>
                );
              })}
            </ol>

            {/* Factory Address */}
            <label>{t("Factory Location")}</label>
            {validFactoryAddresses.length > 0 ? (
                <ol>
                    {validFactoryAddresses.map((item, i) => {
                        return (
                            <li key={item.id}>
                                <Row className="mb-2">
                                    {/* Country */}
                                    <Col md="3" sm="12">
                                    <Label for="country">{t("Country")}: </Label>
                                    <p>{t(validFactoryAddresses[i]?.country?.label)}</p>
                                    </Col>

                                    {/* City */}
                                    <Col md="3" sm="12">
                                    <Label for="city">{t("City")}: </Label>
                                    <p>{t(validFactoryAddresses[i]?.city)}</p>
                                    </Col>

                                    {/* Extra */}
                                    <Col md="5" sm="12">
                                    <Label for="extra">{t("Extra")}: </Label>
                                    <p>{t(validFactoryAddresses[i]?.extra)}</p>
                                    </Col>
                                    {/* <hr></hr> */}
                                </Row>
                            </li>
                        )
                    })}
                </ol>
            ) : (
                <div className="alert alert-warning">
                    <p className="m-0">{t('This list is empty')}</p>
                </div>
            )}

            {/* Products */}
            <div hidden={!addedProducts?.length}>
              <h4>{t("List of products supplied")}</h4>
              <Row className="mb-2">
                <div className="table-container">
                  <table>
                    <thead hidden={!addedProducts.length}>
                      <tr>
                        <td style={{ width: "25%" }}>{t("Product Name")}</td>
                        <td style={{ width: "75%" }}>{t("Description")}</td>
                      </tr>
                    </thead>
                    <tbody>
                      {addedProducts.length
                        ? addedProducts.map((item, index) => {
                            return (
                              <tr key={item?.id}>
                                <td style={{ width: "25%" }}>
                                  {item.productName.label}
                                </td>
                                <td style={{ width: "75%" }}>
                                  {item.description}
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              </Row>
            </div>
          </div>
        </div>

        {/* Contact Person */}
        <div className="section">
          <div
            className="section-header"
            style={{
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "space-between",
            }}
          >
            <span>{`3. ${t("Responsible Person")}`}</span>
            <Button color="primary" onClick={() => goToSpecificTab(3)} outline className="edit-section-btn">
              <MdOutlineModeEdit />
            </Button>
          </div>
          <div className="section-body">
            <ol>
              {contacts.map((item, i) => {
                return (
                  <li key={i}>
                    <Row>
                      <Col md="4" sm="12">
                        <Label for="fullName">{t("Full Name")}: </Label>
                        <p>{`(${t(item?.title)}) ${item?.firstName} ${item?.lastName}`}</p>
                      </Col>

                      <Col md="4" sm="12">
                        <Label for="position">{t("Position")}: </Label>
                        <p>{t(item?.position)}</p>
                      </Col>

                      <Col md="4" sm="12">
                        <Label for="language">{t("Language")}: </Label>
                        <p>{t(item?.language?.label)}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="4" sm="12">
                        <Label for="email">{t("Email")}: </Label>
                        <p>{item?.email}</p>
                      </Col>

                      <Col md="4" sm="12">
                        <Label for="phone">{t("Phone Number")}: </Label>
                        <PhoneInput
                            disabled={true}
                            buttonStyle={{
                                display: 'none'
                            }}
                            dropdownStyle={{
                                border: 'none'
                            }}
                            inputStyle={{
                                border: 'none',
                                paddingLeft: '0px',
                                color: '#000',
                                fontSize: '14px',
                                background: 'transparent'
                            }}
                            disableDropdown={true}
                            value={ item?.phoneNumber }
                        />
                      </Col>
                    </Row>

                    {/* <hr></hr> */}
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
      </div>

      <Row className="mb-4">
        <FormGroup
          style={{
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "space-between",
          }}
          className="actions-buttons-container"
        >
          <Button color="primary" onClick={handleBack} disabled={isLoading} outline>
            {t("Back")}
          </Button>
          <Button color="primary" onClick={onFormSubmit} disabled={ isLoading } >
            {
              isLoading ? (
                <>
                  <TbLoader /> { t("Creating...") }
                </>
              ) : <>{t("Create")}</>
            }
          </Button>
        </FormGroup>
      </Row>
    </>
  );
};

export default withNamespaces()(ReviewReport);
