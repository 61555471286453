import { memo, useState } from 'react';
import { Col, Row, Label, Tooltip } from 'reactstrap';
import { AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { withNamespaces } from 'react-i18next';

const Checkbox = (props) => {
    const {
        questionIndex,
        id, 
        title, 
        isMandatory, 
        key,
        options,
        onChange,
        elRefIdPrefix,
        helpText,
        onNoOptionSelected,
        onAnyOptionSelected
    } = props;

    const [helpTooltipTextStatus, setHelpTooltipTextStatus] = useState(false);

    return (
        <Row key={key}>
            <Col sm="12">
                <div className="mb-3">
                <Label className="form-label" htmlFor={`${elRefIdPrefix}-question-${id}`}>
                    {
                        parseInt(questionIndex) >= 0 ? (questionIndex + 1 + '. ') : ''
                    } {props.t(title)}

                    {
                        helpText && (
                            <span className="ms-2 tooltip-container">
                                <i
                                    className="fa fa-info-circle help-text-icon"
                                    aria-hidden="true"
                                    id={`${elRefIdPrefix}-question-${id}-help-text`}
                                />
                                <Tooltip
                                    style={{
                                        backgroundColor: '#899ba1',
                                        fontWeight: 'bold'
                                    }}
                                    placement="top"
                                    isOpen={ helpTooltipTextStatus }
                                    target={`${elRefIdPrefix}-question-${id}-help-text`}
                                    toggle={() =>
                                        setHelpTooltipTextStatus(!helpTooltipTextStatus)
                                    }>
                                        {
                                            <p>
                                                {props.t(helpText)}
                                            </p>
                                        }
                                </Tooltip>
                            </span>
                        )
                    }
                </Label>
                <AvCheckboxGroup
                    name={"question[" + id + "]"}
                    errorMessage={props.t(
                        "This field cannot be blank"
                    )}
                    validate={{
                        required: { value: isMandatory },
                    }}>
                        <Row>
                            {(options || []).map(
                                (option) => {
                                    return (
                                        <Col
                                            sm="12"
                                            md="6"
                                            key={`${id}-${option.id}`}>
                                            <AvCheckbox
                                                id={`${elRefIdPrefix}-question-${id}-checkbox-${option.id}`}
                                                label={props.t(
                                                    option.title
                                                )}
                                                onChange={(e) => {
                                                    onChange && onChange(e);

                                                    option.checked = e.target.checked;

                                                    if(option.checked){
                                                        onAnyOptionSelected && onAnyOptionSelected(option);
                                                    }
                                                    else{
                                                        if(!options.filter((o) => o?.checked).length){
                                                            onNoOptionSelected && onNoOptionSelected();
                                                        }
                                                    }
                                                }}
                                                value={option.id}
                                            />
                                        </Col>
                                    );
                                }
                            )}
                        </Row>
                </AvCheckboxGroup>
                </div>
            </Col>
        </Row>
    );
}

export default withNamespaces()(memo(Checkbox));