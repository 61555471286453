import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import reportAIcon from "../../../assets/images/report_anonymously.svg";
import reportCIcon from "../../../assets/images/report_confidently.svg";
import arrangeMeetingIcon from "../../../assets/images/arrange_meeting.svg";
import voiceReportIcon from "../../../assets/images/voice.svg";
import { getCurrentLanguage } from "../../../i18n";
import { 
	getActiveModuleInfos,
	isSettingEnabledForActiveModule
} from "../../../helpers/module_helper";

class EmployeeHome extends Component {

  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "SIP", link: "/" },
        { title: "Home", link: "#" },
      ],
      selectedLanguage: getCurrentLanguage(),
    };
  }

  render() {
    const activeModule = getActiveModuleInfos();

	const isVoice = isSettingEnabledForActiveModule('isVoice');

	const isAnonymously = isSettingEnabledForActiveModule('isAnonymously');

	const isConfidentially = isSettingEnabledForActiveModule('isConfidentially');

	const isMeeting = isSettingEnabledForActiveModule('isMeeting');

    return (
			<React.Fragment>
				<div className='page-content'>
					<Container fluid>
						{activeModule
							? this.props.Organization.translations
									.filter((t) => t.module === activeModule.name)
									.map((item, index) => {
										return (
											<div className={item.lang === this.state.selectedLanguage ? '' : 'd-none'}>
												<Breadcrumbs key={index} title={item.title} breadcrumbItems={this.state.breadcrumbItems} />
											</div>
										);
									})
							: null}
						<Row>
							{activeModule && isAnonymously && (
								<Col className={isVoice ? 'col col-12 col-md-12 col-lg-3' : 'col col-12 col-md-12 col-lg-4'}>
									<Card>
										<CardBody
											style={{
												minHeight: '180px',
											}}
										>
											<div className='d-flex'>
												<div className='flex-1 overflow-hidden'>
													<h4 className='mb-2'>{this.props.t('Report anonymously')}</h4>
													<p className='font-size-14 mb-2'>
														{this.props.t(
															'You do not disclose your identity. You communicate exclusively via the Smart Integrity Platform with those responsible from the company.',
														)}
													</p>
												</div>
												<div className='text-primary'>
													<img src={reportAIcon} alt='' />
												</div>
											</div>
										</CardBody>

										<CardBody className='border-top py-3'>
											<div className='text-truncate'>
												<Link to='/employee/report-anonymously'>
													<Button
														color='info'
														style={{
															background: this.props.Organization.primaryColor,
														}}
													>
														{this.props.t('Send a report')}
													</Button>
												</Link>
											</div>
										</CardBody>
									</Card>
								</Col>
							)}
							{activeModule && isConfidentially && (
								<Col className={isVoice ? 'col col-12 col-md-12 col-lg-3' : 'col col-12 col-md-12 col-lg-4'}>
									<Card>
										<CardBody
											style={{
												minHeight: '180px',
											}}
										>
											<div className='d-flex'>
												<div className='flex-1 overflow-hidden'>
													<h4 className='mb-2'>{this.props.t('Report confidentially')}</h4>
													<p className='font-size-14 mb-2'>
														{this.props.t(
															'You disclose your identity confidentially to the responsible persons and discuss your case discreetly with the responsible persons.',
														)}
													</p>
												</div>
												<div className='text-primary'>
													<img src={reportCIcon} alt='' />
												</div>
											</div>
										</CardBody>

										<CardBody className='border-top py-3'>
											<div className='text-truncate'>
												<Link to='/employee/report-confidentially'>
													<Button
														color='info'
														style={{
															background: this.props.Organization.primaryColor,
														}}
													>
														{this.props.t('Send a report')}
													</Button>
												</Link>
											</div>
										</CardBody>
									</Card>
								</Col>
							)}
							{activeModule && isMeeting && (
								<Col className={isVoice ? 'col col-12 col-md-12 col-lg-3' : 'col col-12 col-md-12 col-lg-4'}>
									<Card>
										<CardBody
											style={{
												minHeight: '180px',
											}}
										>
											<div className='d-flex'>
												<div className='flex-1 overflow-hidden'>
													<h4 className='mb-2'>{this.props.t('Arrange a meeting')}</h4>
													<p className='font-size-14 mb-2'>
														{this.props.t('You disclose your identity in order to arrange a confidential meeting with the responsible persons.')}
													</p>
												</div>
												<div className='text-primary ms-1'>
													<img src={arrangeMeetingIcon} alt='' />
												</div>
											</div>
										</CardBody>

										<CardBody className='border-top py-3'>
											<div className='text-truncate'>
												<Link to='/employee/arrange-meeting'>
													<Button
														color='info'
														style={{
															background: this.props.Organization.primaryColor,
														}}
													>
														{this.props.t('Arrange a meeting')}
													</Button>
												</Link>
											</div>
										</CardBody>
									</Card>
								</Col>
							)}
							{activeModule && isVoice && (
								<Col className='col col-12 col-md-12 col-lg-3'>
									<Card>
										<CardBody
											style={{
												minHeight: '180px',
											}}
										>
											<div className='d-flex'>
												<div className='flex-1 overflow-hidden'>
													<h4 className='mb-2'>{this.props.t('Voice report')}</h4>
													<p className='font-size-14 mb-2'>{this.props.t('Send a confidential or anonymous report with a voice message')}</p>
												</div>
												<div className='text-primary'>
													<img src={voiceReportIcon} alt='' />
												</div>
											</div>
										</CardBody>

										<CardBody className='border-top py-3'>
											<div className='text-truncate'>
												<Link to='voice-report'>
													<Button
														color='info'
														style={{
															background: this.props.Organization.primaryColor,
														}}
													>
														{this.props.t('Send a voice report')}
													</Button>
												</Link>
											</div>
										</CardBody>
									</Card>
								</Col>
							)}
						</Row>

						{activeModule
							? this.props.Organization.translations
									.filter((t) => t.module === activeModule.name)
									.map((item, index) => {
										return (
											<Row key={index} className={item.lang === this.state.selectedLanguage ? '' : 'd-none'}>
												<Col lg='12'>
													<Card>
														<CardBody>{<div className='introText' dangerouslySetInnerHTML={{ __html: item.intro }} />}</CardBody>
													</Card>
												</Col>
											</Row>
										);
									})
							: null}
					</Container>
				</div>
			</React.Fragment>
		);
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.App.loaded && this.props.App.loaded) ||
      this.props.t !== prevProps.t
    ) {
      this.setState({
        selectedLanguage: getCurrentLanguage(),
      });
    }
  }
}

const mapStatetoProps = (state) => {
    const { user, token } = state.Login;
    const { App, Organization, Modules : modules } = state;
    return { App, user, token, Organization, modules };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, null)(EmployeeHome))
);
