import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import {
  errorNotification,
  successNotification,
} from "../../../../../store/actions";
import React, { memo, useEffect, useState } from "react";
import { Button, Col, Container, Input, Row } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import DataProtectionSoftwareService from "../../../apis/DataProtectionSoftwareService";
// icons
import flagOnIcon from "src/modules/data-protection/assets/icons/flag-icon.png";
import flagOffIcon from "src/modules/data-protection/assets/icons/flag-icon-off.png";
import highPriorityIcon from "src/modules/data-protection/assets/icons/priority-high.png";
import mediumPriorityIcon from "src/modules/data-protection/assets/icons/priority-medium.png";
import lowPriorityIcon from "src/modules/data-protection/assets/icons/priority-low.png";
// styles
import "./style.scss";
// components
import PageBody from "../../../components/page-body";
import PageContent from "../../../components/page-content";
import DataProtectionSoftwareCreate from "src/modules/data-protection/pages/software/create";

import useUploadCSV from "../../../hooks/useUploadCSV";

import Deletion from "../../software/modal/deletion";

import Spinner from "react-bootstrap/Spinner";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import { EMPTY_LIST } from "src/common/constants";
import TableFilter from "../components/table-filter";
import CommonService from "src/modules/data-protection/apis/CommonService";
import {Assets_STATUS_LIST} from "../../../constants"

const DataProtectionSoftwareList = (props) => {
  const location = useLocation();
  const { t } = props;

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const SoftwareStatuses = {
    NEW: t("New"),
    IN_PROGRESS: t("In Progress"),
    OPEN: t("Open"),
    APPROVED: t("Approved"),
    ASSIGNED: t("Assigned"),
    DELETED: t("Deleted"),
  };

  const [tableFilters, setTableFilters] = useState({
    pageIndex: 1,
    pageSize: 25,
    status: null,
    flag: null,
    owner: null,
    sortField :null,
    sortOrder: null
  });

  const [tableResultTotalCount, setTableResultTotalCount] = useState(0);

  const { openUploadDialog, uploadCSV } = useUploadCSV(
    DataProtectionSoftwareService,
    (data) => {
      toast(t("csv uploaded successfully"), {
        type: "success",
      });

      handleFetchSoftwareListQuery.refetch();
    },

    (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);
      const errors = [];
      if (error?.response?.data) {
        const errorData = error.response.data.error;
        Object.keys(errorData).forEach((key, index) => {
          if (typeof errorData[key] === "string") {
            errors.push(
              <li>{`${props.t("Line")} ${key}: ${errorData[key]}`}</li>
            );
          }
          if (typeof errorData[key] === "object") {
            Object.keys(errorData[key]).forEach((errorKey) => {
              errors.push(
                <li>{`${props.t("Line")} ${key}: ${
                  errorData[key][errorKey]
                }`}</li>
              );
            });
          }
        });
      }
      toast(<ul>{errors}</ul>, {
        type: "error",
        style: {
          width: "max-content",
        },
      });
      handleFetchSoftwareListQuery.refetch();
    }
  );
  const searchParams = new URLSearchParams(location.search);

  const [softwares, setSoftwares] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalCount, setTotalCount] = useState(0);

  const [showDeletionModal, setDeletionModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [deletionComplete, setDeletionComplete] = useState(false);

  const tab = searchParams.get("tab");

  const [users, setUsers] = useState([]);

  const tableColumns = [
    {
      dataField: "checkbox",
      text: "#",
      sort: false,
      key: 1,
      formatter: (cellContent, row) => {
        return (
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={selectedIds.includes(row.id)}
            onChange={() => handleCheckboxChange(row.id)}
          />
        );
      },
      style: {
        width: "1%",
        textAlign: "center",
      },
    },
    {
      key: 2,
      dataField: "financial_risk",
      text: props.t("Risk Rating"),
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <span
            style={{ backgroundColor: `#${cellContent.color}` }}
            className="risk-rate mx-2"
          ></span>
        );
      },
            style: {
        width: "5%",
        textAlign: "right",
      },
    },
        {
      dataField: "service",
      text: t("Service"),
      sort: false,
      key: 3,
      formatter: (cell, row) => {
        const isDeleted = row?.status === "DELETED";
    
        return (
          <Link
            to={isDeleted ? "#" : `softwares/${row.id}`}
            className={`text-truncate text-capitalize text-left ${isDeleted ? 'disabled-link' : ''}`}
            onClick={e => isDeleted && e.preventDefault()}
            style={isDeleted ? { cursor: 'not-allowed', } : {}}
          >
            {row.service}
          </Link>
        );
      },
      style: {
        width: "10%",
      },
    },
        {
      dataField: "id",
      text: t("Assets ID"),
      sort: false,
      key: 4,
      style: {
        width: "5%",
      },
    },
    {
      dataField: "version",
      text: t("Version"),
      sort: false,
      key: 5,   
      style: {
        width: "5%",
      },        
    },
    {
      dataField: "operating_system_name",
      text: t("Operating System"),
      sort: true,
      key: 6,
      style: {
        width: "10%",
      },
    },
    {
      dataField: "analyst_assigned_name",
      text: t("Assign Analyst"),
      sort: true,
      key: 7,
      formatter: (cell, row) => {
        return (
          <p>{row.analyst_assigned_name ? row.analyst_assigned_name : "---"}</p>
        );
      },
      style: {
        width: "7%",
      },
    },
    // {
    //   dataField: "service",
    //   text: t("Service"),
    //   sort: false,
    //   key: 3,
    //   formatter: (cell, row) => {
    //     const isDeleted = row?.status === "DELETED";
    
    //     return (
    //       <Link
    //         to={isDeleted ? "#" : `softwares/${row.id}`}
    //         className={`text-truncate text-capitalize text-left ${isDeleted ? 'disabled-link' : ''}`}
    //         onClick={e => isDeleted && e.preventDefault()}
    //         style={isDeleted ? { cursor: 'not-allowed', } : {}}
    //       >
    //         {row.service}
    //       </Link>
    //     );
    //   },
    //   style: {
    //     width: "10%",
    //   },
    // },
    // {
    //   dataField: "provider",
    //   text: t("Provider"),
    //   sort: false,
    //   key: 4,
    //   formatter: (cell, row) =>
    //     row.provider ? row.provider.name : "No Provider",
    //   style: {
    //     width: "10%",
    //   },
    // },
    // {
    //   dataField: "version",
    //   text: t("Version"),
    //   sort: false,
    //   key: 5,
    //   style: {
    //     width: "5%",
    //   },
    // },
    {
      dataField: "flag",
      text: t("Flag"),
      sort: true,
      key: 8,
      formatter: (cell, row) => handleRenderFlag(row),
      style: {
        textAlign: "center",
        width: "5%",
      },

    },
    {
      dataField: "priority",
      text: t("Priority"),
      sort: true,
      key:9,
      formatter: (cell, row) => {
        return <p>{handleRenderPriority(row.priority)}</p>;
      },
      style: {
        textAlign: "center",
      },
      headerStyle: {
        width: "7%",
      },
    },
    {
      dataField: "status",
      text: t("Status"),
      sort: true,
      key: 10,
      formatter: (cell, row) => {
        return (
          <p >
            {handleRenderStatus(row.status)}
          </p>
        );
      },
      style: {
        width: "7%",
      },
    },
   
    {
      dataField: "empty",
      text: "",
      sort: false,
      key: 11,
      formatter: (cellContent, row) => {
        return row.status !== "DELETED" ? (
          <div className="text-danger text-truncate text-capitalize text-center d-flex justify-content-center remove">
            <a
              onClick={() => onRemoveClick(row.id)}
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </a>
          </div>
        ) : (
          <span className="text-danger text-truncate text-capitalize text-center d-flex justify-content-center remove">
            {t("Deleted")}
          </span>
        );
      },
      style: {
        width: "5.3%",
        textAlign: "center",
      },
    },
  ];

  const handleFetchSoftwareListQuery = useQuery({
    queryKey: ["data-protection-softwares-list", pageIndex, pageSize, tableFilters],
    queryFn: async () => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.list(tableFilters.pageIndex, tableFilters.pageSize, {
        status:
          tab === "deletion-protocol"
            ? "DELETED"
            : tableFilters.status
            ? tableFilters.status
            : null,
        owner: tableFilters.owner ?  tableFilters.owner : null,
        flag: tableFilters.flag ? tableFilters.flag : null,
        ...(tableFilters.sortField && { sortField: tableFilters.sortField }),
        ...(tableFilters.sortField && { sortOrder: tableFilters.sortOrder }),
      });
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(t("An error occurred while fetching softwares."), {
        type: "error",
      });
    },
  });

  const handleFetchUsersListQuery = useQuery({
    queryKey: ["data-protection-users-list"],
    queryFn: async () => {
      const service = CommonService.getInstance();
      return await service.analystAdminsAndAnalysts();
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(t("An error occurred while fetching softwares."), {
        type: "error",
      });
    },
  });

  const toggleSoftwareFlagMutation = useMutation({
    mutationFn: async ({ softwareId }) => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.toggleFlag({
        softwareId: softwareId,
      });
    },
    onSuccess: () => {
      toast(t("Software flag status successfully changed."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while changing flag status."), {
        type: "error",
      });
    },
    onMutate: ({ softwareId }) => {
      setSoftwares({
        ...softwares,
        assets: softwares.assets.map((software) => {
          if (software.id === softwareId) {
            return {
              ...software,
              flagIsUpdating: true,
            };
          }
          return software;
        }),
      });
    },
  });

  const onApproveSelection = useMutation({
    mutationFn: async () => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.approveSelection({
        softwareIds: selectedIds,
      });
    },
    onSuccess: () => {
      toast(t("Selected softwares are approved"), {
        type: "success",
      });
      handleFetchSoftwareListQuery.refetch();
    },
    onError: () => {
      toast(t("An error occurred while approving selected softwares."), {
        type: "error",
      });
    },
  });

  const handleFetchSoftwareQuery = async () => {
    
    try {
      const service = DataProtectionSoftwareService.getInstance();
      await service.SoftwareFetch();
      handleFetchSoftwareListQuery.refetch(); 
    } catch (error) {
      if (process.env.NODE_ENV === 'development') console.error(error);
      toast(t("An error occurred while fetching software."), { type: "error" });
    } finally {
      
    }
  };

  const NoDataIndication = () =>
    handleFetchSoftwareListQuery.isFetched &&
    !handleFetchSoftwareListQuery.length ? (
      <div className="alert m-0" role="alert">
        <p
          style={{
            textAlign: "center",
            marginBottom: 0,
          }}
        >
          {t(EMPTY_LIST)}
        </p>
      </div>
    ) : (
      <></>
    );

  const handleTableChange = (type, data) => {
    switch (type) {
      case "pagination":
        const { page, sizePerPage } = data
        setTableFilters({
          ...tableFilters,
          pageIndex: page,
          pageSize: sizePerPage,
        });
        break;

        case 'sort': {
          const { sortField, sortOrder } = data

          if (sortField === 'operating_system_name') {
            setTableFilters({
              ...tableFilters,
              sortField: 'os',
              sortOrder: sortOrder,
            });
          }
          else if (sortField === 'analyst_assigned_name') {
            setTableFilters({
              ...tableFilters,
              sortField: 'assignedAnalyst',
              sortOrder: sortOrder,
            });
          }
          else{
            setTableFilters({
              ...tableFilters,
              sortField: sortField,
              sortOrder: sortOrder,
            });
          }
          break
      }
      default:
        return false;
    }
  };

  const onRemoveClick = (id) => {
    setSelectedId(id);
    setDeletionModal(true);
  };

  const toggleAssetFlag = (asset) => {
    toggleSoftwareFlagMutation.mutate(
      {
        softwareId: asset.id,
      },
      {
        onSuccess: () => {
          setSoftwares({
            ...softwares,
            assets: softwares.assets.map((software) => {
              if (software.id === asset.id) {
                return {
                  ...software,
                  flag: !asset.flag,
                };
              }
              return software;
            }),
          });
        },
      }
    );
  };

  const handleRenderFlag = (asset) => {
    return (
      <span
        onClick={() => toggleAssetFlag(asset)}
        className="flag-icon d-flex align-items-center justify-content-center text-center"
        role="button"
      >
        {asset.flagIsUpdating === true ? (
          <Spinner animation="border" variant="secondary" size="sm" />
        ) : (
          <img
            src={asset.flag ? flagOnIcon : flagOffIcon}
            alt="Flag Icon"
            className="object-fit-contain d-inline-block mw-100"
            style={{ width: "24px" }}
          />
        )}
      </span>
    );
  };

  const handleRenderPriority = (priority) => {
    switch (priority) {
      case "HIGH": {
        return (
          <div className="priority text-capitalize">
            <span className="priority-icon d-flex align-items-center justify-content-center">
              <img
                src={highPriorityIcon}
                alt="Flag Icon"
                className="object-fit-contain d-inline-block mw-100"
              />
            </span>
            {t(priority.toLowerCase())}
          </div>
        );
      }
      case "MEDIUM": {
        return (
          <div className="priority text-capitalize">
            <span className="priority-icon d-flex align-items-center justify-content-center">
              <img
                src={mediumPriorityIcon}
                alt="Flag Icon"
                className="object-fit-contain d-inline-block mw-100"
              />
            </span>
            {t(priority.toLowerCase())}
          </div>
        );
      }
      case "LOW": {
        return (
          <div className="priority text-capitalize">
            <span className="priority-icon d-flex align-items-center justify-content-center">
              <img
                src={lowPriorityIcon}
                alt="Flag Icon"
                className="object-fit-contain d-inline-block mw-100"
              />
            </span>
            {t(priority.toLowerCase())}
          </div>
        );
      }
      default: {
        return (
          <div className="priority text-capitalize">
          <span className="priority-icon d-flex align-items-center justify-content-center">
            <img
              src={lowPriorityIcon}
              alt="Flag Icon"
              className="object-fit-contain d-inline-block mw-100"
            />
          </span>
          {t(priority.toLowerCase())}
        </div>
        )
      }
    }
  };

  const handleRenderStatus = (status) => {
    const statusData = Assets_STATUS_LIST[status];
  
    if (!statusData) {
      return "---";
    }
  
    return (
      <div
        style={{
          fontSize: "10px",
          fontWeight: 700,
          padding: "3px 8px",
          background: statusData.backgroundColor,
          opacity: statusData?.opacity || 1,
          color:"#1D1D1D",
          borderRadius:'7px',
          whiteSpace:'nowrap',
          width:'fit-content'
        }}
        className={"dt-task-status "}
      >
        {t(statusData.title)}
      </div>
    );
  };

  const handleDownloadCSV = async (fileName) => {
    try {
      const service = DataProtectionSoftwareService.getInstance();
      const result = await service.downloadCSV();
      const fileData = new Uint8Array(result);
      const blob = new Blob([fileData], { type: "application/octet-stream" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      // document.body.removeChild(link);
    } catch (error) {
      console.log(error)
      toast(props.t("An error occurred while downloading CSV."), {
        type: "error",
      });
      console.error("failed to download csv: ", error);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((currentIds) => {
      if (currentIds.includes(id)) {
        // Remove id from the array if it is already included
        return currentIds.filter((currentId) => currentId !== id);
      } else {
        // Add id to the array if it is not included
        return [...currentIds, id];
      }
    });
  };

  const onTableFilterOkButtonClicked = (filters) => {    
    let result = {};
    for (const tableFilterKey in tableFilters) {
      result[[tableFilterKey]] = filters[tableFilterKey];
    }

    setTableFilters(result);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageIndex = searchParams.get("pageIndex");
    if (pageIndex !== null && !Number.isNaN(Number(pageIndex)))
      setPageIndex(Number(pageIndex));

    const pageSize = searchParams.get("pageSize");
    if (pageSize !== null && !Number.isNaN(Number(pageSize)))
      setPageSize(Number(pageSize));
  }, [location.search]);

  useEffect(() => {
    if (handleFetchSoftwareListQuery.data) {
      setTableResultTotalCount(
        Number(handleFetchSoftwareListQuery.data.itemsCount) || 0
      );
    }

    if (deletionComplete) {
      handleFetchSoftwareListQuery.refetch();
      setDeletionComplete(false);
    }
  }, [deletionComplete, handleFetchSoftwareListQuery.data]);

  useEffect(() => {
    if (handleFetchSoftwareListQuery.data) {
      setTotalCount(Number(handleFetchSoftwareListQuery.data.itemsCount));
    }

    handleFetchSoftwareListQuery.refetch();
  }, [tab]);


  useEffect(() => {
    if (handleFetchSoftwareListQuery.data) {
      setSoftwares(handleFetchSoftwareListQuery.data);
    }
    
  }, [handleFetchSoftwareListQuery.data]);

  useEffect(() => {
    if (handleFetchUsersListQuery.data) {
      setUsers(handleFetchUsersListQuery.data.map(user => {
        return {
          value: user.id,
          label: user?.first_name + " " + user?.last_name
        }
      }));
    }
    
  }, [handleFetchUsersListQuery.data]);

  useEffect(() => {
    handleFetchSoftwareListQuery.refetch();
  }, []);




  return (
    <PageContent classNames="data-protection-software-list-page pt-4 mt-3">
      {/* Create Modal */}
      <DataProtectionSoftwareCreate
        createModalOpen={createModalOpen}
        fetchData={() => handleFetchSoftwareListQuery.refetch()}
        toggleModal={() => setCreateModalOpen(!createModalOpen)}
      />
      <Container fluid>
        {/* page header */}
        <Row
          title={props.t("Software Assets List")}
          className="d-flex mb-5"
          style={{ justifyContent: "space-between" }}
        >
          <Col md="auto">
            <Button
              outline
              color="primary"
              disabled={!selectedIds?.length}
              onClick={() => onApproveSelection.mutate()}
            >
              {props.t("Approve Selected")}
            </Button>
          </Col>

          <Col className="flex-grow-1"></Col>

          {/* Select components for status, flags, and asset owner, with fit-content width */}
          <Col md="auto" className="d-flex">
            <TableFilter
              defaultValues={{
                ...tableFilters,
              }}
              statuses={SoftwareStatuses}
              onOkButtonClicked={onTableFilterOkButtonClicked}
              type={props.type}
            />
          </Col>

          {/* Right side Select components and buttons */}
          <Col md="auto" className="d-flex justify-content-end">
            <Button
              style={{ height: "fit-content" }}
              className="btn me-2"
              color="primary"
              outline
              // to={"create"}
              onClick={() => setCreateModalOpen(!createModalOpen)}
            >
              {props.t("Add New Asset")}
            </Button>
            <Button
              style={{ height: "fit-content" }}
              className="btn btn-primary me-2"
              color="primary"
              outline
              // to={"create"}
              onClick={() => handleFetchSoftwareQuery()}
            >
              {props.t("Fetch Software")}
            </Button>
            <Button
              className="btn btn-primary me-2"
              color="primary"
              style={{ height: "fit-content" }}
              onClick={() =>
                handleDownloadCSV(
                  `Software List - ${new Date().toDateString()}.xlsx`
                )
              }
            >
              {props.t("Download CSV")}
            </Button>
            <Button
              className="btn btn-primary"
              color="primary"
              style={{ height: "fit-content" }}
              onClick={() => openUploadDialog(uploadCSV.mutate)}
            >
              {props.t("Upload CSV")}
            </Button>
          </Col>
        </Row>

        {/* page body */}
        <PageBody>
          <Row>
            <Col sm="12">
              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  page: tableFilters.pageIndex,
                  sizePerPage: tableFilters.pageSize,
                  totalSize: tableResultTotalCount,
                  withFirstAndLast: false,
                  alwaysShowAllBtns: true,
                  prePageText: (
                    <span>
                      <i className="ri-arrow-left-s-line"></i> {t("Back")}
                    </span>
                  ),
                  nextPageText: (
                    <span>
                      {t("Next")} <i className="ri-arrow-right-s-line"></i>
                    </span>
                  ),
                  prePageTitle: t("Pre page"),
                  firstPageTitle: t("Next page"),
                  showTotal: true,
                  hideSizePerPage: false,
                  sizePerPageList: [
                    {
                      text: "25",
                      value: 25,
                    },
                    {
                      text: "50",
                      value: 50,
                    },
                  ],
                })}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <React.Fragment>
                    <Row>
                      <Col sm="12">
                        <BootstrapTable
                          remote={{
                            pagination: true,
                            filter: false,
                            sort: true,
                            cellEdit: false,
                            search: false,
                          }}
                          loading={
                            handleFetchSoftwareListQuery.isFetching ||
                            handleFetchSoftwareListQuery.isLoading
                          }
                          overlay={overlayFactory({
                            spinner: (
                              <Spinner
                                animation="border"
                                variant="primary"
                                size="md"
                              />
                            ),
                            text: "Loading...",
                          })}
                          onTableChange={handleTableChange}
                          defaultSorted={[]}
                          keyField={"id"}
                          responsive
                          bordered={false}
                          data={softwares?.assets || []}
                          striped={false}
                          columns={tableColumns}
                          wrapperClasses="table-responsive"
                          classes={"table tpdd-table"}
                          headerWrapperClasses={"thead-light"}
                          style={{
                            overflowX: "auto",
                          }}
                          noDataIndication={() => <NoDataIndication />}
                          {...paginationTableProps}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12" md="6">
                        <div className="tpdd-pagination-style-1">
                          <PaginationListStandalone {...paginationProps} />

                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </PaginationProvider>
            </Col>
          </Row>
        </PageBody>
      </Container>
      {/* Deletion Modal */}
      {users?.length ? (
        <Deletion
          t={t}
          modal={showDeletionModal}
          setModal={setDeletionModal}
          users={users}
          id={selectedId}
          setDeletionComplete={setDeletionComplete}
        />
      ) : null}
    </PageContent>
  );
};

export default withNamespaces()(
  withRouter(
    connect(null, {
      errorNotification,
      successNotification,
    })(memo(DataProtectionSoftwareList))
  )
);
