export const MESSAGE_DATA_DELETED_SUCCESSFULLY =
  "MESSAGE_DATA_DELETED_SUCCESSFULLY";
export const MESSAGE_DATA_SAVED_SUCCESSFULLY =
  "MESSAGE_DATA_SAVED_SUCCESSFULLY";
export const FILE_UPDATED_SUCCESSFULLY = "File is updated successfully";
export const FILE_NOT_UPLOADED =
  "Your file could not be uploaded. The file is corrupted or the size is bigger than 1 GB. Please check your file or contact the support.";
export const MESSAGE_SET_PASSWORD_SUCCESSFULLY =
  "MESSAGE_SET_PASSWORD_SUCCESSFULLY";

export const LIST_UPDATED_SUCCESSFULLY =
  "The list has been updated successfully";

export const EMPTY_LIST = "This list is empty";

export const DATA_UPDATED_SUCCESSFULLY = "The data is updated successfully";
export const DATA_NOT_UPDATED_SUCCESSFULLY =
  "The data has not been updated successfully";
export const DATA_SAVED_SUCCESSFULLY = "The data is saved successfully";
export const DATA_NOT_SAVED_SUCCESSFULLY =
  "The data has not been saved successfully";
export const DATA_RESTORED = "Data is restored successfully";
export const DATA_NOT_RESTORED = "Data has not been restored";
/****************************** REPORT ********************* */
export const REPORT_CREATTION_FAILED = "Report creation failed";

/**************************** TASK MANAGER ************************* */
export const TASK_DELETED_SUCCESSFULLY = "Task is deleted successfully";
export const TASK_NOT_DELETED_SUCCESSFULLY =
  "Task can not be deleted successfully";
export const TASK_FILE_DELETED_SUCCESSFULLY = "File is deleted successfully";
export const TASK_FILE_NOT_DELETED_SUCCESSFULLY =
  "The file hasn't deleted successfully.";
export const TASK_FILE_CAN_BE_DELETED_BY_CREATOR =
  "This file can only be deleted by creator";
export const TASK_CREATED_SUCCESSFULLY = "Task is created successfully";
export const TASK_NOT_CREATED_SUCCESSFULLY =
  "The task hasn't created successfully.";
export const COMMENT_POSTED_SUCCESSFULLY = "Comment is posted successfully";
export const COMMENT_NOT_POSTED_SUCCESSFULLY =
  "The Comment hasn't posted successfully.";
export const DESCRIPTION_UPDATED_SUCCESSFULLY =
  "The description is updated successfully";
export const DESCRIPTION_NOT_UPDATED_SUCCESSFULLY =
  "The description has not been updated.";
export const STATUS_UPDATED_SUCCESSFULLY = "The status is updated successfully";
export const STATUS_NOT_UPDATED_SUCCESSFULLY =
  "The status has not been updated.";
export const PRIORITY_UPDATED_SUCCESSFULLY =
  "The priority is updated successfully";
export const PRIORITY_NOT_UPDATED_SUCCESSFULLY =
  "The priority has not been updated.";
export const OWNER_UPDATED_SUCCESSFULLY = "The owner is updated successfully";
export const OWNER_NOT_UPDATED_SUCCESSFULLY = "The owner has not been updated.";
export const CATEGORY_UPDATED_SUCCESSFULLY =
  "The category is updated successfully";
export const CATEGORY_NOT_UPDATED_SUCCESSFULLY =
  "The category has not been updated.";
export const SUBCATEGORY_UPDATED_SUCCESSFULLY =
  "The subcategory is updated successfully";
export const SUBCATEGORY_NOT_UPDATED_SUCCESSFULLY =
  "The subcategory has not been updated.";

//
export const UPDATE_READONLY_ANALYSTS_SUCCESSFUL =
  "Read-only analysts updated successfully.";
export const UPDATE_READONLY_ANALYSTS_FAILED =
  "Failed to update read-only analysts.";
export const FETCH_CASES_FAILED = "Failed to fetch cases. please try again.";
export const FETCH_ANALYSTS_FAILED =
  "Failed to fetch analysts. please try again.";
export const UPDATE_LINK_CASE_SUCCESSFUL = "Link case updated successfully.";
export const UPDATE_LINK_CASE_FAILED = "Failed to update link case.";
export const FETCH_ANALYSTS_AND_ANALYST_ADMINS_FAILED =
  "Failed to fetch analysts and analyst admins. please try again.";
export const UPDATE_ASSIGNED_ANALYST_SUCCESSFUL =
  "Assigned analyst updated successfully.";
export const UPDATE_ASSIGNED_ANALYST_FAILED =
  "Failed to update assigned analyst.";
export const VERIFY_ORGANIZATION_FAILED =
  "Failed to verify organization. please try again.";
export const FETCH_ORGANIZATION_QUESTIONS_FAILED =
  "Failed to organization's questions. please try again.";
export const CASE_ALREADY_HAS_BEEN_DELETED =
  "This case has already been deleted.";
export const ASSIGN_CASE_TO_AN_ANALYST =
  "Please assign the case to an analyst first.";
export const CLOSE_CASE_BEFORE_PENDING_DELETION =
  "Please close the case before deletion.";
export const SET_PENDING_DELETION_BEFORE_DELETE =
  "Please set status to pending deletion before delete.";
export const UPDATE_STATUS_SUCCESSFUL = "Status updated successfully.";
export const UPDATE_STATUS_FAILED = "Failed to update status.";
export const DELETE_CASE_SUCCESSFUL = "Case deleted successfully.";
export const DELETE_CASE_FAILED = "Failed to delete case.";
export const FETCH_CASE_LOGS_FAILED = "Failed to fetch the logs of the case.";
export const FETCH_CASE_INTERNAL_COMMENTS_FAILED =
  "Failed to fetch the internal comments of the case.";
export const FETCH_CASE_COMMENTS_FAILED =
  "Failed to fetch the comments of the case.";
export const FETCH_ORGANIZATION_TRANSLATIONS_FAILED =
  "Failed to fetch organization's translations.";
export const FETCH_TASKS_FAILED = "Failed to fetch tasks.";
export const DELETE_TASK_SUCCESSFUL = "Task deleted successfully.";
export const DELETE_TASK_FAILED = "Failed to delete task.";
export const FETCH_OWNERS_FAILED = "Faild to fetch owners.";
export const FETCH_MANAGERS_FAILED = "Faild to fetch managers.";
export const FETCH_TASKS_CATEGORIES_FAILED = "Faild to fetch categories.";
export const UPLOAD_ATTACHMENT_FAILED =
  "Your file could not be uploaded. The file is corrupted or the size is bigger than 1 GB. Please check your file or contact the support.";
export const CREATE_RISK_SUCCESSFUL = "Risk created successfully.";
export const CREATE_RISK_FAILED = "Failed to create risk.";
export const FETCH_RISKS_CATEGORIES_FAILED = "Faild to fetch categories.";
export const CREATE_TASK_SUCCESSFUL = "Task created successfully.";
export const CREATE_TASK_FAILED = "Failed to create task.";
export const SUBMIT_INTERNAL_COMMENT_FAILED =
  "Failed to submit internal comment.";
export const SUBMIT_MESSAGE_FAILED = "Failed to submit message.";
export const FETCH_MATRIX_FAILED = "Failed to fetch matrix.";
export const FETCH_RISKS_FAILED = "Failed to fetch risks.";
export const DELETE_RISK_SUCCESSFUL = "Risk deleted successfully.";
export const DELETE_RISK_FAILED = "Failed to delete risk.";
export const FETCH_LOCATIONS_FAILED = "Failed to fetch locations.";
export const FETCH_TASK_FAILED = "Failed to fetch task.";
export const UPDATE_CATEGORY_SUCCESSFUL = "Category updated successfully.";
export const UPDATE_CATEGORY_FAILED = "Failed to update category.";
export const UPDATE_DESCRIPTION_SUCCESSFUL =
  "Description updated successfully.";
export const UPDATE_DESCRIPTION_FAILED = "Failed to update description.";
export const UPDATE_OWNER_SUCCESSFUL = "Owner updated successfully.";
export const UPDATE_OWNER_FAILED = "Failed to update owner.";
export const UPDATE_PRIORITY_SUCCESSFUL = "Priority updated successfully.";
export const UPDATE_PRIORITY_FAILED = "Failed to update priority.";
export const UPDATE_DEADLINE_SUCCESSFUL = "Deadline updated successfully.";
export const UPDATE_DEADLINE_FAILED = "Failed to update deadline.";
export const UPDATE_DAMAGE_SUCCESSFUL = "Damage updated successfully.";
export const UPDATE_DAMAGE_FAILED = "Failed to update damage.";
export const UPDATE_LIKELIHOOD_SUCCESSFUL = "Likelihood updated successfully.";
export const UPDATE_LIKELIHOOD_FAILED = "Failed to update likelihood.";
export const FETCH_RISK_FAILED = "Failed to fetch risk.";
export const INVALID_DEADLINE_DATE = "Deadline date can't be less than today.";
export const SUBMIT_COMMENT_FAILED = "Failed to submit comment.";
export const SUBMIT_COMMENT_SUCCESSFUL = "Comment added successfully.";
export const FETCH_COMMENTS_FAILED = "Failed to fetch comments.";
export const DELETE_FILE_FAILED = "Failed to delete file.";
export const DELETE_FILE_SUCCESSFUL = "File deleted successfully.";
export const DOWNLOAD_FILE_FAILED = "Failed to download file.";
export const UPDATE_FILE_SUCCESSFUL = "Selected file updated successfully.";
export const UPDATE_FILE_FAILED = "Failed to update selected file.";
export const SUBCATEGORY_IS_REQUIRED = "Subcategory can't be empty.";
export const CATEGORY_IS_REQUIRED = "Category can't be empty.";
export const DESCRIPTION_IS_REQUIRED = "Description can't be empty.";
export const READ_ONLY_ANALYST_GRANT_FULL_ACCESS =
  "The user has been removed from the read-only analysts role and now has full access.";
export const FAILED_TO_CHANGE_RISK_RATE = "Failed to update risk rate.";
export const ASSIGN_CASE_BEFORE_CHANGING_STATUS =
  "Please assign the case to an analyst before changing the status.";
/**************************** NOTIFICATIONS ************************* */
export const NOTIFICATIONS_DELETED_FAILED =
  "An error occurred while deleting notifications.";
export const NOTIFICATIONS_DELETED_SUCCESSFULLY =
  "Notifications Cleared! All notifications have been successfully deleted.";
