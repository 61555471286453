import { memo, useEffect, useState } from 'react';
import { Col, Row, Label, Tooltip} from 'reactstrap';
import { AvRadioGroup } from 'availity-reactstrap-validation';
import Option from './Option';
import { withNamespaces } from 'react-i18next';

const Radiobox = (props) => {
    const {
        questionIndex,
        id, 
        name,
        title, 
        isMandatory, 
        key,
        options,
        elRefIdPrefix,
        defaultValue,
        helpText,
        questionRendererFunc,
        onChange,
        onAnyOptionSelected,
        onNoOptionSelected
    } = props;

    const [selectedOptionId, setSelectedOptionId] = useState(defaultValue?.value || '');

    const [helpTooltipTextStatus, setHelpTooltipTextStatus] = useState(false);

    const [optionChildren, setOptionChildren] = useState([]);

    const defaultOptions = [
        {
            id      :   1,
            title   :   "Yes",
            type    :   "radiobox"
        },
        {
            id      :   0,
            title   :   "No",
            type    :   "radiobox"
        }
    ];

    useEffect(() => {
        if(selectedOptionId){
            const selectedOption = (options || defaultOptions).find((option) => selectedOptionId.toString() === (option.id).toString());

            if(selectedOption && selectedOption.children && selectedOption.children.length > 0){
                setOptionChildren(selectedOption.children);
            }
            else{
                setOptionChildren([]);
            }
        }
    }, [selectedOptionId]);

    return (
        <Row key={key}>
            <Col sm="12">
                <div className="mb-3">
                    <Label
                        className="form-label"
                        htmlFor={`${elRefIdPrefix}-question-${id}`} >
                        {
                            parseInt(questionIndex) >= 0 ? (questionIndex + 1 + '. ') : ''
                        } {props.t(title)}

                        {
                            helpText && (
                                <span className="ms-2 tooltip-container">
                                    <i
                                        className="fa fa-info-circle help-text-icon"
                                        aria-hidden="true"
                                        id={`${elRefIdPrefix}-question-${id}-help-text`}
                                    />
                                    <Tooltip
                                        style={{
                                            backgroundColor: '#899ba1',
                                            fontWeight: 'bold'
                                        }}
                                        placement="top"
                                        isOpen={ helpTooltipTextStatus }
                                        target={`${elRefIdPrefix}-question-${id}-help-text`}
                                        toggle={() =>
                                            setHelpTooltipTextStatus(!helpTooltipTextStatus)
                                        }>
                                            {
                                                <p>
                                                    {props.t(helpText)}
                                                </p>
                                            }
                                    </Tooltip>
                                </span>
                            )
                        }
                    </Label>
                    <AvRadioGroup
                        name={name ? name : ("question[" + id + "]")}
                        onChange={(e) => {
                            e.target.type === 'radio' && setSelectedOptionId(e.target.value);
                        }}
                        validate={{
                            required: { value: isMandatory },
                        }}
                        defaultValue={defaultValue?.value}
                        errorMessage={props.t(
                            "This field cannot be blank"
                        )}>
                        <Row>
                            {(options || defaultOptions).map(
                                (option) => {
                                    return (
                                        <Col sm="12" md="6" key={`${id}-${option.id}`}>
                                            <Option type={option.type}
                                                    title={option.title}
                                                    id={option.id}
                                                    groupId={id}
                                                    elRefIdPrefix={elRefIdPrefix}
                                                    isSelected={selectedOptionId.toString() === (option.id).toString()}
                                                    defaultValue={(defaultValue && defaultValue?.title) ? defaultValue.title : ''}
                                                    extendedTitle={option.extended_title}
                                                    onChange={(e) => {
                                                        onChange && onChange(e);
                                                        
                                                        option.checked = e.target.checked;
    
                                                        if(option.checked){
                                                            onAnyOptionSelected && onAnyOptionSelected(option);
                                                        }
                                                        else{
                                                            if(!options.filter((o) => o?.checked).length){
                                                                onNoOptionSelected && onNoOptionSelected();
                                                            }
                                                        }
                                                    }} />
                                        </Col>
                                    );
                                }
                            )}
                        </Row>
                    </AvRadioGroup>
                    {
                        optionChildren.length > 0 && (
                            <div className="children-questions-container">
                                {
                                    optionChildren.map((subQuestion, index) => {
                                        subQuestion.parentQuestionId = id;
                                        subQuestion.isReactSubQuestion = true;
                                        return (
                                            <div className='children-question' key={index}>
                                                { questionRendererFunc(subQuestion, `sub_questions[${id}][${selectedOptionId}][${subQuestion.id}]`) }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                </div>
            </Col>
        </Row>
    );
}

export default withNamespaces()(memo(Radiobox));