import HorizontalLayout from '../../../components/HorizontalLayout';
import ThirdPartyQuestioner from '../pages/thirdparty/questioner';
import ThirdpartyHome from '../pages/thirdparty/home';
import Reports from '../pages/thirdparty/reports/list';
import ReportDetails from '../pages/thirdparty/reports/details/index';

import {
	ROUTE_PATH_THIRDPARTY_HOME,
	ROUTE_PATH_THIRDPARTY_FILL_QUESTIONNAIRE,
	ROUTE_PATH_THIRD_PARTY_REPORT_LIST,
	ROUTE_PATH_THIRD_PARTY_REPORT_DETAILS
} from '../constants';

const thirdPartyRoutes = [
	{
		path		: 	ROUTE_PATH_THIRDPARTY_HOME,
		component	: 	ThirdpartyHome,
		layout		: 	HorizontalLayout,
		type		: 	'third-party',
		modules		: 	['tp']
	},
	{
		path		: 	ROUTE_PATH_THIRDPARTY_FILL_QUESTIONNAIRE,
		component	: 	ThirdPartyQuestioner,
		layout		: 	HorizontalLayout,
		type		: 	'third-party',
		modules		: 	['tp']
	},
	{
		path		: 	ROUTE_PATH_THIRD_PARTY_REPORT_DETAILS,
		component	: 	ReportDetails,
		layout		: 	HorizontalLayout,
		type		: 	'third-party',
		modules		: 	['tp']
	},
	{
		path		: 	ROUTE_PATH_THIRD_PARTY_REPORT_LIST,
		component	: 	Reports,
		layout		: 	HorizontalLayout,
		type		: 	'third-party',
		modules		: 	['tp']
	}
];

export default thirdPartyRoutes;

export {
	thirdPartyRoutes
};