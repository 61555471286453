import {
    ROLE_TASK_OWNER
} from "src/components/constants";

import {
    ROUTE_PATH_EMPLOYEE_DOCUMENTS,
    ROUTE_PATH_REPORT_LIST,
    ROUTE_PATH_REPORT_ANONYMOUSLY,
    ROUTE_PATH_REPORT_CONFIDENTLY,
    ROUTE_PATH_ARRANGE_MEETING,
    ROUTE_PATH_EMPLOYEE_HOME,
    ROUTE_PATH_EMPLOYEE_E_LEARNING,
    ROUTE_PATH_EMPLOYEE_TUTORIAL,
    ROUTE_PATH_VOICE_REPORT,
    ROUTE_PATH_EMPLOYEE_TASKS_LIST,
} from "src/common/constants";

const EmployeeMenuItems = [
    {
        titleKey    :   "Home",
        path        :   ROUTE_PATH_EMPLOYEE_HOME,
        icon        :   "ri-home-4-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey: "My reports",
        path: ROUTE_PATH_REPORT_LIST,
        icon: "ri-mail-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey: "My Tasks",
        path: ROUTE_PATH_EMPLOYEE_TASKS_LIST,
        icon: "ri-pencil-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [
            ROLE_TASK_OWNER
        ],
        customValidator :   null
    },
    {
        titleKey: "Add A New Case",
        path: "#",
        icon: "ri-mail-send-line",
        linkType        :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null,
        items: [
            {
                titleKey    :   "Report anonymously",
                path        :   ROUTE_PATH_REPORT_ANONYMOUSLY,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   "isAnonymously",
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            },
            {
                titleKey    : "Report confidentially",
                path        : ROUTE_PATH_REPORT_CONFIDENTLY,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   "isConfidentially",
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            },
            {
                titleKey    : "Arrange a meeting",
                path        : ROUTE_PATH_ARRANGE_MEETING,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   "isMeeting",
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            },
            {
                titleKey    : "Voice report",
                path        : ROUTE_PATH_VOICE_REPORT,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   "isVoice",
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            },
        ],
    },
    {
        titleKey    :   "Documents",
        path        :   ROUTE_PATH_EMPLOYEE_DOCUMENTS,
        icon        :   "ri-book-open-line",
        linkType    :   "react-router",
        moduleFeatures  :   [
            'Documents'
        ],
        authProtected   :   true,
        settingKey      :   null,
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "E-Learning",
        path        :   ROUTE_PATH_EMPLOYEE_E_LEARNING,
        icon        :   "ri-video-line",
        linkType    :   "react-router",
        moduleFeatures  :   [],
        authProtected   :   true,
        settingKey      :   "isElearning",
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Tutorial",
        path        :   ROUTE_PATH_EMPLOYEE_TUTORIAL,
        icon        :   "ri-video-line",
        linkType    :   "react-router",
        moduleFeatures  :   [],
        authProtected   :   true,
        settingKey      :   null,
        roles           :   [],
        customValidator :   null
    }
];

export default EmployeeMenuItems;