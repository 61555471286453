import React, { useState, useEffect} from 'react'
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { API_URL_GET_QR_CODE} from "src/common/constants/ApiRoutes"
import { API_BASE_URL} from "src/modules/data-protection/constants/ApiRoutes"
import {  Card, Button } from "reactstrap";
import axios from 'axios';
import "../assets/index.scss"
 const QRCode =(props) => {
    const [qrcode, setQRCode] = useState('');
    const [loading, setLoading] = useState(true);
 
    const ButtonUrl =  API_BASE_URL.replace('/api', '') + (props?.organization?.url) + '/third-party/home';

    const fetchQRCode = () => {
      axios
        .get(API_URL_GET_QR_CODE, {
          headers: {
            Authorization: `Bearer ${props?.token}`,
          },
          responseType: "blob",
        })
        .then((response) => {
          const href = URL.createObjectURL(response?.data);
          setQRCode(href);
          setLoading(false);
        })
        .catch((error) => {
          if (error.response) {
            const data = error.response.data;
            toast.error(props.t(data.message || data.title));
          }
        });
    };


    useEffect(() => {
        fetchQRCode();
      }, []);
   
      if (loading) {
        return (
          <div
            className="dt-field dt-skeleton"
            style={{ width: "100%", height: "320px" }}
          ></div>
        );
      }

      const handleButtonClick = () => {
        navigator.clipboard.writeText(ButtonUrl)
          .then(() => {
            toast.success(props.t('URL copied to clipboard'));
          })
          .catch((error) => {
            toast.error(props.t('Failed to copy URL'));
          });
      };

      
      return (
        <div className='d-flex justify-content-center align-items-center'>
            <Card className='p-4 d-flex justify-content-center align-items-center mt-4' 
            style={{boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'}}>
            <h4 className="card-title">{`${props.t("QR Code")}: `}{props.organization?.name}</h4>
                <p>{props.t('Please use this QR code for the user view')}</p>
                <img src={qrcode} alt="qrcode" width={'248px'} height={'248px'}/>
                <p className='mt-2'>{props.t('In order to access, scan the QR Code above or click on the link below ')}</p>
                <Button className="copyURl" onClick={handleButtonClick} style={{
                      background: "#E0E0E0",
                      border:'unset',
                }}><div style={{
                  color:'#9304FF'
            }}>{props.t('URL link')}</div></Button>
            </Card>
        </div>
      );
}

const mapStatetoProps = (state) => {
	const { token } = state.Login;
    const { Organization } = state;
	return {
		token,
        organization: Organization,
	};
};


export default withNamespaces()(
  withRouter(connect(mapStatetoProps)(QRCode))
);
