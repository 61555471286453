import { memo, useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button, Card, CardBody, Col, FormGroup, Row } from "reactstrap";
import { useQuery } from "@tanstack/react-query";
import Select from "react-select";
import { RiDeleteBin5Line } from "react-icons/ri";
import { MdOutlineModeEdit } from "react-icons/md";
import { VscSaveAs } from "react-icons/vsc";
import { toast } from "react-toastify";
import IndustriesService from "src/modules/3rd-party-management/apis/IndustriesService";

const FactorySitesAndProducts = ({
  t,
  industryId,
  countries,
  setCompanyDetailsTabCompleted,
  goToPrevTab,
  officeAddressCount,
  setOfficeAddressCount,
  officeAddresses,
  setOfficeAddresses,
  factoryAddressCount,
  setFactoryAddressCount,
  factoryAddresses,
  setFactoryAddresses,
  addedProducts,
  setAddedProducts,
  addedProductsCount,
  setAddedProductsCount,
}) => {
  // List of products
  const [ productsList, setProductsList ] = useState();

  const [selectedProductName, setSelectedProductName] = useState(null);
  const [selectedProductDescription, setSelectedProductDescription] = useState("");
  const [ emptyProductsError, setEmptyProductsError ] = useState(false);

  const [editingRowId, setEditingRowId] = useState(null);

  const [tempEdit, setTempEdit] = useState({
    productName: "",
    description: "",
  });

  const handleFetchSupplierIndustryProductsList = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-industry-products-list'],
		queryFn: async () => {
			const service = IndustriesService.getInstance();
        return await service.fetchProducts(industryId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching supplier products list.'), {
				type: 'error',
			});
		},
	});

  useEffect(() => {
    if(handleFetchSupplierIndustryProductsList.data && Array.isArray(handleFetchSupplierIndustryProductsList.data)){
      setProductsList(handleFetchSupplierIndustryProductsList.data.map((product) => {
        return {
          value   :   product.id,
          label    :   product.name
        }
      }));
    }
  }, [ handleFetchSupplierIndustryProductsList.data ]);

  // Factory
  useEffect(() => {
    const difference = factoryAddressCount - factoryAddresses.length;
    if (difference > 0) {
      const newAddresses = Array(difference)
        .fill(null)
        .map((_, index) => ({
          id: `factory-${Date.now() + index}`, 
          country: "",
          city: "",
          extra: "",
        }));
      setFactoryAddresses(factoryAddresses.concat(newAddresses));
    }
  }, [factoryAddressCount]);

  const updateFactoryAddressCountry = (index, newValue) => {
    setFactoryAddresses((currentAddresses) => {
      const newAddresses = [...currentAddresses];
      newAddresses[index] = { ...newAddresses[index], country: newValue, isRequired: true };
      return newAddresses;
    });
  };

  const onDeleteFactory = (index) => {
    const newFactoryAddresses = factoryAddresses.filter((_, i) => i !== index);
    setFactoryAddresses(newFactoryAddresses);
    setFactoryAddressCount((prevCount) => prevCount - 1);
  };

  // Office
  useEffect(() => {
    const difference = officeAddressCount - officeAddresses.length;
    if (difference > 0) {
      const newAddresses = Array(difference)
        .fill(null)
        .map((_, index) => ({
          id: `office-${Date.now() + index}`, 
          country: "",
          city: "",
          extra: "",
        }));
      setOfficeAddresses(officeAddresses.concat(newAddresses));
    }
  }, [officeAddressCount]);

  const updateOfficeAddressCountry = (index, newValue) => {
    setOfficeAddresses((currentAddresses) => {
      const newAddresses = [...currentAddresses];
      newAddresses[index] = { ...newAddresses[index], country: newValue };
      return newAddresses;
    });
  };

  const onDeleteOffice = (index) => {
    const newOfficeAddresses = officeAddresses.filter((_, i) => i !== index);
    setOfficeAddresses(newOfficeAddresses);
    setOfficeAddressCount((prevCount) => prevCount - 1);
  };

  // Products
  useEffect(() => {
    const difference = addedProductsCount - addedProducts.length;
    if (difference > 0) {
      const newProduct = Array(difference)
        .fill(null)
        .map((_, index) => ({
          id: `product-${Date.now() + index}`,
          productName: selectedProductName,
          description: selectedProductDescription,
        }));
      setAddedProducts(addedProducts.concat(newProduct));
      setSelectedProductName(null);
      setSelectedProductDescription("");
    }
  }, [addedProductsCount]);

  const handleEditClick = (item) => {
    setEditingRowId(item.id);
    setTempEdit({
      productName: item.productName,
      description: item.description,
    });
  };

  const handleChange = (e, field) => {
    setTempEdit({ ...tempEdit, [field]: e.target.value });
  };

  const handleSave = (id) => {
    const updatedProducts = addedProducts.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          productName: tempEdit.productName,
          description: tempEdit.description,
        };
      }
      return item;
    });

    setAddedProducts(updatedProducts);
    setEditingRowId(null); // Exit editing mode
    setTempEdit({ productName: "", description: "" }); // Clear temporary edits
  };

  const handleDelete = (id) => {
    const updatedProducts = addedProducts.filter((item) => item.id !== id);
    setAddedProducts(updatedProducts);
    setAddedProductsCount(addedProductsCount - 1);
  };


  useEffect(() => {
    if(addedProducts.length > 0){
        setEmptyProductsError(false)
    }
  }, [addedProducts])

  // General
  const handleBack = () => {
    goToPrevTab(1);
  };

  return (
    <>
      <AvForm
        className="needs-validation"
        onValidSubmit={() => {
            if(!addedProducts.length) {
                setEmptyProductsError(true)
            }else{
                return setCompanyDetailsTabCompleted("factory-sites-and-products");
            }
        }}
        id="factory_sites_and_products"
      >
        <Card className="wizard-steps-content-container">
          <CardBody className="p-0">
            <p className="sub-header medium">
              {t("Location")}
            </p>
            {/* Office Address */}
            <label>{t("Office Address")}: </label>
            {officeAddresses.map((item, i) => {
              return (
                <Row className="mb-2" key={item.id}>
                  {/* Country */}
                  <Col md="3" sm="12">
                    <Select
                      placeholder={t("Country")}
                      classNamePrefix="select2-selection"
                      id={`officeCountry`}
                      options={countries}
                      menuPortalTarget={document.body}
                      onChange={(e) => {
                        updateOfficeAddressCountry(i, e);
                      }}
                      value={officeAddresses[i]?.country}
                    />
                    <AvField
                      name={`officeCountry`}
                      placeholder=""
                      type="hidden"
                      errorMessage={t("This field cannot be blank")}
                      className="form-control"
                      validate={{
                        required: { value: true },
                      }}
                      value={officeAddresses[i]?.country?.label}
                    />
                  </Col>

                  {/* City */}
                  <Col md="3" sm="12">
                    <AvField
                      name={`officeCity${i}`}
                      placeholder={t("City")}
                      type="text"
                      errorMessage={t("This field cannot be blank")}
                      className="form-control"
                      validate={{
                        required: { value: true },
                      }}
                      id={`officeCity${i}`}
                      onChange={(e) => {
                        officeAddresses[i].city = e.target.value;
                      }}
                      value={officeAddresses[i]?.city}
                    />
                  </Col>

                  {/* Extra */}
                  <Col md="5" sm="12">
                    <AvField
                      name={`officeExtra${i}`}
                      placeholder={t("Address")}
                      type="text"
                      errorMessage={t("This field cannot be blank")}
                      className="form-control"
                      validate={{
                        required: { value: true },
                      }}
                      id={`officeExtra${i}`}
                      onChange={(e) => {
                        officeAddresses[i].extra = e.target.value;
                      }}
                      value={officeAddresses[i].extra}
                    />
                  </Col>

                  <Col md="1" sm="12" hidden={officeAddressCount === 1}>
                    <h4
                      style={{ cursor: "pointer" }}
                      className="p-1 text-danger"
                    >
                      <RiDeleteBin5Line onClick={() => onDeleteOffice(i)} />
                    </h4>
                  </Col>
                </Row>
              );
            })}
            <Row className="mt-3">
              <Col md="10"></Col>
              <Col
                md="2"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  color="primary"
                  onClick={() => {
                    setOfficeAddressCount(officeAddressCount + 1);
                  }}
                  outline
                >
                  {" + " + t("Add Location")}
                </Button>
              </Col>
            </Row>

            {/* Factory Location */}
            <label>{t("Factory Location")}: </label>
            {factoryAddresses.map((item, i) => {
              return (
                <Row className="mb-2" key={item.id}>
                  {/* Country */}
                  <Col md="3" sm="12">
                    <Select
                      placeholder={t("Country")}
                      classNamePrefix="select2-selection"
                      id={`factoryCountry`}
                      options={countries}
                      menuPortalTarget={document.body}
                      onChange={(e) => {
                        updateFactoryAddressCountry(i, e);
                      }}
                      value={factoryAddresses[i]?.country}
                    />
                    <AvField
                      name={"factoryCountry"}
                      placeholder=""
                      type="hidden"
                      errorMessage={t("This field cannot be blank")}
                      className="form-control"
                      validate={{
                        required: { value: !!factoryAddresses[i]?.isRequired },
                      }}
                      value={factoryAddresses[i]?.country?.label || ""}
                    />
                  </Col>

                  {/* City */}
                  <Col md="3" sm="12">
                    <AvField
                      name={`factoryCity${i}`}
                      placeholder={t("City")}
                      type="text"
                      errorMessage={t("This field cannot be blank")}
                      className="form-control"
                      validate={{
                        required: { value: !!factoryAddresses[i]?.isRequired },
                      }}
                      id={`factoryCity${i}`}
                      onChange={(e) => {
                        factoryAddresses[i].city = e.target.value;
                      }}
                      value={factoryAddresses[i].city}
                    />
                  </Col>

                  {/* Extra */}
                  <Col md="5" sm="12">
                    <AvField
                      name={`factoryExtra${i}`}
                      placeholder={t("Address")}
                      type="text"
                      errorMessage={t("This field cannot be blank")}
                      className="form-control"
                      validate={{
                        required: { value: !!factoryAddresses[i]?.isRequired },
                      }}
                      id={`factoryExtra${i}`}
                      onChange={(e) => {
                        factoryAddresses[i].extra = e.target.value;
                      }}
                      value={factoryAddresses[i].extra}
                    />
                  </Col>

                  <Col md="1" sm="12">
                    <h4
                      style={{ cursor: "pointer" }}
                      className="p-1 text-danger"
                    >
                      <RiDeleteBin5Line onClick={() => onDeleteFactory(i)} />
                    </h4>
                  </Col>
                </Row>
              );
            })}
            <Row className="mt-3">
              <Col md="10"></Col>
              <Col
                md="2"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  color="primary"
                  onClick={() =>
                    setFactoryAddressCount(factoryAddressCount + 1)
                  }
                  outline
                >
                  {" + " + t("Add Location")}
                </Button>
              </Col>
            </Row>

            {/* List of products supplied */}
            <label>{t("List of products supplied")}</label>

            <Row className="mb-2">
              <div className="table-container">
                <table>
                  <thead hidden={!addedProducts.length}>
                    <tr>
                      <td style={{ width: "20%" }}>{t("Product Name")}</td>
                      <td style={{ width: "70%" }}>{t("Description")}</td>
                      <td style={{ width: "10%" }}>{t("Action")}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {addedProducts.length
                      ? addedProducts.map((item, index) => {
                          const isEditing = item.id === editingRowId;
                          return (
                            <tr key={item?.id}>
                              <td style={{ width: "20%" }}>
                                {isEditing ? (
                                  <>
                                    <Select
                                      isLoading={ handleFetchSupplierIndustryProductsList.isLoading || handleFetchSupplierIndustryProductsList.isFetching }
                                      isDisabled={ handleFetchSupplierIndustryProductsList.isLoading || handleFetchSupplierIndustryProductsList.isFetching }
                                      placeholder={t("Product")}
                                      classNamePrefix="select2-selection"
                                      options={ productsList }
                                      menuPortalTarget={document.body}
                                      onChange={(e) => {
                                        setTempEdit({ ...tempEdit, productName  : e });
                                      }}
                                      value={ tempEdit.productName }
                                    />
                                    <AvField
                                      name={`productName${item?.id}`}
                                      type="hidden"
                                      errorMessage={t("This field cannot be blank")}
                                      validate={{
                                        required: { value: true },
                                      }}
                                      value={ tempEdit.productName }
                                    />
                                  </>
                                ) : (
                                  item.productName.label
                                )}
                              </td>
                              <td style={{ width: "70%" }}>
                                {isEditing ? (
                                  <AvField
                                    name={`productDescription${item?.id}`}
                                    type="text"
                                    errorMessage={t(
                                      "This field cannot be blank"
                                    )}
                                    className="form-control"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    id={`productDescription${item?.id}`}
                                    value={tempEdit.description}
                                    onChange={(e) =>
                                      handleChange(e, "description")
                                    }
                                  />
                                ) : (
                                  item.description
                                )}
                              </td>
                              <td style={{ width: "10%" }}>
                                {isEditing ? (
                                  <VscSaveAs
                                    className="text-primary"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleSave(item.id)}
                                  />
                                ) : (
                                  <>
                                    <MdOutlineModeEdit
                                      className="text-primary"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleEditClick(item)}
                                    />
                                    <span
                                      className="p-1"
                                      style={{
                                        color: "rgba(217, 221, 255, 0.6)",
                                      }}
                                    >
                                      |
                                    </span>
                                    <RiDeleteBin5Line
                                      className="text-danger"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleDelete(item.id)}
                                    />
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                  <tr key={"tableForm"}>
                    <td style={{ width: "20%" }}>
                      <Select
                        isDisabled={ handleFetchSupplierIndustryProductsList.isLoading || handleFetchSupplierIndustryProductsList.isFetching }
                        isLoading={ handleFetchSupplierIndustryProductsList.isLoading || handleFetchSupplierIndustryProductsList.isFetching }
                        placeholder={t("Product")}
                        classNamePrefix="select2-selection"
                        options={ productsList }
                        menuPortalTarget={document.body}
                        onChange={(e) => {
                          setSelectedProductName(e);
                        }}
                        value={ selectedProductName }
                      />
                      <AvField
                        name={`productName`}
                        type="hidden"
                        errorMessage={t("This field cannot be blank")}
                        validate={{
                            required: { value: !addedProducts.length },
                        }}
                        value={ selectedProductName }
                    />
                    </td>
                    <td style={{ width: "70%" }}>
                      <AvField
                        name={`productDescription`}
                        placeholder={t("Description")}
                        type="text"
                        errorMessage={t("This field cannot be blank")}
                        className="form-control"
                        id={`productDescription`}
                        value={selectedProductDescription}
                        onChange={(e) => {
                          setSelectedProductDescription(e.target.value);
                        }}
                        validate={{
                            required: { value: !addedProducts.length },
                        }}
                      />
                    </td>
                    <td style={{ width: "10%" }}>
                      <Button
                        color="primary"
                        onClick={() =>
                          setAddedProductsCount(addedProductsCount + 1)
                        }
                        disabled={
                          !selectedProductName || !selectedProductDescription
                        }
                        outline
                      >
                        {" + " + t("Add Product")}
                      </Button>
                    </td>
                  </tr>
                  {emptyProductsError && (
                        <tr>
                            <td colSpan={3}>
                                <div className="alert alert-danger">
                                    <p className="m-0">
                                        {t('Please click Add Product button to save the product in the list')}
                                    </p>
                                </div>
                            </td>
                        </tr>
                    )}
                </table>
              </div>
            </Row>
          </CardBody>
        </Card>

        <Row className="mt-2 mb-4">
          <FormGroup
            style={{
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "space-between",
            }}
            className="actions-buttons-container"
          >
            <Button color="primary" onClick={handleBack} outline>
              {t("Back")}
            </Button>
            <Button color="primary" type="submit">
              {t("Next")}
            </Button>
          </FormGroup>
        </Row>
      </AvForm>
    </>
  );
};

export default withNamespaces()(memo(FactorySitesAndProducts));
