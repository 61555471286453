import { memo, useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import {
  CompanySizes
} from "src/modules/3rd-party-management/constants";

import IndustriesService from "src/modules/3rd-party-management/apis/IndustriesService";

import ThirdpartyUsersService from "src/modules/3rd-party-management/apis/ThirdpartyUsersService";

const filterOptions = (
    candidate,
    input
) => {
    if (input) {
        const loweredInput = input.toLowerCase();

        return candidate.label.toLowerCase().includes(loweredInput) || (candidate.data.keywords || []).find((keyword) => keyword.toLowerCase().includes(loweredInput));
    }

    return true;
};

const CompanyDetails = ({
  t,
  setCompanyName,
  setIndustry,
  setCompanySize,
  setWebsite,
  companyName,
  website,
  industry,
  companySize,
  description,
  setDescription,
  setCompanyDetailsTabCompleted,
}) => {
  const [industries, setIndustries] = useState([]);

  const handleFetchIndustriesListQuery = useQuery({
    queryKey: ['3rd-party-management-fetch-industries-list-query'],
    queryFn: async () => {
      const service = IndustriesService.getInstance();

      return await service.fetchList();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t('An error occurred while fetching industires list.'), {
        type: 'error',
      });
    }
  });

  const {
    mutate      :   checkThirdpartyExistenceMutation,
    isLoading   :   checkingThirdpartyExistenceInProcess
  } = useMutation({
    mutationFn: async (payload) => {
      const service = ThirdpartyUsersService.getInstance();

      return await service.checkThirdpartyExistence(payload);
    }
  });

  const onValidSubmit = (e, values) => {
    checkThirdpartyExistenceMutation({
        name        :   values.companyName,
        websiteUrl  :   values.website
    }, {
        onSuccess: () => {
            setCompanyDetailsTabCompleted('company-details')
        },
        onError: () => {
            toast(t(`The company named ${values.companyName} already exists.`), {
                type: "error",
            });
        }
    })
  }

  useEffect(() => {
    if(handleFetchIndustriesListQuery?.data){
      const data = Array.isArray(handleFetchIndustriesListQuery.data) ? handleFetchIndustriesListQuery.data : [];

      const list = data.map((industry) => {
        return {
          value : industry.id,
          label : `${industry.id} - ${industry.title}`,
          keywords: industry?.keywords || []
        };
      });

      setIndustries(list);
    }
  }, [handleFetchIndustriesListQuery.data]);

  const sizes = [];

  const industriesListIsLoading = handleFetchIndustriesListQuery.isFetching || handleFetchIndustriesListQuery.isLoading;

  for(const sizeKey in CompanySizes){
    sizes.push({
      value : sizeKey,
      label : `${CompanySizes[sizeKey]?.title} ${t("Employees")}`
    });
  }

  return (
    <>
      <AvForm
        className="needs-validation"
        onValidSubmit={onValidSubmit}
        id="company_details"
      >
        <Card className="wizard-steps-content-container">
          <CardBody className="p-0">
            <p className="sub-header medium">
              {t("General Information")}
            </p>

            <Row>
              {/* COMPANY NAME */}
              <Col md="3" sm="12">
                <Label for="companyName">{t("Company Name")}: </Label>
                <AvField
                  name={"companyName"}
                  placeholder=""
                  type="text"
                  errorMessage={t("This field cannot be blank")}
                  className="form-control"
                  validate={{
                    required: { value: true },
                  }}
                  value={ companyName }
                  onChange={(e) => setCompanyName(e.target.value)}
                  id="company-name"
                />
              </Col>

              {/* Industry */}
              <Col md="3" sm="12">
                <Label for="industry">{t("Industry")}: </Label>

                <Select
                  isDisabled={ industriesListIsLoading }
                  isLoading={ industriesListIsLoading }
                  placeholder={t("Select") + "..."}
                  classNamePrefix="select2-selection"
                  id={`industry`}
                  options={industries}
                  menuPortalTarget={document.body}
                  onChange={(e) => setIndustry(e)}
                  value={industry}
                  filterOption={filterOptions}
                />

                <AvField
                  name={"industry"}
                  placeholder=""
                  type="hidden"
                  errorMessage={t("This field cannot be blank")}
                  className="form-control"
                  validate={{
                    required: { value: true },
                  }}
                  value={industry}
                />
              </Col>

              {/* Company Size */}
              <Col md="3" sm="12">
                <Label for="companySize">{t("Company Size")}: </Label>
                <Select
                  placeholder={t("Select") + "..."}
                  classNamePrefix="select2-selection"
                  id={`companySize`}
                  options={sizes}
                  menuPortalTarget={document.body}
                  onChange={(e) => setCompanySize(e)}
                  value={companySize}
                />
                <AvField
                  name={"companySize"}
                  placeholder=""
                  type="hidden"
                  errorMessage={t("This field cannot be blank")}
                  className="form-control"
                  validate={{
                    required: { value: true },
                  }}
                  value={companySize}
                />
              </Col>

              {/* Website */}
              <Col md="3" sm="12">
                <Label for="website">{t("Website")}: </Label>
                <AvField
                  name={"website"}
                  placeholder=""
                  type="text"
                  errorMessage={t("This field cannot be blank")}
                  className="form-control"
                  validate={{
                    required: { value: true },
                  }}
                  value={website}
                  id="website"
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </Col>
            </Row>

            {/* DESCRIPTION */}
            <Row className="mt-4">
              <Col>
                <Label for="description">{t("Description")}: </Label>
                <Editor
                  editorState={description}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={(e) => setDescription(e)}
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "fontSize",
                      "list",
                      "textAlign",
                      "colorPicker",
                      "link",
                      "remove",
                      "history",
                    ],
                    inline: {
                      options: [
                        "bold",
                        "italic",
                        "underline",
                        "strikethrough",
                        "monospace",
                      ],
                      bold: { className: "bordered-option-classname" },
                      italic: { className: "bordered-option-classname" },
                      underline: { className: "bordered-option-classname" },
                      strikethrough: {
                        className: "bordered-option-classname",
                      },
                      code: { className: "bordered-option-classname" },
                    },
                    blockType: {
                      className: "bordered-option-classname",
                    },
                    fontSize: {
                      className: "bordered-option-classname",
                    },
                  }}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Row className="mt-2 mb-4">
          <FormGroup
            style={{
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "flex-end",
            }}
            className="actions-buttons-container"
          >
            <Button disabled={checkingThirdpartyExistenceInProcess} color="primary">
                {checkingThirdpartyExistenceInProcess && (
                    <Spinner className="me-2" animation="border" variant="white" size="sm"/>
                )}
                {t("Next")}
            </Button>
          </FormGroup>
        </Row>
      </AvForm>
    </>
  );
};

export default withNamespaces()(memo(CompanyDetails));
