import userLightIcon from '../../../../../../assets/images/filter_user_light.svg'; 
import userBlueIcon from '../../../../../../assets/images/filter_user_blue.svg';

import userSectionsLightIcon from '../../../../../../assets/images/filter_sections_light.svg';
import userSectionsBlueIcon from '../../../../../../assets/images/filter_sections_blue.svg.svg';

import calendarLightIcon from '../../../../../../assets/images/filter_calendar_light.svg';
import calendarBlueIcon from '../../../../../../assets/images/filter_calendar_blue.svg';

import activityLightIcon from '../../../../../../assets/images/filter_activity_light.svg';
import activityBlueIcon from '../../../../../../assets/images/filter_activity_blue.svg';

export const icons = {
    date    :   {
        default :   calendarLightIcon,
        active  :   calendarBlueIcon
    },
    user    :   {
        default :   userLightIcon,
        active  :   userBlueIcon
    },
    section    :   {
        default :   userSectionsLightIcon,
        active  :   userSectionsBlueIcon
    },
    actions    :   {
        default :   activityLightIcon,
        active  :   activityBlueIcon
    },
};