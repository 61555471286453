import React, { useEffect, useRef } from "react";
import { formatDistanceToNow } from "date-fns";
import flagOnIcon from "src/assets/icons/flag-icon.png";
import flagOffIcon from "src/assets/icons/flag-icon-off.png";
import { withNamespaces } from "react-i18next";
import Form from 'react-bootstrap/Form';

import FindingsService from "src/modules/3rd-party-management/apis/FindingsService";
import { useMutation } from "@tanstack/react-query";

import { toast } from "react-toastify";

import Spinner from 'react-bootstrap/Spinner';

const FindingsListItem = (props) => {
    const { data, t, checked, onCheckStatusChanged, onSelectedToShow } = props;
    const ref = useRef();

    const handleToggleFlagStatusMutation = useMutation({
        mutationFn: async (payload) => {
            const service = FindingsService.getInstance();

            return await service.toggleFlag(payload);
        },
        onSuccess: () => {
        data.flag = !data.flag;
        toast(t("Flag status updated successfully."), {
            type: "success",
        });
        },
        onError: () => {
            toast(t("An error occurred while updating flag status."), {
                type: "error",
            });
        }
    });

    useEffect(() => {
        if(ref.current && ref.current.checked !== checked){
            ref.current.checked = checked;
            onCheckStatusChanged && onCheckStatusChanged(checked, data);
        }
    }, [checked]);

    return (
        <div className="finding-list-item mb-2">
            <div className="finding-list-item-checkbox">
                <Form.Check type="checkbox" ref={ (itemRef) => ref.current = itemRef} onChange={(e) => {onCheckStatusChanged && onCheckStatusChanged(e.target.checked, data)}} />
            </div>

            <div className="finging-container d-flex align-items-center">
                <div className="image-container me-4">
                    <img src={`/images/findings/${data.image}.jpg`} alt="" />
                </div>
                <div className="finding-content d-flex flex-column align-items-start w-100">
                    <div className="finding-content-row d-flex align-items-center justify-content-between w-100 mb-1">
                        <span className="category-title">
                        {data.news_agency}
                        </span>

                        <button disabled={ handleToggleFlagStatusMutation.isLoading } className="flag-btn" onClick={() => handleToggleFlagStatusMutation.mutate({
                        newsId : data.id
                        })}>
                        {
                            handleToggleFlagStatusMutation.isLoading ? (
                            <Spinner animation="border" variant="primary" size="sm"/>
                            ) : (
                                data.flag ? (
                                    <img src={flagOnIcon} width={20} height={20} alt=""/>
                                ) : (
                                    <img src={flagOffIcon} width={20} height={20} alt=""/>
                                )
                            )
                        }
                        
                        </button>
                    </div>

                    <div className="finding-content-row d-flex align-items-center justify-content-start flex-wrap w-100 mb-1">
                        <i className="ri-checkbox-blank-circle-fill text-danger me-2"></i>
                        <h4 onClick={() => onSelectedToShow && onSelectedToShow(data)} className="finding-title fw-bolder m-0">
                            {data.title}
                        </h4><br/>
                        <a href={ data.source_link } rel="noreferrer" target="_blank" className="d-block w-100 link">
                            { data.source_link }
                        </a>
                    </div>

                    <div className="finding-content-row mb-1">
                        <p className="pt-1 w-100 m-0 desc">
                            { data.lead_text }
                        </p>
                    </div>

                    <div className="finding-content-row">
                        <small className="time">
                            { formatDistanceToNow(new Date(data?.release_date?.timestamp * 1000), { addSuffix: true }) }
                        </small>
                    </div>

                    <div className="finding-content-row">
                        <div className="key-words">
                            <span className="badge me-1">{ data?.keyword }</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withNamespaces()(FindingsListItem);
