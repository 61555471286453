import { withNamespaces } from "react-i18next";
import SearchNews from "./search";
import { Button, Col, Row } from "reactstrap";
import Filter from "./Filter";
import ManageKeywordsModal from "../modals/manage-keywords-modal/ManageKeywordsModal";
import CreatingRiskModal from "../modals/create-risk-modal/create-risk-modal";
import { useState } from "react";
import CreateRiskModal from "../../../../../../reporting/Components/RiskComponents/CreateRiskModal";
const TopNewsMenu = ({ t, assetId }) => {
  // Manage Keywords Modal
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // Creating Risk Modal
  const [createRiskModal, setCreateRiskModal] = useState(false);
  const toggleCreateRiskModal = () => setCreateRiskModal(!createRiskModal);

  return (
    <>
      {/* Manage Keywords Modal */}
      <ManageKeywordsModal
        t={t}
        modal={modal}
        setModal={setModal}
        toggle={toggle}
      />

      {/* Create Risk Modal */}
      <CreatingRiskModal
        t={t}
        modal={createRiskModal}
        setModal={setCreateRiskModal}
        toggle={toggleCreateRiskModal}
        assetId={assetId}
      />
      <div className="d-flex mb-4 justify-content-end align-items-center w-full px-5 flex-wrap gap-3">
        <SearchNews t={t} />
        <div style={{width:'280px'}}>
        <Filter t={t} />
        </div>
    
        <Button
          // className="me-3"
          color="primary"
          onClick={() => setModal(true)}
        >
          {t("Manage Keywords")}
        </Button>
        <Button
          className="me-3"
          color="primary"
          onClick={() => setCreateRiskModal(true)}
        >
          {t("Create Risk")}
        </Button>
      </div>
    </>
  );
};

export default withNamespaces()(TopNewsMenu);
