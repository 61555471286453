import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { useState, useEffect, memo, useRef } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import { toast } from "react-toastify";

import SupplierService from "src/modules/3rd-party-management/apis/SupplierService";

import OverviewHeader from "../../../../components/overview-header";

import Accordion from 'react-bootstrap/Accordion';

import GeneralInformationImage from "src/modules/3rd-party-management/assets/images/general-information.svg";
import ProductsListImage from "src/modules/3rd-party-management/assets/images/products-list.svg";
import ContactPersonImage from "src/modules/3rd-party-management/assets/images/contact-person.svg";
import ShareholderImage from "src/modules/3rd-party-management/assets/images/shareholders.svg";

import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
  
import ProductList from "./components/products";
import ContactPeople from "./components/contact-people";
import ManagementTeam from "./components/management-team";
import Select from "react-select";
import IndustriesService from "src/modules/3rd-party-management/apis/IndustriesService";

import {
    CompanySizes
} from "src/modules/3rd-party-management/constants";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Shareholders from "./components/shareholders";

const industriesFilterOption = (
    candidate,
    input
) => {
    if (input) {
        const loweredInput = input.toLowerCase();

        return candidate.label.toLowerCase().includes(loweredInput) || (candidate.data.keywords || []).find((keyword) => keyword.toLowerCase().includes(loweredInput));
    }

    return true;
};

const BasicInformation = ({
    t,
    supplierId
}) => {

    const [ activeAccordion, setActiveAccordion ] = useState(null);
    const [ supplierInfos, setSupplierInfos ] = useState(null);
    const [ industires, setIndustries ] = useState([]);
    const [ showEditGeneralInfosModal, setShowEditGeneralInfosModal ] = useState(false);

    const editGeneralInfosFormRef = useRef();
    const overviewHeaderRef = useRef();

    const handleFetchSupplierBasicInformation = useQuery({
		queryKey: ['3rd-party-management-supplier-details-basic-infos'],
		queryFn: async () => {
			const service = SupplierService.getInstance();

            return await service.fetchBasicInfos(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching supplier basic information.'), {
				type: 'error',
			});
		},
        onSuccess: (data) => {
            setSupplierInfos(data);
        }
	});

    const handleFetchIndustriesListQuery = useQuery({
        queryKey: ['3rd-party-management-fetch-industries-list-query'],
        queryFn: async () => {
            const service = IndustriesService.getInstance();

            return await service.fetchList();
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (error) => {
            toast(t('An error occurred while fetching industires list.'), {
            type: 'error',
            });
        },
        onSuccess: (data) => {
            const list = (Array.isArray(data) ? data : []).map((industry) => {
                return {
                    value       :   industry.id,
                    label       :   `${industry.id} - ${industry.title}`,
                    keywords    :   industry?.keywords || []
                };
            });

            setIndustries(list);
        }
    });

    const handleUpdateSupplierGeneralInfosMutation = useMutation({
        mutationFn: async (payload) => {
            const service = SupplierService.getInstance();
            return await service.updateGeneralInfos(payload);
        },
        onSuccess: () => {
            handleFetchSupplierBasicInformation.refetch();

            const {
                reload  :   reloadOverviewHeader
            } = (overviewHeaderRef?.current || {});

            reloadOverviewHeader && reloadOverviewHeader();

            toast(t("General information updated successfully."), {
                type: "success",
            });

            toggleEditGeneralInfosModal();
        },
        onError: () => {
            toast(t("An error occurred while updating General information."), {
                type: "error",
            });
        }
    });

    const toggleEditGeneralInfosModal = () => setShowEditGeneralInfosModal(!showEditGeneralInfosModal);

    const handleEditGeneralInfosForm = (e, {
        generalInfos
    }) => {
        handleUpdateSupplierGeneralInfosMutation.mutate({
            supplier        :   supplierId,
            generalInfo    :   generalInfos
        });
    }

    const renderLoadingSection = () => {
        return (
            <Row>
                <Col sm="12" className="mt-4 mb-4">
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '100px'}}></div>
                </Col>
                <Col sm="12" className="mb-4">
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '100px'}}></div>
                </Col>
                <Col sm="12">
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '100px'}}></div>
                </Col>
            </Row>
        )
    }

    const renderEditGeneralInfosModal = () => {
        const industriesListIsLoading = handleFetchIndustriesListQuery.isFetching || handleFetchIndustriesListQuery.isLoading;
        const isUpdatingInProgress = handleUpdateSupplierGeneralInfosMutation.isFetching || handleUpdateSupplierGeneralInfosMutation.isLoading;
        const sizes = [];

        for(const sizeKey in CompanySizes){
            sizes.push({
                value : sizeKey,
                label : `${CompanySizes[sizeKey]?.title} ${t("Employees")}`
            });
        }

        return (
            <Modal isOpen={ showEditGeneralInfosModal } backdrop="static" keyboard={ false } size="xl">   
                <AvForm ref={ editGeneralInfosFormRef } onValidSubmit={ handleEditGeneralInfosForm } className="needs-validation m-2">
                    <ModalHeader toggle={ toggleEditGeneralInfosModal }>
                        {t("General Information")}
                    </ModalHeader>

                    <ModalBody>
                        <Row className="mb-3">
                            <Col md="6" sm="12">
                                <Label for="edit-general-infos-name-input">
                                    {t("Name")}
                                </Label>
                                <AvField
                                    name="generalInfos[name]"
                                    type="text"
                                    errorMessage={t("This field cannot be blank")}
                                    className="form-control"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    id="edit-general-infos-name-input"
                                    defaultValue={supplierInfos.name}
                                />
                            </Col>

                            <Col md="6" sm="12">
                                <Label for="edit-general-infos-website-input">
                                    {t("Website")}
                                </Label>
                                <AvField
                                    name="generalInfos[websiteUrl]"
                                    type="text"
                                    errorMessage={t("This field cannot be blank")}
                                    className="form-control"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    id="edit-general-infos-website-input"
                                    defaultValue={supplierInfos.websiteUrl}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md="6" sm="12">
                                <Label for="edit-general-infos-industry-input">
                                    {t("Industry")}
                                </Label>
                                <Select
                                    isLoading={ industriesListIsLoading }
                                    isDisabled={ industriesListIsLoading }
                                    placeholder={t("Select") + "..."}
                                    classNamePrefix="select2-selection"
                                    id='edit-general-infos-industry-input'
                                    options={ industires }
                                    menuPortalTarget={ document.body }
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    onChange={(e) => {
                                        const industryInput = editGeneralInfosFormRef.current._inputs['generalInfos[industry]'];
                                        industryInput.value = e.value;
                                        industryInput.validate();
                                    }}
                                    defaultValue={supplierInfos.industry && {
                                        value   :   supplierInfos.industry?.id,
                                        label   :   supplierInfos.industry?.name
                                    }}
                                    filterOption={industriesFilterOption}
                                />
                                <AvField
                                    name="generalInfos[industry]"
                                    type="hidden" 
                                    errorMessage={t("This field cannot be blank")}
                                    validate={{
                                        required: { value: true },
                                    }}
                                    defaultValue={supplierInfos.industry?.id}
                                />
                            </Col>

                            <Col md="6" sm="12">
                                <Label for="edit-general-infos-company-size-input">
                                    {t("Company Size")}
                                </Label>
                                <Select
                                    placeholder={t("Select") + "..."}
                                    classNamePrefix="select2-selection"
                                    id='edit-general-infos-company-size-input'
                                    options={ sizes }
                                    menuPortalTarget={ document.body }
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    onChange={(e) => {
                                        const sizeInput = editGeneralInfosFormRef.current._inputs['generalInfos[companySize]'];
                                        sizeInput.value = e.value;
                                        sizeInput.validate();
                                    }}
                                    defaultValue={supplierInfos.employeeSize && {
                                        value   :   supplierInfos.employeeSize,
                                        label   :   CompanySizes[supplierInfos.employeeSize]?.title
                                    }}
                                />
                                <AvField
                                    name="generalInfos[companySize]"
                                    type="hidden" 
                                    errorMessage={t("This field cannot be blank")}
                                    validate={{
                                        required: { value: true },
                                    }}
                                    defaultValue={ supplierInfos.employeeSize }
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <ManagementTeam supplierId={supplierId} />
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={ toggleEditGeneralInfosModal } color="primary" type="button" outline>
                            { t('Cancel') }
                        </Button>

                        <Button disabled={ isUpdatingInProgress } color="primary" type="submit" size="md">
                            {
                                isUpdatingInProgress ? (
                                    <Spinner className="me-2" animation="border" variant="white" size="sm"/>
                                ) : (
                                    <i className="ri-pencil-line align-middle me-2"></i>
                                )
                                
                            }
                            { t('Update') }
                        </Button>
                    </ModalFooter>
                </AvForm>
            </Modal>
        )
    }

    const dataIsLoading = (
        handleFetchSupplierBasicInformation.isLoading || handleFetchSupplierBasicInformation.isFetching
    );

    return (
        <div className="p-4">
            <OverviewHeader supplierId={ supplierId } actionsRef={ overviewHeaderRef } />
            
            {
                !dataIsLoading && supplierInfos ?  (
                    <>
                        <Accordion className="mt-4" defaultActiveKey={ activeAccordion }>
                            <Accordion.Item onClick={() => setActiveAccordion('general-information')} className={activeAccordion === 'general-information' ? 'active' : ''} eventKey={ 'general-information' }>
                                <Accordion.Header>
                                    <img src={ GeneralInformationImage } alt="" />
                                    <span>
                                        { t('General Information') }
                                    </span>
                                    <span onClick={ (e) => {
                                        e.stopPropagation();
                                        setShowEditGeneralInfosModal(true);
                                    } } className="edit-general-infos-btn">
                                        <i className="ri-pencil-line"></i>
                                    </span>
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <Row className="mb-2">
                                        <Col sm="12" md="6">
                                            <Label className="form-label">
                                                {t('Website')}:
                                            </Label>
                                            <p>
                                                { supplierInfos.websiteUrl }
                                            </p>
                                        </Col>
                                        <Col sm="12" md="6">
                                            <Label className="form-label">
                                                {t('Employee Size')}:
                                            </Label>
                                            <p>
                                                { `${CompanySizes[supplierInfos.employeeSize]?.title} ${t("Employees")}` }
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row className="mb-2">
                                        <Col sm="12" md="6">
                                            <Label className="form-label">
                                                {t('Industry')}:
                                            </Label>
                                            <p>
                                                { supplierInfos.industry?.name }
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row className="mb-2">
                                        <Col sm="12">
                                            <Label className="form-label">
                                                {t('Management team')}:
                                            </Label>
                                            
                                            <ManagementTeam readOnly={true} supplierId={supplierId} />
                                        </Col>
                                    </Row>

                                    <Row className="mb-2">
                                        <Col sm="12">
                                            <Label className="form-label">
                                                {t('Description')}:
                                            </Label>
                                            <div dangerouslySetInnerHTML={{__html: supplierInfos.description}} />
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item onClick={() => setActiveAccordion('products-list')} className={activeAccordion === 'products-list' ? 'active' : ''} eventKey={ 'products-list' }>
                                <Accordion.Header>
                                    <img src={ ProductsListImage } alt="" />
                                    <span>
                                        { t('List Of Products') }
                                    </span>
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <ProductList industryId={ supplierInfos.industry?.id } supplierId={ supplierId } />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item onClick={() => setActiveAccordion('contact-person')} className={activeAccordion === 'contact-person' ? 'active' : ''} eventKey={ 'contact-person' }>
                                <Accordion.Header>
                                    <img src={ ContactPersonImage } alt="" />
                                    <span>
                                        { t('Contact Person') }
                                    </span>
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <ContactPeople supplierId={ supplierId } />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item onClick={() => setActiveAccordion('shareholders')} className={activeAccordion === 'shareholders' ? 'active' : ''} eventKey={ 'shareholders' }>
                                <Accordion.Header>
                                    <img src={ ShareholderImage } alt="" />
                                    <span>
                                        { t('Shareholders') }
                                    </span>
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <Shareholders supplierId={ supplierId } />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        { renderEditGeneralInfosModal() }
                    </>
                ) : (
                    <>
                        { renderLoadingSection() }
                    </>
                )
            }
        </div>
    );
};

export default withNamespaces()(
    withRouter(memo(BasicInformation))
); 