import DataProtectionAdminRoutes from '../router/admin';
import DataProtectionThirdPartyRoutes from '../router/thirdparty';

import {
    API_URL_GET_NOTIFICATION_LIST,
    API_URL_GET_FEATURE_FLAGGING_SETTINGS,
    API_URL_GET_MODULE_SETTINGS,
    API_URL_NOTIFICATION_MARK_READ,
    API_URL_NOTIFICATION_MARK_ALL_READ
} from '../constants';

const ModuleInfos = {
    domain      :   'gd',
    subTitle    :   'GDPR',
    title       :   'Data Protection'
};

const Settings = {
    getFeatureFlaggingUrl           :   API_URL_GET_FEATURE_FLAGGING_SETTINGS,
    getNotificationsUrl             :   API_URL_GET_NOTIFICATION_LIST,
    getSettingsUrl                  :   API_URL_GET_MODULE_SETTINGS,
    markNotificationAsReadUrl       :   API_URL_NOTIFICATION_MARK_READ,
    markAllNotificationsAsReadUrl   :   API_URL_NOTIFICATION_MARK_ALL_READ
}

export {
    DataProtectionAdminRoutes,
    DataProtectionThirdPartyRoutes,
    ModuleInfos,
    Settings
};