import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
  Row,
  Col,
  Input,
  Label,
  Button,
  Form,
  FormGroup,
  Table,
} from "reactstrap";
import Select from "react-select";
import { errorNotification, successNotification } from "src/store/actions";
import {
  API_URL_FETCH_RISK_MATRIX,
  API_URL_RISK_COSTOMIZATION,
  DATA_UPDATED_SUCCESSFULLY,
  API_URL_RISK_RESTORE_COSTOMIZATION,
  DATA_NOT_UPDATED_SUCCESSFULLY,
  DATA_RESTORED,
  DATA_NOT_RESTORED,
  API_URL_FETCH_RISK_LIKELIHOODS,
} from "src/common/constants";
import { fetch, getCurrentLanguage } from "src/i18n";
import ModuleContext from "src/contexts/ModuleContext";
import UserUtils
 from "src/services/utils/UserUtils";
class DataProtectionAdministrationSettings extends Component {
  static contextType = ModuleContext;

  constructor(props) {
    super(props);
    this.userUtils = new UserUtils();
    this.state = {
      loading: true,
      header: {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      },
      case_assign: true,
      report_read: true,
      change_role: true,
      not_read_case: true,
      comment_report: true,
      not_closed_case: true,
      new_chat_message: true,
      new_case_reported: true,
      status_case_report: true,
      new_meeting_request: true,
      remind_unanswer_chat: true,
      new_meeting_requested: true,
      new_case_confident_submitted: true,
      new_task_created: true,
      task_status_new: true,
      task_status_in_progress: true,
      task_status_review: true,
      task_status_close: true,
      task_status_delete: true,
      task_priority_low: true,
      task_priority_medium: true,
      task_priority_high: true,
      task_comment: true,
      task_overdue: true,
      task_change_deadline: true,
      task_change_description: true,
      task_before_overdue: true,
      risk_rating: true,
      risk_change_description: true,
      new_task: true,
      risk_assign: true,
      risk_comment: true,
      readonly_access: true,
      voice_not_deleted: true,
      qrcode: null,
      consequences: [],
      matrix: [],
      likelihoods: [],
      options: [],
      ratings: [],
      topRatings: [],
      reload: false,
      likLabelError: "",
      likLabelErrorId: -1,
      lang: getCurrentLanguage(),
      likelihoodsInputs: [],
      currency: "",
      currencyOptions: [
        { value: "USD", label: "USD" },
        { value: "EUR", label: "EUR" },
        { value: "GBP", label: "GBP" },
      ],
      defCurrency: "",
      errConsIndx: -1,
      errCons: false,
      errLikIndx: -1,
      errLik: false,
    };

    this.consequenceRef = React.createRef();
    this.likelihoodsRef = React.createRef();
  }

  fetchMatrix = () => {
    const url = API_URL_FETCH_RISK_MATRIX
    const { header } = this.state;
    axios
      .get(url, header)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            matrix: [],
            likelihoods: [],
            ratings: [],
            consequences: [],
            topRatings: [],
          });
          const { data } = response.data;
          const newConsequencesData = data.consequences.map((cons) => {
            return {
              id: cons.id,
              label: this.props.t(cons.label),
              title: cons.title,
              number: cons.number,
            };
          });

          const newLikelihoodsData = data.likelihoods.map((lik) => {
            return {
              id: lik.id,
              label: this.props.t(lik.label),
              title: lik.title,
              number: lik.number,
            };
          });

          const newRatingsData = data.ratings.map((rate) => {
            return {
              id: rate.id,
              name: this.props.t(rate.name),
              number: rate.number,
            };
          });
          this.setState(
            {
              consequences: newConsequencesData,
              likelihoods: newLikelihoodsData,
              matrix: data.matrix,
              currency: data.currency,
              // ratings: data.ratings,
            },
            () => {
              const defCurr = this.state.currencyOptions.filter(
                (c) => c.label === this.state.currency
              )[0];
              this.setState({ defCurrency: defCurr });
            }
          );
          const rates = newRatingsData.map((r) => {
            return {
              id: r.id,
              label: this.props.t(r.name),
              number: r.number,
            };
          });

          const topRates = newRatingsData.map((r) => {
            return {
              id: r.id,
              label: this.props.t(r.name),
              number: r.number,
            };
          });
          this.setState({ ratings: rates, topRatings: topRates }        
          );
        }
      })
      .catch((err) => console.log(err));
  };

  fetchLikelihoods = () => {
    const url = API_URL_FETCH_RISK_LIKELIHOODS
    axios
      .get(url, this.state.header)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            likelihoodsInputs: [],
          });
          const { data } = response.data;
          const result = data.map((item) => {
            return {
              value: item.id,
              label: this.props.t(item.label),
              title: this.props.t(item.title),
              number: item.number,
            };
          });
          this.setState({
            likelihoodsInputs: result,
            loading:false,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  onRatingChange = (e, number) => {
    const { topRatings } = this.state;
    const result = topRatings.filter((rate) => rate.number === number);
    result[0].label = e.target.value;
    this.setState({
      topRatings: topRatings,
    });
  };
  onSubmit = (e, id) => {
    const url =API_URL_RISK_COSTOMIZATION
    const {
      // ratings,
      topRatings,
      header,
      consequences,
      // likelihoods,
      matrices,
      likelihoodsInputs,
    } = this.state;
    const ratings = topRatings;
    this.setState({
      errCons: false,
      errConsIndx: -1,
      errLik: false,
      errLikIndx: -1,
    });
    let consErrFlag = false;
    let likelihoodsErrFlag = false;
    const newConsArr = consequences.map((item, i) => {
      if (consErrFlag) {
        return null;
      }
      const { number, ...rest } = item; // using destructuring to remove the 'number' property
      if (
        consequences[i - 1] &&
        Number(consequences[i].title) < Number(consequences[i - 1].title)
      ) {
        this.setState({
          errCons: true,
          errConsIndx: i,
        });
        return (consErrFlag = true);
      } else {
        return rest;
      }
    });

    if (consErrFlag) {
      this.props.errorNotification({
        message: this.props.t(DATA_NOT_UPDATED_SUCCESSFULLY),
      });
      return this.consequenceRef.current.scrollIntoView();
    }
    const result = ratings.map((c) => {
      return {
        id: c.id,
        name: c.label,
      };
    });

    const likelihoodsResult = likelihoodsInputs.map((lik, i) => {
      if (likelihoodsErrFlag) {
        return null;
      }

      if (
        likelihoodsInputs[i - 1] &&
        Number(likelihoodsInputs[i].label) <
          Number(likelihoodsInputs[i - 1].label)
      ) {
        this.setState({
          errLik: true,
          errLikIndx: i,
        });
        return (likelihoodsErrFlag = true);
      } else {
        return {
          id: lik.value,
          title: lik.title,
          label: lik.label,
        };
      }
    });

    if (likelihoodsErrFlag) {
      this.props.errorNotification({
        message: this.props.t(DATA_NOT_UPDATED_SUCCESSFULLY),
      });
      return this.likelihoodsRef.current.scrollIntoView();
    }

    const data =
      id === "currency"
        ? {
            consequences: [],
            likelihoods: [],
            matrices: [],
            ratings: [],
            currency: e.label,
          }
        : {
            consequences: newConsArr,
            likelihoods: likelihoodsResult,
            matrices: matrices,
            ratings: result,
            currency: this.state.currency,
          };
    axios
      .post(url, data, header)
      .then(async (response) => {
        if (response.status === 200) {
          await fetch();
          // this.fetchMatrix();
          this.fetchLikelihoods();
          if (id === "currency") {
            this.setState({ currency: e.label });
          }
          this.props.successNotification({
            code: "Success",
            message: this.props.t(DATA_UPDATED_SUCCESSFULLY),
          });
        } else {
          this.props.errorNotification({
            code: response.code,
            message: this.props.t(DATA_NOT_UPDATED_SUCCESSFULLY),
          });
        }
      })
      .catch((err) => {
        this.props.errorNotification({
          code: err.code,
          message: this.props.t(DATA_NOT_UPDATED_SUCCESSFULLY),
        });
      });
  };

  onConsequenceLabelChange = (e, number) => {
    const { consequences } = this.state;
    const result = consequences.filter((c) => c.number === number);
    result[0].label = e.target.value;
    this.setState({
      consequences: consequences,
    });
  };

  onConsequenceTitleChange = (e, number) => {
    const { consequences } = this.state;
    this.setState({
      errCons: false,
      errConsIndx: -1,
    });
    const result = consequences.filter((c) => c.number === number);
    result[0].title = e;
    this.setState({
      consequences: consequences,
    });
  };

  onLikelihoodsSubmit = () => {
    const url = API_URL_RISK_COSTOMIZATION
    const { likelihoodsInputs, header } = this.state;
    const result = likelihoodsInputs.map((c) => {
      return {
        id: c.id,
        label: c.label,
        title: c.title,
      };
    });
    const data = {
      consequences: [],
      likelihoods: result,
      matrices: [],
      ratings: [],
    };
    axios
      .post(url, data, header)
      .then((response) => {
        if (response.status === 200) {
          this.props.successNotification({
            code: "Success",
            message: this.props.t(DATA_UPDATED_SUCCESSFULLY),
          });
        } else {
          this.props.errorNotification({
            code: response.code,
            message: this.props.t(DATA_NOT_UPDATED_SUCCESSFULLY),
          });
        }
      })
      .catch((err) => {
        this.props.errorNotification({
          code: err.code,
          message: this.props.t(DATA_NOT_UPDATED_SUCCESSFULLY),
        });
      });
  };

  onLikelihoodsLabelChange = (e, number) => {
    this.setState({
      errLik: false,
      errLikIndx: -1,
    });
    if (Number(e.target.value) >= 100) {
      return this.setState({
        likLabelError: "Maximum allowed value is" + " 99",
        likLabelErrorId: number,
      });
    } else {
      this.setState({
        likLabelError: "",
        likLabelErrorId: -1,
      });
      const { likelihoodsInputs } = this.state;
      const result = likelihoodsInputs.filter((c) => c.number === number);
      result[0].label = e.target.value;

      this.setState({
        likelihoodsInputs: likelihoodsInputs,
      });
    }
  };

  onLikelihoodsTitleChange = (e, number) => {
    const { likelihoodsInputs } = this.state;
    const result = likelihoodsInputs.filter((c) => c.number === number);
    result[0].title = e.target.value;
    this.setState({
      likelihoodsInputs: likelihoodsInputs,
    });
  };

  onMatricesSubmit = () => {};

  onMatricesChange = (e, matrice) => {
    const url = API_URL_RISK_COSTOMIZATION
    const { matrix, header } = this.state;
    const result = matrix.map((m) => {
      return {
        id: m.id,
        name: m.name,
        color: m.color,
      };
    });
    const data = {
      consequences: [],
      likelihoods: [],
      matrices: [{ id: matrice.id, ratingId: e.id }],
      ratings: [],
      currency: this.state.currency,
    };
    axios
      .post(url, data, header)
      .then((response) => {
        if (response.status === 200) {
          this.fetchMatrix();
          this.props.successNotification({
            code: "Success",
            message: this.props.t(DATA_UPDATED_SUCCESSFULLY),
          });
        } else {
          this.props.errorNotification({
            code: response.code,
            message: this.props.t(DATA_NOT_UPDATED_SUCCESSFULLY),
          });
        }
      })
      .catch((err) => {
        this.props.errorNotification({
          code: err.code,
          message: this.props.t(DATA_NOT_UPDATED_SUCCESSFULLY),
        });
      });
  };

  restore = () => {
    const url = API_URL_RISK_RESTORE_COSTOMIZATION
    const { header } = this.state;
    axios
      .post(url, {}, header)
      .then((response) => {
        this.fetchMatrix();
        this.fetchLikelihoods();
        this.setState({
          reload: true,
          matrix: response.data.data,
        });
        this.forceUpdate();
        this.props.successNotification({
          message: this.props.t(DATA_RESTORED),
        });
      })
      .catch((err) => {
        this.props.errorNotification({
          message: this.props.t(DATA_NOT_RESTORED),
        });
      });
  };

  render() {
    const { consequences, likelihoods, matrix, ratings, likelihoodsInputs, loading } =
      this.state;

      if (loading) {
        return (
          <div
            className="dt-field dt-skeleton"
            style={{ width: "100%", height: "320px" }}
          ></div>
        );
      }

    return (
      <div className="mb-3">
        <Form inline>
          <fieldset
            style={{
              border: "1px solid #ced4da",
            }}
            className="p-3"
          >
            {/* *********************** RATINGS ********************* */}
            {this.state.defCurrency !== "" ? (
              <Row
                style={{
                  display: "flex",
                }}
              >
                <Col lg="11" md="10" sm="5" xs="9">
                  <Label>{this.props.t("Ratings")}</Label>
                </Col>
                <Col lg="1" md="2" sm="2" xs="1">
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={this.state.currencyOptions}
                    placeholder={"Currency"}
                    defaultValue={this.state.defCurrency}
                    onChange={(e) => this.onSubmit(e, "currency")}
                  />
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row>
              <Col lg="10">
                {/* <h5>{this.props.t("Name")}</h5> */}
                <Row className="mb-3">
                  {ratings.map((rate, i) => {
                    const { id, label, number } = rate;
                    return (
                      <Col>
                        <FormGroup>
                          <Label for={`cl${i + 1}}`}>
                            {this.props.t("Rating") + " " + (i + 1)}
                          </Label>
                          <Input
                            type="text"
                            name={`rate${i + 1}}`}
                            id={`rate${i + 1}}`}
                            defaultValue={this.props.t(label)}
                            onChange={(e) => this.onRatingChange(e, number)}
                          />
                        </FormGroup>{" "}
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
            <hr></hr>
            {/* *********************** CONSEQUENCES ********************* */}
            <Label>
              {this.props.t("Damage in") + ` ${this.state.currency}`}
            </Label>
            <Row>
              <Col lg="10">
                {/* <h5>{this.props.t("Label")}</h5> */}
                <span ref={this.consequenceRef}></span>
                <Row className="mb-3">
                  {consequences.map((cons, i) => {
                    const { id, label, number } = cons;
                    return (
                      <Col>
                        <FormGroup>
                          <Label for={`cl${i + 1}}`}>
                            {this.props.t("Damage") + " " + (i + 1)}
                          </Label>
                          <Input
                            type="text"
                            // step="any"
                            name={`cl${i + 1}`}
                            id={`cl${i + 1}`}
                            defaultValue={this.props.t(label)}
                            onChange={(e) => {
                              this.onConsequenceLabelChange(e, number);
                            }}
                          />
                        </FormGroup>{" "}
                      </Col>
                    );
                  })}
                </Row>

                {/* <h5>{this.props.t("Title")}</h5> */}
                <Row className="mb-3">
                  {consequences.map((cons, i) => {
                    const { title, number } = cons;
                    return (
                      <Col>
                        <FormGroup>
                          <Label for={`cl${i + 1}`}>
                            {this.props.t("Damage") + " " + (i + 1) + " >="}
                          </Label>
                          <Input
                            type="text"
                            name={`cl${i + 1}`}
                            id={`cl${i + 1}`}
                            defaultValue={Number(title).toLocaleString(
                              this.state.lang
                            )}
                            style={{
                              textAlign: "right",
                              border:
                                this.state.errCons &&
                                this.state.errConsIndx === i
                                  ? "2px solid red"
                                  : null,
                            }}
                            onKeyDown={(e) => {
                              // Allow only numeric and control keys (e.g., backspace, delete)
                              if (
                                !(
                                  /[0-9.\b]/.test(e.key) ||
                                  e.key === "ArrowLeft" ||
                                  e.key === "ArrowRight" ||
                                  e.key === "Delete" ||
                                  e.key === "Backspace"
                                )
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              const val = e.target.value.replace(/[,\.]/g, "");
                              const formattedValue = Number(val).toLocaleString(
                                this.state.lang
                              );
                              e.target.value = formattedValue;
                              this.onConsequenceTitleChange(
                                Number(val),
                                number
                              );
                            }}
                          />
                          <p
                            className="text-danger"
                            hidden={
                              !(
                                this.state.errCons &&
                                this.state.errConsIndx === i
                              )
                            }
                          >
                            {this.props.t(
                              "The value of this field should be greater than the previous one"
                            )}
                          </p>
                        </FormGroup>{" "}
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
            <hr></hr>
            <span ref={this.likelihoodsRef}></span>
            {/******************************** LIKELIHOODS ***************************/}
            <Label>{this.props.t("Likelihood")}</Label>
            <Row>
              <Col lg="10">
                {/* <h5>{this.props.t("Title")}</h5> */}
                <Row className="mb-3">
                  {likelihoodsInputs.map((lik, i) => {
                    // console.log(lik);
                    const { title, number } = lik;
                    const id = lik.value;
                    return (
                      <Col>
                        <FormGroup>
                          <Label for={`lt${i + 1}`}>
                            {this.props.t("Likelihood") +
                              " " +
                              (i + 1 == 1
                                ? "A"
                                : i + 1 == 2
                                ? "B"
                                : i + 1 == 3
                                ? "C"
                                : i + 1 == 4
                                ? "D"
                                : "E")}
                          </Label>
                          <Input
                            type="text"
                            name={`lt${i + 1}`}
                            id={`lt${i + 1}`}
                            defaultValue={this.props.t(title)}
                            onChange={(e) =>
                              this.onLikelihoodsTitleChange(e, number)
                            }
                          />
                        </FormGroup>{" "}
                      </Col>
                    );
                  })}
                </Row>
                {/* <h5>{this.props.t("Label")}</h5> */}
                <Row className="mb-3">
                  {likelihoodsInputs.map((lik, i) => {
                    const { label, number } = lik;
                    const id = lik.value;
                    return (
                      <Col>
                        <FormGroup>
                          <Label for={`ll${i + 1}`}>
                            {this.props.t("Likelihood") +
                              " " +
                              (i + 1 == 1
                                ? "A"
                                : i + 1 == 2
                                ? "B"
                                : i + 1 == 3
                                ? "C"
                                : i + 1 == 4
                                ? "D"
                                : "E") +
                              " >=" +
                              " (%)"}
                          </Label>
                          <Input
                            type="number"
                            min="0"
                            max="99"
                            name={`ll${i + 1}`}
                            id={`ll${i + 1}`}
                            defaultValue={label}
                            style={{
                              textAlign: "right",
                              border:
                                this.state.errLik && this.state.errLikIndx === i
                                  ? "2px solid red"
                                  : null,
                            }}
                            onChange={(e) => {
                              this.onLikelihoodsLabelChange(e, number);
                            }}
                          />
                          <p
                            className="text-danger"
                            hidden={id !== this.state.likLabelErrorId}
                          >
                            {this.state.likLabelError
                              ? this.props.t(this.state.likLabelError)
                              : ""}
                          </p>
                          <p
                            className="text-danger"
                            hidden={
                              !(
                                this.state.errLik && this.state.errLikIndx === i
                              )
                            }
                          >
                            {this.props.t(
                              "The value of this field should be greater than the previous one"
                            )}
                          </p>
                        </FormGroup>{" "}
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
            <hr></hr>
            {/******************************** MATRICES ***************************/}
            <Row
              style={{
                textAlign: "center",
              }}
            >
              <h5>{this.props.t("Damage")}</h5>
            </Row>
            <Row>
              <Table borderless responsive>
                <thead
                  style={{
                    textAlign: "center",
                  }}
                >
                  <th></th>
                  <th
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {"#"}
                  </th>
                  {consequences.map((item) => {
                    return (
                      <th
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        {this.props.t(item.label)}
                      </th>
                    );
                  })}
                </thead>

                <tbody
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  {matrix.map((item, i) => {
                    return (
                      <>
                        <tr>
                          <th
                            style={{
                              width: "1%",
                              // height: "1%",
                            }}
                          >
                            <span
                              style={{
                                display: "inline-block",
                                textAlign: "center",
                                transform: "rotate(-90deg)",
                              }}
                              hidden={i !== 2}
                            >
                              {this.props.t("Likelihood")}
                            </span>
                          </th>
                          <th
                            style={{
                              border: "1px solid rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            {this.props.t(likelihoods[i].label)}
                          </th>
                          {item.map((m) => {
                            const { id, name, color } = m;
                            const def = ratings.filter(
                              (r) =>
                                this.props.t(r.label) === this.props.t(name)
                            );
                            if (def[0]) {
                              return (
                                <td
                                  style={{
                                    background: `#${color}`,
                                    border: "1px solid rgba(0, 0, 0, 0.1)",
                                  }}
                                >
                                  <Select
                                    name="matr"
                                    styles={{
                                      control: (styles) => ({
                                        ...styles,
                                        backgroundColor: "white",
                                      }),
                                      option: (
                                        styles,
                                        {
                                          data,
                                          isDisabled,
                                          isFocused,
                                          isSelected,
                                        }
                                      ) => {
                                        return {
                                          ...styles,
                                          backgroundColor: isFocused
                                            ? "#4aa3ff"
                                            : "white",
                                          color: "black",
                                        };
                                      },
                                    }}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={ratings}
                                    menuPortalTarget={document.body}
                                    defaultValue={def[0]}
                                    onChange={(e) =>
                                      this.onMatricesChange(e, m)
                                    }
                                  />
                                </td>
                              );
                            }
                          })}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </Row>
            <Row className="w-100 settings">
              <div className="d-flex gap-3 mt-4 justify-content-end">
                <button className="restoreBtn" onClick={this.restore} disabled={!this.userUtils.isAnalystOrAnalystAdmin(this.props.user)}>
                  {this.props.t("Restore")}
                </button>
                <button className="saveBtn" onClick={this.onSubmit} disabled={!this.userUtils.isAnalystOrAnalystAdmin(this.props.user)}>
                  {this.props.t("Save")}
                </button>
              </div>
            </Row>
          </fieldset>
        </Form>
      </div>
    );
  }

  componentDidMount() {
    this.fetchMatrix();
    this.fetchLikelihoods();
    // this.fetchRatings();
  }

  componentDidUpdate() {
    if (getCurrentLanguage() !== this.state.lang) {
      this.setState(
        {
          lang: getCurrentLanguage(),
          consequences: [],
          matrix: [],
          likelihoods: [],
          options: [],
          ratings: [],
        },
        () => {
          this.fetchMatrix();
          this.fetchLikelihoods();
          this.forceUpdate();
        }
      );
    }
  }
}

const mapStatetoProps = (state) => {
  const App = state.App;
  const { token, user } = state.Login;
  const { Modules } = state;
  const feature= Modules.list.find((item) => item?.name === Modules.active)?.features;

  return {
    token,
    App,
    Feature: feature,
  };
};

const mapDispachToProps = (dispach) => {
  return {
    successNotification: (message) => dispach(successNotification(message)),
    errorNotification: (message) => dispach(errorNotification(message)),
  };
};

export default withNamespaces()(
  withRouter(
    connect(
      mapStatetoProps,
      mapDispachToProps
    )(DataProtectionAdministrationSettings)
  )
);
