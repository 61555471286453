import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import FindingsListItem from "./FindingsListItem";
import { Alert, Button, Col, Row } from "reactstrap";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Select, { components } from 'react-select';
import ManageKeywordsModal from './manageKeyWords'
import { toast } from "react-toastify";

import FindingsService from "src/modules/3rd-party-management/apis/FindingsService";

import { useMutation, useQuery } from "@tanstack/react-query";

import Spinner from 'react-bootstrap/Spinner';

const FindingsList = (props) => {
    const { t, supplierId, onItemSelectedToShow } = props;
    
    const [ items, setItems ] = useState([]);
    const [ filterResult, setFileterResult ] = useState(null);
    const [isModalKeyWordsOpen, setIsModalKeyWordsOpen] = useState(false);

    useQuery({
		queryKey: ['3rd-party-management-fetch-findings-list'],
		queryFn: async () => {
			const service = FindingsService.getInstance();
            return await service.list(supplierId, {
                pageSize  : 100,
                pageIndex : 1
            });
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching findings.'), {
				type: 'error',
			});
		},
        onSuccess: (data) => {
            setItems(data?.news || [])
        }
	});

    const handleToggleFlagStatusMutation = useMutation({
        mutationFn: async (selectedItemsIds) => {
            const service = FindingsService.getInstance();

            return await service.bulkToggleFlag({
            news  : selectedItemsIds
            });
        },
        onSuccess: () => {
        toast(t("Flag status of selected items changed successfully."), {
            type: "success",
        });
        },
        onError: () => {
        toast(t("An error occurred while updating."), {
            type: "error",
        });
        }
    });

    const toggleCheckedItemsFlag = () => {
        const checkedItems = (items.filter((item) => !!item.checked)).map((item) => item.id);
        if(checkedItems.length > 0){
            handleToggleFlagStatusMutation.mutate(checkedItems, {
                onSuccess : () => {
                    const newList = [...items].map((item) => {
                        if(checkedItems.includes(item.id)){
                        return {
                            ...item,
                            checked : false,
                            flag  : !item.flag
                        }
                        }
                        return {...item};
                    });

                    setItems(newList);
                }
            });
        }
        else{
            toast(t('Please select at least one of the findings to continue'), {
                type: 'error',
            });
        }
    }

    //used for show the modal of kewords
    const toggleKeywordsModal = () => {
        setIsModalKeyWordsOpen(!isModalKeyWordsOpen);
    };

    if (items?.length === 0) {
        return (
            <Alert color="warning" className="text-center">
                <p className="m-0">{t(`Findings list is empty.`)}</p>
            </Alert>
        );
    }

    return ( 
        <React.Fragment>  
            <Row className="findings-bulk-actions mb-3">
                <Col sm="12" md="4">
                    <Button onClick={() => {
                        const status = ((items.filter((item) => !!item.checked)).length === items.length) ? false : true;
                        setItems([
                            ...items.map((item) => {
                                return {
                                    ...item,
                                    checked : status
                                }
                            })
                        ])
                        }} color="primary" outline>
                        {
                            ((items.filter((item) => !!item.checked)).length === items.length) ? (
                                t('Deselect All') 
                            ) : (
                                t('Select All') 
                            )
                        }
                    </Button>
                </Col>

                <Col sm="12" md="8">
                    <Row className="row flex-row-reverse">
                        <Col sm="12" md="4" className="d-flex flex-row-reverse justify-content-start align-items-center">
                            <Button disabled={ handleToggleFlagStatusMutation.isLoading } onClick={toggleCheckedItemsFlag} color="primary">
                                {handleToggleFlagStatusMutation.isLoading && (
                                    <Spinner animation="border" variant="white" size="sm" style={{marginRight: '4px'}} />
                                )}
                                { t('Toggle Items Flag') }
                            </Button>

                            <Button color="primary" outline className="me-2" onClick={toggleKeywordsModal}>
                                { t('Manage Keywords') }
                            </Button>
                        </Col>

                        <Col sm="12" md="8" className="d-flex flex-row-reverse justify-content-start align-items-center">
                            <Select
                                required
                                isClearable={ true }
                                name='filter_result'
                                classNamePrefix='select2-selection'
                                options={[
                                    {
                                        value: 'last_year',
                                        label: t('Last year'),
                                    }
                                ]}
                                value={ filterResult }
                                onChange={(e) => setFileterResult(e)}
                                placeholder={t('Filter')}
                                className="w-100"
                                style={{
                                    width: '250px'
                                }}
                                components={{
                                    Control: ({ children, ...rest }) => (
                                    <components.Control {...rest}>
                                        <i className="ri-filter-3-fill" style={{marginLeft : '8px'}}></i> {children}
                                    </components.Control>
                                    )
                                }}
                                />

                            <InputGroup style={{width: '250px', marginRight: '12px'}}>
                                <Form.Control placeholder="Search" aria-describedby="search-result-input" style={{borderRight: 'none'}} />
                                <InputGroup.Text className="bg-transparent border-left-0" id="search-result-input" style={{borderLeft: 'none'}}>
                                    <i className="ri-search-line"></i>
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <div className="findings-list d-flex flex-column">
                {
                    items?.map((item) => (
                        <FindingsListItem
                            key={item.id}
                            data={ item }
                            checked={ !!item?.checked }
                            onCheckStatusChanged={(status, item) => {
                                setItems((items) => {
                                    const itemIndex = items.findIndex((i) => i.id === item.id);
                                    const newItems = [...items];

                                    if(itemIndex > -1){
                                        newItems[itemIndex].checked = status;
                                    }

                                    return newItems;
                                });
                            }}
                            onSelectedToShow={(i) => onItemSelectedToShow && onItemSelectedToShow(i)}
                        />
                    ))
                }
            </div>

            {/* Render the ManageKeywordsModal */}
            <ManageKeywordsModal
                modal={isModalKeyWordsOpen}
                setModal={setIsModalKeyWordsOpen}
                toggle={toggleKeywordsModal}
                t={t}
                />

        </React.Fragment>
    );
};

export default withNamespaces()(FindingsList);
