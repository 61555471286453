import React, { useState } from "react";
import { Form, FormGroup, Input } from "reactstrap";
import { FaSearch } from "react-icons/fa";
import "./Style.css";

const SearchNews = ({ t }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement your search logic here
    console.log(searchTerm);
  };

  const inputStyle = {
    position: "relative",
  };

  return (
    <Form inline onSubmit={handleSubmit}>
      <FormGroup style={inputStyle}>
        <Input
          type="search"
          name="search"
          placeholder={t("Search")}
          value={searchTerm}
          onChange={handleInputChange}
        />
        <FaSearch className="iconStyle" />
      </FormGroup>
    </Form>
  );
};

export default SearchNews;
