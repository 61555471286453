import { withNamespaces } from "react-i18next";
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import Select from "react-select";
import { connect } from 'react-redux';

import SupplierTeamService from "src/modules/3rd-party-management/apis/SupplierTeamService";
import { AvField, AvForm } from "availity-reactstrap-validation";

import { EMPTY_LIST } from "src/common/constants";
import PhoneInput from "react-phone-input-2";

const ManagementTeam = ({
    t,
    supplierId,
    Organization,
    readOnly = false
}) => {
    const [ team, setTeam ] = useState([]);

    const [ showPersonModal, setShowPersonModal ] = useState(false);

    const [ editingPersonValues, setEditingPersonValues ] = useState(null);

    const avFormRef = useRef();

    const columns = [
        {
            dataField   :   "name",
            text        :   "Name",
            sort        :   false
        },
        {
            dataField   :   "position",
            text        :   "Position",
            sort        :   false
        },
        {
            dataField   :   "email",
            text        :   "Email",
            sort        :   false
        },
        {
            dataField   :   "phoneNumber",
            text        :   "Phone Number",
            sort        :   false
        },
        {
            dataField   :   "language",
            text        :   "Language",
            sort        :   false,
            formatter   :   (cellContent) => {
                return getLanguageName(cellContent);
            }
        },
        !readOnly && {...{
            dataField   : "action",
            text        : "Action",
            sort        : false,
            formatter   :   (cellContent, row) => {
                return (
                    <div className="d-flex justify-content-start">
                        <Button onClick={ () => handleDeleteSupplierTeamPerson(row) } disabled={ row.state.isDeletionInProcess } outline color="danger" size="md" className="border-0">
                            {
                                row.state.isDeletionInProcess ? (
                                    <Spinner animation="border" variant="danger" size="sm"/>
                                ) : (
                                    <i className="ri-delete-bin-line font-size-20"></i>
                                )
                            }
                        </Button>

                        <Button onClick={ () => showTeamPersonEditingForm(row) } outline color="primary" size="md" className="border-0">
                            <i className="ri-pencil-line font-size-20"></i>
                        </Button>
                    </div>
                );
            }
        }}
    ];

    const setTeamStateFieldsValue = (rowId, fieldsNames, fieldValue) => {
        const fields = Array.isArray(fieldsNames) ? fieldsNames : [fieldsNames];

        setTeam((team) => {
            const result = [...team].map((person) => {
                if(person.id === rowId){
                    const newstate = {};

                    fields.forEach((fieldName) => {
                        newstate[[fieldName]] = fieldValue;
                    });

                    return {
                        ...person,
                        state   :   {
                            ...person.state,
                            ...newstate
                        }
                    }
                }
    
                return person;
            });

            return result;
        });
    };

    const handleFetchSupplierTeamListQuery = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-team-list'],
		queryFn: async () => {
			const service = SupplierTeamService.getInstance();

            return await service.list(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching supplier management team list.'), {
				type: 'error',
			});
		},
	});

    const handleCreateSupplierNewTeamPersonMutation = useMutation({
        mutationFn: async (payload) => {
            const service = SupplierTeamService.getInstance();

            return await service.create(payload);
        },
        onSuccess: () => {
            handleFetchSupplierTeamListQuery.refetch();

            toast(t("New person created successfully."), {
                type: "success",
            });

            togglePersonModal();
        },
        onError: () => {
            toast(t("An error occurred while creating new person."), {
                type: "error",
            });
        }
    });

    const handleUpdateSupplierTeamPersonMutation = useMutation({
        mutationFn: async ({
            personId,
            payload
        }) => {
            const service = SupplierTeamService.getInstance();
            return await service.update(supplierId, personId, payload);
        },
        onSuccess: () => {
            handleFetchSupplierTeamListQuery.refetch();

            toast(t("Person updated successfully."), {
                type: "success",
            });

            togglePersonModal();
        },
        onError: () => {
            toast(t("An error occurred while updating person."), {
                type: "error",
            });
        }
    });

    const handleDeleteSupplierProductMutation = useMutation({
        mutationFn: async (personId) => {
            const service = SupplierTeamService.getInstance();

            setTeamStateFieldsValue(personId, 'isDeletionInProcess', true);

            return await service.delete(supplierId, personId);
        },
        onSuccess: () => {
            handleFetchSupplierTeamListQuery.refetch();

            toast(t("Team person deleted successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while deleting team person."), {
                type: "error",
            });
        }
    });

    const getLanguageName = (id) => ((Organization?.languages || []).find((lng) => lng?.language?.id === id))?.name

    const togglePersonModal = () => setShowPersonModal(!showPersonModal);

    const NoDataIndication = () => {
        if(handleFetchSupplierTeamListQuery.isFetched && !team.length){
            return (
                <div className="alert m-0" role="alert">
                <p style={{
                    textAlign: "center",
                    marginBottom: 0,
                }}>
                    {t(EMPTY_LIST)}
                </p>
            </div>
            );
        }

        return null;
    };

    const handleCreationModalSubmit = (e, values) => {
        if(editingPersonValues && editingPersonValues.personId){
            handleUpdateSupplierTeamPersonMutation.mutate({
                personId    :   editingPersonValues.personId,
                payload     :   values['person']
            });
        }
        else{
            handleCreateSupplierNewTeamPersonMutation.mutate({
                ...values['person'],
                'supplier'      :   supplierId
            });
        }
    }

    const handleDeleteSupplierTeamPerson = (person) => {
        handleDeleteSupplierProductMutation.mutate(person.id, {
            onSettled   :   () => {
                setTeamStateFieldsValue(person.id, 'isDeletionInProcess', false);
            }
        });
    }

    const showTeamPersonEditingForm = (person) => {
        setEditingPersonValues({
            'person[name]'          :   person.name,
            'person[position]'      :   person.position,
            'person[language]'      :   person.language,
            'person[email]'         :   person.email,
            'person[phoneNumber]'   :   person.phoneNumber,
            'personId'              :   person.id
        });
    }

    const renderModals = () => {
        const personFormInProcess = (
            handleCreateSupplierNewTeamPersonMutation.isFetching || handleCreateSupplierNewTeamPersonMutation.isLoading
        ) || (
            handleUpdateSupplierTeamPersonMutation.isFetching || handleUpdateSupplierTeamPersonMutation.isLoading
        );

        const languages = (Organization?.languages || []).map((lng) => {
            return {
                value   :   lng?.language?.id,
                label   :   t(lng.name),
                code    :   lng.language_code,
            }
        });

        return (
            <Modal isOpen={ showPersonModal } onClosed={() => {
                editingPersonValues && setEditingPersonValues(null);
            }} backdrop="static" keyboard={ false } size="xl" className="contact-person-creation-modal">
                
                <AvForm ref={ avFormRef } onValidSubmit={ handleCreationModalSubmit } className="needs-validation m-2" model={ editingPersonValues }>
                    <ModalHeader toggle={ togglePersonModal }>
                        {t(editingPersonValues?.personId ? "Edit Person" : "Add A New Person")}
                    </ModalHeader>

                    <ModalBody>
                        <Row className="mb-3">
                            <Col md="4" sm="12">
                                <Label for="new-team-person-name">
                                    {t("Name")}
                                </Label>
                                <AvField
                                    name="person[name]"
                                    type="text"
                                    errorMessage={t("This field cannot be blank")}
                                    className="form-control"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    id="new-team-person-name"
                                />
                            </Col>

                            <Col md="4" sm="12">
                                <Label for="new-team-person-position">
                                    {t("Position")}
                                </Label>
                                <AvField
                                    name="person[position]"
                                    type="text"
                                    errorMessage={t("This field cannot be blank")}
                                    className="form-control"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    id="new-team-person-position"
                                />
                            </Col>

                            <Col md="4" sm="12">
                                <Label for="new-team-person-language">
                                    {t("Language")}
                                </Label>
                                <Select
                                    placeholder={t("Select") + "..."}
                                    classNamePrefix="select2-selection"
                                    id='new-team-person-language'
                                    options={ languages }
                                    menuPortalTarget={ document.body }
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    onChange={ (e) => {
                                        const languageInput = avFormRef.current._inputs['person[language]'];
                                        languageInput.value = e.value;
                                        languageInput.validate();
                                    } }
                                    defaultValue={editingPersonValues && {
                                        value   :   editingPersonValues['person[language]'],
                                        label   :   getLanguageName(editingPersonValues['person[language]'])
                                    }}
                                />
                                <AvField
                                    name="person[language]"
                                    type="hidden" 
                                    errorMessage={t("This field cannot be blank")}
                                    validate={{
                                        required: { value: true },
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md="4" sm="12">
                                <Label for="new-team-person-email">
                                    {t("Email")}
                                </Label>
                                <AvField
                                    name="person[email]"
                                    type="email"
                                    className="form-control"
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: t("This field cannot be blank"),
                                        },
                                        email: { errorMessage: t("Your email is invalid") },
                                    }}
                                    id="new-team-person-email"
                                />
                            </Col>

                            <Col md="4" sm="12">
                                <Label for="new-team-person-phone-number">
                                    {t("Phone Number")}
                                </Label>

                                <PhoneInput
                                    inputClass="w-100"
                                    inputStyle={{
                                        height  :   '38px'
                                    }}
                                    country={ "us" }
                                    disableInitialCountryGuess={ false }
                                    disableCountryGuess={ false }
                                    onChange={ (e) => {
                                        const input = avFormRef.current?._inputs['person[phoneNumber]'];
                                        if(input){
                                            input.value = e;
                                            input.validate();
                                        }
                                    }}
                                    value={ editingPersonValues && editingPersonValues['person[phoneNumber]'] }
                                />

                                <AvField
                                    name={`person[phoneNumber]`}
                                    type="hidden"
                                    errorMessage={t("This field cannot be blank")}
                                    validate={{
                                        required: { value: true },
                                    }}
                                    id={`new-team-person-phone-number`}
                                />
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={ togglePersonModal } color="primary" type="button" outline>
                            { t('Cancel') }
                        </Button>

                        <Button onClick={ () => avFormRef.current.submit() } disabled={ personFormInProcess } color="primary" type="button" size="md">
                            {
                                personFormInProcess ? (
                                    <Spinner className="me-2" animation="border" variant="white" size="sm"/>
                                ) : (
                                    <>
                                        {
                                            editingPersonValues?.personId ? (
                                                <i className="ri-pencil-line align-middle me-2"></i>
                                            ) : (
                                                <i className="ri-add-line align-middle me-2"></i>
                                            )
                                        }
                                    </>
                                )
                                
                            }
                            { t(editingPersonValues?.personId ? 'Update Person' : 'Add Person') }
                        </Button>
                    </ModalFooter>
                </AvForm>
            </Modal>
        )
    }

    useEffect(() => {
        const list = (handleFetchSupplierTeamListQuery?.data?.teamManagers || []).map((teamItem) => {
            return {
                'id'            :   teamItem.id,
                'name'          :   teamItem.name,
                'email'         :   teamItem.email,
                'position'      :   teamItem.position,
                'phoneNumber'   :   teamItem.phoneNumber,
                'language'      :   teamItem.language,
                'state'         :   {
                    isDeletionInProcess :   false
                }
            }
        });

        setTeam(list);
    }, [ handleFetchSupplierTeamListQuery.data ]);

    useEffect(() => {
        if(editingPersonValues){
            setShowPersonModal(true);
        }
    }, [ editingPersonValues ]);

    return (
        <div>
            <div className="table-responsive products-list mb-4">
                <BootstrapTable
                    loading={ handleFetchSupplierTeamListQuery.isFetching || handleFetchSupplierTeamListQuery.isLoading }
                    bordered={ false }
                    keyField="id"
                    data={ team }
                    columns={ columns }
                    noDataIndication={() => <NoDataIndication />}
                    overlay={overlayFactory({
                        spinner: (
                            <Spinner 
                                animation="border" 
                                variant="primary"
                                size="md"
                            />
                        ),
                        text: `${t('Loading')}...`,
                    })}
                />
            </div>

            {
                !readOnly && (
                    <>
                        <div className="d-flex justify-content-end align-items-center">
                            <Button onClick={() => { setShowPersonModal(true) }} 
                                color="primary" 
                                outline>
                                    <i className="ri-add-line align-middle me-2 font-size-20"></i>
                                    {t('Add A New Person')}
                            </Button>
                        </div>
                        { renderModals() }
                    </>
                )
            }
        </div>
    );
}

const mapStatetoProps = (state) => {
	const { Organization } = state;

	return {
		Organization
	};
};

export default withNamespaces()(connect(
    mapStatetoProps,
    {}
)(ManagementTeam));