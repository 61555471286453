export const ACTION_HANDLE_START_FETCHING_NOTIFICATIONS = 'ACTION_HANDLE_START_FETCHING_NOTIFICATIONS';

export const ACTION_HANDLE_STOP_FETCHING_NOTIFICATIONS = 'ACTION_HANDLE_STOP_FETCHING_NOTIFICATIONS';

export const ACTION_MARK_NOTIFICATION_AS_READ = 'ACTION_MARK_NOTIFICATION_AS_READ';

export const ACTION_MARK_NOTIFICATION_AS_READ_IN_LIST = 'ACTION_MARK_NOTIFICATION_AS_READ_IN_LIST';

export const ACTION_HANDLE_UPDATE_NOTIFICATIONS_LIST = 'ACTION_HANDLE_UPDATE_NOTIFICATIONS_LIST';

export const ACTION_HANDLE_EMPTY_NOTIFICATIONS_LIST = 'ACTION_HANDLE_EMPTY_NOTIFICATIONS_LIST';

export const ACTION_MARK_ALL_NOTIFICATIONS_AS_READ = 'ACTION_MARK_ALL_NOTIFICATIONS_AS_READ';

export const ACTION_MARK_ALL_NOTIFICATIONS_AS_READ_IN_LIST = 'ACTION_MARK_ALL_NOTIFICATIONS_AS_READ_IN_LIST';

export const ACTION_HANDLE_LOAD_MORE_NOTIFICATIONS = 'ACTION_HANDLE_LOAD_MORE_NOTIFICATIONS';

export const ACTION_HANDLE_UPDATE_IS_LOADING = 'ACTION_HANDLE_UPDATE_IS_LOADING';